.remote-support, .download-firmware, .delete-user {
	.alert-button-group {
		button:first-child {
			border-right: 1px solid #C6C6C6;
		}

		button:last-child {
			border-left: 1px solid #C6C6C6;
		}

		button {
			border-top: 2px solid #C6C6C6;
		}
	}
}

.alert-wrapper {
	--background: #F0F0F0;
	font-size: 20px;
	box-shadow: 0px 0px 15px #00000033;

	.alert-button-group {
		padding: 0;

		button:first-child {
			// border-right: 1px solid #C6C6C6;
		}

		button:last-child {
			// border-left: 1px solid #C6C6C6;
		}

		button {
			padding: 1rem 0 1rem 0;
			margin: 0;
			width: 50%;

			// border-top: 1px solid #C6C6C6;
			.alert-button-inner {
				text-transform: none;
				justify-content: center;
				font-size: 20px;
				color: var(--ion-color-primary);
			}
		}
	}
}

.alert-button.text-roto-blue {
	span {
		color: #65B2E5 !important;
	}
}
