@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Black.otf');
	font-weight: 900;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Bold.otf');
	font-family: 700;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-BoldUpright.otf');
	font-family: 800;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Light.otf');
	font-weight: 300;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-LightItalic.otf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
  font-family: Titillium;
  src: url('../../assets/fonts/Titillium-LightUpright.otf');
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Regular.otf');
	font-weight: 400;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-RegularItalic.otf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Semibold.otf');
	font-weight: 600;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-SemiboldItalic.otf');
	font-weight: 600;
	font-style: italic;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-SemiboldUpright.otf');
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-Thin.otf');
	font-weight: 200;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-ThinItalic.otf');
	font-weight: 200;
	font-style: italic;
}

@font-face {
  font-family: Titillium;
	src: url('../../assets/fonts/Titillium-ThinUpright.otf');
}