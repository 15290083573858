/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* 
 Use the Tailwind reset instead of the one 
 from Ionic because it sets some required 
 defaults for Tailwind
*/
@import './styles/tailwind/base.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import './styles/tailwind/components.css';
/* Optional CSS utils that can be commented out */
@import './styles/tailwind/utilities.css';
@import './styles/rototilt/font.css';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
.remote-support .alert-button-group button:first-child, .download-firmware .alert-button-group button:first-child, .delete-user .alert-button-group button:first-child {
  border-right: 1px solid #C6C6C6;
}
.remote-support .alert-button-group button:last-child, .download-firmware .alert-button-group button:last-child, .delete-user .alert-button-group button:last-child {
  border-left: 1px solid #C6C6C6;
}
.remote-support .alert-button-group button, .download-firmware .alert-button-group button, .delete-user .alert-button-group button {
  border-top: 2px solid #C6C6C6;
}

.alert-wrapper {
  --background: #F0F0F0;
  font-size: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
.alert-wrapper .alert-button-group {
  padding: 0;
}
.alert-wrapper .alert-button-group button {
  padding: 1rem 0 1rem 0;
  margin: 0;
  width: 50%;
}
.alert-wrapper .alert-button-group button .alert-button-inner {
  text-transform: none;
  justify-content: center;
  font-size: 20px;
  color: var(--ion-color-primary);
}

.alert-button.text-roto-blue span {
  color: #65B2E5 !important;
}

.action-sheet-button.disabled {
  text-decoration: line-through;
  pointer-events: none;
}

.action-sheet-button.color-danger {
  --color: var(--ion-color-danger);
  --button-color: var(--ion-color-danger);
}

/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: rgb(170, 209, 249);
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: rgb(16, 108, 200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/*
bootstrap table theme
*/
.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 13px;
}
.ngx-datatable.bootstrap .datatable-header {
  height: unset !important;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
  border-bottom: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.05);
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: #fff;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem;
  text-align: left;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-body .empty-row {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
}
.ngx-datatable.bootstrap .datatable-footer {
  background: #424242;
  color: #ededed;
  margin-top: -1px;
}
.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: bold;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}
.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

ion-content#container {
  --ion-background-color: #e6e6e6;
}

:root {
  --ion-font-family: "Titillium", sans-serif;
}

body {
  background-color: #f0f0f0;
}

ion-backdrop {
  --backdrop-opacity: 0.8;
  --background-color: #ffffff;
}

datatable-scroller {
  width: 100% !important;
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .modal-wrapper {
  width: 90vw;
  max-width: 400px;
  padding: 8px;
}
ion-modal.auto-height .modal-wrapper .ion-page {
  position: relative;
  contain: content;
  max-height: 90vh;
}
ion-modal.auto-height .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}

ion-modal.full-width {
  --height: auto;
  --width: 60vw;
}
ion-modal.full-width .modal-wrapper {
  width: 60vw; /* Use full available width */
  max-width: 400px; /* But limit it to 400px */
  margin: 0 auto; /* Centers the modal horizontally */
  padding: 8px;
}
ion-modal.full-width .modal-wrapper .ion-page {
  position: relative;
  contain: content;
  max-height: 90vh;
  max-width: 400px;
}
ion-modal.full-width .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}

ion-modal.full-height {
  --height: 80vh;
}
ion-modal.full-height .modal-wrapper {
  width: 90vw;
  max-width: 400px;
  padding: 8px;
}
ion-modal.full-height .modal-wrapper .ion-page {
  position: relative;
  contain: content;
}
ion-modal.full-height .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}

ion-modal::part(backdrop) {
  background: rgb(0, 0, 0); /* Adjust opacity as needed */
}

#SparePartModalComponent .modal-wrapper {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.ngx-slider .ngx-slider-pointer:after {
  height: 0px !important;
}

.ngx-slider .ngx-slider-pointer {
  height: 0px !important;
}

.ngx-slider .ngx-slider-pointer:after {
  height: 0px !important;
}

.ngx-slider .ngx-slider-bar {
  height: 20px !important;
  background: #ffffff !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none !important;
  background: white !important;
  top: -6px !important;
  z-index: 10 !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  display: none !important;
  top: -6px !important;
  z-index: 10 !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  opacity: 1 !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none !important;
  top: -6px !important;
  z-index: 10 !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  opacity: 1 !important;
}

.ngx-slider {
  margin: -5px 0 15px !important;
}

.ngx-slider[disabled] .ngx-slider-selection {
  background: #f8ae00 !important;
}

.maxSlider .ngx-slider-bar {
  height: 6px !important;
  background: #ebebeb !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  display: none !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none !important;
}

.maxSlider {
  padding: 0 !important;
  display: block !important;
  padding-top: 10px !important;
  margin-bottom: 0 !important;
  padding-bottom: 5px !important;
  background: #ebebeb !important;
}

.maxSliderPointer .ngx-slider-pointer {
  width: 8px !important;
  height: 30px !important;
  top: auto !important;
  /* to remove the default positioning */
  bottom: 0 !important;
  background-color: #333 !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.maxSliderInput[disabled] .ngx-slider-selection {
  background: #ff8f1c !important;
}

.maxSliderOutput[disabled] .ngx-slider-selection {
  background: #ff8f1c !important;
}

.maxSlider .ngx-slider-bar-wrapper {
  height: 6px !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-right: 0;
  margin-right: 0;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-combined {
  display: none;
}

.current-position-slider span span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
  background: black !important;
}

.current-position-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

.current-position-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  visibility: hidden !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value,
.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  visibility: hidden !important;
}

.deadband-slider {
  padding: 0 !important;
  display: block !important;
  padding-top: 5px !important;
  margin-bottom: 0 !important;
  padding-bottom: 6px !important;
}

.deadband-slider .ngx-slider-bar-wrapper {
  height: 6px !important;
}

.deadband-slider .ngx-slider-bar {
  height: 6px !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-full-bar {
  visibility: visible !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:before,
.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:after {
  display: block !important;
  height: 6px !important;
  content: "" !important;
  background: #dc1215 !important;
  right: 100% !important;
  top: 0px !important;
  left: -1000px !important;
  position: absolute !important;
  z-index: 1000000 !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:after {
  background: #f8ae00 !important;
  left: 100% !important;
  right: -1000px !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
  background: #40bf40 !important;
}

.deadband-slider {
  overflow: hidden;
}

.armed-timer-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

.feeder-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

ngx-slider > span,
.ngx-slider > span > * {
  transition-duration: 0.1s !important;
}

.custom-interface .popover-content {
  overflow: unset;
}

.custom-popover .popover-content {
  overflow-y: scroll;
}

.popover-content ion-select-popover {
  overflow-y: scroll !important;
}

.search-bar-start input {
  padding: 1rem !important;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-moz-outer-spin-button,
input::-moz-inner-spin-button {
  -moz-appearance: none;
  margin: 0;
}

.new-company {
  border-top: 2px solid black !important;
}

.datatable-row-center.datatable-row-group {
  align-items: center;
}

.ion-input-company > input,
.ion-input-date > input {
  opacity: 1 !important;
  padding-left: 0 !important;
}

.ion-input-company .ion-input-date {
  padding-left: 0 !important;
}

.datatable-row-center.datatable-row-group {
  width: 100% !important;
}

.auth-label {
  color: #576d7c;
  font-size: 30px;
  font-weight: 600;
}

.delete-alert-icon {
  color: #db0f0f;
  font-size: 42px;
}

.text-roto-blue {
  color: #65b2e5 !important;
}

.text-roto-green {
  color: #576d7c !important;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-14pt {
  font-size: 14px;
}

.text-16pt {
  font-size: 16px;
}

.text-18pt {
  font-size: 18px;
}

.text-20pt {
  font-size: 20px;
}

.text-22pt {
  font-size: 22px;
}

.text-26pt {
  font-size: 26px;
}

.text-30pt {
  font-size: 30px;
}

@media (max-width: 320px) {
  .text-18pt {
    font-size: 14px;
  }
  .text-20pt {
    font-size: 16px;
  }
  .text-22pt {
    font-size: 18px;
  }
  .text-30pt {
    font-size: 26px;
  }
  .text-26pt {
    font-size: 22px;
  }
}
.div-dash {
  border: 1px solid #e8e8e8;
  height: 1px;
  width: 49%;
}

.placeholder-error {
  color: #c83026;
  font-size: 20px;
  font-weight: 600;
}

.error-input ion-item {
  background: rgba(220, 18, 21, 0.18) !important;
  --background: rgba(220, 18, 21, 0.18) !important;
  --border-color: gray !important;
}

.error-input ion-input {
  background: transparent !important;
  --background: transparent !important;
}

.border-bot-gray {
  border-bottom: 1px solid #e8e8e8;
}

.text-transform-none {
  text-transform: none !important;
}

#profile-settings-page ion-toggle {
  --background-checked: #f8ae00;
}

.fat-alert-button {
  text-transform: none !important;
  font-weight: 600 !important;
}

.no-button-borders {
  border: 0 !important;
}

.full-height {
  height: 100%;
}
.full-height .alert-message {
  max-height: 100%;
}

.border-r-8 {
  --border-radius: 8px !important;
  border-radius: 8px !important;
  --border-width: 0 0 0 10px;
  --border-style: solid;
}

ion-toast {
  opacity: 0.9;
}

.border-toast-black {
  --border-color: #000000;
}

.border-toast-error {
  --border-color: #dc1215;
}

.border-toast-info {
  --border-color: #65b2e5;
}

.border-toast-warning {
  --border-color: #ff8f1c;
}

.border-top-bot-black {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

ion-toast::part(container) {
  background: #576d7c 0% 0% no-repeat padding-box;
  --background: #576d7c 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

ion-toast::part(header) {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
}

ion-toast::part(message) {
  font-size: 16px;
  padding-top: 0.5rem;
  font-weight: 600;
}

ion-toast::part(button) {
  color: #ffffff;
}

.secondary-header {
  color: rgba(255, 255, 255, 0.5);
  background: #2b3035;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
}

.icon-back {
  width: 32px;
  padding-right: 0.5rem;
  opacity: 0.5;
}

#background-content {
  background-size: cover;
}

.loaderClass {
  transform: scale(5);
}
.loaderClass .loading-wrapper {
  background: transparent !important;
  box-shadow: unset !important;
}

ion-select-popover ion-label {
  padding-right: 1rem;
}

.popover-content {
  width: auto !important;
}

body.barcode-scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  background: transparent;
}
body.barcode-scanner-active ion-app ion-content .ion-page,
body.barcode-scanner-active ion-app app-main-header,
body.barcode-scanner-active ion-app ion-modal,
body.barcode-scanner-active ion-app app-main-footer {
  display: none;
  position: relative;
}

#cancel-qr-container {
  width: 100%;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

ion-item {
  --highlight-color-valid: #576d7c;
}

.delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-pwd-spinner {
  width: 28px;
  height: 28px;
}