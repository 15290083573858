/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* 
 Use the Tailwind reset instead of the one 
 from Ionic because it sets some required 
 defaults for Tailwind
*/
@import './styles/tailwind/base.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

@import './styles/tailwind/components.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import './styles/tailwind/utilities.css';

@import './styles/rototilt/font.css';
@import './styles/rototilt/alerts';
@import './styles/rototilt/action-sheet';

@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.scss';
// @import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

ion-content#container {
  --ion-background-color: #e6e6e6;
}

:root {
  --ion-font-family: 'Titillium', sans-serif;
}

body {
  background-color: #f0f0f0;
}

ion-backdrop {
  --backdrop-opacity: 0.8;
  --background-color: #ffffff;
}

datatable-scroller {
  width: 100% !important;
}

ion-modal.auto-height {
  --height: auto;

  .modal-wrapper {
    width: 90vw;
    max-width: 400px;
    padding: 8px;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.full-width {
  --height: auto;
  --width: 60vw;

  .modal-wrapper {
    width: 60vw; /* Use full available width */
    max-width: 400px; /* But limit it to 400px */
    margin: 0 auto; /* Centers the modal horizontally */
    padding: 8px;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      max-width: 400px;
      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.full-height {
  --height: 80vh;

  .modal-wrapper {
    width: 90vw;
    max-width: 400px;
    padding: 8px;

    .ion-page {
      position: relative;
      contain: content;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal::part(backdrop) {
  background: rgba(0, 0, 0, 1); /* Adjust opacity as needed */
}

#SparePartModalComponent .modal-wrapper {
  box-shadow: 0px 0px 15px #00000033;
}

.ngx-slider .ngx-slider-pointer:after {
  height: 0px !important;
}

.ngx-slider .ngx-slider-pointer {
  height: 0px !important;
}

.ngx-slider .ngx-slider-pointer:after {
  height: 0px !important;
}

.ngx-slider .ngx-slider-bar {
  height: 20px !important;
  background: #ffffff !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none !important;
  background: white !important;
  top: -6px !important;
  z-index: 10 !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  display: none !important;
  top: -6px !important;
  z-index: 10 !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  opacity: 1 !important;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none !important;
  top: -6px !important;
  z-index: 10 !important;
  left: 50% !important;
  transform: translate(-50%, 0);
  opacity: 1 !important;
}

.ngx-slider {
  margin: -5px 0 15px !important;
}

.ngx-slider[disabled] .ngx-slider-selection {
  background: #f8ae00 !important;
}

//MAXSLIDER
.maxSlider .ngx-slider-bar {
  height: 6px !important;
  background: #ebebeb !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  display: none !important;
}

.maxSlider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none !important;
}

.maxSlider {
  padding: 0 !important;
  display: block !important;
  padding-top: 10px !important;
  margin-bottom: 0 !important;
  padding-bottom: 5px !important;
  background: #ebebeb !important;
}

.maxSliderPointer .ngx-slider-pointer {
  width: 8px !important;
  height: 30px !important;
  top: auto !important;
  /* to remove the default positioning */
  bottom: 0 !important;
  background-color: #333 !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.maxSliderInput[disabled] .ngx-slider-selection {
  background: #ff8f1c !important;
}

.maxSliderOutput[disabled] .ngx-slider-selection {
  background: #ff8f1c !important;
}

.maxSlider .ngx-slider-bar-wrapper {
  height: 6px !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-right: 0;
  margin-right: 0;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-combined {
  display: none;
}

.current-position-slider span span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
  background: black !important;
}

.current-position-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

.current-position-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  visibility: hidden !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value,
.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-high {
  visibility: hidden !important;
}

.deadband-slider {
  padding: 0 !important;
  display: block !important;
  padding-top: 5px !important;
  margin-bottom: 0 !important;
  padding-bottom: 6px !important;
  // background: #ebebeb !important;
}

.deadband-slider .ngx-slider-bar-wrapper {
  height: 6px !important;
}

.deadband-slider .ngx-slider-bar {
  height: 6px !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
  display: none;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit {
  display: none;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-full-bar {
  visibility: visible !important;
}

// .deadband-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-full-bar span {
//   background: #576d7c !important;
// }

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:before,
.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:after {
  display: block !important;
  height: 6px !important;
  content: '' !important;
  background: #dc1215 !important;
  right: 100% !important;
  top: 0px !important;
  left: -1000px !important;
  position: absolute !important;
  z-index: 1000000 !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection:after {
  background: #f8ae00 !important;
  left: 100% !important;
  right: -1000px !important;
}

.deadband-slider span.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
  background: #40bf40 !important;
}

.deadband-slider {
  overflow: hidden;
}

.armed-timer-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

.feeder-slider span.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
  visibility: visible !important;
}

ngx-slider > span,
.ngx-slider > span > * {
  transition-duration: 0.1s !important;
}

.custom-interface .popover-content {
  overflow: unset;
}

.custom-popover .popover-content {
  overflow-y: scroll;
}

.popover-content ion-select-popover {
  overflow-y: scroll !important;
}

.search-bar-start input {
  padding: 1rem !important;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-moz-outer-spin-button,
input::-moz-inner-spin-button {
  -moz-appearance: none;
  margin: 0;
}

.new-company {
  border-top: 2px solid black !important;
}

.datatable-row-center.datatable-row-group {
  align-items: center;
}

.ion-input-company > input,
.ion-input-date > input {
  opacity: 1 !important;
  padding-left: 0 !important;
}

.ion-input-company .ion-input-date {
  padding-left: 0 !important;
}

.datatable-row-center.datatable-row-group {
  width: 100% !important;
}

.auth-label {
  color: #576d7c;
  font-size: 30px;
  font-weight: 600;
}

.delete-alert-icon {
  color: #db0f0f;
  font-size: 42px;
}

.text-roto-blue {
  color: #65b2e5 !important;
}

.text-roto-green {
  color: #576d7c !important;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-14pt {
  font-size: 14px;
}

.text-16pt {
  font-size: 16px;
}

.text-18pt {
  font-size: 18px;
}

.text-20pt {
  font-size: 20px;
}

.text-22pt {
  font-size: 22px;
}

.text-26pt {
  font-size: 26px;
}

.text-30pt {
  font-size: 30px;
}

@media (max-width: 320px) {
  .text-18pt {
    font-size: 14px;
  }

  .text-20pt {
    font-size: 16px;
  }

  .text-22pt {
    font-size: 18px;
  }

  .text-30pt {
    font-size: 26px;
  }

  .text-26pt {
    font-size: 22px;
  }
}

.div-dash {
  border: 1px solid #e8e8e8;
  height: 1px;
  width: 49%;
}

.placeholder-error {
  color: #c83026;
  font-size: 20px;
  font-weight: 600;
}

.error-input ion-item {
  background: rgba(220, 18, 21, 0.18) !important;
  --background: rgba(220, 18, 21, 0.18) !important;
  --border-color: gray !important;
}

.error-input ion-input {
  background: transparent !important;
  --background: transparent !important;
}

.border-bot-gray {
  border-bottom: 1px solid #e8e8e8;
}

.text-transform-none {
  text-transform: none !important;
}

#profile-settings-page ion-toggle {
  --background-checked: #f8ae00;
}

.fat-alert-button {
  text-transform: none !important;
  font-weight: 600 !important;
}

.no-button-borders {
  border: 0 !important;
}

.full-height {
  height: 100%;

  .alert-message {
    max-height: 100%;
  }
}

.border-r-8 {
  --border-radius: 8px !important;
  border-radius: 8px !important;
  --border-width: 0 0 0 10px;
  --border-style: solid;
}

ion-toast {
  opacity: 0.9;
}

.border-toast-black {
  --border-color: #000000;
}

.border-toast-error {
  --border-color: #dc1215;
}

.border-toast-info {
  --border-color: #65b2e5;
}

.border-toast-warning {
  --border-color: #ff8f1c;
}

.border-top-bot-black {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

ion-toast::part(container) {
  background: #576d7c 0% 0% no-repeat padding-box;
  --background: #576d7c 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000033;
  color: #ffffff;
}

ion-toast::part(header) {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
}

ion-toast::part(message) {
  font-size: 16px;
  padding-top: 0.5rem;
  font-weight: 600;
  // text-transform: capitalize;
}

ion-toast::part(button) {
  color: #ffffff;
}

.secondary-header {
  color: rgba(255, 255, 255, 0.5);
  background: #2b3035;
  box-shadow: 0px 0px 15px #00000033;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
}

.icon-back {
  width: 32px;
  padding-right: 0.5rem;
  opacity: 0.5;
}

#background-content {
  background-size: cover;
}

.loaderClass {
  .loading-wrapper {
    background: transparent !important;
    box-shadow: unset !important;
  }
  transform: scale(5);
}

ion-select-popover ion-label {
  padding-right: 1rem;
}

.popover-content {
  width: auto !important;
}

body.barcode-scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  background: transparent;

  ion-app {
    ion-content .ion-page,
    app-main-header,
    ion-modal,
    app-main-footer {
      display: none;
      position: relative;
    }
  }
}

#cancel-qr-container {
  width: 100%;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

ion-item {
  --highlight-color-valid: #576d7c;
}

.delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-pwd-spinner {
  width: 28px;
  height: 28px;
}
