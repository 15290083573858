.space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse))
}

.space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse))
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse))
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse))
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse))
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--space-y-reverse))
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--space-y-reverse))
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2rem * var(--space-y-reverse))
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--space-y-reverse))
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(3rem * var(--space-y-reverse))
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4rem * var(--space-y-reverse))
}

.space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(4rem * var(--space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
}

.space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(5rem * var(--space-y-reverse))
}

.space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(5rem * var(--space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(6rem * var(--space-y-reverse))
}

.space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(6rem * var(--space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
}

.space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(8rem * var(--space-y-reverse))
}

.space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8rem * var(--space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
}

.space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10rem * var(--space-y-reverse))
}

.space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10rem * var(--space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
}

.space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(12rem * var(--space-y-reverse))
}

.space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(12rem * var(--space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
}

.space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(14rem * var(--space-y-reverse))
}

.space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(14rem * var(--space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
}

.space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(16rem * var(--space-y-reverse))
}

.space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16rem * var(--space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
}

.space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1px * var(--space-y-reverse))
}

.space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1px * var(--space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--space-y-reverse))
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--space-y-reverse))
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--space-y-reverse))
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1rem * var(--space-y-reverse))
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1rem * var(--space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--space-y-reverse))
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--space-y-reverse))
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2rem * var(--space-y-reverse))
}

.-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2rem * var(--space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--space-y-reverse))
}

.-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-3rem * var(--space-y-reverse))
}

.-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3rem * var(--space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-4rem * var(--space-y-reverse))
}

.-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-4rem * var(--space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-5rem * var(--space-y-reverse))
}

.-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-5rem * var(--space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-6rem * var(--space-y-reverse))
}

.-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-6rem * var(--space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-8rem * var(--space-y-reverse))
}

.-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-8rem * var(--space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10rem * var(--space-y-reverse))
}

.-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10rem * var(--space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-12rem * var(--space-y-reverse))
}

.-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-12rem * var(--space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-14rem * var(--space-y-reverse))
}

.-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-14rem * var(--space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-16rem * var(--space-y-reverse))
}

.-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16rem * var(--space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
}

.-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1px * var(--space-y-reverse))
}

.-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1px * var(--space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(0px * var(--divide-y-reverse))
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(2px * var(--divide-y-reverse))
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(4px * var(--divide-y-reverse))
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(8px * var(--divide-y-reverse))
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse))
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1
}

.divide-primary > :not(template) ~ :not(template) {
  border-color: var(--ion-color-primary)
}

.divide-primary-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-primary-shade)
}

.divide-primary-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-primary-tint)
}

.divide-secondary > :not(template) ~ :not(template) {
  border-color: var(--ion-color-secondary)
}

.divide-secondary-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-secondary-shade)
}

.divide-secondary-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-secondary-tint)
}

.divide-tertiary > :not(template) ~ :not(template) {
  border-color: var(--ion-color-tertiary)
}

.divide-tertiary-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-tertiary-shade)
}

.divide-tertiary-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-tertiary-tint)
}

.divide-light > :not(template) ~ :not(template) {
  border-color: var(--ion-color-light)
}

.divide-light-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-light-shade)
}

.divide-light-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-light-tint)
}

.divide-medium > :not(template) ~ :not(template) {
  border-color: var(--ion-color-medium)
}

.divide-medium-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-medium-shade)
}

.divide-medium-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-medium-tint)
}

.divide-dark > :not(template) ~ :not(template) {
  border-color: var(--ion-color-dark)
}

.divide-dark-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-dark-shade)
}

.divide-dark-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-dark-tint)
}

.divide-success > :not(template) ~ :not(template) {
  border-color: var(--ion-color-success)
}

.divide-success-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-success-shade)
}

.divide-success-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-success-tint)
}

.divide-warning > :not(template) ~ :not(template) {
  border-color: var(--ion-color-warning)
}

.divide-warning-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-warning-shade)
}

.divide-warning-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-warning-tint)
}

.divide-danger > :not(template) ~ :not(template) {
  border-color: var(--ion-color-danger)
}

.divide-danger-shade > :not(template) ~ :not(template) {
  border-color: var(--ion-color-danger-shade)
}

.divide-danger-tint > :not(template) ~ :not(template) {
  border-color: var(--ion-color-danger-tint)
}

.divide-step-50 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-50)
}

.divide-step-100 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-100)
}

.divide-step-150 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-150)
}

.divide-step-200 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-200)
}

.divide-step-250 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-250)
}

.divide-step-300 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-300)
}

.divide-step-350 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-350)
}

.divide-step-400 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-400)
}

.divide-step-450 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-450)
}

.divide-step-500 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-500)
}

.divide-step-550 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-550)
}

.divide-step-600 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-600)
}

.divide-step-650 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-650)
}

.divide-step-700 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-700)
}

.divide-step-750 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-750)
}

.divide-step-800 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-800)
}

.divide-step-850 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-850)
}

.divide-step-900 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-900)
}

.divide-step-950 > :not(template) ~ :not(template) {
  border-color: var(--ion-color-step-950)
}

.divide-solid > :not(template) ~ :not(template) {
  border-style: solid
}

.divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed
}

.divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted
}

.divide-double > :not(template) ~ :not(template) {
  border-style: double
}

.divide-none > :not(template) ~ :not(template) {
  border-style: none
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.bg-fixed {
  background-attachment: fixed
}

.bg-local {
  background-attachment: local
}

.bg-scroll {
  background-attachment: scroll
}

.bg-clip-border {
  background-clip: border-box
}

.bg-clip-padding {
  background-clip: padding-box
}

.bg-clip-content {
  background-clip: content-box
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text
}

.bg-primary {
  background-color: var(--ion-color-primary)
}

.bg-primary-shade {
  background-color: var(--ion-color-primary-shade)
}

.bg-primary-tint {
  background-color: var(--ion-color-primary-tint)
}

.bg-secondary {
  background-color: var(--ion-color-secondary)
}

.bg-secondary-shade {
  background-color: var(--ion-color-secondary-shade)
}

.bg-secondary-tint {
  background-color: var(--ion-color-secondary-tint)
}

.bg-tertiary {
  background-color: var(--ion-color-tertiary)
}

.bg-tertiary-shade {
  background-color: var(--ion-color-tertiary-shade)
}

.bg-tertiary-tint {
  background-color: var(--ion-color-tertiary-tint)
}

.bg-light {
  background-color: var(--ion-color-light)
}

.bg-light-shade {
  background-color: var(--ion-color-light-shade)
}

.bg-light-tint {
  background-color: var(--ion-color-light-tint)
}

.bg-medium {
  background-color: var(--ion-color-medium)
}

.bg-medium-shade {
  background-color: var(--ion-color-medium-shade)
}

.bg-medium-tint {
  background-color: var(--ion-color-medium-tint)
}

.bg-dark {
  background-color: var(--ion-color-dark)
}

.bg-dark-shade {
  background-color: var(--ion-color-dark-shade)
}

.bg-dark-tint {
  background-color: var(--ion-color-dark-tint)
}

.bg-success {
  background-color: var(--ion-color-success)
}

.bg-success-shade {
  background-color: var(--ion-color-success-shade)
}

.bg-success-tint {
  background-color: var(--ion-color-success-tint)
}

.bg-warning {
  background-color: var(--ion-color-warning)
}

.bg-warning-shade {
  background-color: var(--ion-color-warning-shade)
}

.bg-warning-tint {
  background-color: var(--ion-color-warning-tint)
}

.bg-danger {
  background-color: var(--ion-color-danger)
}

.bg-danger-shade {
  background-color: var(--ion-color-danger-shade)
}

.bg-danger-tint {
  background-color: var(--ion-color-danger-tint)
}

.bg-step-50 {
  background-color: var(--ion-color-step-50)
}

.bg-step-100 {
  background-color: var(--ion-color-step-100)
}

.bg-step-150 {
  background-color: var(--ion-color-step-150)
}

.bg-step-200 {
  background-color: var(--ion-color-step-200)
}

.bg-step-250 {
  background-color: var(--ion-color-step-250)
}

.bg-step-300 {
  background-color: var(--ion-color-step-300)
}

.bg-step-350 {
  background-color: var(--ion-color-step-350)
}

.bg-step-400 {
  background-color: var(--ion-color-step-400)
}

.bg-step-450 {
  background-color: var(--ion-color-step-450)
}

.bg-step-500 {
  background-color: var(--ion-color-step-500)
}

.bg-step-550 {
  background-color: var(--ion-color-step-550)
}

.bg-step-600 {
  background-color: var(--ion-color-step-600)
}

.bg-step-650 {
  background-color: var(--ion-color-step-650)
}

.bg-step-700 {
  background-color: var(--ion-color-step-700)
}

.bg-step-750 {
  background-color: var(--ion-color-step-750)
}

.bg-step-800 {
  background-color: var(--ion-color-step-800)
}

.bg-step-850 {
  background-color: var(--ion-color-step-850)
}

.bg-step-900 {
  background-color: var(--ion-color-step-900)
}

.bg-step-950 {
  background-color: var(--ion-color-step-950)
}

.hover\:bg-primary:hover {
  background-color: var(--ion-color-primary)
}

.hover\:bg-primary-shade:hover {
  background-color: var(--ion-color-primary-shade)
}

.hover\:bg-primary-tint:hover {
  background-color: var(--ion-color-primary-tint)
}

.hover\:bg-secondary:hover {
  background-color: var(--ion-color-secondary)
}

.hover\:bg-secondary-shade:hover {
  background-color: var(--ion-color-secondary-shade)
}

.hover\:bg-secondary-tint:hover {
  background-color: var(--ion-color-secondary-tint)
}

.hover\:bg-tertiary:hover {
  background-color: var(--ion-color-tertiary)
}

.hover\:bg-tertiary-shade:hover {
  background-color: var(--ion-color-tertiary-shade)
}

.hover\:bg-tertiary-tint:hover {
  background-color: var(--ion-color-tertiary-tint)
}

.hover\:bg-light:hover {
  background-color: var(--ion-color-light)
}

.hover\:bg-light-shade:hover {
  background-color: var(--ion-color-light-shade)
}

.hover\:bg-light-tint:hover {
  background-color: var(--ion-color-light-tint)
}

.hover\:bg-medium:hover {
  background-color: var(--ion-color-medium)
}

.hover\:bg-medium-shade:hover {
  background-color: var(--ion-color-medium-shade)
}

.hover\:bg-medium-tint:hover {
  background-color: var(--ion-color-medium-tint)
}

.hover\:bg-dark:hover {
  background-color: var(--ion-color-dark)
}

.hover\:bg-dark-shade:hover {
  background-color: var(--ion-color-dark-shade)
}

.hover\:bg-dark-tint:hover {
  background-color: var(--ion-color-dark-tint)
}

.hover\:bg-success:hover {
  background-color: var(--ion-color-success)
}

.hover\:bg-success-shade:hover {
  background-color: var(--ion-color-success-shade)
}

.hover\:bg-success-tint:hover {
  background-color: var(--ion-color-success-tint)
}

.hover\:bg-warning:hover {
  background-color: var(--ion-color-warning)
}

.hover\:bg-warning-shade:hover {
  background-color: var(--ion-color-warning-shade)
}

.hover\:bg-warning-tint:hover {
  background-color: var(--ion-color-warning-tint)
}

.hover\:bg-danger:hover {
  background-color: var(--ion-color-danger)
}

.hover\:bg-danger-shade:hover {
  background-color: var(--ion-color-danger-shade)
}

.hover\:bg-danger-tint:hover {
  background-color: var(--ion-color-danger-tint)
}

.hover\:bg-step-50:hover {
  background-color: var(--ion-color-step-50)
}

.hover\:bg-step-100:hover {
  background-color: var(--ion-color-step-100)
}

.hover\:bg-step-150:hover {
  background-color: var(--ion-color-step-150)
}

.hover\:bg-step-200:hover {
  background-color: var(--ion-color-step-200)
}

.hover\:bg-step-250:hover {
  background-color: var(--ion-color-step-250)
}

.hover\:bg-step-300:hover {
  background-color: var(--ion-color-step-300)
}

.hover\:bg-step-350:hover {
  background-color: var(--ion-color-step-350)
}

.hover\:bg-step-400:hover {
  background-color: var(--ion-color-step-400)
}

.hover\:bg-step-450:hover {
  background-color: var(--ion-color-step-450)
}

.hover\:bg-step-500:hover {
  background-color: var(--ion-color-step-500)
}

.hover\:bg-step-550:hover {
  background-color: var(--ion-color-step-550)
}

.hover\:bg-step-600:hover {
  background-color: var(--ion-color-step-600)
}

.hover\:bg-step-650:hover {
  background-color: var(--ion-color-step-650)
}

.hover\:bg-step-700:hover {
  background-color: var(--ion-color-step-700)
}

.hover\:bg-step-750:hover {
  background-color: var(--ion-color-step-750)
}

.hover\:bg-step-800:hover {
  background-color: var(--ion-color-step-800)
}

.hover\:bg-step-850:hover {
  background-color: var(--ion-color-step-850)
}

.hover\:bg-step-900:hover {
  background-color: var(--ion-color-step-900)
}

.hover\:bg-step-950:hover {
  background-color: var(--ion-color-step-950)
}

.focus\:bg-primary:focus {
  background-color: var(--ion-color-primary)
}

.focus\:bg-primary-shade:focus {
  background-color: var(--ion-color-primary-shade)
}

.focus\:bg-primary-tint:focus {
  background-color: var(--ion-color-primary-tint)
}

.focus\:bg-secondary:focus {
  background-color: var(--ion-color-secondary)
}

.focus\:bg-secondary-shade:focus {
  background-color: var(--ion-color-secondary-shade)
}

.focus\:bg-secondary-tint:focus {
  background-color: var(--ion-color-secondary-tint)
}

.focus\:bg-tertiary:focus {
  background-color: var(--ion-color-tertiary)
}

.focus\:bg-tertiary-shade:focus {
  background-color: var(--ion-color-tertiary-shade)
}

.focus\:bg-tertiary-tint:focus {
  background-color: var(--ion-color-tertiary-tint)
}

.focus\:bg-light:focus {
  background-color: var(--ion-color-light)
}

.focus\:bg-light-shade:focus {
  background-color: var(--ion-color-light-shade)
}

.focus\:bg-light-tint:focus {
  background-color: var(--ion-color-light-tint)
}

.focus\:bg-medium:focus {
  background-color: var(--ion-color-medium)
}

.focus\:bg-medium-shade:focus {
  background-color: var(--ion-color-medium-shade)
}

.focus\:bg-medium-tint:focus {
  background-color: var(--ion-color-medium-tint)
}

.focus\:bg-dark:focus {
  background-color: var(--ion-color-dark)
}

.focus\:bg-dark-shade:focus {
  background-color: var(--ion-color-dark-shade)
}

.focus\:bg-dark-tint:focus {
  background-color: var(--ion-color-dark-tint)
}

.focus\:bg-success:focus {
  background-color: var(--ion-color-success)
}

.focus\:bg-success-shade:focus {
  background-color: var(--ion-color-success-shade)
}

.focus\:bg-success-tint:focus {
  background-color: var(--ion-color-success-tint)
}

.focus\:bg-warning:focus {
  background-color: var(--ion-color-warning)
}

.focus\:bg-warning-shade:focus {
  background-color: var(--ion-color-warning-shade)
}

.focus\:bg-warning-tint:focus {
  background-color: var(--ion-color-warning-tint)
}

.focus\:bg-danger:focus {
  background-color: var(--ion-color-danger)
}

.focus\:bg-danger-shade:focus {
  background-color: var(--ion-color-danger-shade)
}

.focus\:bg-danger-tint:focus {
  background-color: var(--ion-color-danger-tint)
}

.focus\:bg-step-50:focus {
  background-color: var(--ion-color-step-50)
}

.focus\:bg-step-100:focus {
  background-color: var(--ion-color-step-100)
}

.focus\:bg-step-150:focus {
  background-color: var(--ion-color-step-150)
}

.focus\:bg-step-200:focus {
  background-color: var(--ion-color-step-200)
}

.focus\:bg-step-250:focus {
  background-color: var(--ion-color-step-250)
}

.focus\:bg-step-300:focus {
  background-color: var(--ion-color-step-300)
}

.focus\:bg-step-350:focus {
  background-color: var(--ion-color-step-350)
}

.focus\:bg-step-400:focus {
  background-color: var(--ion-color-step-400)
}

.focus\:bg-step-450:focus {
  background-color: var(--ion-color-step-450)
}

.focus\:bg-step-500:focus {
  background-color: var(--ion-color-step-500)
}

.focus\:bg-step-550:focus {
  background-color: var(--ion-color-step-550)
}

.focus\:bg-step-600:focus {
  background-color: var(--ion-color-step-600)
}

.focus\:bg-step-650:focus {
  background-color: var(--ion-color-step-650)
}

.focus\:bg-step-700:focus {
  background-color: var(--ion-color-step-700)
}

.focus\:bg-step-750:focus {
  background-color: var(--ion-color-step-750)
}

.focus\:bg-step-800:focus {
  background-color: var(--ion-color-step-800)
}

.focus\:bg-step-850:focus {
  background-color: var(--ion-color-step-850)
}

.focus\:bg-step-900:focus {
  background-color: var(--ion-color-step-900)
}

.focus\:bg-step-950:focus {
  background-color: var(--ion-color-step-950)
}

.bg-none {
  background-image: none
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops))
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops))
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops))
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops))
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops))
}

.from-primary {
  --gradient-from-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-primary-shade {
  --gradient-from-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-primary-tint {
  --gradient-from-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-secondary {
  --gradient-from-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-secondary-shade {
  --gradient-from-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-secondary-tint {
  --gradient-from-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-tertiary {
  --gradient-from-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-tertiary-shade {
  --gradient-from-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-tertiary-tint {
  --gradient-from-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-light {
  --gradient-from-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-light-shade {
  --gradient-from-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-light-tint {
  --gradient-from-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-medium {
  --gradient-from-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-medium-shade {
  --gradient-from-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-medium-tint {
  --gradient-from-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-dark {
  --gradient-from-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-dark-shade {
  --gradient-from-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-dark-tint {
  --gradient-from-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-success {
  --gradient-from-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-success-shade {
  --gradient-from-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-success-tint {
  --gradient-from-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-warning {
  --gradient-from-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-warning-shade {
  --gradient-from-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-warning-tint {
  --gradient-from-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-danger {
  --gradient-from-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-danger-shade {
  --gradient-from-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-danger-tint {
  --gradient-from-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-50 {
  --gradient-from-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-100 {
  --gradient-from-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-150 {
  --gradient-from-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-200 {
  --gradient-from-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-250 {
  --gradient-from-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-300 {
  --gradient-from-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-350 {
  --gradient-from-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-400 {
  --gradient-from-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-450 {
  --gradient-from-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-500 {
  --gradient-from-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-550 {
  --gradient-from-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-600 {
  --gradient-from-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-650 {
  --gradient-from-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-700 {
  --gradient-from-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-750 {
  --gradient-from-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-800 {
  --gradient-from-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-850 {
  --gradient-from-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-900 {
  --gradient-from-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.from-step-950 {
  --gradient-from-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-primary {
  --gradient-via-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-primary-shade {
  --gradient-via-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-primary-tint {
  --gradient-via-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-secondary {
  --gradient-via-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-secondary-shade {
  --gradient-via-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-secondary-tint {
  --gradient-via-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-tertiary {
  --gradient-via-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-tertiary-shade {
  --gradient-via-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-tertiary-tint {
  --gradient-via-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-light {
  --gradient-via-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-light-shade {
  --gradient-via-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-light-tint {
  --gradient-via-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-medium {
  --gradient-via-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-medium-shade {
  --gradient-via-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-medium-tint {
  --gradient-via-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-dark {
  --gradient-via-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-dark-shade {
  --gradient-via-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-dark-tint {
  --gradient-via-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-success {
  --gradient-via-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-success-shade {
  --gradient-via-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-success-tint {
  --gradient-via-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-warning {
  --gradient-via-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-warning-shade {
  --gradient-via-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-warning-tint {
  --gradient-via-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-danger {
  --gradient-via-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-danger-shade {
  --gradient-via-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-danger-tint {
  --gradient-via-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-50 {
  --gradient-via-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-100 {
  --gradient-via-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-150 {
  --gradient-via-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-200 {
  --gradient-via-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-250 {
  --gradient-via-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-300 {
  --gradient-via-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-350 {
  --gradient-via-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-400 {
  --gradient-via-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-450 {
  --gradient-via-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-500 {
  --gradient-via-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-550 {
  --gradient-via-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-600 {
  --gradient-via-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-650 {
  --gradient-via-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-700 {
  --gradient-via-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-750 {
  --gradient-via-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-800 {
  --gradient-via-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-850 {
  --gradient-via-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-900 {
  --gradient-via-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.via-step-950 {
  --gradient-via-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.to-primary {
  --gradient-to-color: var(--ion-color-primary)
}

.to-primary-shade {
  --gradient-to-color: var(--ion-color-primary-shade)
}

.to-primary-tint {
  --gradient-to-color: var(--ion-color-primary-tint)
}

.to-secondary {
  --gradient-to-color: var(--ion-color-secondary)
}

.to-secondary-shade {
  --gradient-to-color: var(--ion-color-secondary-shade)
}

.to-secondary-tint {
  --gradient-to-color: var(--ion-color-secondary-tint)
}

.to-tertiary {
  --gradient-to-color: var(--ion-color-tertiary)
}

.to-tertiary-shade {
  --gradient-to-color: var(--ion-color-tertiary-shade)
}

.to-tertiary-tint {
  --gradient-to-color: var(--ion-color-tertiary-tint)
}

.to-light {
  --gradient-to-color: var(--ion-color-light)
}

.to-light-shade {
  --gradient-to-color: var(--ion-color-light-shade)
}

.to-light-tint {
  --gradient-to-color: var(--ion-color-light-tint)
}

.to-medium {
  --gradient-to-color: var(--ion-color-medium)
}

.to-medium-shade {
  --gradient-to-color: var(--ion-color-medium-shade)
}

.to-medium-tint {
  --gradient-to-color: var(--ion-color-medium-tint)
}

.to-dark {
  --gradient-to-color: var(--ion-color-dark)
}

.to-dark-shade {
  --gradient-to-color: var(--ion-color-dark-shade)
}

.to-dark-tint {
  --gradient-to-color: var(--ion-color-dark-tint)
}

.to-success {
  --gradient-to-color: var(--ion-color-success)
}

.to-success-shade {
  --gradient-to-color: var(--ion-color-success-shade)
}

.to-success-tint {
  --gradient-to-color: var(--ion-color-success-tint)
}

.to-warning {
  --gradient-to-color: var(--ion-color-warning)
}

.to-warning-shade {
  --gradient-to-color: var(--ion-color-warning-shade)
}

.to-warning-tint {
  --gradient-to-color: var(--ion-color-warning-tint)
}

.to-danger {
  --gradient-to-color: var(--ion-color-danger)
}

.to-danger-shade {
  --gradient-to-color: var(--ion-color-danger-shade)
}

.to-danger-tint {
  --gradient-to-color: var(--ion-color-danger-tint)
}

.to-step-50 {
  --gradient-to-color: var(--ion-color-step-50)
}

.to-step-100 {
  --gradient-to-color: var(--ion-color-step-100)
}

.to-step-150 {
  --gradient-to-color: var(--ion-color-step-150)
}

.to-step-200 {
  --gradient-to-color: var(--ion-color-step-200)
}

.to-step-250 {
  --gradient-to-color: var(--ion-color-step-250)
}

.to-step-300 {
  --gradient-to-color: var(--ion-color-step-300)
}

.to-step-350 {
  --gradient-to-color: var(--ion-color-step-350)
}

.to-step-400 {
  --gradient-to-color: var(--ion-color-step-400)
}

.to-step-450 {
  --gradient-to-color: var(--ion-color-step-450)
}

.to-step-500 {
  --gradient-to-color: var(--ion-color-step-500)
}

.to-step-550 {
  --gradient-to-color: var(--ion-color-step-550)
}

.to-step-600 {
  --gradient-to-color: var(--ion-color-step-600)
}

.to-step-650 {
  --gradient-to-color: var(--ion-color-step-650)
}

.to-step-700 {
  --gradient-to-color: var(--ion-color-step-700)
}

.to-step-750 {
  --gradient-to-color: var(--ion-color-step-750)
}

.to-step-800 {
  --gradient-to-color: var(--ion-color-step-800)
}

.to-step-850 {
  --gradient-to-color: var(--ion-color-step-850)
}

.to-step-900 {
  --gradient-to-color: var(--ion-color-step-900)
}

.to-step-950 {
  --gradient-to-color: var(--ion-color-step-950)
}

.hover\:from-primary:hover {
  --gradient-from-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-primary-shade:hover {
  --gradient-from-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-primary-tint:hover {
  --gradient-from-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-secondary:hover {
  --gradient-from-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-secondary-shade:hover {
  --gradient-from-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-secondary-tint:hover {
  --gradient-from-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-tertiary:hover {
  --gradient-from-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-tertiary-shade:hover {
  --gradient-from-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-tertiary-tint:hover {
  --gradient-from-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-light:hover {
  --gradient-from-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-light-shade:hover {
  --gradient-from-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-light-tint:hover {
  --gradient-from-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-medium:hover {
  --gradient-from-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-medium-shade:hover {
  --gradient-from-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-medium-tint:hover {
  --gradient-from-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-dark:hover {
  --gradient-from-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-dark-shade:hover {
  --gradient-from-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-dark-tint:hover {
  --gradient-from-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-success:hover {
  --gradient-from-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-success-shade:hover {
  --gradient-from-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-success-tint:hover {
  --gradient-from-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-warning:hover {
  --gradient-from-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-warning-shade:hover {
  --gradient-from-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-warning-tint:hover {
  --gradient-from-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-danger:hover {
  --gradient-from-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-danger-shade:hover {
  --gradient-from-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-danger-tint:hover {
  --gradient-from-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-50:hover {
  --gradient-from-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-100:hover {
  --gradient-from-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-150:hover {
  --gradient-from-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-200:hover {
  --gradient-from-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-250:hover {
  --gradient-from-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-300:hover {
  --gradient-from-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-350:hover {
  --gradient-from-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-400:hover {
  --gradient-from-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-450:hover {
  --gradient-from-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-500:hover {
  --gradient-from-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-550:hover {
  --gradient-from-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-600:hover {
  --gradient-from-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-650:hover {
  --gradient-from-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-700:hover {
  --gradient-from-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-750:hover {
  --gradient-from-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-800:hover {
  --gradient-from-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-850:hover {
  --gradient-from-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-900:hover {
  --gradient-from-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:from-step-950:hover {
  --gradient-from-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-primary:hover {
  --gradient-via-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-primary-shade:hover {
  --gradient-via-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-primary-tint:hover {
  --gradient-via-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-secondary:hover {
  --gradient-via-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-secondary-shade:hover {
  --gradient-via-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-secondary-tint:hover {
  --gradient-via-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-tertiary:hover {
  --gradient-via-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-tertiary-shade:hover {
  --gradient-via-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-tertiary-tint:hover {
  --gradient-via-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-light:hover {
  --gradient-via-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-light-shade:hover {
  --gradient-via-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-light-tint:hover {
  --gradient-via-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-medium:hover {
  --gradient-via-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-medium-shade:hover {
  --gradient-via-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-medium-tint:hover {
  --gradient-via-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-dark:hover {
  --gradient-via-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-dark-shade:hover {
  --gradient-via-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-dark-tint:hover {
  --gradient-via-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-success:hover {
  --gradient-via-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-success-shade:hover {
  --gradient-via-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-success-tint:hover {
  --gradient-via-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-warning:hover {
  --gradient-via-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-warning-shade:hover {
  --gradient-via-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-warning-tint:hover {
  --gradient-via-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-danger:hover {
  --gradient-via-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-danger-shade:hover {
  --gradient-via-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-danger-tint:hover {
  --gradient-via-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-50:hover {
  --gradient-via-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-100:hover {
  --gradient-via-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-150:hover {
  --gradient-via-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-200:hover {
  --gradient-via-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-250:hover {
  --gradient-via-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-300:hover {
  --gradient-via-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-350:hover {
  --gradient-via-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-400:hover {
  --gradient-via-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-450:hover {
  --gradient-via-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-500:hover {
  --gradient-via-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-550:hover {
  --gradient-via-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-600:hover {
  --gradient-via-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-650:hover {
  --gradient-via-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-700:hover {
  --gradient-via-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-750:hover {
  --gradient-via-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-800:hover {
  --gradient-via-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-850:hover {
  --gradient-via-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-900:hover {
  --gradient-via-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:via-step-950:hover {
  --gradient-via-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.hover\:to-primary:hover {
  --gradient-to-color: var(--ion-color-primary)
}

.hover\:to-primary-shade:hover {
  --gradient-to-color: var(--ion-color-primary-shade)
}

.hover\:to-primary-tint:hover {
  --gradient-to-color: var(--ion-color-primary-tint)
}

.hover\:to-secondary:hover {
  --gradient-to-color: var(--ion-color-secondary)
}

.hover\:to-secondary-shade:hover {
  --gradient-to-color: var(--ion-color-secondary-shade)
}

.hover\:to-secondary-tint:hover {
  --gradient-to-color: var(--ion-color-secondary-tint)
}

.hover\:to-tertiary:hover {
  --gradient-to-color: var(--ion-color-tertiary)
}

.hover\:to-tertiary-shade:hover {
  --gradient-to-color: var(--ion-color-tertiary-shade)
}

.hover\:to-tertiary-tint:hover {
  --gradient-to-color: var(--ion-color-tertiary-tint)
}

.hover\:to-light:hover {
  --gradient-to-color: var(--ion-color-light)
}

.hover\:to-light-shade:hover {
  --gradient-to-color: var(--ion-color-light-shade)
}

.hover\:to-light-tint:hover {
  --gradient-to-color: var(--ion-color-light-tint)
}

.hover\:to-medium:hover {
  --gradient-to-color: var(--ion-color-medium)
}

.hover\:to-medium-shade:hover {
  --gradient-to-color: var(--ion-color-medium-shade)
}

.hover\:to-medium-tint:hover {
  --gradient-to-color: var(--ion-color-medium-tint)
}

.hover\:to-dark:hover {
  --gradient-to-color: var(--ion-color-dark)
}

.hover\:to-dark-shade:hover {
  --gradient-to-color: var(--ion-color-dark-shade)
}

.hover\:to-dark-tint:hover {
  --gradient-to-color: var(--ion-color-dark-tint)
}

.hover\:to-success:hover {
  --gradient-to-color: var(--ion-color-success)
}

.hover\:to-success-shade:hover {
  --gradient-to-color: var(--ion-color-success-shade)
}

.hover\:to-success-tint:hover {
  --gradient-to-color: var(--ion-color-success-tint)
}

.hover\:to-warning:hover {
  --gradient-to-color: var(--ion-color-warning)
}

.hover\:to-warning-shade:hover {
  --gradient-to-color: var(--ion-color-warning-shade)
}

.hover\:to-warning-tint:hover {
  --gradient-to-color: var(--ion-color-warning-tint)
}

.hover\:to-danger:hover {
  --gradient-to-color: var(--ion-color-danger)
}

.hover\:to-danger-shade:hover {
  --gradient-to-color: var(--ion-color-danger-shade)
}

.hover\:to-danger-tint:hover {
  --gradient-to-color: var(--ion-color-danger-tint)
}

.hover\:to-step-50:hover {
  --gradient-to-color: var(--ion-color-step-50)
}

.hover\:to-step-100:hover {
  --gradient-to-color: var(--ion-color-step-100)
}

.hover\:to-step-150:hover {
  --gradient-to-color: var(--ion-color-step-150)
}

.hover\:to-step-200:hover {
  --gradient-to-color: var(--ion-color-step-200)
}

.hover\:to-step-250:hover {
  --gradient-to-color: var(--ion-color-step-250)
}

.hover\:to-step-300:hover {
  --gradient-to-color: var(--ion-color-step-300)
}

.hover\:to-step-350:hover {
  --gradient-to-color: var(--ion-color-step-350)
}

.hover\:to-step-400:hover {
  --gradient-to-color: var(--ion-color-step-400)
}

.hover\:to-step-450:hover {
  --gradient-to-color: var(--ion-color-step-450)
}

.hover\:to-step-500:hover {
  --gradient-to-color: var(--ion-color-step-500)
}

.hover\:to-step-550:hover {
  --gradient-to-color: var(--ion-color-step-550)
}

.hover\:to-step-600:hover {
  --gradient-to-color: var(--ion-color-step-600)
}

.hover\:to-step-650:hover {
  --gradient-to-color: var(--ion-color-step-650)
}

.hover\:to-step-700:hover {
  --gradient-to-color: var(--ion-color-step-700)
}

.hover\:to-step-750:hover {
  --gradient-to-color: var(--ion-color-step-750)
}

.hover\:to-step-800:hover {
  --gradient-to-color: var(--ion-color-step-800)
}

.hover\:to-step-850:hover {
  --gradient-to-color: var(--ion-color-step-850)
}

.hover\:to-step-900:hover {
  --gradient-to-color: var(--ion-color-step-900)
}

.hover\:to-step-950:hover {
  --gradient-to-color: var(--ion-color-step-950)
}

.focus\:from-primary:focus {
  --gradient-from-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-primary-shade:focus {
  --gradient-from-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-primary-tint:focus {
  --gradient-from-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-secondary:focus {
  --gradient-from-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-secondary-shade:focus {
  --gradient-from-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-secondary-tint:focus {
  --gradient-from-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-tertiary:focus {
  --gradient-from-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-tertiary-shade:focus {
  --gradient-from-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-tertiary-tint:focus {
  --gradient-from-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-light:focus {
  --gradient-from-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-light-shade:focus {
  --gradient-from-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-light-tint:focus {
  --gradient-from-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-medium:focus {
  --gradient-from-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-medium-shade:focus {
  --gradient-from-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-medium-tint:focus {
  --gradient-from-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-dark:focus {
  --gradient-from-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-dark-shade:focus {
  --gradient-from-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-dark-tint:focus {
  --gradient-from-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-success:focus {
  --gradient-from-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-success-shade:focus {
  --gradient-from-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-success-tint:focus {
  --gradient-from-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-warning:focus {
  --gradient-from-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-warning-shade:focus {
  --gradient-from-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-warning-tint:focus {
  --gradient-from-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-danger:focus {
  --gradient-from-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-danger-shade:focus {
  --gradient-from-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-danger-tint:focus {
  --gradient-from-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-50:focus {
  --gradient-from-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-100:focus {
  --gradient-from-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-150:focus {
  --gradient-from-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-200:focus {
  --gradient-from-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-250:focus {
  --gradient-from-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-300:focus {
  --gradient-from-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-350:focus {
  --gradient-from-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-400:focus {
  --gradient-from-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-450:focus {
  --gradient-from-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-500:focus {
  --gradient-from-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-550:focus {
  --gradient-from-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-600:focus {
  --gradient-from-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-650:focus {
  --gradient-from-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-700:focus {
  --gradient-from-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-750:focus {
  --gradient-from-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-800:focus {
  --gradient-from-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-850:focus {
  --gradient-from-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-900:focus {
  --gradient-from-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:from-step-950:focus {
  --gradient-from-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-primary:focus {
  --gradient-via-color: var(--ion-color-primary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-primary-shade:focus {
  --gradient-via-color: var(--ion-color-primary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-primary-tint:focus {
  --gradient-via-color: var(--ion-color-primary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-secondary:focus {
  --gradient-via-color: var(--ion-color-secondary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-secondary-shade:focus {
  --gradient-via-color: var(--ion-color-secondary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-secondary-tint:focus {
  --gradient-via-color: var(--ion-color-secondary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-tertiary:focus {
  --gradient-via-color: var(--ion-color-tertiary);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-tertiary-shade:focus {
  --gradient-via-color: var(--ion-color-tertiary-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-tertiary-tint:focus {
  --gradient-via-color: var(--ion-color-tertiary-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-light:focus {
  --gradient-via-color: var(--ion-color-light);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-light-shade:focus {
  --gradient-via-color: var(--ion-color-light-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-light-tint:focus {
  --gradient-via-color: var(--ion-color-light-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-medium:focus {
  --gradient-via-color: var(--ion-color-medium);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-medium-shade:focus {
  --gradient-via-color: var(--ion-color-medium-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-medium-tint:focus {
  --gradient-via-color: var(--ion-color-medium-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-dark:focus {
  --gradient-via-color: var(--ion-color-dark);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-dark-shade:focus {
  --gradient-via-color: var(--ion-color-dark-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-dark-tint:focus {
  --gradient-via-color: var(--ion-color-dark-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-success:focus {
  --gradient-via-color: var(--ion-color-success);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-success-shade:focus {
  --gradient-via-color: var(--ion-color-success-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-success-tint:focus {
  --gradient-via-color: var(--ion-color-success-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-warning:focus {
  --gradient-via-color: var(--ion-color-warning);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-warning-shade:focus {
  --gradient-via-color: var(--ion-color-warning-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-warning-tint:focus {
  --gradient-via-color: var(--ion-color-warning-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-danger:focus {
  --gradient-via-color: var(--ion-color-danger);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-danger-shade:focus {
  --gradient-via-color: var(--ion-color-danger-shade);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-danger-tint:focus {
  --gradient-via-color: var(--ion-color-danger-tint);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-50:focus {
  --gradient-via-color: var(--ion-color-step-50);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-100:focus {
  --gradient-via-color: var(--ion-color-step-100);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-150:focus {
  --gradient-via-color: var(--ion-color-step-150);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-200:focus {
  --gradient-via-color: var(--ion-color-step-200);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-250:focus {
  --gradient-via-color: var(--ion-color-step-250);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-300:focus {
  --gradient-via-color: var(--ion-color-step-300);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-350:focus {
  --gradient-via-color: var(--ion-color-step-350);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-400:focus {
  --gradient-via-color: var(--ion-color-step-400);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-450:focus {
  --gradient-via-color: var(--ion-color-step-450);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-500:focus {
  --gradient-via-color: var(--ion-color-step-500);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-550:focus {
  --gradient-via-color: var(--ion-color-step-550);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-600:focus {
  --gradient-via-color: var(--ion-color-step-600);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-650:focus {
  --gradient-via-color: var(--ion-color-step-650);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-700:focus {
  --gradient-via-color: var(--ion-color-step-700);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-750:focus {
  --gradient-via-color: var(--ion-color-step-750);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-800:focus {
  --gradient-via-color: var(--ion-color-step-800);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-850:focus {
  --gradient-via-color: var(--ion-color-step-850);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-900:focus {
  --gradient-via-color: var(--ion-color-step-900);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:via-step-950:focus {
  --gradient-via-color: var(--ion-color-step-950);
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.focus\:to-primary:focus {
  --gradient-to-color: var(--ion-color-primary)
}

.focus\:to-primary-shade:focus {
  --gradient-to-color: var(--ion-color-primary-shade)
}

.focus\:to-primary-tint:focus {
  --gradient-to-color: var(--ion-color-primary-tint)
}

.focus\:to-secondary:focus {
  --gradient-to-color: var(--ion-color-secondary)
}

.focus\:to-secondary-shade:focus {
  --gradient-to-color: var(--ion-color-secondary-shade)
}

.focus\:to-secondary-tint:focus {
  --gradient-to-color: var(--ion-color-secondary-tint)
}

.focus\:to-tertiary:focus {
  --gradient-to-color: var(--ion-color-tertiary)
}

.focus\:to-tertiary-shade:focus {
  --gradient-to-color: var(--ion-color-tertiary-shade)
}

.focus\:to-tertiary-tint:focus {
  --gradient-to-color: var(--ion-color-tertiary-tint)
}

.focus\:to-light:focus {
  --gradient-to-color: var(--ion-color-light)
}

.focus\:to-light-shade:focus {
  --gradient-to-color: var(--ion-color-light-shade)
}

.focus\:to-light-tint:focus {
  --gradient-to-color: var(--ion-color-light-tint)
}

.focus\:to-medium:focus {
  --gradient-to-color: var(--ion-color-medium)
}

.focus\:to-medium-shade:focus {
  --gradient-to-color: var(--ion-color-medium-shade)
}

.focus\:to-medium-tint:focus {
  --gradient-to-color: var(--ion-color-medium-tint)
}

.focus\:to-dark:focus {
  --gradient-to-color: var(--ion-color-dark)
}

.focus\:to-dark-shade:focus {
  --gradient-to-color: var(--ion-color-dark-shade)
}

.focus\:to-dark-tint:focus {
  --gradient-to-color: var(--ion-color-dark-tint)
}

.focus\:to-success:focus {
  --gradient-to-color: var(--ion-color-success)
}

.focus\:to-success-shade:focus {
  --gradient-to-color: var(--ion-color-success-shade)
}

.focus\:to-success-tint:focus {
  --gradient-to-color: var(--ion-color-success-tint)
}

.focus\:to-warning:focus {
  --gradient-to-color: var(--ion-color-warning)
}

.focus\:to-warning-shade:focus {
  --gradient-to-color: var(--ion-color-warning-shade)
}

.focus\:to-warning-tint:focus {
  --gradient-to-color: var(--ion-color-warning-tint)
}

.focus\:to-danger:focus {
  --gradient-to-color: var(--ion-color-danger)
}

.focus\:to-danger-shade:focus {
  --gradient-to-color: var(--ion-color-danger-shade)
}

.focus\:to-danger-tint:focus {
  --gradient-to-color: var(--ion-color-danger-tint)
}

.focus\:to-step-50:focus {
  --gradient-to-color: var(--ion-color-step-50)
}

.focus\:to-step-100:focus {
  --gradient-to-color: var(--ion-color-step-100)
}

.focus\:to-step-150:focus {
  --gradient-to-color: var(--ion-color-step-150)
}

.focus\:to-step-200:focus {
  --gradient-to-color: var(--ion-color-step-200)
}

.focus\:to-step-250:focus {
  --gradient-to-color: var(--ion-color-step-250)
}

.focus\:to-step-300:focus {
  --gradient-to-color: var(--ion-color-step-300)
}

.focus\:to-step-350:focus {
  --gradient-to-color: var(--ion-color-step-350)
}

.focus\:to-step-400:focus {
  --gradient-to-color: var(--ion-color-step-400)
}

.focus\:to-step-450:focus {
  --gradient-to-color: var(--ion-color-step-450)
}

.focus\:to-step-500:focus {
  --gradient-to-color: var(--ion-color-step-500)
}

.focus\:to-step-550:focus {
  --gradient-to-color: var(--ion-color-step-550)
}

.focus\:to-step-600:focus {
  --gradient-to-color: var(--ion-color-step-600)
}

.focus\:to-step-650:focus {
  --gradient-to-color: var(--ion-color-step-650)
}

.focus\:to-step-700:focus {
  --gradient-to-color: var(--ion-color-step-700)
}

.focus\:to-step-750:focus {
  --gradient-to-color: var(--ion-color-step-750)
}

.focus\:to-step-800:focus {
  --gradient-to-color: var(--ion-color-step-800)
}

.focus\:to-step-850:focus {
  --gradient-to-color: var(--ion-color-step-850)
}

.focus\:to-step-900:focus {
  --gradient-to-color: var(--ion-color-step-900)
}

.focus\:to-step-950:focus {
  --gradient-to-color: var(--ion-color-step-950)
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: center
}

.bg-left {
  background-position: left
}

.bg-left-bottom {
  background-position: left bottom
}

.bg-left-top {
  background-position: left top
}

.bg-right {
  background-position: right
}

.bg-right-bottom {
  background-position: right bottom
}

.bg-right-top {
  background-position: right top
}

.bg-top {
  background-position: top
}

.bg-repeat {
  background-repeat: repeat
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-repeat-x {
  background-repeat: repeat-x
}

.bg-repeat-y {
  background-repeat: repeat-y
}

.bg-repeat-round {
  background-repeat: round
}

.bg-repeat-space {
  background-repeat: space
}

.bg-auto {
  background-size: auto
}

.bg-cover {
  background-size: cover
}

.bg-contain {
  background-size: contain
}

.border-collapse {
  border-collapse: collapse
}

.border-separate {
  border-collapse: separate
}

.border-primary {
  border-color: var(--ion-color-primary)
}

.border-primary-shade {
  border-color: var(--ion-color-primary-shade)
}

.border-primary-tint {
  border-color: var(--ion-color-primary-tint)
}

.border-secondary {
  border-color: var(--ion-color-secondary)
}

.border-secondary-shade {
  border-color: var(--ion-color-secondary-shade)
}

.border-secondary-tint {
  border-color: var(--ion-color-secondary-tint)
}

.border-tertiary {
  border-color: var(--ion-color-tertiary)
}

.border-tertiary-shade {
  border-color: var(--ion-color-tertiary-shade)
}

.border-tertiary-tint {
  border-color: var(--ion-color-tertiary-tint)
}

.border-light {
  border-color: var(--ion-color-light)
}

.border-light-shade {
  border-color: var(--ion-color-light-shade)
}

.border-light-tint {
  border-color: var(--ion-color-light-tint)
}

.border-medium {
  border-color: var(--ion-color-medium)
}

.border-medium-shade {
  border-color: var(--ion-color-medium-shade)
}

.border-medium-tint {
  border-color: var(--ion-color-medium-tint)
}

.border-dark {
  border-color: var(--ion-color-dark)
}

.border-dark-shade {
  border-color: var(--ion-color-dark-shade)
}

.border-dark-tint {
  border-color: var(--ion-color-dark-tint)
}

.border-success {
  border-color: var(--ion-color-success)
}

.border-success-shade {
  border-color: var(--ion-color-success-shade)
}

.border-success-tint {
  border-color: var(--ion-color-success-tint)
}

.border-warning {
  border-color: var(--ion-color-warning)
}

.border-warning-shade {
  border-color: var(--ion-color-warning-shade)
}

.border-warning-tint {
  border-color: var(--ion-color-warning-tint)
}

.border-danger {
  border-color: var(--ion-color-danger)
}

.border-danger-shade {
  border-color: var(--ion-color-danger-shade)
}

.border-danger-tint {
  border-color: var(--ion-color-danger-tint)
}

.border-step-50 {
  border-color: var(--ion-color-step-50)
}

.border-step-100 {
  border-color: var(--ion-color-step-100)
}

.border-step-150 {
  border-color: var(--ion-color-step-150)
}

.border-step-200 {
  border-color: var(--ion-color-step-200)
}

.border-step-250 {
  border-color: var(--ion-color-step-250)
}

.border-step-300 {
  border-color: var(--ion-color-step-300)
}

.border-step-350 {
  border-color: var(--ion-color-step-350)
}

.border-step-400 {
  border-color: var(--ion-color-step-400)
}

.border-step-450 {
  border-color: var(--ion-color-step-450)
}

.border-step-500 {
  border-color: var(--ion-color-step-500)
}

.border-step-550 {
  border-color: var(--ion-color-step-550)
}

.border-step-600 {
  border-color: var(--ion-color-step-600)
}

.border-step-650 {
  border-color: var(--ion-color-step-650)
}

.border-step-700 {
  border-color: var(--ion-color-step-700)
}

.border-step-750 {
  border-color: var(--ion-color-step-750)
}

.border-step-800 {
  border-color: var(--ion-color-step-800)
}

.border-step-850 {
  border-color: var(--ion-color-step-850)
}

.border-step-900 {
  border-color: var(--ion-color-step-900)
}

.border-step-950 {
  border-color: var(--ion-color-step-950)
}

.hover\:border-primary:hover {
  border-color: var(--ion-color-primary)
}

.hover\:border-primary-shade:hover {
  border-color: var(--ion-color-primary-shade)
}

.hover\:border-primary-tint:hover {
  border-color: var(--ion-color-primary-tint)
}

.hover\:border-secondary:hover {
  border-color: var(--ion-color-secondary)
}

.hover\:border-secondary-shade:hover {
  border-color: var(--ion-color-secondary-shade)
}

.hover\:border-secondary-tint:hover {
  border-color: var(--ion-color-secondary-tint)
}

.hover\:border-tertiary:hover {
  border-color: var(--ion-color-tertiary)
}

.hover\:border-tertiary-shade:hover {
  border-color: var(--ion-color-tertiary-shade)
}

.hover\:border-tertiary-tint:hover {
  border-color: var(--ion-color-tertiary-tint)
}

.hover\:border-light:hover {
  border-color: var(--ion-color-light)
}

.hover\:border-light-shade:hover {
  border-color: var(--ion-color-light-shade)
}

.hover\:border-light-tint:hover {
  border-color: var(--ion-color-light-tint)
}

.hover\:border-medium:hover {
  border-color: var(--ion-color-medium)
}

.hover\:border-medium-shade:hover {
  border-color: var(--ion-color-medium-shade)
}

.hover\:border-medium-tint:hover {
  border-color: var(--ion-color-medium-tint)
}

.hover\:border-dark:hover {
  border-color: var(--ion-color-dark)
}

.hover\:border-dark-shade:hover {
  border-color: var(--ion-color-dark-shade)
}

.hover\:border-dark-tint:hover {
  border-color: var(--ion-color-dark-tint)
}

.hover\:border-success:hover {
  border-color: var(--ion-color-success)
}

.hover\:border-success-shade:hover {
  border-color: var(--ion-color-success-shade)
}

.hover\:border-success-tint:hover {
  border-color: var(--ion-color-success-tint)
}

.hover\:border-warning:hover {
  border-color: var(--ion-color-warning)
}

.hover\:border-warning-shade:hover {
  border-color: var(--ion-color-warning-shade)
}

.hover\:border-warning-tint:hover {
  border-color: var(--ion-color-warning-tint)
}

.hover\:border-danger:hover {
  border-color: var(--ion-color-danger)
}

.hover\:border-danger-shade:hover {
  border-color: var(--ion-color-danger-shade)
}

.hover\:border-danger-tint:hover {
  border-color: var(--ion-color-danger-tint)
}

.hover\:border-step-50:hover {
  border-color: var(--ion-color-step-50)
}

.hover\:border-step-100:hover {
  border-color: var(--ion-color-step-100)
}

.hover\:border-step-150:hover {
  border-color: var(--ion-color-step-150)
}

.hover\:border-step-200:hover {
  border-color: var(--ion-color-step-200)
}

.hover\:border-step-250:hover {
  border-color: var(--ion-color-step-250)
}

.hover\:border-step-300:hover {
  border-color: var(--ion-color-step-300)
}

.hover\:border-step-350:hover {
  border-color: var(--ion-color-step-350)
}

.hover\:border-step-400:hover {
  border-color: var(--ion-color-step-400)
}

.hover\:border-step-450:hover {
  border-color: var(--ion-color-step-450)
}

.hover\:border-step-500:hover {
  border-color: var(--ion-color-step-500)
}

.hover\:border-step-550:hover {
  border-color: var(--ion-color-step-550)
}

.hover\:border-step-600:hover {
  border-color: var(--ion-color-step-600)
}

.hover\:border-step-650:hover {
  border-color: var(--ion-color-step-650)
}

.hover\:border-step-700:hover {
  border-color: var(--ion-color-step-700)
}

.hover\:border-step-750:hover {
  border-color: var(--ion-color-step-750)
}

.hover\:border-step-800:hover {
  border-color: var(--ion-color-step-800)
}

.hover\:border-step-850:hover {
  border-color: var(--ion-color-step-850)
}

.hover\:border-step-900:hover {
  border-color: var(--ion-color-step-900)
}

.hover\:border-step-950:hover {
  border-color: var(--ion-color-step-950)
}

.focus\:border-primary:focus {
  border-color: var(--ion-color-primary)
}

.focus\:border-primary-shade:focus {
  border-color: var(--ion-color-primary-shade)
}

.focus\:border-primary-tint:focus {
  border-color: var(--ion-color-primary-tint)
}

.focus\:border-secondary:focus {
  border-color: var(--ion-color-secondary)
}

.focus\:border-secondary-shade:focus {
  border-color: var(--ion-color-secondary-shade)
}

.focus\:border-secondary-tint:focus {
  border-color: var(--ion-color-secondary-tint)
}

.focus\:border-tertiary:focus {
  border-color: var(--ion-color-tertiary)
}

.focus\:border-tertiary-shade:focus {
  border-color: var(--ion-color-tertiary-shade)
}

.focus\:border-tertiary-tint:focus {
  border-color: var(--ion-color-tertiary-tint)
}

.focus\:border-light:focus {
  border-color: var(--ion-color-light)
}

.focus\:border-light-shade:focus {
  border-color: var(--ion-color-light-shade)
}

.focus\:border-light-tint:focus {
  border-color: var(--ion-color-light-tint)
}

.focus\:border-medium:focus {
  border-color: var(--ion-color-medium)
}

.focus\:border-medium-shade:focus {
  border-color: var(--ion-color-medium-shade)
}

.focus\:border-medium-tint:focus {
  border-color: var(--ion-color-medium-tint)
}

.focus\:border-dark:focus {
  border-color: var(--ion-color-dark)
}

.focus\:border-dark-shade:focus {
  border-color: var(--ion-color-dark-shade)
}

.focus\:border-dark-tint:focus {
  border-color: var(--ion-color-dark-tint)
}

.focus\:border-success:focus {
  border-color: var(--ion-color-success)
}

.focus\:border-success-shade:focus {
  border-color: var(--ion-color-success-shade)
}

.focus\:border-success-tint:focus {
  border-color: var(--ion-color-success-tint)
}

.focus\:border-warning:focus {
  border-color: var(--ion-color-warning)
}

.focus\:border-warning-shade:focus {
  border-color: var(--ion-color-warning-shade)
}

.focus\:border-warning-tint:focus {
  border-color: var(--ion-color-warning-tint)
}

.focus\:border-danger:focus {
  border-color: var(--ion-color-danger)
}

.focus\:border-danger-shade:focus {
  border-color: var(--ion-color-danger-shade)
}

.focus\:border-danger-tint:focus {
  border-color: var(--ion-color-danger-tint)
}

.focus\:border-step-50:focus {
  border-color: var(--ion-color-step-50)
}

.focus\:border-step-100:focus {
  border-color: var(--ion-color-step-100)
}

.focus\:border-step-150:focus {
  border-color: var(--ion-color-step-150)
}

.focus\:border-step-200:focus {
  border-color: var(--ion-color-step-200)
}

.focus\:border-step-250:focus {
  border-color: var(--ion-color-step-250)
}

.focus\:border-step-300:focus {
  border-color: var(--ion-color-step-300)
}

.focus\:border-step-350:focus {
  border-color: var(--ion-color-step-350)
}

.focus\:border-step-400:focus {
  border-color: var(--ion-color-step-400)
}

.focus\:border-step-450:focus {
  border-color: var(--ion-color-step-450)
}

.focus\:border-step-500:focus {
  border-color: var(--ion-color-step-500)
}

.focus\:border-step-550:focus {
  border-color: var(--ion-color-step-550)
}

.focus\:border-step-600:focus {
  border-color: var(--ion-color-step-600)
}

.focus\:border-step-650:focus {
  border-color: var(--ion-color-step-650)
}

.focus\:border-step-700:focus {
  border-color: var(--ion-color-step-700)
}

.focus\:border-step-750:focus {
  border-color: var(--ion-color-step-750)
}

.focus\:border-step-800:focus {
  border-color: var(--ion-color-step-800)
}

.focus\:border-step-850:focus {
  border-color: var(--ion-color-step-850)
}

.focus\:border-step-900:focus {
  border-color: var(--ion-color-step-900)
}

.focus\:border-step-950:focus {
  border-color: var(--ion-color-step-950)
}

.border-opacity-0 {
  --border-opacity: 0
}

.border-opacity-25 {
  --border-opacity: 0.25
}

.border-opacity-50 {
  --border-opacity: 0.5
}

.border-opacity-75 {
  --border-opacity: 0.75
}

.border-opacity-100 {
  --border-opacity: 1
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-3xl {
  border-radius: 1.5rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px
}

.rounded-tl-none {
  border-top-left-radius: 0
}

.rounded-tr-none {
  border-top-right-radius: 0
}

.rounded-br-none {
  border-bottom-right-radius: 0
}

.rounded-bl-none {
  border-bottom-left-radius: 0
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem
}

.rounded-tl {
  border-top-left-radius: 0.25rem
}

.rounded-tr {
  border-top-right-radius: 0.25rem
}

.rounded-br {
  border-bottom-right-radius: 0.25rem
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem
}

.rounded-tl-full {
  border-top-left-radius: 9999px
}

.rounded-tr-full {
  border-top-right-radius: 9999px
}

.rounded-br-full {
  border-bottom-right-radius: 9999px
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-double {
  border-style: double
}

.border-none {
  border-style: none
}

.border-0 {
  border-width: 0
}

.border-2 {
  border-width: 2px
}

.border-4 {
  border-width: 4px
}

.border-8 {
  border-width: 8px
}

.border {
  border-width: 1px
}

.border-t-0 {
  border-top-width: 0
}

.border-r-0 {
  border-right-width: 0
}

.border-b-0 {
  border-bottom-width: 0
}

.border-l-0 {
  border-left-width: 0
}

.border-t-2 {
  border-top-width: 2px
}

.border-r-2 {
  border-right-width: 2px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-2 {
  border-left-width: 2px
}

.border-t-4 {
  border-top-width: 4px
}

.border-r-4 {
  border-right-width: 4px
}

.border-b-4 {
  border-bottom-width: 4px
}

.border-l-4 {
  border-left-width: 4px
}

.border-t-8 {
  border-top-width: 8px
}

.border-r-8 {
  border-right-width: 8px
}

.border-b-8 {
  border-bottom-width: 8px
}

.border-l-8 {
  border-left-width: 8px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.box-border {
  box-sizing: border-box
}

.box-content {
  box-sizing: content-box
}

.cursor-auto {
  cursor: auto
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-wait {
  cursor: wait
}

.cursor-text {
  cursor: text
}

.cursor-move {
  cursor: move
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-caption {
  display: table-caption
}

.table-cell {
  display: table-cell
}

.table-column {
  display: table-column
}

.table-column-group {
  display: table-column-group
}

.table-footer-group {
  display: table-footer-group
}

.table-header-group {
  display: table-header-group
}

.table-row-group {
  display: table-row-group
}

.table-row {
  display: table-row
}

.flow-root {
  display: flow-root
}

.grid {
  display: grid
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.place-items-auto {
  place-items: auto
}

.place-items-start {
  place-items: start
}

.place-items-end {
  place-items: end
}

.place-items-center {
  place-items: center
}

.place-items-stretch {
  place-items: stretch
}

.place-content-center {
  place-content: center
}

.place-content-start {
  place-content: start
}

.place-content-end {
  place-content: end
}

.place-content-between {
  place-content: space-between
}

.place-content-around {
  place-content: space-around
}

.place-content-evenly {
  place-content: space-evenly
}

.place-content-stretch {
  place-content: stretch
}

.place-self-auto {
  place-self: auto
}

.place-self-start {
  place-self: start
}

.place-self-end {
  place-self: end
}

.place-self-center {
  place-self: center
}

.place-self-stretch {
  place-self: stretch
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-baseline {
  align-items: baseline
}

.items-stretch {
  align-items: stretch
}

.content-center {
  align-content: center
}

.content-start {
  align-content: flex-start
}

.content-end {
  align-content: flex-end
}

.content-between {
  align-content: space-between
}

.content-around {
  align-content: space-around
}

.content-evenly {
  align-content: space-evenly
}

.self-auto {
  align-self: auto
}

.self-start {
  align-self: flex-start
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.justify-items-auto {
  justify-items: auto
}

.justify-items-start {
  justify-items: start
}

.justify-items-end {
  justify-items: end
}

.justify-items-center {
  justify-items: center
}

.justify-items-stretch {
  justify-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.justify-evenly {
  justify-content: space-evenly
}

.justify-self-auto {
  justify-self: auto
}

.justify-self-start {
  justify-self: start
}

.justify-self-end {
  justify-self: end
}

.justify-self-center {
  justify-self: center
}

.justify-self-stretch {
  justify-self: stretch
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.order-first {
  order: -9999
}

.order-last {
  order: 9999
}

.order-none {
  order: 0
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.float-none {
  float: none
}

.clearfix:after {
  content: "";
  display: table;
  clear: both
}

.clear-left {
  clear: left
}

.clear-right {
  clear: right
}

.clear-both {
  clear: both
}

.clear-none {
  clear: none
}

.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.font-hairline {
  font-weight: 100
}

.font-thin {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-black {
  font-weight: 900
}

.hover\:font-hairline:hover {
  font-weight: 100
}

.hover\:font-thin:hover {
  font-weight: 200
}

.hover\:font-light:hover {
  font-weight: 300
}

.hover\:font-normal:hover {
  font-weight: 400
}

.hover\:font-medium:hover {
  font-weight: 500
}

.hover\:font-semibold:hover {
  font-weight: 600
}

.hover\:font-bold:hover {
  font-weight: 700
}

.hover\:font-extrabold:hover {
  font-weight: 800
}

.hover\:font-black:hover {
  font-weight: 900
}

.focus\:font-hairline:focus {
  font-weight: 100
}

.focus\:font-thin:focus {
  font-weight: 200
}

.focus\:font-light:focus {
  font-weight: 300
}

.focus\:font-normal:focus {
  font-weight: 400
}

.focus\:font-medium:focus {
  font-weight: 500
}

.focus\:font-semibold:focus {
  font-weight: 600
}

.focus\:font-bold:focus {
  font-weight: 700
}

.focus\:font-extrabold:focus {
  font-weight: 800
}

.focus\:font-black:focus {
  font-weight: 900
}

.h-0 {
  height: 0
}

.h-1 {
  height: 0.25rem
}

.h-2 {
  height: 0.5rem
}

.h-3 {
  height: 0.75rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-8 {
  height: 2rem
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-16 {
  height: 4rem
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-32 {
  height: 8rem
}

.h-40 {
  height: 10rem
}

.h-48 {
  height: 12rem
}

.h-56 {
  height: 14rem
}

.h-64 {
  height: 16rem
}

.h-auto {
  height: auto
}

.h-px {
  height: 1px
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.text-xs {
  font-size: 0.75rem
}

.text-sm {
  font-size: 0.875rem
}

.text-base {
  font-size: 1rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.text-3xl {
  font-size: 1.875rem
}

.text-4xl {
  font-size: 2.25rem
}

.text-5xl {
  font-size: 3rem
}

.text-6xl {
  font-size: 4rem
}

.leading-3 {
  line-height: .75rem
}

.leading-4 {
  line-height: 1rem
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.leading-7 {
  line-height: 1.75rem
}

.leading-8 {
  line-height: 2rem
}

.leading-9 {
  line-height: 2.25rem
}

.leading-10 {
  line-height: 2.5rem
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.leading-snug {
  line-height: 1.375
}

.leading-normal {
  line-height: 1.5
}

.leading-relaxed {
  line-height: 1.625
}

.leading-loose {
  line-height: 2
}

.list-inside {
  list-style-position: inside
}

.list-outside {
  list-style-position: outside
}

.list-none {
  list-style-type: none
}

.list-disc {
  list-style-type: disc
}

.list-decimal {
  list-style-type: decimal
}

.m-0 {
  margin: 0
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 0.5rem
}

.m-3 {
  margin: 0.75rem
}

.m-4 {
  margin: 1rem
}

.m-5 {
  margin: 1.25rem
}

.m-6 {
  margin: 1.5rem
}

.m-8 {
  margin: 2rem
}

.m-10 {
  margin: 2.5rem
}

.m-12 {
  margin: 3rem
}

.m-16 {
  margin: 4rem
}

.m-20 {
  margin: 5rem
}

.m-24 {
  margin: 6rem
}

.m-32 {
  margin: 8rem
}

.m-40 {
  margin: 10rem
}

.m-48 {
  margin: 12rem
}

.m-56 {
  margin: 14rem
}

.m-64 {
  margin: 16rem
}

.m-auto {
  margin: auto
}

.m-px {
  margin: 1px
}

.-m-1 {
  margin: -0.25rem
}

.-m-2 {
  margin: -0.5rem
}

.-m-3 {
  margin: -0.75rem
}

.-m-4 {
  margin: -1rem
}

.-m-5 {
  margin: -1.25rem
}

.-m-6 {
  margin: -1.5rem
}

.-m-8 {
  margin: -2rem
}

.-m-10 {
  margin: -2.5rem
}

.-m-12 {
  margin: -3rem
}

.-m-16 {
  margin: -4rem
}

.-m-20 {
  margin: -5rem
}

.-m-24 {
  margin: -6rem
}

.-m-32 {
  margin: -8rem
}

.-m-40 {
  margin: -10rem
}

.-m-48 {
  margin: -12rem
}

.-m-56 {
  margin: -14rem
}

.-m-64 {
  margin: -16rem
}

.-m-px {
  margin: -1px
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.ml-3 {
  margin-left: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mr-8 {
  margin-right: 2rem
}

.mb-8 {
  margin-bottom: 2rem
}

.ml-8 {
  margin-left: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mr-10 {
  margin-right: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-10 {
  margin-left: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mr-12 {
  margin-right: 3rem
}

.mb-12 {
  margin-bottom: 3rem
}

.ml-12 {
  margin-left: 3rem
}

.mt-16 {
  margin-top: 4rem
}

.mr-16 {
  margin-right: 4rem
}

.mb-16 {
  margin-bottom: 4rem
}

.ml-16 {
  margin-left: 4rem
}

.mt-20 {
  margin-top: 5rem
}

.mr-20 {
  margin-right: 5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.ml-20 {
  margin-left: 5rem
}

.mt-24 {
  margin-top: 6rem
}

.mr-24 {
  margin-right: 6rem
}

.mb-24 {
  margin-bottom: 6rem
}

.ml-24 {
  margin-left: 6rem
}

.mt-32 {
  margin-top: 8rem
}

.mr-32 {
  margin-right: 8rem
}

.mb-32 {
  margin-bottom: 8rem
}

.ml-32 {
  margin-left: 8rem
}

.mt-40 {
  margin-top: 10rem
}

.mr-40 {
  margin-right: 10rem
}

.mb-40 {
  margin-bottom: 10rem
}

.ml-40 {
  margin-left: 10rem
}

.mt-48 {
  margin-top: 12rem
}

.mr-48 {
  margin-right: 12rem
}

.mb-48 {
  margin-bottom: 12rem
}

.ml-48 {
  margin-left: 12rem
}

.mt-56 {
  margin-top: 14rem
}

.mr-56 {
  margin-right: 14rem
}

.mb-56 {
  margin-bottom: 14rem
}

.ml-56 {
  margin-left: 14rem
}

.mt-64 {
  margin-top: 16rem
}

.mr-64 {
  margin-right: 16rem
}

.mb-64 {
  margin-bottom: 16rem
}

.ml-64 {
  margin-left: 16rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.mt-px {
  margin-top: 1px
}

.mr-px {
  margin-right: 1px
}

.mb-px {
  margin-bottom: 1px
}

.ml-px {
  margin-left: 1px
}

.-mt-1 {
  margin-top: -0.25rem
}

.-mr-1 {
  margin-right: -0.25rem
}

.-mb-1 {
  margin-bottom: -0.25rem
}

.-ml-1 {
  margin-left: -0.25rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mr-2 {
  margin-right: -0.5rem
}

.-mb-2 {
  margin-bottom: -0.5rem
}

.-ml-2 {
  margin-left: -0.5rem
}

.-mt-3 {
  margin-top: -0.75rem
}

.-mr-3 {
  margin-right: -0.75rem
}

.-mb-3 {
  margin-bottom: -0.75rem
}

.-ml-3 {
  margin-left: -0.75rem
}

.-mt-4 {
  margin-top: -1rem
}

.-mr-4 {
  margin-right: -1rem
}

.-mb-4 {
  margin-bottom: -1rem
}

.-ml-4 {
  margin-left: -1rem
}

.-mt-5 {
  margin-top: -1.25rem
}

.-mr-5 {
  margin-right: -1.25rem
}

.-mb-5 {
  margin-bottom: -1.25rem
}

.-ml-5 {
  margin-left: -1.25rem
}

.-mt-6 {
  margin-top: -1.5rem
}

.-mr-6 {
  margin-right: -1.5rem
}

.-mb-6 {
  margin-bottom: -1.5rem
}

.-ml-6 {
  margin-left: -1.5rem
}

.-mt-8 {
  margin-top: -2rem
}

.-mr-8 {
  margin-right: -2rem
}

.-mb-8 {
  margin-bottom: -2rem
}

.-ml-8 {
  margin-left: -2rem
}

.-mt-10 {
  margin-top: -2.5rem
}

.-mr-10 {
  margin-right: -2.5rem
}

.-mb-10 {
  margin-bottom: -2.5rem
}

.-ml-10 {
  margin-left: -2.5rem
}

.-mt-12 {
  margin-top: -3rem
}

.-mr-12 {
  margin-right: -3rem
}

.-mb-12 {
  margin-bottom: -3rem
}

.-ml-12 {
  margin-left: -3rem
}

.-mt-16 {
  margin-top: -4rem
}

.-mr-16 {
  margin-right: -4rem
}

.-mb-16 {
  margin-bottom: -4rem
}

.-ml-16 {
  margin-left: -4rem
}

.-mt-20 {
  margin-top: -5rem
}

.-mr-20 {
  margin-right: -5rem
}

.-mb-20 {
  margin-bottom: -5rem
}

.-ml-20 {
  margin-left: -5rem
}

.-mt-24 {
  margin-top: -6rem
}

.-mr-24 {
  margin-right: -6rem
}

.-mb-24 {
  margin-bottom: -6rem
}

.-ml-24 {
  margin-left: -6rem
}

.-mt-32 {
  margin-top: -8rem
}

.-mr-32 {
  margin-right: -8rem
}

.-mb-32 {
  margin-bottom: -8rem
}

.-ml-32 {
  margin-left: -8rem
}

.-mt-40 {
  margin-top: -10rem
}

.-mr-40 {
  margin-right: -10rem
}

.-mb-40 {
  margin-bottom: -10rem
}

.-ml-40 {
  margin-left: -10rem
}

.-mt-48 {
  margin-top: -12rem
}

.-mr-48 {
  margin-right: -12rem
}

.-mb-48 {
  margin-bottom: -12rem
}

.-ml-48 {
  margin-left: -12rem
}

.-mt-56 {
  margin-top: -14rem
}

.-mr-56 {
  margin-right: -14rem
}

.-mb-56 {
  margin-bottom: -14rem
}

.-ml-56 {
  margin-left: -14rem
}

.-mt-64 {
  margin-top: -16rem
}

.-mr-64 {
  margin-right: -16rem
}

.-mb-64 {
  margin-bottom: -16rem
}

.-ml-64 {
  margin-left: -16rem
}

.-mt-px {
  margin-top: -1px
}

.-mr-px {
  margin-right: -1px
}

.-mb-px {
  margin-bottom: -1px
}

.-ml-px {
  margin-left: -1px
}

.max-h-full {
  max-height: 100%
}

.max-h-screen {
  max-height: 100vh
}

.max-w-none {
  max-width: none
}

.max-w-xs {
  max-width: 20rem
}

.max-w-sm {
  max-width: 24rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-3xl {
  max-width: 48rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-5xl {
  max-width: 64rem
}

.max-w-6xl {
  max-width: 72rem
}

.max-w-full {
  max-width: 100%
}

.max-w-screen-sm {
  max-width: 640px
}

.max-w-screen-md {
  max-width: 768px
}

.max-w-screen-lg {
  max-width: 1024px
}

.max-w-screen-xl {
  max-width: 1280px
}

.min-h-0 {
  min-height: 0
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0
}

.min-w-full {
  min-width: 100%
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover
}

.object-fill {
  -o-object-fit: fill;
     object-fit: fill
}

.object-none {
  -o-object-fit: none;
     object-fit: none
}

.object-scale-down {
  -o-object-fit: scale-down;
     object-fit: scale-down
}

.object-bottom {
  -o-object-position: bottom;
     object-position: bottom
}

.object-center {
  -o-object-position: center;
     object-position: center
}

.object-left {
  -o-object-position: left;
     object-position: left
}

.object-left-bottom {
  -o-object-position: left bottom;
     object-position: left bottom
}

.object-left-top {
  -o-object-position: left top;
     object-position: left top
}

.object-right {
  -o-object-position: right;
     object-position: right
}

.object-right-bottom {
  -o-object-position: right bottom;
     object-position: right bottom
}

.object-right-top {
  -o-object-position: right top;
     object-position: right top
}

.object-top {
  -o-object-position: top;
     object-position: top
}

.opacity-0 {
  opacity: 0
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.opacity-100 {
  opacity: 1
}

.hover\:opacity-0:hover {
  opacity: 0
}

.hover\:opacity-25:hover {
  opacity: 0.25
}

.hover\:opacity-50:hover {
  opacity: 0.5
}

.hover\:opacity-75:hover {
  opacity: 0.75
}

.hover\:opacity-100:hover {
  opacity: 1
}

.focus\:opacity-0:focus {
  opacity: 0
}

.focus\:opacity-25:focus {
  opacity: 0.25
}

.focus\:opacity-50:focus {
  opacity: 0.5
}

.focus\:opacity-75:focus {
  opacity: 0.75
}

.focus\:opacity-100:focus {
  opacity: 1
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-visible {
  overflow: visible
}

.overflow-scroll {
  overflow: scroll
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-x-visible {
  overflow-x: visible
}

.overflow-y-visible {
  overflow-y: visible
}

.overflow-x-scroll {
  overflow-x: scroll
}

.overflow-y-scroll {
  overflow-y: scroll
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto
}

.overscroll-auto {
  overscroll-behavior: auto
}

.overscroll-contain {
  overscroll-behavior: contain
}

.overscroll-none {
  overscroll-behavior: none
}

.overscroll-y-auto {
  overscroll-behavior-y: auto
}

.overscroll-y-contain {
  overscroll-behavior-y: contain
}

.overscroll-y-none {
  overscroll-behavior-y: none
}

.overscroll-x-auto {
  overscroll-behavior-x: auto
}

.overscroll-x-contain {
  overscroll-behavior-x: contain
}

.overscroll-x-none {
  overscroll-behavior-x: none
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-8 {
  padding: 2rem
}

.p-10 {
  padding: 2.5rem
}

.p-12 {
  padding: 3rem
}

.p-16 {
  padding: 4rem
}

.p-20 {
  padding: 5rem
}

.p-24 {
  padding: 6rem
}

.p-32 {
  padding: 8rem
}

.p-40 {
  padding: 10rem
}

.p-48 {
  padding: 12rem
}

.p-56 {
  padding: 14rem
}

.p-64 {
  padding: 16rem
}

.p-px {
  padding: 1px
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px
}

.px-px {
  padding-left: 1px;
  padding-right: 1px
}

.pt-0 {
  padding-top: 0
}

.pr-0 {
  padding-right: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pr-4 {
  padding-right: 1rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-4 {
  padding-left: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pr-8 {
  padding-right: 2rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pl-8 {
  padding-left: 2rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pr-12 {
  padding-right: 3rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pl-12 {
  padding-left: 3rem
}

.pt-16 {
  padding-top: 4rem
}

.pr-16 {
  padding-right: 4rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pl-16 {
  padding-left: 4rem
}

.pt-20 {
  padding-top: 5rem
}

.pr-20 {
  padding-right: 5rem
}

.pb-20 {
  padding-bottom: 5rem
}

.pl-20 {
  padding-left: 5rem
}

.pt-24 {
  padding-top: 6rem
}

.pr-24 {
  padding-right: 6rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pl-24 {
  padding-left: 6rem
}

.pt-32 {
  padding-top: 8rem
}

.pr-32 {
  padding-right: 8rem
}

.pb-32 {
  padding-bottom: 8rem
}

.pl-32 {
  padding-left: 8rem
}

.pt-40 {
  padding-top: 10rem
}

.pr-40 {
  padding-right: 10rem
}

.pb-40 {
  padding-bottom: 10rem
}

.pl-40 {
  padding-left: 10rem
}

.pt-48 {
  padding-top: 12rem
}

.pr-48 {
  padding-right: 12rem
}

.pb-48 {
  padding-bottom: 12rem
}

.pl-48 {
  padding-left: 12rem
}

.pt-56 {
  padding-top: 14rem
}

.pr-56 {
  padding-right: 14rem
}

.pb-56 {
  padding-bottom: 14rem
}

.pl-56 {
  padding-left: 14rem
}

.pt-64 {
  padding-top: 16rem
}

.pr-64 {
  padding-right: 16rem
}

.pb-64 {
  padding-bottom: 16rem
}

.pl-64 {
  padding-left: 16rem
}

.pt-px {
  padding-top: 1px
}

.pr-px {
  padding-right: 1px
}

.pb-px {
  padding-bottom: 1px
}

.pl-px {
  padding-left: 1px
}

.placeholder-primary::-moz-placeholder {
  color: var(--ion-color-primary)
}

.placeholder-primary::placeholder {
  color: var(--ion-color-primary)
}

.placeholder-primary-shade::-moz-placeholder {
  color: var(--ion-color-primary-shade)
}

.placeholder-primary-shade::placeholder {
  color: var(--ion-color-primary-shade)
}

.placeholder-primary-tint::-moz-placeholder {
  color: var(--ion-color-primary-tint)
}

.placeholder-primary-tint::placeholder {
  color: var(--ion-color-primary-tint)
}

.placeholder-secondary::-moz-placeholder {
  color: var(--ion-color-secondary)
}

.placeholder-secondary::placeholder {
  color: var(--ion-color-secondary)
}

.placeholder-secondary-shade::-moz-placeholder {
  color: var(--ion-color-secondary-shade)
}

.placeholder-secondary-shade::placeholder {
  color: var(--ion-color-secondary-shade)
}

.placeholder-secondary-tint::-moz-placeholder {
  color: var(--ion-color-secondary-tint)
}

.placeholder-secondary-tint::placeholder {
  color: var(--ion-color-secondary-tint)
}

.placeholder-tertiary::-moz-placeholder {
  color: var(--ion-color-tertiary)
}

.placeholder-tertiary::placeholder {
  color: var(--ion-color-tertiary)
}

.placeholder-tertiary-shade::-moz-placeholder {
  color: var(--ion-color-tertiary-shade)
}

.placeholder-tertiary-shade::placeholder {
  color: var(--ion-color-tertiary-shade)
}

.placeholder-tertiary-tint::-moz-placeholder {
  color: var(--ion-color-tertiary-tint)
}

.placeholder-tertiary-tint::placeholder {
  color: var(--ion-color-tertiary-tint)
}

.placeholder-light::-moz-placeholder {
  color: var(--ion-color-light)
}

.placeholder-light::placeholder {
  color: var(--ion-color-light)
}

.placeholder-light-shade::-moz-placeholder {
  color: var(--ion-color-light-shade)
}

.placeholder-light-shade::placeholder {
  color: var(--ion-color-light-shade)
}

.placeholder-light-tint::-moz-placeholder {
  color: var(--ion-color-light-tint)
}

.placeholder-light-tint::placeholder {
  color: var(--ion-color-light-tint)
}

.placeholder-medium::-moz-placeholder {
  color: var(--ion-color-medium)
}

.placeholder-medium::placeholder {
  color: var(--ion-color-medium)
}

.placeholder-medium-shade::-moz-placeholder {
  color: var(--ion-color-medium-shade)
}

.placeholder-medium-shade::placeholder {
  color: var(--ion-color-medium-shade)
}

.placeholder-medium-tint::-moz-placeholder {
  color: var(--ion-color-medium-tint)
}

.placeholder-medium-tint::placeholder {
  color: var(--ion-color-medium-tint)
}

.placeholder-dark::-moz-placeholder {
  color: var(--ion-color-dark)
}

.placeholder-dark::placeholder {
  color: var(--ion-color-dark)
}

.placeholder-dark-shade::-moz-placeholder {
  color: var(--ion-color-dark-shade)
}

.placeholder-dark-shade::placeholder {
  color: var(--ion-color-dark-shade)
}

.placeholder-dark-tint::-moz-placeholder {
  color: var(--ion-color-dark-tint)
}

.placeholder-dark-tint::placeholder {
  color: var(--ion-color-dark-tint)
}

.placeholder-success::-moz-placeholder {
  color: var(--ion-color-success)
}

.placeholder-success::placeholder {
  color: var(--ion-color-success)
}

.placeholder-success-shade::-moz-placeholder {
  color: var(--ion-color-success-shade)
}

.placeholder-success-shade::placeholder {
  color: var(--ion-color-success-shade)
}

.placeholder-success-tint::-moz-placeholder {
  color: var(--ion-color-success-tint)
}

.placeholder-success-tint::placeholder {
  color: var(--ion-color-success-tint)
}

.placeholder-warning::-moz-placeholder {
  color: var(--ion-color-warning)
}

.placeholder-warning::placeholder {
  color: var(--ion-color-warning)
}

.placeholder-warning-shade::-moz-placeholder {
  color: var(--ion-color-warning-shade)
}

.placeholder-warning-shade::placeholder {
  color: var(--ion-color-warning-shade)
}

.placeholder-warning-tint::-moz-placeholder {
  color: var(--ion-color-warning-tint)
}

.placeholder-warning-tint::placeholder {
  color: var(--ion-color-warning-tint)
}

.placeholder-danger::-moz-placeholder {
  color: var(--ion-color-danger)
}

.placeholder-danger::placeholder {
  color: var(--ion-color-danger)
}

.placeholder-danger-shade::-moz-placeholder {
  color: var(--ion-color-danger-shade)
}

.placeholder-danger-shade::placeholder {
  color: var(--ion-color-danger-shade)
}

.placeholder-danger-tint::-moz-placeholder {
  color: var(--ion-color-danger-tint)
}

.placeholder-danger-tint::placeholder {
  color: var(--ion-color-danger-tint)
}

.placeholder-step-50::-moz-placeholder {
  color: var(--ion-color-step-50)
}

.placeholder-step-50::placeholder {
  color: var(--ion-color-step-50)
}

.placeholder-step-100::-moz-placeholder {
  color: var(--ion-color-step-100)
}

.placeholder-step-100::placeholder {
  color: var(--ion-color-step-100)
}

.placeholder-step-150::-moz-placeholder {
  color: var(--ion-color-step-150)
}

.placeholder-step-150::placeholder {
  color: var(--ion-color-step-150)
}

.placeholder-step-200::-moz-placeholder {
  color: var(--ion-color-step-200)
}

.placeholder-step-200::placeholder {
  color: var(--ion-color-step-200)
}

.placeholder-step-250::-moz-placeholder {
  color: var(--ion-color-step-250)
}

.placeholder-step-250::placeholder {
  color: var(--ion-color-step-250)
}

.placeholder-step-300::-moz-placeholder {
  color: var(--ion-color-step-300)
}

.placeholder-step-300::placeholder {
  color: var(--ion-color-step-300)
}

.placeholder-step-350::-moz-placeholder {
  color: var(--ion-color-step-350)
}

.placeholder-step-350::placeholder {
  color: var(--ion-color-step-350)
}

.placeholder-step-400::-moz-placeholder {
  color: var(--ion-color-step-400)
}

.placeholder-step-400::placeholder {
  color: var(--ion-color-step-400)
}

.placeholder-step-450::-moz-placeholder {
  color: var(--ion-color-step-450)
}

.placeholder-step-450::placeholder {
  color: var(--ion-color-step-450)
}

.placeholder-step-500::-moz-placeholder {
  color: var(--ion-color-step-500)
}

.placeholder-step-500::placeholder {
  color: var(--ion-color-step-500)
}

.placeholder-step-550::-moz-placeholder {
  color: var(--ion-color-step-550)
}

.placeholder-step-550::placeholder {
  color: var(--ion-color-step-550)
}

.placeholder-step-600::-moz-placeholder {
  color: var(--ion-color-step-600)
}

.placeholder-step-600::placeholder {
  color: var(--ion-color-step-600)
}

.placeholder-step-650::-moz-placeholder {
  color: var(--ion-color-step-650)
}

.placeholder-step-650::placeholder {
  color: var(--ion-color-step-650)
}

.placeholder-step-700::-moz-placeholder {
  color: var(--ion-color-step-700)
}

.placeholder-step-700::placeholder {
  color: var(--ion-color-step-700)
}

.placeholder-step-750::-moz-placeholder {
  color: var(--ion-color-step-750)
}

.placeholder-step-750::placeholder {
  color: var(--ion-color-step-750)
}

.placeholder-step-800::-moz-placeholder {
  color: var(--ion-color-step-800)
}

.placeholder-step-800::placeholder {
  color: var(--ion-color-step-800)
}

.placeholder-step-850::-moz-placeholder {
  color: var(--ion-color-step-850)
}

.placeholder-step-850::placeholder {
  color: var(--ion-color-step-850)
}

.placeholder-step-900::-moz-placeholder {
  color: var(--ion-color-step-900)
}

.placeholder-step-900::placeholder {
  color: var(--ion-color-step-900)
}

.placeholder-step-950::-moz-placeholder {
  color: var(--ion-color-step-950)
}

.placeholder-step-950::placeholder {
  color: var(--ion-color-step-950)
}

.focus\:placeholder-primary:focus::-moz-placeholder {
  color: var(--ion-color-primary)
}

.focus\:placeholder-primary:focus::placeholder {
  color: var(--ion-color-primary)
}

.focus\:placeholder-primary-shade:focus::-moz-placeholder {
  color: var(--ion-color-primary-shade)
}

.focus\:placeholder-primary-shade:focus::placeholder {
  color: var(--ion-color-primary-shade)
}

.focus\:placeholder-primary-tint:focus::-moz-placeholder {
  color: var(--ion-color-primary-tint)
}

.focus\:placeholder-primary-tint:focus::placeholder {
  color: var(--ion-color-primary-tint)
}

.focus\:placeholder-secondary:focus::-moz-placeholder {
  color: var(--ion-color-secondary)
}

.focus\:placeholder-secondary:focus::placeholder {
  color: var(--ion-color-secondary)
}

.focus\:placeholder-secondary-shade:focus::-moz-placeholder {
  color: var(--ion-color-secondary-shade)
}

.focus\:placeholder-secondary-shade:focus::placeholder {
  color: var(--ion-color-secondary-shade)
}

.focus\:placeholder-secondary-tint:focus::-moz-placeholder {
  color: var(--ion-color-secondary-tint)
}

.focus\:placeholder-secondary-tint:focus::placeholder {
  color: var(--ion-color-secondary-tint)
}

.focus\:placeholder-tertiary:focus::-moz-placeholder {
  color: var(--ion-color-tertiary)
}

.focus\:placeholder-tertiary:focus::placeholder {
  color: var(--ion-color-tertiary)
}

.focus\:placeholder-tertiary-shade:focus::-moz-placeholder {
  color: var(--ion-color-tertiary-shade)
}

.focus\:placeholder-tertiary-shade:focus::placeholder {
  color: var(--ion-color-tertiary-shade)
}

.focus\:placeholder-tertiary-tint:focus::-moz-placeholder {
  color: var(--ion-color-tertiary-tint)
}

.focus\:placeholder-tertiary-tint:focus::placeholder {
  color: var(--ion-color-tertiary-tint)
}

.focus\:placeholder-light:focus::-moz-placeholder {
  color: var(--ion-color-light)
}

.focus\:placeholder-light:focus::placeholder {
  color: var(--ion-color-light)
}

.focus\:placeholder-light-shade:focus::-moz-placeholder {
  color: var(--ion-color-light-shade)
}

.focus\:placeholder-light-shade:focus::placeholder {
  color: var(--ion-color-light-shade)
}

.focus\:placeholder-light-tint:focus::-moz-placeholder {
  color: var(--ion-color-light-tint)
}

.focus\:placeholder-light-tint:focus::placeholder {
  color: var(--ion-color-light-tint)
}

.focus\:placeholder-medium:focus::-moz-placeholder {
  color: var(--ion-color-medium)
}

.focus\:placeholder-medium:focus::placeholder {
  color: var(--ion-color-medium)
}

.focus\:placeholder-medium-shade:focus::-moz-placeholder {
  color: var(--ion-color-medium-shade)
}

.focus\:placeholder-medium-shade:focus::placeholder {
  color: var(--ion-color-medium-shade)
}

.focus\:placeholder-medium-tint:focus::-moz-placeholder {
  color: var(--ion-color-medium-tint)
}

.focus\:placeholder-medium-tint:focus::placeholder {
  color: var(--ion-color-medium-tint)
}

.focus\:placeholder-dark:focus::-moz-placeholder {
  color: var(--ion-color-dark)
}

.focus\:placeholder-dark:focus::placeholder {
  color: var(--ion-color-dark)
}

.focus\:placeholder-dark-shade:focus::-moz-placeholder {
  color: var(--ion-color-dark-shade)
}

.focus\:placeholder-dark-shade:focus::placeholder {
  color: var(--ion-color-dark-shade)
}

.focus\:placeholder-dark-tint:focus::-moz-placeholder {
  color: var(--ion-color-dark-tint)
}

.focus\:placeholder-dark-tint:focus::placeholder {
  color: var(--ion-color-dark-tint)
}

.focus\:placeholder-success:focus::-moz-placeholder {
  color: var(--ion-color-success)
}

.focus\:placeholder-success:focus::placeholder {
  color: var(--ion-color-success)
}

.focus\:placeholder-success-shade:focus::-moz-placeholder {
  color: var(--ion-color-success-shade)
}

.focus\:placeholder-success-shade:focus::placeholder {
  color: var(--ion-color-success-shade)
}

.focus\:placeholder-success-tint:focus::-moz-placeholder {
  color: var(--ion-color-success-tint)
}

.focus\:placeholder-success-tint:focus::placeholder {
  color: var(--ion-color-success-tint)
}

.focus\:placeholder-warning:focus::-moz-placeholder {
  color: var(--ion-color-warning)
}

.focus\:placeholder-warning:focus::placeholder {
  color: var(--ion-color-warning)
}

.focus\:placeholder-warning-shade:focus::-moz-placeholder {
  color: var(--ion-color-warning-shade)
}

.focus\:placeholder-warning-shade:focus::placeholder {
  color: var(--ion-color-warning-shade)
}

.focus\:placeholder-warning-tint:focus::-moz-placeholder {
  color: var(--ion-color-warning-tint)
}

.focus\:placeholder-warning-tint:focus::placeholder {
  color: var(--ion-color-warning-tint)
}

.focus\:placeholder-danger:focus::-moz-placeholder {
  color: var(--ion-color-danger)
}

.focus\:placeholder-danger:focus::placeholder {
  color: var(--ion-color-danger)
}

.focus\:placeholder-danger-shade:focus::-moz-placeholder {
  color: var(--ion-color-danger-shade)
}

.focus\:placeholder-danger-shade:focus::placeholder {
  color: var(--ion-color-danger-shade)
}

.focus\:placeholder-danger-tint:focus::-moz-placeholder {
  color: var(--ion-color-danger-tint)
}

.focus\:placeholder-danger-tint:focus::placeholder {
  color: var(--ion-color-danger-tint)
}

.focus\:placeholder-step-50:focus::-moz-placeholder {
  color: var(--ion-color-step-50)
}

.focus\:placeholder-step-50:focus::placeholder {
  color: var(--ion-color-step-50)
}

.focus\:placeholder-step-100:focus::-moz-placeholder {
  color: var(--ion-color-step-100)
}

.focus\:placeholder-step-100:focus::placeholder {
  color: var(--ion-color-step-100)
}

.focus\:placeholder-step-150:focus::-moz-placeholder {
  color: var(--ion-color-step-150)
}

.focus\:placeholder-step-150:focus::placeholder {
  color: var(--ion-color-step-150)
}

.focus\:placeholder-step-200:focus::-moz-placeholder {
  color: var(--ion-color-step-200)
}

.focus\:placeholder-step-200:focus::placeholder {
  color: var(--ion-color-step-200)
}

.focus\:placeholder-step-250:focus::-moz-placeholder {
  color: var(--ion-color-step-250)
}

.focus\:placeholder-step-250:focus::placeholder {
  color: var(--ion-color-step-250)
}

.focus\:placeholder-step-300:focus::-moz-placeholder {
  color: var(--ion-color-step-300)
}

.focus\:placeholder-step-300:focus::placeholder {
  color: var(--ion-color-step-300)
}

.focus\:placeholder-step-350:focus::-moz-placeholder {
  color: var(--ion-color-step-350)
}

.focus\:placeholder-step-350:focus::placeholder {
  color: var(--ion-color-step-350)
}

.focus\:placeholder-step-400:focus::-moz-placeholder {
  color: var(--ion-color-step-400)
}

.focus\:placeholder-step-400:focus::placeholder {
  color: var(--ion-color-step-400)
}

.focus\:placeholder-step-450:focus::-moz-placeholder {
  color: var(--ion-color-step-450)
}

.focus\:placeholder-step-450:focus::placeholder {
  color: var(--ion-color-step-450)
}

.focus\:placeholder-step-500:focus::-moz-placeholder {
  color: var(--ion-color-step-500)
}

.focus\:placeholder-step-500:focus::placeholder {
  color: var(--ion-color-step-500)
}

.focus\:placeholder-step-550:focus::-moz-placeholder {
  color: var(--ion-color-step-550)
}

.focus\:placeholder-step-550:focus::placeholder {
  color: var(--ion-color-step-550)
}

.focus\:placeholder-step-600:focus::-moz-placeholder {
  color: var(--ion-color-step-600)
}

.focus\:placeholder-step-600:focus::placeholder {
  color: var(--ion-color-step-600)
}

.focus\:placeholder-step-650:focus::-moz-placeholder {
  color: var(--ion-color-step-650)
}

.focus\:placeholder-step-650:focus::placeholder {
  color: var(--ion-color-step-650)
}

.focus\:placeholder-step-700:focus::-moz-placeholder {
  color: var(--ion-color-step-700)
}

.focus\:placeholder-step-700:focus::placeholder {
  color: var(--ion-color-step-700)
}

.focus\:placeholder-step-750:focus::-moz-placeholder {
  color: var(--ion-color-step-750)
}

.focus\:placeholder-step-750:focus::placeholder {
  color: var(--ion-color-step-750)
}

.focus\:placeholder-step-800:focus::-moz-placeholder {
  color: var(--ion-color-step-800)
}

.focus\:placeholder-step-800:focus::placeholder {
  color: var(--ion-color-step-800)
}

.focus\:placeholder-step-850:focus::-moz-placeholder {
  color: var(--ion-color-step-850)
}

.focus\:placeholder-step-850:focus::placeholder {
  color: var(--ion-color-step-850)
}

.focus\:placeholder-step-900:focus::-moz-placeholder {
  color: var(--ion-color-step-900)
}

.focus\:placeholder-step-900:focus::placeholder {
  color: var(--ion-color-step-900)
}

.focus\:placeholder-step-950:focus::-moz-placeholder {
  color: var(--ion-color-step-950)
}

.focus\:placeholder-step-950:focus::placeholder {
  color: var(--ion-color-step-950)
}

.placeholder-opacity-0::-moz-placeholder {
  --placeholder-opacity: 0
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0
}

.placeholder-opacity-25::-moz-placeholder {
  --placeholder-opacity: 0.25
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25
}

.placeholder-opacity-50::-moz-placeholder {
  --placeholder-opacity: 0.5
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5
}

.placeholder-opacity-75::-moz-placeholder {
  --placeholder-opacity: 0.75
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75
}

.placeholder-opacity-100::-moz-placeholder {
  --placeholder-opacity: 1
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1
}

.focus\:placeholder-opacity-0:focus::-moz-placeholder {
  --placeholder-opacity: 0
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0
}

.focus\:placeholder-opacity-25:focus::-moz-placeholder {
  --placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25
}

.focus\:placeholder-opacity-50:focus::-moz-placeholder {
  --placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5
}

.focus\:placeholder-opacity-75:focus::-moz-placeholder {
  --placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75
}

.focus\:placeholder-opacity-100:focus::-moz-placeholder {
  --placeholder-opacity: 1
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.inset-y-auto {
  top: auto;
  bottom: auto
}

.inset-x-auto {
  right: auto;
  left: auto
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.top-auto {
  top: auto
}

.right-auto {
  right: auto
}

.bottom-auto {
  bottom: auto
}

.left-auto {
  left: auto
}

.resize-none {
  resize: none
}

.resize-y {
  resize: vertical
}

.resize-x {
  resize: horizontal
}

.resize {
  resize: both
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.shadow-none {
  box-shadow: none
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.hover\:shadow-none:hover {
  box-shadow: none
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
}

.focus\:shadow-none:focus {
  box-shadow: none
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.stroke-0 {
  stroke-width: 0
}

.stroke-1 {
  stroke-width: 1
}

.stroke-2 {
  stroke-width: 2
}

.table-auto {
  table-layout: auto
}

.table-fixed {
  table-layout: fixed
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-primary {
  color: var(--ion-color-primary)
}

.text-primary-shade {
  color: var(--ion-color-primary-shade)
}

.text-primary-tint {
  color: var(--ion-color-primary-tint)
}

.text-secondary {
  color: var(--ion-color-secondary)
}

.text-secondary-shade {
  color: var(--ion-color-secondary-shade)
}

.text-secondary-tint {
  color: var(--ion-color-secondary-tint)
}

.text-tertiary {
  color: var(--ion-color-tertiary)
}

.text-tertiary-shade {
  color: var(--ion-color-tertiary-shade)
}

.text-tertiary-tint {
  color: var(--ion-color-tertiary-tint)
}

.text-light {
  color: var(--ion-color-light)
}

.text-light-shade {
  color: var(--ion-color-light-shade)
}

.text-light-tint {
  color: var(--ion-color-light-tint)
}

.text-medium {
  color: var(--ion-color-medium)
}

.text-medium-shade {
  color: var(--ion-color-medium-shade)
}

.text-medium-tint {
  color: var(--ion-color-medium-tint)
}

.text-dark {
  color: var(--ion-color-dark)
}

.text-dark-shade {
  color: var(--ion-color-dark-shade)
}

.text-dark-tint {
  color: var(--ion-color-dark-tint)
}

.text-success {
  color: var(--ion-color-success)
}

.text-success-shade {
  color: var(--ion-color-success-shade)
}

.text-success-tint {
  color: var(--ion-color-success-tint)
}

.text-warning {
  color: var(--ion-color-warning)
}

.text-warning-shade {
  color: var(--ion-color-warning-shade)
}

.text-warning-tint {
  color: var(--ion-color-warning-tint)
}

.text-danger {
  color: var(--ion-color-danger)
}

.text-danger-shade {
  color: var(--ion-color-danger-shade)
}

.text-danger-tint {
  color: var(--ion-color-danger-tint)
}

.text-step-50 {
  color: var(--ion-color-step-50)
}

.text-step-100 {
  color: var(--ion-color-step-100)
}

.text-step-150 {
  color: var(--ion-color-step-150)
}

.text-step-200 {
  color: var(--ion-color-step-200)
}

.text-step-250 {
  color: var(--ion-color-step-250)
}

.text-step-300 {
  color: var(--ion-color-step-300)
}

.text-step-350 {
  color: var(--ion-color-step-350)
}

.text-step-400 {
  color: var(--ion-color-step-400)
}

.text-step-450 {
  color: var(--ion-color-step-450)
}

.text-step-500 {
  color: var(--ion-color-step-500)
}

.text-step-550 {
  color: var(--ion-color-step-550)
}

.text-step-600 {
  color: var(--ion-color-step-600)
}

.text-step-650 {
  color: var(--ion-color-step-650)
}

.text-step-700 {
  color: var(--ion-color-step-700)
}

.text-step-750 {
  color: var(--ion-color-step-750)
}

.text-step-800 {
  color: var(--ion-color-step-800)
}

.text-step-850 {
  color: var(--ion-color-step-850)
}

.text-step-900 {
  color: var(--ion-color-step-900)
}

.text-step-950 {
  color: var(--ion-color-step-950)
}

.hover\:text-primary:hover {
  color: var(--ion-color-primary)
}

.hover\:text-primary-shade:hover {
  color: var(--ion-color-primary-shade)
}

.hover\:text-primary-tint:hover {
  color: var(--ion-color-primary-tint)
}

.hover\:text-secondary:hover {
  color: var(--ion-color-secondary)
}

.hover\:text-secondary-shade:hover {
  color: var(--ion-color-secondary-shade)
}

.hover\:text-secondary-tint:hover {
  color: var(--ion-color-secondary-tint)
}

.hover\:text-tertiary:hover {
  color: var(--ion-color-tertiary)
}

.hover\:text-tertiary-shade:hover {
  color: var(--ion-color-tertiary-shade)
}

.hover\:text-tertiary-tint:hover {
  color: var(--ion-color-tertiary-tint)
}

.hover\:text-light:hover {
  color: var(--ion-color-light)
}

.hover\:text-light-shade:hover {
  color: var(--ion-color-light-shade)
}

.hover\:text-light-tint:hover {
  color: var(--ion-color-light-tint)
}

.hover\:text-medium:hover {
  color: var(--ion-color-medium)
}

.hover\:text-medium-shade:hover {
  color: var(--ion-color-medium-shade)
}

.hover\:text-medium-tint:hover {
  color: var(--ion-color-medium-tint)
}

.hover\:text-dark:hover {
  color: var(--ion-color-dark)
}

.hover\:text-dark-shade:hover {
  color: var(--ion-color-dark-shade)
}

.hover\:text-dark-tint:hover {
  color: var(--ion-color-dark-tint)
}

.hover\:text-success:hover {
  color: var(--ion-color-success)
}

.hover\:text-success-shade:hover {
  color: var(--ion-color-success-shade)
}

.hover\:text-success-tint:hover {
  color: var(--ion-color-success-tint)
}

.hover\:text-warning:hover {
  color: var(--ion-color-warning)
}

.hover\:text-warning-shade:hover {
  color: var(--ion-color-warning-shade)
}

.hover\:text-warning-tint:hover {
  color: var(--ion-color-warning-tint)
}

.hover\:text-danger:hover {
  color: var(--ion-color-danger)
}

.hover\:text-danger-shade:hover {
  color: var(--ion-color-danger-shade)
}

.hover\:text-danger-tint:hover {
  color: var(--ion-color-danger-tint)
}

.hover\:text-step-50:hover {
  color: var(--ion-color-step-50)
}

.hover\:text-step-100:hover {
  color: var(--ion-color-step-100)
}

.hover\:text-step-150:hover {
  color: var(--ion-color-step-150)
}

.hover\:text-step-200:hover {
  color: var(--ion-color-step-200)
}

.hover\:text-step-250:hover {
  color: var(--ion-color-step-250)
}

.hover\:text-step-300:hover {
  color: var(--ion-color-step-300)
}

.hover\:text-step-350:hover {
  color: var(--ion-color-step-350)
}

.hover\:text-step-400:hover {
  color: var(--ion-color-step-400)
}

.hover\:text-step-450:hover {
  color: var(--ion-color-step-450)
}

.hover\:text-step-500:hover {
  color: var(--ion-color-step-500)
}

.hover\:text-step-550:hover {
  color: var(--ion-color-step-550)
}

.hover\:text-step-600:hover {
  color: var(--ion-color-step-600)
}

.hover\:text-step-650:hover {
  color: var(--ion-color-step-650)
}

.hover\:text-step-700:hover {
  color: var(--ion-color-step-700)
}

.hover\:text-step-750:hover {
  color: var(--ion-color-step-750)
}

.hover\:text-step-800:hover {
  color: var(--ion-color-step-800)
}

.hover\:text-step-850:hover {
  color: var(--ion-color-step-850)
}

.hover\:text-step-900:hover {
  color: var(--ion-color-step-900)
}

.hover\:text-step-950:hover {
  color: var(--ion-color-step-950)
}

.focus\:text-primary:focus {
  color: var(--ion-color-primary)
}

.focus\:text-primary-shade:focus {
  color: var(--ion-color-primary-shade)
}

.focus\:text-primary-tint:focus {
  color: var(--ion-color-primary-tint)
}

.focus\:text-secondary:focus {
  color: var(--ion-color-secondary)
}

.focus\:text-secondary-shade:focus {
  color: var(--ion-color-secondary-shade)
}

.focus\:text-secondary-tint:focus {
  color: var(--ion-color-secondary-tint)
}

.focus\:text-tertiary:focus {
  color: var(--ion-color-tertiary)
}

.focus\:text-tertiary-shade:focus {
  color: var(--ion-color-tertiary-shade)
}

.focus\:text-tertiary-tint:focus {
  color: var(--ion-color-tertiary-tint)
}

.focus\:text-light:focus {
  color: var(--ion-color-light)
}

.focus\:text-light-shade:focus {
  color: var(--ion-color-light-shade)
}

.focus\:text-light-tint:focus {
  color: var(--ion-color-light-tint)
}

.focus\:text-medium:focus {
  color: var(--ion-color-medium)
}

.focus\:text-medium-shade:focus {
  color: var(--ion-color-medium-shade)
}

.focus\:text-medium-tint:focus {
  color: var(--ion-color-medium-tint)
}

.focus\:text-dark:focus {
  color: var(--ion-color-dark)
}

.focus\:text-dark-shade:focus {
  color: var(--ion-color-dark-shade)
}

.focus\:text-dark-tint:focus {
  color: var(--ion-color-dark-tint)
}

.focus\:text-success:focus {
  color: var(--ion-color-success)
}

.focus\:text-success-shade:focus {
  color: var(--ion-color-success-shade)
}

.focus\:text-success-tint:focus {
  color: var(--ion-color-success-tint)
}

.focus\:text-warning:focus {
  color: var(--ion-color-warning)
}

.focus\:text-warning-shade:focus {
  color: var(--ion-color-warning-shade)
}

.focus\:text-warning-tint:focus {
  color: var(--ion-color-warning-tint)
}

.focus\:text-danger:focus {
  color: var(--ion-color-danger)
}

.focus\:text-danger-shade:focus {
  color: var(--ion-color-danger-shade)
}

.focus\:text-danger-tint:focus {
  color: var(--ion-color-danger-tint)
}

.focus\:text-step-50:focus {
  color: var(--ion-color-step-50)
}

.focus\:text-step-100:focus {
  color: var(--ion-color-step-100)
}

.focus\:text-step-150:focus {
  color: var(--ion-color-step-150)
}

.focus\:text-step-200:focus {
  color: var(--ion-color-step-200)
}

.focus\:text-step-250:focus {
  color: var(--ion-color-step-250)
}

.focus\:text-step-300:focus {
  color: var(--ion-color-step-300)
}

.focus\:text-step-350:focus {
  color: var(--ion-color-step-350)
}

.focus\:text-step-400:focus {
  color: var(--ion-color-step-400)
}

.focus\:text-step-450:focus {
  color: var(--ion-color-step-450)
}

.focus\:text-step-500:focus {
  color: var(--ion-color-step-500)
}

.focus\:text-step-550:focus {
  color: var(--ion-color-step-550)
}

.focus\:text-step-600:focus {
  color: var(--ion-color-step-600)
}

.focus\:text-step-650:focus {
  color: var(--ion-color-step-650)
}

.focus\:text-step-700:focus {
  color: var(--ion-color-step-700)
}

.focus\:text-step-750:focus {
  color: var(--ion-color-step-750)
}

.focus\:text-step-800:focus {
  color: var(--ion-color-step-800)
}

.focus\:text-step-850:focus {
  color: var(--ion-color-step-850)
}

.focus\:text-step-900:focus {
  color: var(--ion-color-step-900)
}

.focus\:text-step-950:focus {
  color: var(--ion-color-step-950)
}

.italic {
  font-style: italic
}

.not-italic {
  font-style: normal
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.line-through {
  text-decoration: line-through
}

.no-underline {
  text-decoration: none
}

.hover\:underline:hover {
  text-decoration: underline
}

.hover\:line-through:hover {
  text-decoration: line-through
}

.hover\:no-underline:hover {
  text-decoration: none
}

.focus\:underline:focus {
  text-decoration: underline
}

.focus\:line-through:focus {
  text-decoration: line-through
}

.focus\:no-underline:focus {
  text-decoration: none
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
}

.normal-nums {
  font-variant-numeric: normal
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions
}

.tracking-tighter {
  letter-spacing: -0.05em
}

.tracking-tight {
  letter-spacing: -0.025em
}

.tracking-normal {
  letter-spacing: 0
}

.tracking-wide {
  letter-spacing: 0.025em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.tracking-widest {
  letter-spacing: 0.1em
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none
}

.select-text {
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text
}

.select-all {
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all
}

.select-auto {
  -webkit-user-select: auto;
     -moz-user-select: auto;
          user-select: auto
}

.align-baseline {
  vertical-align: baseline
}

.align-top {
  vertical-align: top
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.align-text-top {
  vertical-align: text-top
}

.align-text-bottom {
  vertical-align: text-bottom
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-normal {
  white-space: normal
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-0 {
  width: 0
}

.w-1 {
  width: 0.25rem
}

.w-2 {
  width: 0.5rem
}

.w-3 {
  width: 0.75rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-8 {
  width: 2rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-20 {
  width: 5rem
}

.w-24 {
  width: 6rem
}

.w-32 {
  width: 8rem
}

.w-40 {
  width: 10rem
}

.w-48 {
  width: 12rem
}

.w-56 {
  width: 14rem
}

.w-64 {
  width: 16rem
}

.w-auto {
  width: auto
}

.w-px {
  width: 1px
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-3\/6 {
  width: 50%
}

.w-4\/6 {
  width: 66.666667%
}

.w-5\/6 {
  width: 83.333333%
}

.w-1\/12 {
  width: 8.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-3\/12 {
  width: 25%
}

.w-4\/12 {
  width: 33.333333%
}

.w-5\/12 {
  width: 41.666667%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-9\/12 {
  width: 75%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-auto {
  z-index: auto
}

.gap-0 {
  grid-gap: 0;
  gap: 0
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem
}

.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem
}

.gap-16 {
  grid-gap: 4rem;
  gap: 4rem
}

.gap-20 {
  grid-gap: 5rem;
  gap: 5rem
}

.gap-24 {
  grid-gap: 6rem;
  gap: 6rem
}

.gap-32 {
  grid-gap: 8rem;
  gap: 8rem
}

.gap-40 {
  grid-gap: 10rem;
  gap: 10rem
}

.gap-48 {
  grid-gap: 12rem;
  gap: 12rem
}

.gap-56 {
  grid-gap: 14rem;
  gap: 14rem
}

.gap-64 {
  grid-gap: 16rem;
  gap: 16rem
}

.gap-px {
  grid-gap: 1px;
  gap: 1px
}

.col-gap-0 {
  grid-column-gap: 0;
  -moz-column-gap: 0;
       column-gap: 0
}

.col-gap-1 {
  grid-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem
}

.col-gap-2 {
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem
}

.col-gap-3 {
  grid-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem
}

.col-gap-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem
}

.col-gap-5 {
  grid-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem
}

.col-gap-6 {
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem
}

.col-gap-8 {
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem
}

.col-gap-10 {
  grid-column-gap: 2.5rem;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem
}

.col-gap-12 {
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem
}

.col-gap-16 {
  grid-column-gap: 4rem;
  -moz-column-gap: 4rem;
       column-gap: 4rem
}

.col-gap-20 {
  grid-column-gap: 5rem;
  -moz-column-gap: 5rem;
       column-gap: 5rem
}

.col-gap-24 {
  grid-column-gap: 6rem;
  -moz-column-gap: 6rem;
       column-gap: 6rem
}

.col-gap-32 {
  grid-column-gap: 8rem;
  -moz-column-gap: 8rem;
       column-gap: 8rem
}

.col-gap-40 {
  grid-column-gap: 10rem;
  -moz-column-gap: 10rem;
       column-gap: 10rem
}

.col-gap-48 {
  grid-column-gap: 12rem;
  -moz-column-gap: 12rem;
       column-gap: 12rem
}

.col-gap-56 {
  grid-column-gap: 14rem;
  -moz-column-gap: 14rem;
       column-gap: 14rem
}

.col-gap-64 {
  grid-column-gap: 16rem;
  -moz-column-gap: 16rem;
       column-gap: 16rem
}

.col-gap-px {
  grid-column-gap: 1px;
  -moz-column-gap: 1px;
       column-gap: 1px
}

.gap-x-0 {
  grid-column-gap: 0;
  -moz-column-gap: 0;
       column-gap: 0
}

.gap-x-1 {
  grid-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem
}

.gap-x-2 {
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem
}

.gap-x-3 {
  grid-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem
}

.gap-x-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem
}

.gap-x-5 {
  grid-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem
}

.gap-x-6 {
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem
}

.gap-x-8 {
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem
}

.gap-x-10 {
  grid-column-gap: 2.5rem;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem
}

.gap-x-12 {
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem
}

.gap-x-16 {
  grid-column-gap: 4rem;
  -moz-column-gap: 4rem;
       column-gap: 4rem
}

.gap-x-20 {
  grid-column-gap: 5rem;
  -moz-column-gap: 5rem;
       column-gap: 5rem
}

.gap-x-24 {
  grid-column-gap: 6rem;
  -moz-column-gap: 6rem;
       column-gap: 6rem
}

.gap-x-32 {
  grid-column-gap: 8rem;
  -moz-column-gap: 8rem;
       column-gap: 8rem
}

.gap-x-40 {
  grid-column-gap: 10rem;
  -moz-column-gap: 10rem;
       column-gap: 10rem
}

.gap-x-48 {
  grid-column-gap: 12rem;
  -moz-column-gap: 12rem;
       column-gap: 12rem
}

.gap-x-56 {
  grid-column-gap: 14rem;
  -moz-column-gap: 14rem;
       column-gap: 14rem
}

.gap-x-64 {
  grid-column-gap: 16rem;
  -moz-column-gap: 16rem;
       column-gap: 16rem
}

.gap-x-px {
  grid-column-gap: 1px;
  -moz-column-gap: 1px;
       column-gap: 1px
}

.row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0
}

.row-gap-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.row-gap-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px
}

.gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0
}

.gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem
}

.gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem
}

.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem
}

.gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem
}

.gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem
}

.gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem
}

.gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem
}

.gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem
}

.gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem
}

.gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem
}

.gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px
}

.grid-flow-row {
  grid-auto-flow: row
}

.grid-flow-col {
  grid-auto-flow: column
}

.grid-flow-row-dense {
  grid-auto-flow: row dense
}

.grid-flow-col-dense {
  grid-auto-flow: column dense
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr))
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr))
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr))
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-none {
  grid-template-columns: none
}

.auto-cols-auto {
  grid-auto-columns: auto
}

.auto-cols-min {
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content
}

.auto-cols-max {
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr)
}

.col-auto {
  grid-column: auto
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.col-span-7 {
  grid-column: span 7 / span 7
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.col-span-10 {
  grid-column: span 10 / span 10
}

.col-span-11 {
  grid-column: span 11 / span 11
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-span-full {
  grid-column: 1 / -1
}

.col-start-1 {
  grid-column-start: 1
}

.col-start-2 {
  grid-column-start: 2
}

.col-start-3 {
  grid-column-start: 3
}

.col-start-4 {
  grid-column-start: 4
}

.col-start-5 {
  grid-column-start: 5
}

.col-start-6 {
  grid-column-start: 6
}

.col-start-7 {
  grid-column-start: 7
}

.col-start-8 {
  grid-column-start: 8
}

.col-start-9 {
  grid-column-start: 9
}

.col-start-10 {
  grid-column-start: 10
}

.col-start-11 {
  grid-column-start: 11
}

.col-start-12 {
  grid-column-start: 12
}

.col-start-13 {
  grid-column-start: 13
}

.col-start-auto {
  grid-column-start: auto
}

.col-end-1 {
  grid-column-end: 1
}

.col-end-2 {
  grid-column-end: 2
}

.col-end-3 {
  grid-column-end: 3
}

.col-end-4 {
  grid-column-end: 4
}

.col-end-5 {
  grid-column-end: 5
}

.col-end-6 {
  grid-column-end: 6
}

.col-end-7 {
  grid-column-end: 7
}

.col-end-8 {
  grid-column-end: 8
}

.col-end-9 {
  grid-column-end: 9
}

.col-end-10 {
  grid-column-end: 10
}

.col-end-11 {
  grid-column-end: 11
}

.col-end-12 {
  grid-column-end: 12
}

.col-end-13 {
  grid-column-end: 13
}

.col-end-auto {
  grid-column-end: auto
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr))
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr))
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr))
}

.grid-rows-none {
  grid-template-rows: none
}

.auto-rows-auto {
  grid-auto-rows: auto
}

.auto-rows-min {
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr)
}

.row-auto {
  grid-row: auto
}

.row-span-1 {
  grid-row: span 1 / span 1
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.row-span-3 {
  grid-row: span 3 / span 3
}

.row-span-4 {
  grid-row: span 4 / span 4
}

.row-span-5 {
  grid-row: span 5 / span 5
}

.row-span-6 {
  grid-row: span 6 / span 6
}

.row-span-full {
  grid-row: 1 / -1
}

.row-start-1 {
  grid-row-start: 1
}

.row-start-2 {
  grid-row-start: 2
}

.row-start-3 {
  grid-row-start: 3
}

.row-start-4 {
  grid-row-start: 4
}

.row-start-5 {
  grid-row-start: 5
}

.row-start-6 {
  grid-row-start: 6
}

.row-start-7 {
  grid-row-start: 7
}

.row-start-auto {
  grid-row-start: auto
}

.row-end-1 {
  grid-row-end: 1
}

.row-end-2 {
  grid-row-end: 2
}

.row-end-3 {
  grid-row-end: 3
}

.row-end-4 {
  grid-row-end: 4
}

.row-end-5 {
  grid-row-end: 5
}

.row-end-6 {
  grid-row-end: 6
}

.row-end-7 {
  grid-row-end: 7
}

.row-end-auto {
  grid-row-end: auto
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transform-none {
  transform: none
}

.origin-center {
  transform-origin: center
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}

.scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.scale-50 {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.scale-75 {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.scale-90 {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.scale-x-0 {
  --transform-scale-x: 0
}

.scale-x-50 {
  --transform-scale-x: .5
}

.scale-x-75 {
  --transform-scale-x: .75
}

.scale-x-90 {
  --transform-scale-x: .9
}

.scale-x-95 {
  --transform-scale-x: .95
}

.scale-x-100 {
  --transform-scale-x: 1
}

.scale-x-105 {
  --transform-scale-x: 1.05
}

.scale-x-110 {
  --transform-scale-x: 1.1
}

.scale-x-125 {
  --transform-scale-x: 1.25
}

.scale-x-150 {
  --transform-scale-x: 1.5
}

.scale-y-0 {
  --transform-scale-y: 0
}

.scale-y-50 {
  --transform-scale-y: .5
}

.scale-y-75 {
  --transform-scale-y: .75
}

.scale-y-90 {
  --transform-scale-y: .9
}

.scale-y-95 {
  --transform-scale-y: .95
}

.scale-y-100 {
  --transform-scale-y: 1
}

.scale-y-105 {
  --transform-scale-y: 1.05
}

.scale-y-110 {
  --transform-scale-y: 1.1
}

.scale-y-125 {
  --transform-scale-y: 1.25
}

.scale-y-150 {
  --transform-scale-y: 1.5
}

.hover\:scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.hover\:scale-50:hover {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.hover\:scale-75:hover {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.hover\:scale-90:hover {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.hover\:scale-95:hover {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.hover\:scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0
}

.hover\:scale-x-50:hover {
  --transform-scale-x: .5
}

.hover\:scale-x-75:hover {
  --transform-scale-x: .75
}

.hover\:scale-x-90:hover {
  --transform-scale-x: .9
}

.hover\:scale-x-95:hover {
  --transform-scale-x: .95
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0
}

.hover\:scale-y-50:hover {
  --transform-scale-y: .5
}

.hover\:scale-y-75:hover {
  --transform-scale-y: .75
}

.hover\:scale-y-90:hover {
  --transform-scale-y: .9
}

.hover\:scale-y-95:hover {
  --transform-scale-y: .95
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5
}

.focus\:scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0
}

.focus\:scale-50:focus {
  --transform-scale-x: .5;
  --transform-scale-y: .5
}

.focus\:scale-75:focus {
  --transform-scale-x: .75;
  --transform-scale-y: .75
}

.focus\:scale-90:focus {
  --transform-scale-x: .9;
  --transform-scale-y: .9
}

.focus\:scale-95:focus {
  --transform-scale-x: .95;
  --transform-scale-y: .95
}

.focus\:scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0
}

.focus\:scale-x-50:focus {
  --transform-scale-x: .5
}

.focus\:scale-x-75:focus {
  --transform-scale-x: .75
}

.focus\:scale-x-90:focus {
  --transform-scale-x: .9
}

.focus\:scale-x-95:focus {
  --transform-scale-x: .95
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0
}

.focus\:scale-y-50:focus {
  --transform-scale-y: .5
}

.focus\:scale-y-75:focus {
  --transform-scale-y: .75
}

.focus\:scale-y-90:focus {
  --transform-scale-y: .9
}

.focus\:scale-y-95:focus {
  --transform-scale-y: .95
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5
}

.rotate-0 {
  --transform-rotate: 0
}

.rotate-1 {
  --transform-rotate: 1deg
}

.rotate-2 {
  --transform-rotate: 2deg
}

.rotate-3 {
  --transform-rotate: 3deg
}

.rotate-6 {
  --transform-rotate: 6deg
}

.rotate-12 {
  --transform-rotate: 12deg
}

.rotate-45 {
  --transform-rotate: 45deg
}

.rotate-90 {
  --transform-rotate: 90deg
}

.rotate-180 {
  --transform-rotate: 180deg
}

.-rotate-180 {
  --transform-rotate: -180deg
}

.-rotate-90 {
  --transform-rotate: -90deg
}

.-rotate-45 {
  --transform-rotate: -45deg
}

.-rotate-12 {
  --transform-rotate: -12deg
}

.-rotate-6 {
  --transform-rotate: -6deg
}

.-rotate-3 {
  --transform-rotate: -3deg
}

.-rotate-2 {
  --transform-rotate: -2deg
}

.-rotate-1 {
  --transform-rotate: -1deg
}

.hover\:rotate-0:hover {
  --transform-rotate: 0
}

.hover\:rotate-1:hover {
  --transform-rotate: 1deg
}

.hover\:rotate-2:hover {
  --transform-rotate: 2deg
}

.hover\:rotate-3:hover {
  --transform-rotate: 3deg
}

.hover\:rotate-6:hover {
  --transform-rotate: 6deg
}

.hover\:rotate-12:hover {
  --transform-rotate: 12deg
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg
}

.hover\:-rotate-12:hover {
  --transform-rotate: -12deg
}

.hover\:-rotate-6:hover {
  --transform-rotate: -6deg
}

.hover\:-rotate-3:hover {
  --transform-rotate: -3deg
}

.hover\:-rotate-2:hover {
  --transform-rotate: -2deg
}

.hover\:-rotate-1:hover {
  --transform-rotate: -1deg
}

.focus\:rotate-0:focus {
  --transform-rotate: 0
}

.focus\:rotate-1:focus {
  --transform-rotate: 1deg
}

.focus\:rotate-2:focus {
  --transform-rotate: 2deg
}

.focus\:rotate-3:focus {
  --transform-rotate: 3deg
}

.focus\:rotate-6:focus {
  --transform-rotate: 6deg
}

.focus\:rotate-12:focus {
  --transform-rotate: 12deg
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg
}

.focus\:-rotate-12:focus {
  --transform-rotate: -12deg
}

.focus\:-rotate-6:focus {
  --transform-rotate: -6deg
}

.focus\:-rotate-3:focus {
  --transform-rotate: -3deg
}

.focus\:-rotate-2:focus {
  --transform-rotate: -2deg
}

.focus\:-rotate-1:focus {
  --transform-rotate: -1deg
}

.translate-x-0 {
  --transform-translate-x: 0
}

.translate-x-1 {
  --transform-translate-x: 0.25rem
}

.translate-x-2 {
  --transform-translate-x: 0.5rem
}

.translate-x-3 {
  --transform-translate-x: 0.75rem
}

.translate-x-4 {
  --transform-translate-x: 1rem
}

.translate-x-5 {
  --transform-translate-x: 1.25rem
}

.translate-x-6 {
  --transform-translate-x: 1.5rem
}

.translate-x-8 {
  --transform-translate-x: 2rem
}

.translate-x-10 {
  --transform-translate-x: 2.5rem
}

.translate-x-12 {
  --transform-translate-x: 3rem
}

.translate-x-16 {
  --transform-translate-x: 4rem
}

.translate-x-20 {
  --transform-translate-x: 5rem
}

.translate-x-24 {
  --transform-translate-x: 6rem
}

.translate-x-32 {
  --transform-translate-x: 8rem
}

.translate-x-40 {
  --transform-translate-x: 10rem
}

.translate-x-48 {
  --transform-translate-x: 12rem
}

.translate-x-56 {
  --transform-translate-x: 14rem
}

.translate-x-64 {
  --transform-translate-x: 16rem
}

.translate-x-px {
  --transform-translate-x: 1px
}

.-translate-x-1 {
  --transform-translate-x: -0.25rem
}

.-translate-x-2 {
  --transform-translate-x: -0.5rem
}

.-translate-x-3 {
  --transform-translate-x: -0.75rem
}

.-translate-x-4 {
  --transform-translate-x: -1rem
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem
}

.-translate-x-8 {
  --transform-translate-x: -2rem
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem
}

.-translate-x-12 {
  --transform-translate-x: -3rem
}

.-translate-x-16 {
  --transform-translate-x: -4rem
}

.-translate-x-20 {
  --transform-translate-x: -5rem
}

.-translate-x-24 {
  --transform-translate-x: -6rem
}

.-translate-x-32 {
  --transform-translate-x: -8rem
}

.-translate-x-40 {
  --transform-translate-x: -10rem
}

.-translate-x-48 {
  --transform-translate-x: -12rem
}

.-translate-x-56 {
  --transform-translate-x: -14rem
}

.-translate-x-64 {
  --transform-translate-x: -16rem
}

.-translate-x-px {
  --transform-translate-x: -1px
}

.-translate-x-full {
  --transform-translate-x: -100%
}

.-translate-x-1\/2 {
  --transform-translate-x: -50%
}

.translate-x-1\/2 {
  --transform-translate-x: 50%
}

.translate-x-full {
  --transform-translate-x: 100%
}

.translate-y-0 {
  --transform-translate-y: 0
}

.translate-y-1 {
  --transform-translate-y: 0.25rem
}

.translate-y-2 {
  --transform-translate-y: 0.5rem
}

.translate-y-3 {
  --transform-translate-y: 0.75rem
}

.translate-y-4 {
  --transform-translate-y: 1rem
}

.translate-y-5 {
  --transform-translate-y: 1.25rem
}

.translate-y-6 {
  --transform-translate-y: 1.5rem
}

.translate-y-8 {
  --transform-translate-y: 2rem
}

.translate-y-10 {
  --transform-translate-y: 2.5rem
}

.translate-y-12 {
  --transform-translate-y: 3rem
}

.translate-y-16 {
  --transform-translate-y: 4rem
}

.translate-y-20 {
  --transform-translate-y: 5rem
}

.translate-y-24 {
  --transform-translate-y: 6rem
}

.translate-y-32 {
  --transform-translate-y: 8rem
}

.translate-y-40 {
  --transform-translate-y: 10rem
}

.translate-y-48 {
  --transform-translate-y: 12rem
}

.translate-y-56 {
  --transform-translate-y: 14rem
}

.translate-y-64 {
  --transform-translate-y: 16rem
}

.translate-y-px {
  --transform-translate-y: 1px
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem
}

.-translate-y-2 {
  --transform-translate-y: -0.5rem
}

.-translate-y-3 {
  --transform-translate-y: -0.75rem
}

.-translate-y-4 {
  --transform-translate-y: -1rem
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem
}

.-translate-y-8 {
  --transform-translate-y: -2rem
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem
}

.-translate-y-12 {
  --transform-translate-y: -3rem
}

.-translate-y-16 {
  --transform-translate-y: -4rem
}

.-translate-y-20 {
  --transform-translate-y: -5rem
}

.-translate-y-24 {
  --transform-translate-y: -6rem
}

.-translate-y-32 {
  --transform-translate-y: -8rem
}

.-translate-y-40 {
  --transform-translate-y: -10rem
}

.-translate-y-48 {
  --transform-translate-y: -12rem
}

.-translate-y-56 {
  --transform-translate-y: -14rem
}

.-translate-y-64 {
  --transform-translate-y: -16rem
}

.-translate-y-px {
  --transform-translate-y: -1px
}

.-translate-y-full {
  --transform-translate-y: -100%
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%
}

.translate-y-1\/2 {
  --transform-translate-y: 50%
}

.translate-y-full {
  --transform-translate-y: 100%
}

.hover\:translate-x-0:hover {
  --transform-translate-x: 0
}

.hover\:translate-x-1:hover {
  --transform-translate-x: 0.25rem
}

.hover\:translate-x-2:hover {
  --transform-translate-x: 0.5rem
}

.hover\:translate-x-3:hover {
  --transform-translate-x: 0.75rem
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 1rem
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 1.25rem
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 1.5rem
}

.hover\:translate-x-8:hover {
  --transform-translate-x: 2rem
}

.hover\:translate-x-10:hover {
  --transform-translate-x: 2.5rem
}

.hover\:translate-x-12:hover {
  --transform-translate-x: 3rem
}

.hover\:translate-x-16:hover {
  --transform-translate-x: 4rem
}

.hover\:translate-x-20:hover {
  --transform-translate-x: 5rem
}

.hover\:translate-x-24:hover {
  --transform-translate-x: 6rem
}

.hover\:translate-x-32:hover {
  --transform-translate-x: 8rem
}

.hover\:translate-x-40:hover {
  --transform-translate-x: 10rem
}

.hover\:translate-x-48:hover {
  --transform-translate-x: 12rem
}

.hover\:translate-x-56:hover {
  --transform-translate-x: 14rem
}

.hover\:translate-x-64:hover {
  --transform-translate-x: 16rem
}

.hover\:translate-x-px:hover {
  --transform-translate-x: 1px
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -0.25rem
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -0.5rem
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -0.75rem
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -1rem
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -1.25rem
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -1.5rem
}

.hover\:-translate-x-8:hover {
  --transform-translate-x: -2rem
}

.hover\:-translate-x-10:hover {
  --transform-translate-x: -2.5rem
}

.hover\:-translate-x-12:hover {
  --transform-translate-x: -3rem
}

.hover\:-translate-x-16:hover {
  --transform-translate-x: -4rem
}

.hover\:-translate-x-20:hover {
  --transform-translate-x: -5rem
}

.hover\:-translate-x-24:hover {
  --transform-translate-x: -6rem
}

.hover\:-translate-x-32:hover {
  --transform-translate-x: -8rem
}

.hover\:-translate-x-40:hover {
  --transform-translate-x: -10rem
}

.hover\:-translate-x-48:hover {
  --transform-translate-x: -12rem
}

.hover\:-translate-x-56:hover {
  --transform-translate-x: -14rem
}

.hover\:-translate-x-64:hover {
  --transform-translate-x: -16rem
}

.hover\:-translate-x-px:hover {
  --transform-translate-x: -1px
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100%
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50%
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50%
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100%
}

.hover\:translate-y-0:hover {
  --transform-translate-y: 0
}

.hover\:translate-y-1:hover {
  --transform-translate-y: 0.25rem
}

.hover\:translate-y-2:hover {
  --transform-translate-y: 0.5rem
}

.hover\:translate-y-3:hover {
  --transform-translate-y: 0.75rem
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 1rem
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 1.25rem
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 1.5rem
}

.hover\:translate-y-8:hover {
  --transform-translate-y: 2rem
}

.hover\:translate-y-10:hover {
  --transform-translate-y: 2.5rem
}

.hover\:translate-y-12:hover {
  --transform-translate-y: 3rem
}

.hover\:translate-y-16:hover {
  --transform-translate-y: 4rem
}

.hover\:translate-y-20:hover {
  --transform-translate-y: 5rem
}

.hover\:translate-y-24:hover {
  --transform-translate-y: 6rem
}

.hover\:translate-y-32:hover {
  --transform-translate-y: 8rem
}

.hover\:translate-y-40:hover {
  --transform-translate-y: 10rem
}

.hover\:translate-y-48:hover {
  --transform-translate-y: 12rem
}

.hover\:translate-y-56:hover {
  --transform-translate-y: 14rem
}

.hover\:translate-y-64:hover {
  --transform-translate-y: 16rem
}

.hover\:translate-y-px:hover {
  --transform-translate-y: 1px
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -0.25rem
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -0.5rem
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -0.75rem
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -1rem
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -1.25rem
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -1.5rem
}

.hover\:-translate-y-8:hover {
  --transform-translate-y: -2rem
}

.hover\:-translate-y-10:hover {
  --transform-translate-y: -2.5rem
}

.hover\:-translate-y-12:hover {
  --transform-translate-y: -3rem
}

.hover\:-translate-y-16:hover {
  --transform-translate-y: -4rem
}

.hover\:-translate-y-20:hover {
  --transform-translate-y: -5rem
}

.hover\:-translate-y-24:hover {
  --transform-translate-y: -6rem
}

.hover\:-translate-y-32:hover {
  --transform-translate-y: -8rem
}

.hover\:-translate-y-40:hover {
  --transform-translate-y: -10rem
}

.hover\:-translate-y-48:hover {
  --transform-translate-y: -12rem
}

.hover\:-translate-y-56:hover {
  --transform-translate-y: -14rem
}

.hover\:-translate-y-64:hover {
  --transform-translate-y: -16rem
}

.hover\:-translate-y-px:hover {
  --transform-translate-y: -1px
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100%
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50%
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50%
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100%
}

.focus\:translate-x-0:focus {
  --transform-translate-x: 0
}

.focus\:translate-x-1:focus {
  --transform-translate-x: 0.25rem
}

.focus\:translate-x-2:focus {
  --transform-translate-x: 0.5rem
}

.focus\:translate-x-3:focus {
  --transform-translate-x: 0.75rem
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 1rem
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 1.25rem
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 1.5rem
}

.focus\:translate-x-8:focus {
  --transform-translate-x: 2rem
}

.focus\:translate-x-10:focus {
  --transform-translate-x: 2.5rem
}

.focus\:translate-x-12:focus {
  --transform-translate-x: 3rem
}

.focus\:translate-x-16:focus {
  --transform-translate-x: 4rem
}

.focus\:translate-x-20:focus {
  --transform-translate-x: 5rem
}

.focus\:translate-x-24:focus {
  --transform-translate-x: 6rem
}

.focus\:translate-x-32:focus {
  --transform-translate-x: 8rem
}

.focus\:translate-x-40:focus {
  --transform-translate-x: 10rem
}

.focus\:translate-x-48:focus {
  --transform-translate-x: 12rem
}

.focus\:translate-x-56:focus {
  --transform-translate-x: 14rem
}

.focus\:translate-x-64:focus {
  --transform-translate-x: 16rem
}

.focus\:translate-x-px:focus {
  --transform-translate-x: 1px
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -0.25rem
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -0.5rem
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -0.75rem
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -1rem
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -1.25rem
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -1.5rem
}

.focus\:-translate-x-8:focus {
  --transform-translate-x: -2rem
}

.focus\:-translate-x-10:focus {
  --transform-translate-x: -2.5rem
}

.focus\:-translate-x-12:focus {
  --transform-translate-x: -3rem
}

.focus\:-translate-x-16:focus {
  --transform-translate-x: -4rem
}

.focus\:-translate-x-20:focus {
  --transform-translate-x: -5rem
}

.focus\:-translate-x-24:focus {
  --transform-translate-x: -6rem
}

.focus\:-translate-x-32:focus {
  --transform-translate-x: -8rem
}

.focus\:-translate-x-40:focus {
  --transform-translate-x: -10rem
}

.focus\:-translate-x-48:focus {
  --transform-translate-x: -12rem
}

.focus\:-translate-x-56:focus {
  --transform-translate-x: -14rem
}

.focus\:-translate-x-64:focus {
  --transform-translate-x: -16rem
}

.focus\:-translate-x-px:focus {
  --transform-translate-x: -1px
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100%
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50%
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50%
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100%
}

.focus\:translate-y-0:focus {
  --transform-translate-y: 0
}

.focus\:translate-y-1:focus {
  --transform-translate-y: 0.25rem
}

.focus\:translate-y-2:focus {
  --transform-translate-y: 0.5rem
}

.focus\:translate-y-3:focus {
  --transform-translate-y: 0.75rem
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 1rem
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 1.25rem
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 1.5rem
}

.focus\:translate-y-8:focus {
  --transform-translate-y: 2rem
}

.focus\:translate-y-10:focus {
  --transform-translate-y: 2.5rem
}

.focus\:translate-y-12:focus {
  --transform-translate-y: 3rem
}

.focus\:translate-y-16:focus {
  --transform-translate-y: 4rem
}

.focus\:translate-y-20:focus {
  --transform-translate-y: 5rem
}

.focus\:translate-y-24:focus {
  --transform-translate-y: 6rem
}

.focus\:translate-y-32:focus {
  --transform-translate-y: 8rem
}

.focus\:translate-y-40:focus {
  --transform-translate-y: 10rem
}

.focus\:translate-y-48:focus {
  --transform-translate-y: 12rem
}

.focus\:translate-y-56:focus {
  --transform-translate-y: 14rem
}

.focus\:translate-y-64:focus {
  --transform-translate-y: 16rem
}

.focus\:translate-y-px:focus {
  --transform-translate-y: 1px
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -0.25rem
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -0.5rem
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -0.75rem
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -1rem
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -1.25rem
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -1.5rem
}

.focus\:-translate-y-8:focus {
  --transform-translate-y: -2rem
}

.focus\:-translate-y-10:focus {
  --transform-translate-y: -2.5rem
}

.focus\:-translate-y-12:focus {
  --transform-translate-y: -3rem
}

.focus\:-translate-y-16:focus {
  --transform-translate-y: -4rem
}

.focus\:-translate-y-20:focus {
  --transform-translate-y: -5rem
}

.focus\:-translate-y-24:focus {
  --transform-translate-y: -6rem
}

.focus\:-translate-y-32:focus {
  --transform-translate-y: -8rem
}

.focus\:-translate-y-40:focus {
  --transform-translate-y: -10rem
}

.focus\:-translate-y-48:focus {
  --transform-translate-y: -12rem
}

.focus\:-translate-y-56:focus {
  --transform-translate-y: -14rem
}

.focus\:-translate-y-64:focus {
  --transform-translate-y: -16rem
}

.focus\:-translate-y-px:focus {
  --transform-translate-y: -1px
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100%
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50%
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50%
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100%
}

.skew-x-0 {
  --transform-skew-x: 0
}

.skew-x-1 {
  --transform-skew-x: 1deg
}

.skew-x-2 {
  --transform-skew-x: 2deg
}

.skew-x-3 {
  --transform-skew-x: 3deg
}

.skew-x-6 {
  --transform-skew-x: 6deg
}

.skew-x-12 {
  --transform-skew-x: 12deg
}

.-skew-x-12 {
  --transform-skew-x: -12deg
}

.-skew-x-6 {
  --transform-skew-x: -6deg
}

.-skew-x-3 {
  --transform-skew-x: -3deg
}

.-skew-x-2 {
  --transform-skew-x: -2deg
}

.-skew-x-1 {
  --transform-skew-x: -1deg
}

.skew-y-0 {
  --transform-skew-y: 0
}

.skew-y-1 {
  --transform-skew-y: 1deg
}

.skew-y-2 {
  --transform-skew-y: 2deg
}

.skew-y-3 {
  --transform-skew-y: 3deg
}

.skew-y-6 {
  --transform-skew-y: 6deg
}

.skew-y-12 {
  --transform-skew-y: 12deg
}

.-skew-y-12 {
  --transform-skew-y: -12deg
}

.-skew-y-6 {
  --transform-skew-y: -6deg
}

.-skew-y-3 {
  --transform-skew-y: -3deg
}

.-skew-y-2 {
  --transform-skew-y: -2deg
}

.-skew-y-1 {
  --transform-skew-y: -1deg
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0
}

.hover\:skew-x-1:hover {
  --transform-skew-x: 1deg
}

.hover\:skew-x-2:hover {
  --transform-skew-x: 2deg
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg
}

.hover\:-skew-x-2:hover {
  --transform-skew-x: -2deg
}

.hover\:-skew-x-1:hover {
  --transform-skew-x: -1deg
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0
}

.hover\:skew-y-1:hover {
  --transform-skew-y: 1deg
}

.hover\:skew-y-2:hover {
  --transform-skew-y: 2deg
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg
}

.hover\:-skew-y-2:hover {
  --transform-skew-y: -2deg
}

.hover\:-skew-y-1:hover {
  --transform-skew-y: -1deg
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0
}

.focus\:skew-x-1:focus {
  --transform-skew-x: 1deg
}

.focus\:skew-x-2:focus {
  --transform-skew-x: 2deg
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg
}

.focus\:-skew-x-2:focus {
  --transform-skew-x: -2deg
}

.focus\:-skew-x-1:focus {
  --transform-skew-x: -1deg
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0
}

.focus\:skew-y-1:focus {
  --transform-skew-y: 1deg
}

.focus\:skew-y-2:focus {
  --transform-skew-y: 2deg
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg
}

.focus\:-skew-y-2:focus {
  --transform-skew-y: -2deg
}

.focus\:-skew-y-1:focus {
  --transform-skew-y: -1deg
}

.transition-none {
  transition-property: none
}

.transition-all {
  transition-property: all
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke
}

.transition-opacity {
  transition-property: opacity
}

.transition-shadow {
  transition-property: box-shadow
}

.transition-transform {
  transition-property: transform
}

.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-75 {
  transition-duration: 75ms
}

.duration-100 {
  transition-duration: 100ms
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

.duration-500 {
  transition-duration: 500ms
}

.duration-700 {
  transition-duration: 700ms
}

.duration-1000 {
  transition-duration: 1000ms
}

.delay-75 {
  transition-delay: 75ms
}

.delay-100 {
  transition-delay: 100ms
}

.delay-150 {
  transition-delay: 150ms
}

.delay-200 {
  transition-delay: 200ms
}

.delay-300 {
  transition-delay: 300ms
}

.delay-500 {
  transition-delay: 500ms
}

.delay-700 {
  transition-delay: 700ms
}

.delay-1000 {
  transition-delay: 1000ms
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-none {
  -webkit-animation: none;
          animation: none
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite
}

.animate-ping {
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.animate-bounce {
  -webkit-animation: bounce 1s infinite;
          animation: bounce 1s infinite
}

@media (min-width: 640px) {
  .sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .sm\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary)
  }

  .sm\:divide-primary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-shade)
  }

  .sm\:divide-primary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-tint)
  }

  .sm\:divide-secondary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary)
  }

  .sm\:divide-secondary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-shade)
  }

  .sm\:divide-secondary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-tint)
  }

  .sm\:divide-tertiary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary)
  }

  .sm\:divide-tertiary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-shade)
  }

  .sm\:divide-tertiary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-tint)
  }

  .sm\:divide-light > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light)
  }

  .sm\:divide-light-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-shade)
  }

  .sm\:divide-light-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-tint)
  }

  .sm\:divide-medium > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium)
  }

  .sm\:divide-medium-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-shade)
  }

  .sm\:divide-medium-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-tint)
  }

  .sm\:divide-dark > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark)
  }

  .sm\:divide-dark-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-shade)
  }

  .sm\:divide-dark-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-tint)
  }

  .sm\:divide-success > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success)
  }

  .sm\:divide-success-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-shade)
  }

  .sm\:divide-success-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-tint)
  }

  .sm\:divide-warning > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning)
  }

  .sm\:divide-warning-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-shade)
  }

  .sm\:divide-warning-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-tint)
  }

  .sm\:divide-danger > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger)
  }

  .sm\:divide-danger-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-shade)
  }

  .sm\:divide-danger-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-tint)
  }

  .sm\:divide-step-50 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-50)
  }

  .sm\:divide-step-100 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-100)
  }

  .sm\:divide-step-150 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-150)
  }

  .sm\:divide-step-200 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-200)
  }

  .sm\:divide-step-250 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-250)
  }

  .sm\:divide-step-300 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-300)
  }

  .sm\:divide-step-350 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-350)
  }

  .sm\:divide-step-400 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-400)
  }

  .sm\:divide-step-450 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-450)
  }

  .sm\:divide-step-500 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-500)
  }

  .sm\:divide-step-550 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-550)
  }

  .sm\:divide-step-600 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-600)
  }

  .sm\:divide-step-650 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-650)
  }

  .sm\:divide-step-700 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-700)
  }

  .sm\:divide-step-750 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-750)
  }

  .sm\:divide-step-800 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-800)
  }

  .sm\:divide-step-850 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-850)
  }

  .sm\:divide-step-900 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-900)
  }

  .sm\:divide-step-950 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-950)
  }

  .sm\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .sm\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .sm\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .sm\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .sm\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .sm\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .sm\:bg-fixed {
    background-attachment: fixed
  }

  .sm\:bg-local {
    background-attachment: local
  }

  .sm\:bg-scroll {
    background-attachment: scroll
  }

  .sm\:bg-clip-border {
    background-clip: border-box
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box
  }

  .sm\:bg-clip-content {
    background-clip: content-box
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .sm\:bg-primary {
    background-color: var(--ion-color-primary)
  }

  .sm\:bg-primary-shade {
    background-color: var(--ion-color-primary-shade)
  }

  .sm\:bg-primary-tint {
    background-color: var(--ion-color-primary-tint)
  }

  .sm\:bg-secondary {
    background-color: var(--ion-color-secondary)
  }

  .sm\:bg-secondary-shade {
    background-color: var(--ion-color-secondary-shade)
  }

  .sm\:bg-secondary-tint {
    background-color: var(--ion-color-secondary-tint)
  }

  .sm\:bg-tertiary {
    background-color: var(--ion-color-tertiary)
  }

  .sm\:bg-tertiary-shade {
    background-color: var(--ion-color-tertiary-shade)
  }

  .sm\:bg-tertiary-tint {
    background-color: var(--ion-color-tertiary-tint)
  }

  .sm\:bg-light {
    background-color: var(--ion-color-light)
  }

  .sm\:bg-light-shade {
    background-color: var(--ion-color-light-shade)
  }

  .sm\:bg-light-tint {
    background-color: var(--ion-color-light-tint)
  }

  .sm\:bg-medium {
    background-color: var(--ion-color-medium)
  }

  .sm\:bg-medium-shade {
    background-color: var(--ion-color-medium-shade)
  }

  .sm\:bg-medium-tint {
    background-color: var(--ion-color-medium-tint)
  }

  .sm\:bg-dark {
    background-color: var(--ion-color-dark)
  }

  .sm\:bg-dark-shade {
    background-color: var(--ion-color-dark-shade)
  }

  .sm\:bg-dark-tint {
    background-color: var(--ion-color-dark-tint)
  }

  .sm\:bg-success {
    background-color: var(--ion-color-success)
  }

  .sm\:bg-success-shade {
    background-color: var(--ion-color-success-shade)
  }

  .sm\:bg-success-tint {
    background-color: var(--ion-color-success-tint)
  }

  .sm\:bg-warning {
    background-color: var(--ion-color-warning)
  }

  .sm\:bg-warning-shade {
    background-color: var(--ion-color-warning-shade)
  }

  .sm\:bg-warning-tint {
    background-color: var(--ion-color-warning-tint)
  }

  .sm\:bg-danger {
    background-color: var(--ion-color-danger)
  }

  .sm\:bg-danger-shade {
    background-color: var(--ion-color-danger-shade)
  }

  .sm\:bg-danger-tint {
    background-color: var(--ion-color-danger-tint)
  }

  .sm\:bg-step-50 {
    background-color: var(--ion-color-step-50)
  }

  .sm\:bg-step-100 {
    background-color: var(--ion-color-step-100)
  }

  .sm\:bg-step-150 {
    background-color: var(--ion-color-step-150)
  }

  .sm\:bg-step-200 {
    background-color: var(--ion-color-step-200)
  }

  .sm\:bg-step-250 {
    background-color: var(--ion-color-step-250)
  }

  .sm\:bg-step-300 {
    background-color: var(--ion-color-step-300)
  }

  .sm\:bg-step-350 {
    background-color: var(--ion-color-step-350)
  }

  .sm\:bg-step-400 {
    background-color: var(--ion-color-step-400)
  }

  .sm\:bg-step-450 {
    background-color: var(--ion-color-step-450)
  }

  .sm\:bg-step-500 {
    background-color: var(--ion-color-step-500)
  }

  .sm\:bg-step-550 {
    background-color: var(--ion-color-step-550)
  }

  .sm\:bg-step-600 {
    background-color: var(--ion-color-step-600)
  }

  .sm\:bg-step-650 {
    background-color: var(--ion-color-step-650)
  }

  .sm\:bg-step-700 {
    background-color: var(--ion-color-step-700)
  }

  .sm\:bg-step-750 {
    background-color: var(--ion-color-step-750)
  }

  .sm\:bg-step-800 {
    background-color: var(--ion-color-step-800)
  }

  .sm\:bg-step-850 {
    background-color: var(--ion-color-step-850)
  }

  .sm\:bg-step-900 {
    background-color: var(--ion-color-step-900)
  }

  .sm\:bg-step-950 {
    background-color: var(--ion-color-step-950)
  }

  .sm\:hover\:bg-primary:hover {
    background-color: var(--ion-color-primary)
  }

  .sm\:hover\:bg-primary-shade:hover {
    background-color: var(--ion-color-primary-shade)
  }

  .sm\:hover\:bg-primary-tint:hover {
    background-color: var(--ion-color-primary-tint)
  }

  .sm\:hover\:bg-secondary:hover {
    background-color: var(--ion-color-secondary)
  }

  .sm\:hover\:bg-secondary-shade:hover {
    background-color: var(--ion-color-secondary-shade)
  }

  .sm\:hover\:bg-secondary-tint:hover {
    background-color: var(--ion-color-secondary-tint)
  }

  .sm\:hover\:bg-tertiary:hover {
    background-color: var(--ion-color-tertiary)
  }

  .sm\:hover\:bg-tertiary-shade:hover {
    background-color: var(--ion-color-tertiary-shade)
  }

  .sm\:hover\:bg-tertiary-tint:hover {
    background-color: var(--ion-color-tertiary-tint)
  }

  .sm\:hover\:bg-light:hover {
    background-color: var(--ion-color-light)
  }

  .sm\:hover\:bg-light-shade:hover {
    background-color: var(--ion-color-light-shade)
  }

  .sm\:hover\:bg-light-tint:hover {
    background-color: var(--ion-color-light-tint)
  }

  .sm\:hover\:bg-medium:hover {
    background-color: var(--ion-color-medium)
  }

  .sm\:hover\:bg-medium-shade:hover {
    background-color: var(--ion-color-medium-shade)
  }

  .sm\:hover\:bg-medium-tint:hover {
    background-color: var(--ion-color-medium-tint)
  }

  .sm\:hover\:bg-dark:hover {
    background-color: var(--ion-color-dark)
  }

  .sm\:hover\:bg-dark-shade:hover {
    background-color: var(--ion-color-dark-shade)
  }

  .sm\:hover\:bg-dark-tint:hover {
    background-color: var(--ion-color-dark-tint)
  }

  .sm\:hover\:bg-success:hover {
    background-color: var(--ion-color-success)
  }

  .sm\:hover\:bg-success-shade:hover {
    background-color: var(--ion-color-success-shade)
  }

  .sm\:hover\:bg-success-tint:hover {
    background-color: var(--ion-color-success-tint)
  }

  .sm\:hover\:bg-warning:hover {
    background-color: var(--ion-color-warning)
  }

  .sm\:hover\:bg-warning-shade:hover {
    background-color: var(--ion-color-warning-shade)
  }

  .sm\:hover\:bg-warning-tint:hover {
    background-color: var(--ion-color-warning-tint)
  }

  .sm\:hover\:bg-danger:hover {
    background-color: var(--ion-color-danger)
  }

  .sm\:hover\:bg-danger-shade:hover {
    background-color: var(--ion-color-danger-shade)
  }

  .sm\:hover\:bg-danger-tint:hover {
    background-color: var(--ion-color-danger-tint)
  }

  .sm\:hover\:bg-step-50:hover {
    background-color: var(--ion-color-step-50)
  }

  .sm\:hover\:bg-step-100:hover {
    background-color: var(--ion-color-step-100)
  }

  .sm\:hover\:bg-step-150:hover {
    background-color: var(--ion-color-step-150)
  }

  .sm\:hover\:bg-step-200:hover {
    background-color: var(--ion-color-step-200)
  }

  .sm\:hover\:bg-step-250:hover {
    background-color: var(--ion-color-step-250)
  }

  .sm\:hover\:bg-step-300:hover {
    background-color: var(--ion-color-step-300)
  }

  .sm\:hover\:bg-step-350:hover {
    background-color: var(--ion-color-step-350)
  }

  .sm\:hover\:bg-step-400:hover {
    background-color: var(--ion-color-step-400)
  }

  .sm\:hover\:bg-step-450:hover {
    background-color: var(--ion-color-step-450)
  }

  .sm\:hover\:bg-step-500:hover {
    background-color: var(--ion-color-step-500)
  }

  .sm\:hover\:bg-step-550:hover {
    background-color: var(--ion-color-step-550)
  }

  .sm\:hover\:bg-step-600:hover {
    background-color: var(--ion-color-step-600)
  }

  .sm\:hover\:bg-step-650:hover {
    background-color: var(--ion-color-step-650)
  }

  .sm\:hover\:bg-step-700:hover {
    background-color: var(--ion-color-step-700)
  }

  .sm\:hover\:bg-step-750:hover {
    background-color: var(--ion-color-step-750)
  }

  .sm\:hover\:bg-step-800:hover {
    background-color: var(--ion-color-step-800)
  }

  .sm\:hover\:bg-step-850:hover {
    background-color: var(--ion-color-step-850)
  }

  .sm\:hover\:bg-step-900:hover {
    background-color: var(--ion-color-step-900)
  }

  .sm\:hover\:bg-step-950:hover {
    background-color: var(--ion-color-step-950)
  }

  .sm\:focus\:bg-primary:focus {
    background-color: var(--ion-color-primary)
  }

  .sm\:focus\:bg-primary-shade:focus {
    background-color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:bg-primary-tint:focus {
    background-color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:bg-secondary:focus {
    background-color: var(--ion-color-secondary)
  }

  .sm\:focus\:bg-secondary-shade:focus {
    background-color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:bg-secondary-tint:focus {
    background-color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:bg-tertiary:focus {
    background-color: var(--ion-color-tertiary)
  }

  .sm\:focus\:bg-tertiary-shade:focus {
    background-color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:bg-tertiary-tint:focus {
    background-color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:bg-light:focus {
    background-color: var(--ion-color-light)
  }

  .sm\:focus\:bg-light-shade:focus {
    background-color: var(--ion-color-light-shade)
  }

  .sm\:focus\:bg-light-tint:focus {
    background-color: var(--ion-color-light-tint)
  }

  .sm\:focus\:bg-medium:focus {
    background-color: var(--ion-color-medium)
  }

  .sm\:focus\:bg-medium-shade:focus {
    background-color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:bg-medium-tint:focus {
    background-color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:bg-dark:focus {
    background-color: var(--ion-color-dark)
  }

  .sm\:focus\:bg-dark-shade:focus {
    background-color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:bg-dark-tint:focus {
    background-color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:bg-success:focus {
    background-color: var(--ion-color-success)
  }

  .sm\:focus\:bg-success-shade:focus {
    background-color: var(--ion-color-success-shade)
  }

  .sm\:focus\:bg-success-tint:focus {
    background-color: var(--ion-color-success-tint)
  }

  .sm\:focus\:bg-warning:focus {
    background-color: var(--ion-color-warning)
  }

  .sm\:focus\:bg-warning-shade:focus {
    background-color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:bg-warning-tint:focus {
    background-color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:bg-danger:focus {
    background-color: var(--ion-color-danger)
  }

  .sm\:focus\:bg-danger-shade:focus {
    background-color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:bg-danger-tint:focus {
    background-color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:bg-step-50:focus {
    background-color: var(--ion-color-step-50)
  }

  .sm\:focus\:bg-step-100:focus {
    background-color: var(--ion-color-step-100)
  }

  .sm\:focus\:bg-step-150:focus {
    background-color: var(--ion-color-step-150)
  }

  .sm\:focus\:bg-step-200:focus {
    background-color: var(--ion-color-step-200)
  }

  .sm\:focus\:bg-step-250:focus {
    background-color: var(--ion-color-step-250)
  }

  .sm\:focus\:bg-step-300:focus {
    background-color: var(--ion-color-step-300)
  }

  .sm\:focus\:bg-step-350:focus {
    background-color: var(--ion-color-step-350)
  }

  .sm\:focus\:bg-step-400:focus {
    background-color: var(--ion-color-step-400)
  }

  .sm\:focus\:bg-step-450:focus {
    background-color: var(--ion-color-step-450)
  }

  .sm\:focus\:bg-step-500:focus {
    background-color: var(--ion-color-step-500)
  }

  .sm\:focus\:bg-step-550:focus {
    background-color: var(--ion-color-step-550)
  }

  .sm\:focus\:bg-step-600:focus {
    background-color: var(--ion-color-step-600)
  }

  .sm\:focus\:bg-step-650:focus {
    background-color: var(--ion-color-step-650)
  }

  .sm\:focus\:bg-step-700:focus {
    background-color: var(--ion-color-step-700)
  }

  .sm\:focus\:bg-step-750:focus {
    background-color: var(--ion-color-step-750)
  }

  .sm\:focus\:bg-step-800:focus {
    background-color: var(--ion-color-step-800)
  }

  .sm\:focus\:bg-step-850:focus {
    background-color: var(--ion-color-step-850)
  }

  .sm\:focus\:bg-step-900:focus {
    background-color: var(--ion-color-step-900)
  }

  .sm\:focus\:bg-step-950:focus {
    background-color: var(--ion-color-step-950)
  }

  .sm\:bg-none {
    background-image: none
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .sm\:from-primary {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-primary-shade {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-primary-tint {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-secondary {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-secondary-shade {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-secondary-tint {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-tertiary {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-tertiary-shade {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-tertiary-tint {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-light {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-light-shade {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-light-tint {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-medium {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-medium-shade {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-medium-tint {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-dark {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-dark-shade {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-dark-tint {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-success {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-success-shade {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-success-tint {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-warning {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-warning-shade {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-warning-tint {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-danger {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-danger-shade {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-danger-tint {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-50 {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-100 {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-150 {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-200 {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-250 {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-300 {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-350 {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-400 {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-450 {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-500 {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-550 {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-600 {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-650 {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-700 {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-750 {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-800 {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-850 {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-900 {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:from-step-950 {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-primary {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-primary-shade {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-primary-tint {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-secondary {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-secondary-shade {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-secondary-tint {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-tertiary {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-tertiary-shade {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-tertiary-tint {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-light {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-light-shade {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-light-tint {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-medium {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-medium-shade {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-medium-tint {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-dark {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-dark-shade {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-dark-tint {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-success {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-success-shade {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-success-tint {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-warning {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-warning-shade {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-warning-tint {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-danger {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-danger-shade {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-danger-tint {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-50 {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-100 {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-150 {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-200 {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-250 {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-300 {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-350 {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-400 {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-450 {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-500 {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-550 {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-600 {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-650 {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-700 {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-750 {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-800 {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-850 {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-900 {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:via-step-950 {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:to-primary {
    --gradient-to-color: var(--ion-color-primary)
  }

  .sm\:to-primary-shade {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .sm\:to-primary-tint {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .sm\:to-secondary {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .sm\:to-secondary-shade {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .sm\:to-secondary-tint {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .sm\:to-tertiary {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .sm\:to-tertiary-shade {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .sm\:to-tertiary-tint {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .sm\:to-light {
    --gradient-to-color: var(--ion-color-light)
  }

  .sm\:to-light-shade {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .sm\:to-light-tint {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .sm\:to-medium {
    --gradient-to-color: var(--ion-color-medium)
  }

  .sm\:to-medium-shade {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .sm\:to-medium-tint {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .sm\:to-dark {
    --gradient-to-color: var(--ion-color-dark)
  }

  .sm\:to-dark-shade {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .sm\:to-dark-tint {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .sm\:to-success {
    --gradient-to-color: var(--ion-color-success)
  }

  .sm\:to-success-shade {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .sm\:to-success-tint {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .sm\:to-warning {
    --gradient-to-color: var(--ion-color-warning)
  }

  .sm\:to-warning-shade {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .sm\:to-warning-tint {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .sm\:to-danger {
    --gradient-to-color: var(--ion-color-danger)
  }

  .sm\:to-danger-shade {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .sm\:to-danger-tint {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .sm\:to-step-50 {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .sm\:to-step-100 {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .sm\:to-step-150 {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .sm\:to-step-200 {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .sm\:to-step-250 {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .sm\:to-step-300 {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .sm\:to-step-350 {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .sm\:to-step-400 {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .sm\:to-step-450 {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .sm\:to-step-500 {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .sm\:to-step-550 {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .sm\:to-step-600 {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .sm\:to-step-650 {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .sm\:to-step-700 {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .sm\:to-step-750 {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .sm\:to-step-800 {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .sm\:to-step-850 {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .sm\:to-step-900 {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .sm\:to-step-950 {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .sm\:hover\:from-primary:hover {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-primary-shade:hover {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-primary-tint:hover {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-secondary:hover {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-secondary-shade:hover {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-secondary-tint:hover {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-tertiary:hover {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-tertiary-shade:hover {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-tertiary-tint:hover {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-light:hover {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-light-shade:hover {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-light-tint:hover {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-medium:hover {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-medium-shade:hover {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-medium-tint:hover {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-dark:hover {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-dark-shade:hover {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-dark-tint:hover {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-success:hover {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-success-shade:hover {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-success-tint:hover {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-warning:hover {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-warning-shade:hover {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-warning-tint:hover {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-danger:hover {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-danger-shade:hover {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-danger-tint:hover {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-50:hover {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-100:hover {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-150:hover {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-200:hover {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-250:hover {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-300:hover {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-350:hover {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-400:hover {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-450:hover {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-500:hover {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-550:hover {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-600:hover {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-650:hover {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-700:hover {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-750:hover {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-800:hover {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-850:hover {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-900:hover {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:from-step-950:hover {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-primary:hover {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-primary-shade:hover {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-primary-tint:hover {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-secondary:hover {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-secondary-shade:hover {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-secondary-tint:hover {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-tertiary:hover {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-tertiary-shade:hover {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-tertiary-tint:hover {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-light:hover {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-light-shade:hover {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-light-tint:hover {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-medium:hover {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-medium-shade:hover {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-medium-tint:hover {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-dark:hover {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-dark-shade:hover {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-dark-tint:hover {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-success:hover {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-success-shade:hover {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-success-tint:hover {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-warning:hover {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-warning-shade:hover {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-warning-tint:hover {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-danger:hover {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-danger-shade:hover {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-danger-tint:hover {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-50:hover {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-100:hover {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-150:hover {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-200:hover {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-250:hover {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-300:hover {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-350:hover {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-400:hover {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-450:hover {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-500:hover {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-550:hover {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-600:hover {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-650:hover {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-700:hover {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-750:hover {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-800:hover {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-850:hover {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-900:hover {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:via-step-950:hover {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:hover\:to-primary:hover {
    --gradient-to-color: var(--ion-color-primary)
  }

  .sm\:hover\:to-primary-shade:hover {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .sm\:hover\:to-primary-tint:hover {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .sm\:hover\:to-secondary:hover {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .sm\:hover\:to-secondary-shade:hover {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .sm\:hover\:to-secondary-tint:hover {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .sm\:hover\:to-tertiary:hover {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .sm\:hover\:to-tertiary-shade:hover {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .sm\:hover\:to-tertiary-tint:hover {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .sm\:hover\:to-light:hover {
    --gradient-to-color: var(--ion-color-light)
  }

  .sm\:hover\:to-light-shade:hover {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .sm\:hover\:to-light-tint:hover {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .sm\:hover\:to-medium:hover {
    --gradient-to-color: var(--ion-color-medium)
  }

  .sm\:hover\:to-medium-shade:hover {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .sm\:hover\:to-medium-tint:hover {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .sm\:hover\:to-dark:hover {
    --gradient-to-color: var(--ion-color-dark)
  }

  .sm\:hover\:to-dark-shade:hover {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .sm\:hover\:to-dark-tint:hover {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .sm\:hover\:to-success:hover {
    --gradient-to-color: var(--ion-color-success)
  }

  .sm\:hover\:to-success-shade:hover {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .sm\:hover\:to-success-tint:hover {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .sm\:hover\:to-warning:hover {
    --gradient-to-color: var(--ion-color-warning)
  }

  .sm\:hover\:to-warning-shade:hover {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .sm\:hover\:to-warning-tint:hover {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .sm\:hover\:to-danger:hover {
    --gradient-to-color: var(--ion-color-danger)
  }

  .sm\:hover\:to-danger-shade:hover {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .sm\:hover\:to-danger-tint:hover {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .sm\:hover\:to-step-50:hover {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .sm\:hover\:to-step-100:hover {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .sm\:hover\:to-step-150:hover {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .sm\:hover\:to-step-200:hover {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .sm\:hover\:to-step-250:hover {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .sm\:hover\:to-step-300:hover {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .sm\:hover\:to-step-350:hover {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .sm\:hover\:to-step-400:hover {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .sm\:hover\:to-step-450:hover {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .sm\:hover\:to-step-500:hover {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .sm\:hover\:to-step-550:hover {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .sm\:hover\:to-step-600:hover {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .sm\:hover\:to-step-650:hover {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .sm\:hover\:to-step-700:hover {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .sm\:hover\:to-step-750:hover {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .sm\:hover\:to-step-800:hover {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .sm\:hover\:to-step-850:hover {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .sm\:hover\:to-step-900:hover {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .sm\:hover\:to-step-950:hover {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .sm\:focus\:from-primary:focus {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-primary-shade:focus {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-primary-tint:focus {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-secondary:focus {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-secondary-shade:focus {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-secondary-tint:focus {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-tertiary:focus {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-tertiary-shade:focus {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-tertiary-tint:focus {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-light:focus {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-light-shade:focus {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-light-tint:focus {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-medium:focus {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-medium-shade:focus {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-medium-tint:focus {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-dark:focus {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-dark-shade:focus {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-dark-tint:focus {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-success:focus {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-success-shade:focus {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-success-tint:focus {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-warning:focus {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-warning-shade:focus {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-warning-tint:focus {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-danger:focus {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-danger-shade:focus {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-danger-tint:focus {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-50:focus {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-100:focus {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-150:focus {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-200:focus {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-250:focus {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-300:focus {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-350:focus {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-400:focus {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-450:focus {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-500:focus {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-550:focus {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-600:focus {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-650:focus {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-700:focus {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-750:focus {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-800:focus {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-850:focus {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-900:focus {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:from-step-950:focus {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-primary:focus {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-primary-shade:focus {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-primary-tint:focus {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-secondary:focus {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-secondary-shade:focus {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-secondary-tint:focus {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-tertiary:focus {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-tertiary-shade:focus {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-tertiary-tint:focus {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-light:focus {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-light-shade:focus {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-light-tint:focus {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-medium:focus {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-medium-shade:focus {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-medium-tint:focus {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-dark:focus {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-dark-shade:focus {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-dark-tint:focus {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-success:focus {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-success-shade:focus {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-success-tint:focus {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-warning:focus {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-warning-shade:focus {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-warning-tint:focus {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-danger:focus {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-danger-shade:focus {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-danger-tint:focus {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-50:focus {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-100:focus {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-150:focus {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-200:focus {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-250:focus {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-300:focus {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-350:focus {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-400:focus {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-450:focus {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-500:focus {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-550:focus {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-600:focus {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-650:focus {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-700:focus {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-750:focus {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-800:focus {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-850:focus {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-900:focus {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:via-step-950:focus {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .sm\:focus\:to-primary:focus {
    --gradient-to-color: var(--ion-color-primary)
  }

  .sm\:focus\:to-primary-shade:focus {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:to-primary-tint:focus {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:to-secondary:focus {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .sm\:focus\:to-secondary-shade:focus {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:to-secondary-tint:focus {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:to-tertiary:focus {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .sm\:focus\:to-tertiary-shade:focus {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:to-tertiary-tint:focus {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:to-light:focus {
    --gradient-to-color: var(--ion-color-light)
  }

  .sm\:focus\:to-light-shade:focus {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .sm\:focus\:to-light-tint:focus {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .sm\:focus\:to-medium:focus {
    --gradient-to-color: var(--ion-color-medium)
  }

  .sm\:focus\:to-medium-shade:focus {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:to-medium-tint:focus {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:to-dark:focus {
    --gradient-to-color: var(--ion-color-dark)
  }

  .sm\:focus\:to-dark-shade:focus {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:to-dark-tint:focus {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:to-success:focus {
    --gradient-to-color: var(--ion-color-success)
  }

  .sm\:focus\:to-success-shade:focus {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .sm\:focus\:to-success-tint:focus {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .sm\:focus\:to-warning:focus {
    --gradient-to-color: var(--ion-color-warning)
  }

  .sm\:focus\:to-warning-shade:focus {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:to-warning-tint:focus {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:to-danger:focus {
    --gradient-to-color: var(--ion-color-danger)
  }

  .sm\:focus\:to-danger-shade:focus {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:to-danger-tint:focus {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:to-step-50:focus {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .sm\:focus\:to-step-100:focus {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .sm\:focus\:to-step-150:focus {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .sm\:focus\:to-step-200:focus {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .sm\:focus\:to-step-250:focus {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .sm\:focus\:to-step-300:focus {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .sm\:focus\:to-step-350:focus {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .sm\:focus\:to-step-400:focus {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .sm\:focus\:to-step-450:focus {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .sm\:focus\:to-step-500:focus {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .sm\:focus\:to-step-550:focus {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .sm\:focus\:to-step-600:focus {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .sm\:focus\:to-step-650:focus {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .sm\:focus\:to-step-700:focus {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .sm\:focus\:to-step-750:focus {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .sm\:focus\:to-step-800:focus {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .sm\:focus\:to-step-850:focus {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .sm\:focus\:to-step-900:focus {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .sm\:focus\:to-step-950:focus {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .sm\:bg-bottom {
    background-position: bottom
  }

  .sm\:bg-center {
    background-position: center
  }

  .sm\:bg-left {
    background-position: left
  }

  .sm\:bg-left-bottom {
    background-position: left bottom
  }

  .sm\:bg-left-top {
    background-position: left top
  }

  .sm\:bg-right {
    background-position: right
  }

  .sm\:bg-right-bottom {
    background-position: right bottom
  }

  .sm\:bg-right-top {
    background-position: right top
  }

  .sm\:bg-top {
    background-position: top
  }

  .sm\:bg-repeat {
    background-repeat: repeat
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .sm\:bg-repeat-round {
    background-repeat: round
  }

  .sm\:bg-repeat-space {
    background-repeat: space
  }

  .sm\:bg-auto {
    background-size: auto
  }

  .sm\:bg-cover {
    background-size: cover
  }

  .sm\:bg-contain {
    background-size: contain
  }

  .sm\:border-collapse {
    border-collapse: collapse
  }

  .sm\:border-separate {
    border-collapse: separate
  }

  .sm\:border-primary {
    border-color: var(--ion-color-primary)
  }

  .sm\:border-primary-shade {
    border-color: var(--ion-color-primary-shade)
  }

  .sm\:border-primary-tint {
    border-color: var(--ion-color-primary-tint)
  }

  .sm\:border-secondary {
    border-color: var(--ion-color-secondary)
  }

  .sm\:border-secondary-shade {
    border-color: var(--ion-color-secondary-shade)
  }

  .sm\:border-secondary-tint {
    border-color: var(--ion-color-secondary-tint)
  }

  .sm\:border-tertiary {
    border-color: var(--ion-color-tertiary)
  }

  .sm\:border-tertiary-shade {
    border-color: var(--ion-color-tertiary-shade)
  }

  .sm\:border-tertiary-tint {
    border-color: var(--ion-color-tertiary-tint)
  }

  .sm\:border-light {
    border-color: var(--ion-color-light)
  }

  .sm\:border-light-shade {
    border-color: var(--ion-color-light-shade)
  }

  .sm\:border-light-tint {
    border-color: var(--ion-color-light-tint)
  }

  .sm\:border-medium {
    border-color: var(--ion-color-medium)
  }

  .sm\:border-medium-shade {
    border-color: var(--ion-color-medium-shade)
  }

  .sm\:border-medium-tint {
    border-color: var(--ion-color-medium-tint)
  }

  .sm\:border-dark {
    border-color: var(--ion-color-dark)
  }

  .sm\:border-dark-shade {
    border-color: var(--ion-color-dark-shade)
  }

  .sm\:border-dark-tint {
    border-color: var(--ion-color-dark-tint)
  }

  .sm\:border-success {
    border-color: var(--ion-color-success)
  }

  .sm\:border-success-shade {
    border-color: var(--ion-color-success-shade)
  }

  .sm\:border-success-tint {
    border-color: var(--ion-color-success-tint)
  }

  .sm\:border-warning {
    border-color: var(--ion-color-warning)
  }

  .sm\:border-warning-shade {
    border-color: var(--ion-color-warning-shade)
  }

  .sm\:border-warning-tint {
    border-color: var(--ion-color-warning-tint)
  }

  .sm\:border-danger {
    border-color: var(--ion-color-danger)
  }

  .sm\:border-danger-shade {
    border-color: var(--ion-color-danger-shade)
  }

  .sm\:border-danger-tint {
    border-color: var(--ion-color-danger-tint)
  }

  .sm\:border-step-50 {
    border-color: var(--ion-color-step-50)
  }

  .sm\:border-step-100 {
    border-color: var(--ion-color-step-100)
  }

  .sm\:border-step-150 {
    border-color: var(--ion-color-step-150)
  }

  .sm\:border-step-200 {
    border-color: var(--ion-color-step-200)
  }

  .sm\:border-step-250 {
    border-color: var(--ion-color-step-250)
  }

  .sm\:border-step-300 {
    border-color: var(--ion-color-step-300)
  }

  .sm\:border-step-350 {
    border-color: var(--ion-color-step-350)
  }

  .sm\:border-step-400 {
    border-color: var(--ion-color-step-400)
  }

  .sm\:border-step-450 {
    border-color: var(--ion-color-step-450)
  }

  .sm\:border-step-500 {
    border-color: var(--ion-color-step-500)
  }

  .sm\:border-step-550 {
    border-color: var(--ion-color-step-550)
  }

  .sm\:border-step-600 {
    border-color: var(--ion-color-step-600)
  }

  .sm\:border-step-650 {
    border-color: var(--ion-color-step-650)
  }

  .sm\:border-step-700 {
    border-color: var(--ion-color-step-700)
  }

  .sm\:border-step-750 {
    border-color: var(--ion-color-step-750)
  }

  .sm\:border-step-800 {
    border-color: var(--ion-color-step-800)
  }

  .sm\:border-step-850 {
    border-color: var(--ion-color-step-850)
  }

  .sm\:border-step-900 {
    border-color: var(--ion-color-step-900)
  }

  .sm\:border-step-950 {
    border-color: var(--ion-color-step-950)
  }

  .sm\:hover\:border-primary:hover {
    border-color: var(--ion-color-primary)
  }

  .sm\:hover\:border-primary-shade:hover {
    border-color: var(--ion-color-primary-shade)
  }

  .sm\:hover\:border-primary-tint:hover {
    border-color: var(--ion-color-primary-tint)
  }

  .sm\:hover\:border-secondary:hover {
    border-color: var(--ion-color-secondary)
  }

  .sm\:hover\:border-secondary-shade:hover {
    border-color: var(--ion-color-secondary-shade)
  }

  .sm\:hover\:border-secondary-tint:hover {
    border-color: var(--ion-color-secondary-tint)
  }

  .sm\:hover\:border-tertiary:hover {
    border-color: var(--ion-color-tertiary)
  }

  .sm\:hover\:border-tertiary-shade:hover {
    border-color: var(--ion-color-tertiary-shade)
  }

  .sm\:hover\:border-tertiary-tint:hover {
    border-color: var(--ion-color-tertiary-tint)
  }

  .sm\:hover\:border-light:hover {
    border-color: var(--ion-color-light)
  }

  .sm\:hover\:border-light-shade:hover {
    border-color: var(--ion-color-light-shade)
  }

  .sm\:hover\:border-light-tint:hover {
    border-color: var(--ion-color-light-tint)
  }

  .sm\:hover\:border-medium:hover {
    border-color: var(--ion-color-medium)
  }

  .sm\:hover\:border-medium-shade:hover {
    border-color: var(--ion-color-medium-shade)
  }

  .sm\:hover\:border-medium-tint:hover {
    border-color: var(--ion-color-medium-tint)
  }

  .sm\:hover\:border-dark:hover {
    border-color: var(--ion-color-dark)
  }

  .sm\:hover\:border-dark-shade:hover {
    border-color: var(--ion-color-dark-shade)
  }

  .sm\:hover\:border-dark-tint:hover {
    border-color: var(--ion-color-dark-tint)
  }

  .sm\:hover\:border-success:hover {
    border-color: var(--ion-color-success)
  }

  .sm\:hover\:border-success-shade:hover {
    border-color: var(--ion-color-success-shade)
  }

  .sm\:hover\:border-success-tint:hover {
    border-color: var(--ion-color-success-tint)
  }

  .sm\:hover\:border-warning:hover {
    border-color: var(--ion-color-warning)
  }

  .sm\:hover\:border-warning-shade:hover {
    border-color: var(--ion-color-warning-shade)
  }

  .sm\:hover\:border-warning-tint:hover {
    border-color: var(--ion-color-warning-tint)
  }

  .sm\:hover\:border-danger:hover {
    border-color: var(--ion-color-danger)
  }

  .sm\:hover\:border-danger-shade:hover {
    border-color: var(--ion-color-danger-shade)
  }

  .sm\:hover\:border-danger-tint:hover {
    border-color: var(--ion-color-danger-tint)
  }

  .sm\:hover\:border-step-50:hover {
    border-color: var(--ion-color-step-50)
  }

  .sm\:hover\:border-step-100:hover {
    border-color: var(--ion-color-step-100)
  }

  .sm\:hover\:border-step-150:hover {
    border-color: var(--ion-color-step-150)
  }

  .sm\:hover\:border-step-200:hover {
    border-color: var(--ion-color-step-200)
  }

  .sm\:hover\:border-step-250:hover {
    border-color: var(--ion-color-step-250)
  }

  .sm\:hover\:border-step-300:hover {
    border-color: var(--ion-color-step-300)
  }

  .sm\:hover\:border-step-350:hover {
    border-color: var(--ion-color-step-350)
  }

  .sm\:hover\:border-step-400:hover {
    border-color: var(--ion-color-step-400)
  }

  .sm\:hover\:border-step-450:hover {
    border-color: var(--ion-color-step-450)
  }

  .sm\:hover\:border-step-500:hover {
    border-color: var(--ion-color-step-500)
  }

  .sm\:hover\:border-step-550:hover {
    border-color: var(--ion-color-step-550)
  }

  .sm\:hover\:border-step-600:hover {
    border-color: var(--ion-color-step-600)
  }

  .sm\:hover\:border-step-650:hover {
    border-color: var(--ion-color-step-650)
  }

  .sm\:hover\:border-step-700:hover {
    border-color: var(--ion-color-step-700)
  }

  .sm\:hover\:border-step-750:hover {
    border-color: var(--ion-color-step-750)
  }

  .sm\:hover\:border-step-800:hover {
    border-color: var(--ion-color-step-800)
  }

  .sm\:hover\:border-step-850:hover {
    border-color: var(--ion-color-step-850)
  }

  .sm\:hover\:border-step-900:hover {
    border-color: var(--ion-color-step-900)
  }

  .sm\:hover\:border-step-950:hover {
    border-color: var(--ion-color-step-950)
  }

  .sm\:focus\:border-primary:focus {
    border-color: var(--ion-color-primary)
  }

  .sm\:focus\:border-primary-shade:focus {
    border-color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:border-primary-tint:focus {
    border-color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:border-secondary:focus {
    border-color: var(--ion-color-secondary)
  }

  .sm\:focus\:border-secondary-shade:focus {
    border-color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:border-secondary-tint:focus {
    border-color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:border-tertiary:focus {
    border-color: var(--ion-color-tertiary)
  }

  .sm\:focus\:border-tertiary-shade:focus {
    border-color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:border-tertiary-tint:focus {
    border-color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:border-light:focus {
    border-color: var(--ion-color-light)
  }

  .sm\:focus\:border-light-shade:focus {
    border-color: var(--ion-color-light-shade)
  }

  .sm\:focus\:border-light-tint:focus {
    border-color: var(--ion-color-light-tint)
  }

  .sm\:focus\:border-medium:focus {
    border-color: var(--ion-color-medium)
  }

  .sm\:focus\:border-medium-shade:focus {
    border-color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:border-medium-tint:focus {
    border-color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:border-dark:focus {
    border-color: var(--ion-color-dark)
  }

  .sm\:focus\:border-dark-shade:focus {
    border-color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:border-dark-tint:focus {
    border-color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:border-success:focus {
    border-color: var(--ion-color-success)
  }

  .sm\:focus\:border-success-shade:focus {
    border-color: var(--ion-color-success-shade)
  }

  .sm\:focus\:border-success-tint:focus {
    border-color: var(--ion-color-success-tint)
  }

  .sm\:focus\:border-warning:focus {
    border-color: var(--ion-color-warning)
  }

  .sm\:focus\:border-warning-shade:focus {
    border-color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:border-warning-tint:focus {
    border-color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:border-danger:focus {
    border-color: var(--ion-color-danger)
  }

  .sm\:focus\:border-danger-shade:focus {
    border-color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:border-danger-tint:focus {
    border-color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:border-step-50:focus {
    border-color: var(--ion-color-step-50)
  }

  .sm\:focus\:border-step-100:focus {
    border-color: var(--ion-color-step-100)
  }

  .sm\:focus\:border-step-150:focus {
    border-color: var(--ion-color-step-150)
  }

  .sm\:focus\:border-step-200:focus {
    border-color: var(--ion-color-step-200)
  }

  .sm\:focus\:border-step-250:focus {
    border-color: var(--ion-color-step-250)
  }

  .sm\:focus\:border-step-300:focus {
    border-color: var(--ion-color-step-300)
  }

  .sm\:focus\:border-step-350:focus {
    border-color: var(--ion-color-step-350)
  }

  .sm\:focus\:border-step-400:focus {
    border-color: var(--ion-color-step-400)
  }

  .sm\:focus\:border-step-450:focus {
    border-color: var(--ion-color-step-450)
  }

  .sm\:focus\:border-step-500:focus {
    border-color: var(--ion-color-step-500)
  }

  .sm\:focus\:border-step-550:focus {
    border-color: var(--ion-color-step-550)
  }

  .sm\:focus\:border-step-600:focus {
    border-color: var(--ion-color-step-600)
  }

  .sm\:focus\:border-step-650:focus {
    border-color: var(--ion-color-step-650)
  }

  .sm\:focus\:border-step-700:focus {
    border-color: var(--ion-color-step-700)
  }

  .sm\:focus\:border-step-750:focus {
    border-color: var(--ion-color-step-750)
  }

  .sm\:focus\:border-step-800:focus {
    border-color: var(--ion-color-step-800)
  }

  .sm\:focus\:border-step-850:focus {
    border-color: var(--ion-color-step-850)
  }

  .sm\:focus\:border-step-900:focus {
    border-color: var(--ion-color-step-900)
  }

  .sm\:focus\:border-step-950:focus {
    border-color: var(--ion-color-step-950)
  }

  .sm\:border-opacity-0 {
    --border-opacity: 0
  }

  .sm\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .sm\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .sm\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .sm\:border-opacity-100 {
    --border-opacity: 1
  }

  .sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .sm\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .sm\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .sm\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .sm\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .sm\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .sm\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .sm\:rounded-none {
    border-radius: 0
  }

  .sm\:rounded-sm {
    border-radius: 0.125rem
  }

  .sm\:rounded {
    border-radius: 0.25rem
  }

  .sm\:rounded-md {
    border-radius: 0.375rem
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:rounded-xl {
    border-radius: 0.75rem
  }

  .sm\:rounded-2xl {
    border-radius: 1rem
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem
  }

  .sm\:rounded-full {
    border-radius: 9999px
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .sm\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .sm\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .sm\:border-solid {
    border-style: solid
  }

  .sm\:border-dashed {
    border-style: dashed
  }

  .sm\:border-dotted {
    border-style: dotted
  }

  .sm\:border-double {
    border-style: double
  }

  .sm\:border-none {
    border-style: none
  }

  .sm\:border-0 {
    border-width: 0
  }

  .sm\:border-2 {
    border-width: 2px
  }

  .sm\:border-4 {
    border-width: 4px
  }

  .sm\:border-8 {
    border-width: 8px
  }

  .sm\:border {
    border-width: 1px
  }

  .sm\:border-t-0 {
    border-top-width: 0
  }

  .sm\:border-r-0 {
    border-right-width: 0
  }

  .sm\:border-b-0 {
    border-bottom-width: 0
  }

  .sm\:border-l-0 {
    border-left-width: 0
  }

  .sm\:border-t-2 {
    border-top-width: 2px
  }

  .sm\:border-r-2 {
    border-right-width: 2px
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px
  }

  .sm\:border-l-2 {
    border-left-width: 2px
  }

  .sm\:border-t-4 {
    border-top-width: 4px
  }

  .sm\:border-r-4 {
    border-right-width: 4px
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px
  }

  .sm\:border-l-4 {
    border-left-width: 4px
  }

  .sm\:border-t-8 {
    border-top-width: 8px
  }

  .sm\:border-r-8 {
    border-right-width: 8px
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px
  }

  .sm\:border-l-8 {
    border-left-width: 8px
  }

  .sm\:border-t {
    border-top-width: 1px
  }

  .sm\:border-r {
    border-right-width: 1px
  }

  .sm\:border-b {
    border-bottom-width: 1px
  }

  .sm\:border-l {
    border-left-width: 1px
  }

  .sm\:box-border {
    box-sizing: border-box
  }

  .sm\:box-content {
    box-sizing: content-box
  }

  .sm\:cursor-auto {
    cursor: auto
  }

  .sm\:cursor-default {
    cursor: default
  }

  .sm\:cursor-pointer {
    cursor: pointer
  }

  .sm\:cursor-wait {
    cursor: wait
  }

  .sm\:cursor-text {
    cursor: text
  }

  .sm\:cursor-move {
    cursor: move
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:flex {
    display: flex
  }

  .sm\:inline-flex {
    display: inline-flex
  }

  .sm\:table {
    display: table
  }

  .sm\:table-caption {
    display: table-caption
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:table-column {
    display: table-column
  }

  .sm\:table-column-group {
    display: table-column-group
  }

  .sm\:table-footer-group {
    display: table-footer-group
  }

  .sm\:table-header-group {
    display: table-header-group
  }

  .sm\:table-row-group {
    display: table-row-group
  }

  .sm\:table-row {
    display: table-row
  }

  .sm\:flow-root {
    display: flow-root
  }

  .sm\:grid {
    display: grid
  }

  .sm\:inline-grid {
    display: inline-grid
  }

  .sm\:contents {
    display: contents
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .sm\:place-items-auto {
    place-items: auto
  }

  .sm\:place-items-start {
    place-items: start
  }

  .sm\:place-items-end {
    place-items: end
  }

  .sm\:place-items-center {
    place-items: center
  }

  .sm\:place-items-stretch {
    place-items: stretch
  }

  .sm\:place-content-center {
    place-content: center
  }

  .sm\:place-content-start {
    place-content: start
  }

  .sm\:place-content-end {
    place-content: end
  }

  .sm\:place-content-between {
    place-content: space-between
  }

  .sm\:place-content-around {
    place-content: space-around
  }

  .sm\:place-content-evenly {
    place-content: space-evenly
  }

  .sm\:place-content-stretch {
    place-content: stretch
  }

  .sm\:place-self-auto {
    place-self: auto
  }

  .sm\:place-self-start {
    place-self: start
  }

  .sm\:place-self-end {
    place-self: end
  }

  .sm\:place-self-center {
    place-self: center
  }

  .sm\:place-self-stretch {
    place-self: stretch
  }

  .sm\:items-start {
    align-items: flex-start
  }

  .sm\:items-end {
    align-items: flex-end
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:items-baseline {
    align-items: baseline
  }

  .sm\:items-stretch {
    align-items: stretch
  }

  .sm\:content-center {
    align-content: center
  }

  .sm\:content-start {
    align-content: flex-start
  }

  .sm\:content-end {
    align-content: flex-end
  }

  .sm\:content-between {
    align-content: space-between
  }

  .sm\:content-around {
    align-content: space-around
  }

  .sm\:content-evenly {
    align-content: space-evenly
  }

  .sm\:self-auto {
    align-self: auto
  }

  .sm\:self-start {
    align-self: flex-start
  }

  .sm\:self-end {
    align-self: flex-end
  }

  .sm\:self-center {
    align-self: center
  }

  .sm\:self-stretch {
    align-self: stretch
  }

  .sm\:justify-items-auto {
    justify-items: auto
  }

  .sm\:justify-items-start {
    justify-items: start
  }

  .sm\:justify-items-end {
    justify-items: end
  }

  .sm\:justify-items-center {
    justify-items: center
  }

  .sm\:justify-items-stretch {
    justify-items: stretch
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-end {
    justify-content: flex-end
  }

  .sm\:justify-center {
    justify-content: center
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:justify-around {
    justify-content: space-around
  }

  .sm\:justify-evenly {
    justify-content: space-evenly
  }

  .sm\:justify-self-auto {
    justify-self: auto
  }

  .sm\:justify-self-start {
    justify-self: start
  }

  .sm\:justify-self-end {
    justify-self: end
  }

  .sm\:justify-self-center {
    justify-self: center
  }

  .sm\:justify-self-stretch {
    justify-self: stretch
  }

  .sm\:flex-1 {
    flex: 1 1 0%
  }

  .sm\:flex-auto {
    flex: 1 1 auto
  }

  .sm\:flex-initial {
    flex: 0 1 auto
  }

  .sm\:flex-none {
    flex: none
  }

  .sm\:flex-grow-0 {
    flex-grow: 0
  }

  .sm\:flex-grow {
    flex-grow: 1
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0
  }

  .sm\:flex-shrink {
    flex-shrink: 1
  }

  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:order-3 {
    order: 3
  }

  .sm\:order-4 {
    order: 4
  }

  .sm\:order-5 {
    order: 5
  }

  .sm\:order-6 {
    order: 6
  }

  .sm\:order-7 {
    order: 7
  }

  .sm\:order-8 {
    order: 8
  }

  .sm\:order-9 {
    order: 9
  }

  .sm\:order-10 {
    order: 10
  }

  .sm\:order-11 {
    order: 11
  }

  .sm\:order-12 {
    order: 12
  }

  .sm\:order-first {
    order: -9999
  }

  .sm\:order-last {
    order: 9999
  }

  .sm\:order-none {
    order: 0
  }

  .sm\:float-right {
    float: right
  }

  .sm\:float-left {
    float: left
  }

  .sm\:float-none {
    float: none
  }

  .sm\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .sm\:clear-left {
    clear: left
  }

  .sm\:clear-right {
    clear: right
  }

  .sm\:clear-both {
    clear: both
  }

  .sm\:clear-none {
    clear: none
  }

  .sm\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .sm\:font-hairline {
    font-weight: 100
  }

  .sm\:font-thin {
    font-weight: 200
  }

  .sm\:font-light {
    font-weight: 300
  }

  .sm\:font-normal {
    font-weight: 400
  }

  .sm\:font-medium {
    font-weight: 500
  }

  .sm\:font-semibold {
    font-weight: 600
  }

  .sm\:font-bold {
    font-weight: 700
  }

  .sm\:font-extrabold {
    font-weight: 800
  }

  .sm\:font-black {
    font-weight: 900
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900
  }

  .sm\:h-0 {
    height: 0
  }

  .sm\:h-1 {
    height: 0.25rem
  }

  .sm\:h-2 {
    height: 0.5rem
  }

  .sm\:h-3 {
    height: 0.75rem
  }

  .sm\:h-4 {
    height: 1rem
  }

  .sm\:h-5 {
    height: 1.25rem
  }

  .sm\:h-6 {
    height: 1.5rem
  }

  .sm\:h-8 {
    height: 2rem
  }

  .sm\:h-10 {
    height: 2.5rem
  }

  .sm\:h-12 {
    height: 3rem
  }

  .sm\:h-16 {
    height: 4rem
  }

  .sm\:h-20 {
    height: 5rem
  }

  .sm\:h-24 {
    height: 6rem
  }

  .sm\:h-32 {
    height: 8rem
  }

  .sm\:h-40 {
    height: 10rem
  }

  .sm\:h-48 {
    height: 12rem
  }

  .sm\:h-56 {
    height: 14rem
  }

  .sm\:h-64 {
    height: 16rem
  }

  .sm\:h-auto {
    height: auto
  }

  .sm\:h-px {
    height: 1px
  }

  .sm\:h-full {
    height: 100%
  }

  .sm\:h-screen {
    height: 100vh
  }

  .sm\:text-xs {
    font-size: 0.75rem
  }

  .sm\:text-sm {
    font-size: 0.875rem
  }

  .sm\:text-base {
    font-size: 1rem
  }

  .sm\:text-lg {
    font-size: 1.125rem
  }

  .sm\:text-xl {
    font-size: 1.25rem
  }

  .sm\:text-2xl {
    font-size: 1.5rem
  }

  .sm\:text-3xl {
    font-size: 1.875rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem
  }

  .sm\:text-5xl {
    font-size: 3rem
  }

  .sm\:text-6xl {
    font-size: 4rem
  }

  .sm\:leading-3 {
    line-height: .75rem
  }

  .sm\:leading-4 {
    line-height: 1rem
  }

  .sm\:leading-5 {
    line-height: 1.25rem
  }

  .sm\:leading-6 {
    line-height: 1.5rem
  }

  .sm\:leading-7 {
    line-height: 1.75rem
  }

  .sm\:leading-8 {
    line-height: 2rem
  }

  .sm\:leading-9 {
    line-height: 2.25rem
  }

  .sm\:leading-10 {
    line-height: 2.5rem
  }

  .sm\:leading-none {
    line-height: 1
  }

  .sm\:leading-tight {
    line-height: 1.25
  }

  .sm\:leading-snug {
    line-height: 1.375
  }

  .sm\:leading-normal {
    line-height: 1.5
  }

  .sm\:leading-relaxed {
    line-height: 1.625
  }

  .sm\:leading-loose {
    line-height: 2
  }

  .sm\:list-inside {
    list-style-position: inside
  }

  .sm\:list-outside {
    list-style-position: outside
  }

  .sm\:list-none {
    list-style-type: none
  }

  .sm\:list-disc {
    list-style-type: disc
  }

  .sm\:list-decimal {
    list-style-type: decimal
  }

  .sm\:m-0 {
    margin: 0
  }

  .sm\:m-1 {
    margin: 0.25rem
  }

  .sm\:m-2 {
    margin: 0.5rem
  }

  .sm\:m-3 {
    margin: 0.75rem
  }

  .sm\:m-4 {
    margin: 1rem
  }

  .sm\:m-5 {
    margin: 1.25rem
  }

  .sm\:m-6 {
    margin: 1.5rem
  }

  .sm\:m-8 {
    margin: 2rem
  }

  .sm\:m-10 {
    margin: 2.5rem
  }

  .sm\:m-12 {
    margin: 3rem
  }

  .sm\:m-16 {
    margin: 4rem
  }

  .sm\:m-20 {
    margin: 5rem
  }

  .sm\:m-24 {
    margin: 6rem
  }

  .sm\:m-32 {
    margin: 8rem
  }

  .sm\:m-40 {
    margin: 10rem
  }

  .sm\:m-48 {
    margin: 12rem
  }

  .sm\:m-56 {
    margin: 14rem
  }

  .sm\:m-64 {
    margin: 16rem
  }

  .sm\:m-auto {
    margin: auto
  }

  .sm\:m-px {
    margin: 1px
  }

  .sm\:-m-1 {
    margin: -0.25rem
  }

  .sm\:-m-2 {
    margin: -0.5rem
  }

  .sm\:-m-3 {
    margin: -0.75rem
  }

  .sm\:-m-4 {
    margin: -1rem
  }

  .sm\:-m-5 {
    margin: -1.25rem
  }

  .sm\:-m-6 {
    margin: -1.5rem
  }

  .sm\:-m-8 {
    margin: -2rem
  }

  .sm\:-m-10 {
    margin: -2.5rem
  }

  .sm\:-m-12 {
    margin: -3rem
  }

  .sm\:-m-16 {
    margin: -4rem
  }

  .sm\:-m-20 {
    margin: -5rem
  }

  .sm\:-m-24 {
    margin: -6rem
  }

  .sm\:-m-32 {
    margin: -8rem
  }

  .sm\:-m-40 {
    margin: -10rem
  }

  .sm\:-m-48 {
    margin: -12rem
  }

  .sm\:-m-56 {
    margin: -14rem
  }

  .sm\:-m-64 {
    margin: -16rem
  }

  .sm\:-m-px {
    margin: -1px
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mr-0 {
    margin-right: 0
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:ml-0 {
    margin-left: 0
  }

  .sm\:mt-1 {
    margin-top: 0.25rem
  }

  .sm\:mr-1 {
    margin-right: 0.25rem
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem
  }

  .sm\:ml-1 {
    margin-left: 0.25rem
  }

  .sm\:mt-2 {
    margin-top: 0.5rem
  }

  .sm\:mr-2 {
    margin-right: 0.5rem
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem
  }

  .sm\:ml-2 {
    margin-left: 0.5rem
  }

  .sm\:mt-3 {
    margin-top: 0.75rem
  }

  .sm\:mr-3 {
    margin-right: 0.75rem
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem
  }

  .sm\:ml-3 {
    margin-left: 0.75rem
  }

  .sm\:mt-4 {
    margin-top: 1rem
  }

  .sm\:mr-4 {
    margin-right: 1rem
  }

  .sm\:mb-4 {
    margin-bottom: 1rem
  }

  .sm\:ml-4 {
    margin-left: 1rem
  }

  .sm\:mt-5 {
    margin-top: 1.25rem
  }

  .sm\:mr-5 {
    margin-right: 1.25rem
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem
  }

  .sm\:ml-5 {
    margin-left: 1.25rem
  }

  .sm\:mt-6 {
    margin-top: 1.5rem
  }

  .sm\:mr-6 {
    margin-right: 1.5rem
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem
  }

  .sm\:ml-6 {
    margin-left: 1.5rem
  }

  .sm\:mt-8 {
    margin-top: 2rem
  }

  .sm\:mr-8 {
    margin-right: 2rem
  }

  .sm\:mb-8 {
    margin-bottom: 2rem
  }

  .sm\:ml-8 {
    margin-left: 2rem
  }

  .sm\:mt-10 {
    margin-top: 2.5rem
  }

  .sm\:mr-10 {
    margin-right: 2.5rem
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem
  }

  .sm\:ml-10 {
    margin-left: 2.5rem
  }

  .sm\:mt-12 {
    margin-top: 3rem
  }

  .sm\:mr-12 {
    margin-right: 3rem
  }

  .sm\:mb-12 {
    margin-bottom: 3rem
  }

  .sm\:ml-12 {
    margin-left: 3rem
  }

  .sm\:mt-16 {
    margin-top: 4rem
  }

  .sm\:mr-16 {
    margin-right: 4rem
  }

  .sm\:mb-16 {
    margin-bottom: 4rem
  }

  .sm\:ml-16 {
    margin-left: 4rem
  }

  .sm\:mt-20 {
    margin-top: 5rem
  }

  .sm\:mr-20 {
    margin-right: 5rem
  }

  .sm\:mb-20 {
    margin-bottom: 5rem
  }

  .sm\:ml-20 {
    margin-left: 5rem
  }

  .sm\:mt-24 {
    margin-top: 6rem
  }

  .sm\:mr-24 {
    margin-right: 6rem
  }

  .sm\:mb-24 {
    margin-bottom: 6rem
  }

  .sm\:ml-24 {
    margin-left: 6rem
  }

  .sm\:mt-32 {
    margin-top: 8rem
  }

  .sm\:mr-32 {
    margin-right: 8rem
  }

  .sm\:mb-32 {
    margin-bottom: 8rem
  }

  .sm\:ml-32 {
    margin-left: 8rem
  }

  .sm\:mt-40 {
    margin-top: 10rem
  }

  .sm\:mr-40 {
    margin-right: 10rem
  }

  .sm\:mb-40 {
    margin-bottom: 10rem
  }

  .sm\:ml-40 {
    margin-left: 10rem
  }

  .sm\:mt-48 {
    margin-top: 12rem
  }

  .sm\:mr-48 {
    margin-right: 12rem
  }

  .sm\:mb-48 {
    margin-bottom: 12rem
  }

  .sm\:ml-48 {
    margin-left: 12rem
  }

  .sm\:mt-56 {
    margin-top: 14rem
  }

  .sm\:mr-56 {
    margin-right: 14rem
  }

  .sm\:mb-56 {
    margin-bottom: 14rem
  }

  .sm\:ml-56 {
    margin-left: 14rem
  }

  .sm\:mt-64 {
    margin-top: 16rem
  }

  .sm\:mr-64 {
    margin-right: 16rem
  }

  .sm\:mb-64 {
    margin-bottom: 16rem
  }

  .sm\:ml-64 {
    margin-left: 16rem
  }

  .sm\:mt-auto {
    margin-top: auto
  }

  .sm\:mr-auto {
    margin-right: auto
  }

  .sm\:mb-auto {
    margin-bottom: auto
  }

  .sm\:ml-auto {
    margin-left: auto
  }

  .sm\:mt-px {
    margin-top: 1px
  }

  .sm\:mr-px {
    margin-right: 1px
  }

  .sm\:mb-px {
    margin-bottom: 1px
  }

  .sm\:ml-px {
    margin-left: 1px
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem
  }

  .sm\:-mt-3 {
    margin-top: -0.75rem
  }

  .sm\:-mr-3 {
    margin-right: -0.75rem
  }

  .sm\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .sm\:-ml-3 {
    margin-left: -0.75rem
  }

  .sm\:-mt-4 {
    margin-top: -1rem
  }

  .sm\:-mr-4 {
    margin-right: -1rem
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem
  }

  .sm\:-ml-4 {
    margin-left: -1rem
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem
  }

  .sm\:-mt-8 {
    margin-top: -2rem
  }

  .sm\:-mr-8 {
    margin-right: -2rem
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem
  }

  .sm\:-ml-8 {
    margin-left: -2rem
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem
  }

  .sm\:-mt-12 {
    margin-top: -3rem
  }

  .sm\:-mr-12 {
    margin-right: -3rem
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem
  }

  .sm\:-ml-12 {
    margin-left: -3rem
  }

  .sm\:-mt-16 {
    margin-top: -4rem
  }

  .sm\:-mr-16 {
    margin-right: -4rem
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem
  }

  .sm\:-ml-16 {
    margin-left: -4rem
  }

  .sm\:-mt-20 {
    margin-top: -5rem
  }

  .sm\:-mr-20 {
    margin-right: -5rem
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem
  }

  .sm\:-ml-20 {
    margin-left: -5rem
  }

  .sm\:-mt-24 {
    margin-top: -6rem
  }

  .sm\:-mr-24 {
    margin-right: -6rem
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem
  }

  .sm\:-ml-24 {
    margin-left: -6rem
  }

  .sm\:-mt-32 {
    margin-top: -8rem
  }

  .sm\:-mr-32 {
    margin-right: -8rem
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem
  }

  .sm\:-ml-32 {
    margin-left: -8rem
  }

  .sm\:-mt-40 {
    margin-top: -10rem
  }

  .sm\:-mr-40 {
    margin-right: -10rem
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem
  }

  .sm\:-ml-40 {
    margin-left: -10rem
  }

  .sm\:-mt-48 {
    margin-top: -12rem
  }

  .sm\:-mr-48 {
    margin-right: -12rem
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem
  }

  .sm\:-ml-48 {
    margin-left: -12rem
  }

  .sm\:-mt-56 {
    margin-top: -14rem
  }

  .sm\:-mr-56 {
    margin-right: -14rem
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem
  }

  .sm\:-ml-56 {
    margin-left: -14rem
  }

  .sm\:-mt-64 {
    margin-top: -16rem
  }

  .sm\:-mr-64 {
    margin-right: -16rem
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem
  }

  .sm\:-ml-64 {
    margin-left: -16rem
  }

  .sm\:-mt-px {
    margin-top: -1px
  }

  .sm\:-mr-px {
    margin-right: -1px
  }

  .sm\:-mb-px {
    margin-bottom: -1px
  }

  .sm\:-ml-px {
    margin-left: -1px
  }

  .sm\:max-h-full {
    max-height: 100%
  }

  .sm\:max-h-screen {
    max-height: 100vh
  }

  .sm\:max-w-none {
    max-width: none
  }

  .sm\:max-w-xs {
    max-width: 20rem
  }

  .sm\:max-w-sm {
    max-width: 24rem
  }

  .sm\:max-w-md {
    max-width: 28rem
  }

  .sm\:max-w-lg {
    max-width: 32rem
  }

  .sm\:max-w-xl {
    max-width: 36rem
  }

  .sm\:max-w-2xl {
    max-width: 42rem
  }

  .sm\:max-w-3xl {
    max-width: 48rem
  }

  .sm\:max-w-4xl {
    max-width: 56rem
  }

  .sm\:max-w-5xl {
    max-width: 64rem
  }

  .sm\:max-w-6xl {
    max-width: 72rem
  }

  .sm\:max-w-full {
    max-width: 100%
  }

  .sm\:max-w-screen-sm {
    max-width: 640px
  }

  .sm\:max-w-screen-md {
    max-width: 768px
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px
  }

  .sm\:min-h-0 {
    min-height: 0
  }

  .sm\:min-h-full {
    min-height: 100%
  }

  .sm\:min-h-screen {
    min-height: 100vh
  }

  .sm\:min-w-0 {
    min-width: 0
  }

  .sm\:min-w-full {
    min-width: 100%
  }

  .sm\:object-contain {
    -o-object-fit: contain;
       object-fit: contain
  }

  .sm\:object-cover {
    -o-object-fit: cover;
       object-fit: cover
  }

  .sm\:object-fill {
    -o-object-fit: fill;
       object-fit: fill
  }

  .sm\:object-none {
    -o-object-fit: none;
       object-fit: none
  }

  .sm\:object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down
  }

  .sm\:object-bottom {
    -o-object-position: bottom;
       object-position: bottom
  }

  .sm\:object-center {
    -o-object-position: center;
       object-position: center
  }

  .sm\:object-left {
    -o-object-position: left;
       object-position: left
  }

  .sm\:object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom
  }

  .sm\:object-left-top {
    -o-object-position: left top;
       object-position: left top
  }

  .sm\:object-right {
    -o-object-position: right;
       object-position: right
  }

  .sm\:object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom
  }

  .sm\:object-right-top {
    -o-object-position: right top;
       object-position: right top
  }

  .sm\:object-top {
    -o-object-position: top;
       object-position: top
  }

  .sm\:opacity-0 {
    opacity: 0
  }

  .sm\:opacity-25 {
    opacity: 0.25
  }

  .sm\:opacity-50 {
    opacity: 0.5
  }

  .sm\:opacity-75 {
    opacity: 0.75
  }

  .sm\:opacity-100 {
    opacity: 1
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1
  }

  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .sm\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .sm\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .sm\:overflow-auto {
    overflow: auto
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:overflow-visible {
    overflow: visible
  }

  .sm\:overflow-scroll {
    overflow: scroll
  }

  .sm\:overflow-x-auto {
    overflow-x: auto
  }

  .sm\:overflow-y-auto {
    overflow-y: auto
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden
  }

  .sm\:overflow-x-visible {
    overflow-x: visible
  }

  .sm\:overflow-y-visible {
    overflow-y: visible
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain
  }

  .sm\:overscroll-none {
    overscroll-behavior: none
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .sm\:p-0 {
    padding: 0
  }

  .sm\:p-1 {
    padding: 0.25rem
  }

  .sm\:p-2 {
    padding: 0.5rem
  }

  .sm\:p-3 {
    padding: 0.75rem
  }

  .sm\:p-4 {
    padding: 1rem
  }

  .sm\:p-5 {
    padding: 1.25rem
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:p-8 {
    padding: 2rem
  }

  .sm\:p-10 {
    padding: 2.5rem
  }

  .sm\:p-12 {
    padding: 3rem
  }

  .sm\:p-16 {
    padding: 4rem
  }

  .sm\:p-20 {
    padding: 5rem
  }

  .sm\:p-24 {
    padding: 6rem
  }

  .sm\:p-32 {
    padding: 8rem
  }

  .sm\:p-40 {
    padding: 10rem
  }

  .sm\:p-48 {
    padding: 12rem
  }

  .sm\:p-56 {
    padding: 14rem
  }

  .sm\:p-64 {
    padding: 16rem
  }

  .sm\:p-px {
    padding: 1px
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  .sm\:pr-0 {
    padding-right: 0
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pl-0 {
    padding-left: 0
  }

  .sm\:pt-1 {
    padding-top: 0.25rem
  }

  .sm\:pr-1 {
    padding-right: 0.25rem
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem
  }

  .sm\:pl-1 {
    padding-left: 0.25rem
  }

  .sm\:pt-2 {
    padding-top: 0.5rem
  }

  .sm\:pr-2 {
    padding-right: 0.5rem
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem
  }

  .sm\:pl-2 {
    padding-left: 0.5rem
  }

  .sm\:pt-3 {
    padding-top: 0.75rem
  }

  .sm\:pr-3 {
    padding-right: 0.75rem
  }

  .sm\:pb-3 {
    padding-bottom: 0.75rem
  }

  .sm\:pl-3 {
    padding-left: 0.75rem
  }

  .sm\:pt-4 {
    padding-top: 1rem
  }

  .sm\:pr-4 {
    padding-right: 1rem
  }

  .sm\:pb-4 {
    padding-bottom: 1rem
  }

  .sm\:pl-4 {
    padding-left: 1rem
  }

  .sm\:pt-5 {
    padding-top: 1.25rem
  }

  .sm\:pr-5 {
    padding-right: 1.25rem
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem
  }

  .sm\:pl-5 {
    padding-left: 1.25rem
  }

  .sm\:pt-6 {
    padding-top: 1.5rem
  }

  .sm\:pr-6 {
    padding-right: 1.5rem
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem
  }

  .sm\:pl-6 {
    padding-left: 1.5rem
  }

  .sm\:pt-8 {
    padding-top: 2rem
  }

  .sm\:pr-8 {
    padding-right: 2rem
  }

  .sm\:pb-8 {
    padding-bottom: 2rem
  }

  .sm\:pl-8 {
    padding-left: 2rem
  }

  .sm\:pt-10 {
    padding-top: 2.5rem
  }

  .sm\:pr-10 {
    padding-right: 2.5rem
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem
  }

  .sm\:pl-10 {
    padding-left: 2.5rem
  }

  .sm\:pt-12 {
    padding-top: 3rem
  }

  .sm\:pr-12 {
    padding-right: 3rem
  }

  .sm\:pb-12 {
    padding-bottom: 3rem
  }

  .sm\:pl-12 {
    padding-left: 3rem
  }

  .sm\:pt-16 {
    padding-top: 4rem
  }

  .sm\:pr-16 {
    padding-right: 4rem
  }

  .sm\:pb-16 {
    padding-bottom: 4rem
  }

  .sm\:pl-16 {
    padding-left: 4rem
  }

  .sm\:pt-20 {
    padding-top: 5rem
  }

  .sm\:pr-20 {
    padding-right: 5rem
  }

  .sm\:pb-20 {
    padding-bottom: 5rem
  }

  .sm\:pl-20 {
    padding-left: 5rem
  }

  .sm\:pt-24 {
    padding-top: 6rem
  }

  .sm\:pr-24 {
    padding-right: 6rem
  }

  .sm\:pb-24 {
    padding-bottom: 6rem
  }

  .sm\:pl-24 {
    padding-left: 6rem
  }

  .sm\:pt-32 {
    padding-top: 8rem
  }

  .sm\:pr-32 {
    padding-right: 8rem
  }

  .sm\:pb-32 {
    padding-bottom: 8rem
  }

  .sm\:pl-32 {
    padding-left: 8rem
  }

  .sm\:pt-40 {
    padding-top: 10rem
  }

  .sm\:pr-40 {
    padding-right: 10rem
  }

  .sm\:pb-40 {
    padding-bottom: 10rem
  }

  .sm\:pl-40 {
    padding-left: 10rem
  }

  .sm\:pt-48 {
    padding-top: 12rem
  }

  .sm\:pr-48 {
    padding-right: 12rem
  }

  .sm\:pb-48 {
    padding-bottom: 12rem
  }

  .sm\:pl-48 {
    padding-left: 12rem
  }

  .sm\:pt-56 {
    padding-top: 14rem
  }

  .sm\:pr-56 {
    padding-right: 14rem
  }

  .sm\:pb-56 {
    padding-bottom: 14rem
  }

  .sm\:pl-56 {
    padding-left: 14rem
  }

  .sm\:pt-64 {
    padding-top: 16rem
  }

  .sm\:pr-64 {
    padding-right: 16rem
  }

  .sm\:pb-64 {
    padding-bottom: 16rem
  }

  .sm\:pl-64 {
    padding-left: 16rem
  }

  .sm\:pt-px {
    padding-top: 1px
  }

  .sm\:pr-px {
    padding-right: 1px
  }

  .sm\:pb-px {
    padding-bottom: 1px
  }

  .sm\:pl-px {
    padding-left: 1px
  }

  .sm\:placeholder-primary::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .sm\:placeholder-primary::placeholder {
    color: var(--ion-color-primary)
  }

  .sm\:placeholder-primary-shade::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .sm\:placeholder-primary-shade::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .sm\:placeholder-primary-tint::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .sm\:placeholder-primary-tint::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .sm\:placeholder-secondary::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .sm\:placeholder-secondary::placeholder {
    color: var(--ion-color-secondary)
  }

  .sm\:placeholder-secondary-shade::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:placeholder-secondary-shade::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:placeholder-secondary-tint::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:placeholder-secondary-tint::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:placeholder-tertiary::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .sm\:placeholder-tertiary::placeholder {
    color: var(--ion-color-tertiary)
  }

  .sm\:placeholder-tertiary-shade::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:placeholder-tertiary-shade::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:placeholder-tertiary-tint::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:placeholder-tertiary-tint::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:placeholder-light::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .sm\:placeholder-light::placeholder {
    color: var(--ion-color-light)
  }

  .sm\:placeholder-light-shade::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .sm\:placeholder-light-shade::placeholder {
    color: var(--ion-color-light-shade)
  }

  .sm\:placeholder-light-tint::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .sm\:placeholder-light-tint::placeholder {
    color: var(--ion-color-light-tint)
  }

  .sm\:placeholder-medium::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .sm\:placeholder-medium::placeholder {
    color: var(--ion-color-medium)
  }

  .sm\:placeholder-medium-shade::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .sm\:placeholder-medium-shade::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .sm\:placeholder-medium-tint::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .sm\:placeholder-medium-tint::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .sm\:placeholder-dark::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .sm\:placeholder-dark::placeholder {
    color: var(--ion-color-dark)
  }

  .sm\:placeholder-dark-shade::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .sm\:placeholder-dark-shade::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .sm\:placeholder-dark-tint::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .sm\:placeholder-dark-tint::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .sm\:placeholder-success::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .sm\:placeholder-success::placeholder {
    color: var(--ion-color-success)
  }

  .sm\:placeholder-success-shade::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .sm\:placeholder-success-shade::placeholder {
    color: var(--ion-color-success-shade)
  }

  .sm\:placeholder-success-tint::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .sm\:placeholder-success-tint::placeholder {
    color: var(--ion-color-success-tint)
  }

  .sm\:placeholder-warning::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .sm\:placeholder-warning::placeholder {
    color: var(--ion-color-warning)
  }

  .sm\:placeholder-warning-shade::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .sm\:placeholder-warning-shade::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .sm\:placeholder-warning-tint::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .sm\:placeholder-warning-tint::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .sm\:placeholder-danger::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .sm\:placeholder-danger::placeholder {
    color: var(--ion-color-danger)
  }

  .sm\:placeholder-danger-shade::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .sm\:placeholder-danger-shade::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .sm\:placeholder-danger-tint::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .sm\:placeholder-danger-tint::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .sm\:placeholder-step-50::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .sm\:placeholder-step-50::placeholder {
    color: var(--ion-color-step-50)
  }

  .sm\:placeholder-step-100::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .sm\:placeholder-step-100::placeholder {
    color: var(--ion-color-step-100)
  }

  .sm\:placeholder-step-150::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .sm\:placeholder-step-150::placeholder {
    color: var(--ion-color-step-150)
  }

  .sm\:placeholder-step-200::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .sm\:placeholder-step-200::placeholder {
    color: var(--ion-color-step-200)
  }

  .sm\:placeholder-step-250::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .sm\:placeholder-step-250::placeholder {
    color: var(--ion-color-step-250)
  }

  .sm\:placeholder-step-300::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .sm\:placeholder-step-300::placeholder {
    color: var(--ion-color-step-300)
  }

  .sm\:placeholder-step-350::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .sm\:placeholder-step-350::placeholder {
    color: var(--ion-color-step-350)
  }

  .sm\:placeholder-step-400::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .sm\:placeholder-step-400::placeholder {
    color: var(--ion-color-step-400)
  }

  .sm\:placeholder-step-450::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .sm\:placeholder-step-450::placeholder {
    color: var(--ion-color-step-450)
  }

  .sm\:placeholder-step-500::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .sm\:placeholder-step-500::placeholder {
    color: var(--ion-color-step-500)
  }

  .sm\:placeholder-step-550::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .sm\:placeholder-step-550::placeholder {
    color: var(--ion-color-step-550)
  }

  .sm\:placeholder-step-600::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .sm\:placeholder-step-600::placeholder {
    color: var(--ion-color-step-600)
  }

  .sm\:placeholder-step-650::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .sm\:placeholder-step-650::placeholder {
    color: var(--ion-color-step-650)
  }

  .sm\:placeholder-step-700::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .sm\:placeholder-step-700::placeholder {
    color: var(--ion-color-step-700)
  }

  .sm\:placeholder-step-750::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .sm\:placeholder-step-750::placeholder {
    color: var(--ion-color-step-750)
  }

  .sm\:placeholder-step-800::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .sm\:placeholder-step-800::placeholder {
    color: var(--ion-color-step-800)
  }

  .sm\:placeholder-step-850::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .sm\:placeholder-step-850::placeholder {
    color: var(--ion-color-step-850)
  }

  .sm\:placeholder-step-900::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .sm\:placeholder-step-900::placeholder {
    color: var(--ion-color-step-900)
  }

  .sm\:placeholder-step-950::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .sm\:placeholder-step-950::placeholder {
    color: var(--ion-color-step-950)
  }

  .sm\:focus\:placeholder-primary:focus::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .sm\:focus\:placeholder-primary:focus::placeholder {
    color: var(--ion-color-primary)
  }

  .sm\:focus\:placeholder-primary-shade:focus::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:placeholder-primary-shade:focus::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:placeholder-primary-tint:focus::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:placeholder-primary-tint:focus::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:placeholder-secondary:focus::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .sm\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--ion-color-secondary)
  }

  .sm\:focus\:placeholder-secondary-shade:focus::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:placeholder-secondary-shade:focus::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:placeholder-secondary-tint:focus::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:placeholder-secondary-tint:focus::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:placeholder-tertiary:focus::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .sm\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--ion-color-tertiary)
  }

  .sm\:focus\:placeholder-tertiary-shade:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:placeholder-tertiary-shade:focus::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:placeholder-tertiary-tint:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:placeholder-tertiary-tint:focus::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:placeholder-light:focus::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .sm\:focus\:placeholder-light:focus::placeholder {
    color: var(--ion-color-light)
  }

  .sm\:focus\:placeholder-light-shade:focus::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .sm\:focus\:placeholder-light-shade:focus::placeholder {
    color: var(--ion-color-light-shade)
  }

  .sm\:focus\:placeholder-light-tint:focus::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .sm\:focus\:placeholder-light-tint:focus::placeholder {
    color: var(--ion-color-light-tint)
  }

  .sm\:focus\:placeholder-medium:focus::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .sm\:focus\:placeholder-medium:focus::placeholder {
    color: var(--ion-color-medium)
  }

  .sm\:focus\:placeholder-medium-shade:focus::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:placeholder-medium-shade:focus::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:placeholder-medium-tint:focus::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:placeholder-medium-tint:focus::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:placeholder-dark:focus::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .sm\:focus\:placeholder-dark:focus::placeholder {
    color: var(--ion-color-dark)
  }

  .sm\:focus\:placeholder-dark-shade:focus::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:placeholder-dark-shade:focus::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:placeholder-dark-tint:focus::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:placeholder-dark-tint:focus::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:placeholder-success:focus::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .sm\:focus\:placeholder-success:focus::placeholder {
    color: var(--ion-color-success)
  }

  .sm\:focus\:placeholder-success-shade:focus::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .sm\:focus\:placeholder-success-shade:focus::placeholder {
    color: var(--ion-color-success-shade)
  }

  .sm\:focus\:placeholder-success-tint:focus::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .sm\:focus\:placeholder-success-tint:focus::placeholder {
    color: var(--ion-color-success-tint)
  }

  .sm\:focus\:placeholder-warning:focus::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .sm\:focus\:placeholder-warning:focus::placeholder {
    color: var(--ion-color-warning)
  }

  .sm\:focus\:placeholder-warning-shade:focus::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:placeholder-warning-shade:focus::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:placeholder-warning-tint:focus::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:placeholder-warning-tint:focus::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:placeholder-danger:focus::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .sm\:focus\:placeholder-danger:focus::placeholder {
    color: var(--ion-color-danger)
  }

  .sm\:focus\:placeholder-danger-shade:focus::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:placeholder-danger-shade:focus::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:placeholder-danger-tint:focus::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:placeholder-danger-tint:focus::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:placeholder-step-50:focus::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .sm\:focus\:placeholder-step-50:focus::placeholder {
    color: var(--ion-color-step-50)
  }

  .sm\:focus\:placeholder-step-100:focus::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .sm\:focus\:placeholder-step-100:focus::placeholder {
    color: var(--ion-color-step-100)
  }

  .sm\:focus\:placeholder-step-150:focus::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .sm\:focus\:placeholder-step-150:focus::placeholder {
    color: var(--ion-color-step-150)
  }

  .sm\:focus\:placeholder-step-200:focus::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .sm\:focus\:placeholder-step-200:focus::placeholder {
    color: var(--ion-color-step-200)
  }

  .sm\:focus\:placeholder-step-250:focus::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .sm\:focus\:placeholder-step-250:focus::placeholder {
    color: var(--ion-color-step-250)
  }

  .sm\:focus\:placeholder-step-300:focus::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .sm\:focus\:placeholder-step-300:focus::placeholder {
    color: var(--ion-color-step-300)
  }

  .sm\:focus\:placeholder-step-350:focus::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .sm\:focus\:placeholder-step-350:focus::placeholder {
    color: var(--ion-color-step-350)
  }

  .sm\:focus\:placeholder-step-400:focus::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .sm\:focus\:placeholder-step-400:focus::placeholder {
    color: var(--ion-color-step-400)
  }

  .sm\:focus\:placeholder-step-450:focus::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .sm\:focus\:placeholder-step-450:focus::placeholder {
    color: var(--ion-color-step-450)
  }

  .sm\:focus\:placeholder-step-500:focus::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .sm\:focus\:placeholder-step-500:focus::placeholder {
    color: var(--ion-color-step-500)
  }

  .sm\:focus\:placeholder-step-550:focus::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .sm\:focus\:placeholder-step-550:focus::placeholder {
    color: var(--ion-color-step-550)
  }

  .sm\:focus\:placeholder-step-600:focus::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .sm\:focus\:placeholder-step-600:focus::placeholder {
    color: var(--ion-color-step-600)
  }

  .sm\:focus\:placeholder-step-650:focus::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .sm\:focus\:placeholder-step-650:focus::placeholder {
    color: var(--ion-color-step-650)
  }

  .sm\:focus\:placeholder-step-700:focus::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .sm\:focus\:placeholder-step-700:focus::placeholder {
    color: var(--ion-color-step-700)
  }

  .sm\:focus\:placeholder-step-750:focus::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .sm\:focus\:placeholder-step-750:focus::placeholder {
    color: var(--ion-color-step-750)
  }

  .sm\:focus\:placeholder-step-800:focus::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .sm\:focus\:placeholder-step-800:focus::placeholder {
    color: var(--ion-color-step-800)
  }

  .sm\:focus\:placeholder-step-850:focus::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .sm\:focus\:placeholder-step-850:focus::placeholder {
    color: var(--ion-color-step-850)
  }

  .sm\:focus\:placeholder-step-900:focus::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .sm\:focus\:placeholder-step-900:focus::placeholder {
    color: var(--ion-color-step-900)
  }

  .sm\:focus\:placeholder-step-950:focus::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .sm\:focus\:placeholder-step-950:focus::placeholder {
    color: var(--ion-color-step-950)
  }

  .sm\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .sm\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .sm\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .sm\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .sm\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .sm\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .sm\:pointer-events-none {
    pointer-events: none
  }

  .sm\:pointer-events-auto {
    pointer-events: auto
  }

  .sm\:static {
    position: static
  }

  .sm\:fixed {
    position: fixed
  }

  .sm\:absolute {
    position: absolute
  }

  .sm\:relative {
    position: relative
  }

  .sm\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .sm\:inset-x-0 {
    right: 0;
    left: 0
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .sm\:inset-x-auto {
    right: auto;
    left: auto
  }

  .sm\:top-0 {
    top: 0
  }

  .sm\:right-0 {
    right: 0
  }

  .sm\:bottom-0 {
    bottom: 0
  }

  .sm\:left-0 {
    left: 0
  }

  .sm\:top-auto {
    top: auto
  }

  .sm\:right-auto {
    right: auto
  }

  .sm\:bottom-auto {
    bottom: auto
  }

  .sm\:left-auto {
    left: auto
  }

  .sm\:resize-none {
    resize: none
  }

  .sm\:resize-y {
    resize: vertical
  }

  .sm\:resize-x {
    resize: horizontal
  }

  .sm\:resize {
    resize: both
  }

  .sm\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:shadow-none {
    box-shadow: none
  }

  .sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .sm\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .sm\:fill-current {
    fill: currentColor
  }

  .sm\:stroke-current {
    stroke: currentColor
  }

  .sm\:stroke-0 {
    stroke-width: 0
  }

  .sm\:stroke-1 {
    stroke-width: 1
  }

  .sm\:stroke-2 {
    stroke-width: 2
  }

  .sm\:table-auto {
    table-layout: auto
  }

  .sm\:table-fixed {
    table-layout: fixed
  }

  .sm\:text-left {
    text-align: left
  }

  .sm\:text-center {
    text-align: center
  }

  .sm\:text-right {
    text-align: right
  }

  .sm\:text-justify {
    text-align: justify
  }

  .sm\:text-primary {
    color: var(--ion-color-primary)
  }

  .sm\:text-primary-shade {
    color: var(--ion-color-primary-shade)
  }

  .sm\:text-primary-tint {
    color: var(--ion-color-primary-tint)
  }

  .sm\:text-secondary {
    color: var(--ion-color-secondary)
  }

  .sm\:text-secondary-shade {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:text-secondary-tint {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:text-tertiary {
    color: var(--ion-color-tertiary)
  }

  .sm\:text-tertiary-shade {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:text-tertiary-tint {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:text-light {
    color: var(--ion-color-light)
  }

  .sm\:text-light-shade {
    color: var(--ion-color-light-shade)
  }

  .sm\:text-light-tint {
    color: var(--ion-color-light-tint)
  }

  .sm\:text-medium {
    color: var(--ion-color-medium)
  }

  .sm\:text-medium-shade {
    color: var(--ion-color-medium-shade)
  }

  .sm\:text-medium-tint {
    color: var(--ion-color-medium-tint)
  }

  .sm\:text-dark {
    color: var(--ion-color-dark)
  }

  .sm\:text-dark-shade {
    color: var(--ion-color-dark-shade)
  }

  .sm\:text-dark-tint {
    color: var(--ion-color-dark-tint)
  }

  .sm\:text-success {
    color: var(--ion-color-success)
  }

  .sm\:text-success-shade {
    color: var(--ion-color-success-shade)
  }

  .sm\:text-success-tint {
    color: var(--ion-color-success-tint)
  }

  .sm\:text-warning {
    color: var(--ion-color-warning)
  }

  .sm\:text-warning-shade {
    color: var(--ion-color-warning-shade)
  }

  .sm\:text-warning-tint {
    color: var(--ion-color-warning-tint)
  }

  .sm\:text-danger {
    color: var(--ion-color-danger)
  }

  .sm\:text-danger-shade {
    color: var(--ion-color-danger-shade)
  }

  .sm\:text-danger-tint {
    color: var(--ion-color-danger-tint)
  }

  .sm\:text-step-50 {
    color: var(--ion-color-step-50)
  }

  .sm\:text-step-100 {
    color: var(--ion-color-step-100)
  }

  .sm\:text-step-150 {
    color: var(--ion-color-step-150)
  }

  .sm\:text-step-200 {
    color: var(--ion-color-step-200)
  }

  .sm\:text-step-250 {
    color: var(--ion-color-step-250)
  }

  .sm\:text-step-300 {
    color: var(--ion-color-step-300)
  }

  .sm\:text-step-350 {
    color: var(--ion-color-step-350)
  }

  .sm\:text-step-400 {
    color: var(--ion-color-step-400)
  }

  .sm\:text-step-450 {
    color: var(--ion-color-step-450)
  }

  .sm\:text-step-500 {
    color: var(--ion-color-step-500)
  }

  .sm\:text-step-550 {
    color: var(--ion-color-step-550)
  }

  .sm\:text-step-600 {
    color: var(--ion-color-step-600)
  }

  .sm\:text-step-650 {
    color: var(--ion-color-step-650)
  }

  .sm\:text-step-700 {
    color: var(--ion-color-step-700)
  }

  .sm\:text-step-750 {
    color: var(--ion-color-step-750)
  }

  .sm\:text-step-800 {
    color: var(--ion-color-step-800)
  }

  .sm\:text-step-850 {
    color: var(--ion-color-step-850)
  }

  .sm\:text-step-900 {
    color: var(--ion-color-step-900)
  }

  .sm\:text-step-950 {
    color: var(--ion-color-step-950)
  }

  .sm\:hover\:text-primary:hover {
    color: var(--ion-color-primary)
  }

  .sm\:hover\:text-primary-shade:hover {
    color: var(--ion-color-primary-shade)
  }

  .sm\:hover\:text-primary-tint:hover {
    color: var(--ion-color-primary-tint)
  }

  .sm\:hover\:text-secondary:hover {
    color: var(--ion-color-secondary)
  }

  .sm\:hover\:text-secondary-shade:hover {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:hover\:text-secondary-tint:hover {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:hover\:text-tertiary:hover {
    color: var(--ion-color-tertiary)
  }

  .sm\:hover\:text-tertiary-shade:hover {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:hover\:text-tertiary-tint:hover {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:hover\:text-light:hover {
    color: var(--ion-color-light)
  }

  .sm\:hover\:text-light-shade:hover {
    color: var(--ion-color-light-shade)
  }

  .sm\:hover\:text-light-tint:hover {
    color: var(--ion-color-light-tint)
  }

  .sm\:hover\:text-medium:hover {
    color: var(--ion-color-medium)
  }

  .sm\:hover\:text-medium-shade:hover {
    color: var(--ion-color-medium-shade)
  }

  .sm\:hover\:text-medium-tint:hover {
    color: var(--ion-color-medium-tint)
  }

  .sm\:hover\:text-dark:hover {
    color: var(--ion-color-dark)
  }

  .sm\:hover\:text-dark-shade:hover {
    color: var(--ion-color-dark-shade)
  }

  .sm\:hover\:text-dark-tint:hover {
    color: var(--ion-color-dark-tint)
  }

  .sm\:hover\:text-success:hover {
    color: var(--ion-color-success)
  }

  .sm\:hover\:text-success-shade:hover {
    color: var(--ion-color-success-shade)
  }

  .sm\:hover\:text-success-tint:hover {
    color: var(--ion-color-success-tint)
  }

  .sm\:hover\:text-warning:hover {
    color: var(--ion-color-warning)
  }

  .sm\:hover\:text-warning-shade:hover {
    color: var(--ion-color-warning-shade)
  }

  .sm\:hover\:text-warning-tint:hover {
    color: var(--ion-color-warning-tint)
  }

  .sm\:hover\:text-danger:hover {
    color: var(--ion-color-danger)
  }

  .sm\:hover\:text-danger-shade:hover {
    color: var(--ion-color-danger-shade)
  }

  .sm\:hover\:text-danger-tint:hover {
    color: var(--ion-color-danger-tint)
  }

  .sm\:hover\:text-step-50:hover {
    color: var(--ion-color-step-50)
  }

  .sm\:hover\:text-step-100:hover {
    color: var(--ion-color-step-100)
  }

  .sm\:hover\:text-step-150:hover {
    color: var(--ion-color-step-150)
  }

  .sm\:hover\:text-step-200:hover {
    color: var(--ion-color-step-200)
  }

  .sm\:hover\:text-step-250:hover {
    color: var(--ion-color-step-250)
  }

  .sm\:hover\:text-step-300:hover {
    color: var(--ion-color-step-300)
  }

  .sm\:hover\:text-step-350:hover {
    color: var(--ion-color-step-350)
  }

  .sm\:hover\:text-step-400:hover {
    color: var(--ion-color-step-400)
  }

  .sm\:hover\:text-step-450:hover {
    color: var(--ion-color-step-450)
  }

  .sm\:hover\:text-step-500:hover {
    color: var(--ion-color-step-500)
  }

  .sm\:hover\:text-step-550:hover {
    color: var(--ion-color-step-550)
  }

  .sm\:hover\:text-step-600:hover {
    color: var(--ion-color-step-600)
  }

  .sm\:hover\:text-step-650:hover {
    color: var(--ion-color-step-650)
  }

  .sm\:hover\:text-step-700:hover {
    color: var(--ion-color-step-700)
  }

  .sm\:hover\:text-step-750:hover {
    color: var(--ion-color-step-750)
  }

  .sm\:hover\:text-step-800:hover {
    color: var(--ion-color-step-800)
  }

  .sm\:hover\:text-step-850:hover {
    color: var(--ion-color-step-850)
  }

  .sm\:hover\:text-step-900:hover {
    color: var(--ion-color-step-900)
  }

  .sm\:hover\:text-step-950:hover {
    color: var(--ion-color-step-950)
  }

  .sm\:focus\:text-primary:focus {
    color: var(--ion-color-primary)
  }

  .sm\:focus\:text-primary-shade:focus {
    color: var(--ion-color-primary-shade)
  }

  .sm\:focus\:text-primary-tint:focus {
    color: var(--ion-color-primary-tint)
  }

  .sm\:focus\:text-secondary:focus {
    color: var(--ion-color-secondary)
  }

  .sm\:focus\:text-secondary-shade:focus {
    color: var(--ion-color-secondary-shade)
  }

  .sm\:focus\:text-secondary-tint:focus {
    color: var(--ion-color-secondary-tint)
  }

  .sm\:focus\:text-tertiary:focus {
    color: var(--ion-color-tertiary)
  }

  .sm\:focus\:text-tertiary-shade:focus {
    color: var(--ion-color-tertiary-shade)
  }

  .sm\:focus\:text-tertiary-tint:focus {
    color: var(--ion-color-tertiary-tint)
  }

  .sm\:focus\:text-light:focus {
    color: var(--ion-color-light)
  }

  .sm\:focus\:text-light-shade:focus {
    color: var(--ion-color-light-shade)
  }

  .sm\:focus\:text-light-tint:focus {
    color: var(--ion-color-light-tint)
  }

  .sm\:focus\:text-medium:focus {
    color: var(--ion-color-medium)
  }

  .sm\:focus\:text-medium-shade:focus {
    color: var(--ion-color-medium-shade)
  }

  .sm\:focus\:text-medium-tint:focus {
    color: var(--ion-color-medium-tint)
  }

  .sm\:focus\:text-dark:focus {
    color: var(--ion-color-dark)
  }

  .sm\:focus\:text-dark-shade:focus {
    color: var(--ion-color-dark-shade)
  }

  .sm\:focus\:text-dark-tint:focus {
    color: var(--ion-color-dark-tint)
  }

  .sm\:focus\:text-success:focus {
    color: var(--ion-color-success)
  }

  .sm\:focus\:text-success-shade:focus {
    color: var(--ion-color-success-shade)
  }

  .sm\:focus\:text-success-tint:focus {
    color: var(--ion-color-success-tint)
  }

  .sm\:focus\:text-warning:focus {
    color: var(--ion-color-warning)
  }

  .sm\:focus\:text-warning-shade:focus {
    color: var(--ion-color-warning-shade)
  }

  .sm\:focus\:text-warning-tint:focus {
    color: var(--ion-color-warning-tint)
  }

  .sm\:focus\:text-danger:focus {
    color: var(--ion-color-danger)
  }

  .sm\:focus\:text-danger-shade:focus {
    color: var(--ion-color-danger-shade)
  }

  .sm\:focus\:text-danger-tint:focus {
    color: var(--ion-color-danger-tint)
  }

  .sm\:focus\:text-step-50:focus {
    color: var(--ion-color-step-50)
  }

  .sm\:focus\:text-step-100:focus {
    color: var(--ion-color-step-100)
  }

  .sm\:focus\:text-step-150:focus {
    color: var(--ion-color-step-150)
  }

  .sm\:focus\:text-step-200:focus {
    color: var(--ion-color-step-200)
  }

  .sm\:focus\:text-step-250:focus {
    color: var(--ion-color-step-250)
  }

  .sm\:focus\:text-step-300:focus {
    color: var(--ion-color-step-300)
  }

  .sm\:focus\:text-step-350:focus {
    color: var(--ion-color-step-350)
  }

  .sm\:focus\:text-step-400:focus {
    color: var(--ion-color-step-400)
  }

  .sm\:focus\:text-step-450:focus {
    color: var(--ion-color-step-450)
  }

  .sm\:focus\:text-step-500:focus {
    color: var(--ion-color-step-500)
  }

  .sm\:focus\:text-step-550:focus {
    color: var(--ion-color-step-550)
  }

  .sm\:focus\:text-step-600:focus {
    color: var(--ion-color-step-600)
  }

  .sm\:focus\:text-step-650:focus {
    color: var(--ion-color-step-650)
  }

  .sm\:focus\:text-step-700:focus {
    color: var(--ion-color-step-700)
  }

  .sm\:focus\:text-step-750:focus {
    color: var(--ion-color-step-750)
  }

  .sm\:focus\:text-step-800:focus {
    color: var(--ion-color-step-800)
  }

  .sm\:focus\:text-step-850:focus {
    color: var(--ion-color-step-850)
  }

  .sm\:focus\:text-step-900:focus {
    color: var(--ion-color-step-900)
  }

  .sm\:focus\:text-step-950:focus {
    color: var(--ion-color-step-950)
  }

  .sm\:italic {
    font-style: italic
  }

  .sm\:not-italic {
    font-style: normal
  }

  .sm\:uppercase {
    text-transform: uppercase
  }

  .sm\:lowercase {
    text-transform: lowercase
  }

  .sm\:capitalize {
    text-transform: capitalize
  }

  .sm\:normal-case {
    text-transform: none
  }

  .sm\:underline {
    text-decoration: underline
  }

  .sm\:line-through {
    text-decoration: line-through
  }

  .sm\:no-underline {
    text-decoration: none
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .sm\:normal-nums {
    font-variant-numeric: normal
  }

  .sm\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .sm\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .sm\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .sm\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .sm\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .sm\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .sm\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .sm\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em
  }

  .sm\:tracking-normal {
    letter-spacing: 0
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em
  }

  .sm\:select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none
  }

  .sm\:select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text
  }

  .sm\:select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto
  }

  .sm\:align-baseline {
    vertical-align: baseline
  }

  .sm\:align-top {
    vertical-align: top
  }

  .sm\:align-middle {
    vertical-align: middle
  }

  .sm\:align-bottom {
    vertical-align: bottom
  }

  .sm\:align-text-top {
    vertical-align: text-top
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom
  }

  .sm\:visible {
    visibility: visible
  }

  .sm\:invisible {
    visibility: hidden
  }

  .sm\:whitespace-normal {
    white-space: normal
  }

  .sm\:whitespace-no-wrap {
    white-space: nowrap
  }

  .sm\:whitespace-pre {
    white-space: pre
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .sm\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .sm\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .sm\:break-all {
    word-break: break-all
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:w-0 {
    width: 0
  }

  .sm\:w-1 {
    width: 0.25rem
  }

  .sm\:w-2 {
    width: 0.5rem
  }

  .sm\:w-3 {
    width: 0.75rem
  }

  .sm\:w-4 {
    width: 1rem
  }

  .sm\:w-5 {
    width: 1.25rem
  }

  .sm\:w-6 {
    width: 1.5rem
  }

  .sm\:w-8 {
    width: 2rem
  }

  .sm\:w-10 {
    width: 2.5rem
  }

  .sm\:w-12 {
    width: 3rem
  }

  .sm\:w-16 {
    width: 4rem
  }

  .sm\:w-20 {
    width: 5rem
  }

  .sm\:w-24 {
    width: 6rem
  }

  .sm\:w-32 {
    width: 8rem
  }

  .sm\:w-40 {
    width: 10rem
  }

  .sm\:w-48 {
    width: 12rem
  }

  .sm\:w-56 {
    width: 14rem
  }

  .sm\:w-64 {
    width: 16rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-px {
    width: 1px
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }

  .sm\:w-2\/3 {
    width: 66.666667%
  }

  .sm\:w-1\/4 {
    width: 25%
  }

  .sm\:w-2\/4 {
    width: 50%
  }

  .sm\:w-3\/4 {
    width: 75%
  }

  .sm\:w-1\/5 {
    width: 20%
  }

  .sm\:w-2\/5 {
    width: 40%
  }

  .sm\:w-3\/5 {
    width: 60%
  }

  .sm\:w-4\/5 {
    width: 80%
  }

  .sm\:w-1\/6 {
    width: 16.666667%
  }

  .sm\:w-2\/6 {
    width: 33.333333%
  }

  .sm\:w-3\/6 {
    width: 50%
  }

  .sm\:w-4\/6 {
    width: 66.666667%
  }

  .sm\:w-5\/6 {
    width: 83.333333%
  }

  .sm\:w-1\/12 {
    width: 8.333333%
  }

  .sm\:w-2\/12 {
    width: 16.666667%
  }

  .sm\:w-3\/12 {
    width: 25%
  }

  .sm\:w-4\/12 {
    width: 33.333333%
  }

  .sm\:w-5\/12 {
    width: 41.666667%
  }

  .sm\:w-6\/12 {
    width: 50%
  }

  .sm\:w-7\/12 {
    width: 58.333333%
  }

  .sm\:w-8\/12 {
    width: 66.666667%
  }

  .sm\:w-9\/12 {
    width: 75%
  }

  .sm\:w-10\/12 {
    width: 83.333333%
  }

  .sm\:w-11\/12 {
    width: 91.666667%
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:w-screen {
    width: 100vw
  }

  .sm\:z-0 {
    z-index: 0
  }

  .sm\:z-10 {
    z-index: 10
  }

  .sm\:z-20 {
    z-index: 20
  }

  .sm\:z-30 {
    z-index: 30
  }

  .sm\:z-40 {
    z-index: 40
  }

  .sm\:z-50 {
    z-index: 50
  }

  .sm\:z-auto {
    z-index: auto
  }

  .sm\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .sm\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .sm\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .sm\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .sm\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .sm\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .sm\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .sm\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .sm\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .sm\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .sm\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .sm\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .sm\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .sm\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .sm\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .sm\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .sm\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .sm\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .sm\:col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .sm\:col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .sm\:col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .sm\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .sm\:col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .sm\:col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .sm\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .sm\:col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .sm\:col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .sm\:col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .sm\:col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .sm\:col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .sm\:col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .sm\:col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .sm\:col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .sm\:col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .sm\:col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .sm\:col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .sm\:col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .sm\:gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .sm\:gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .sm\:gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .sm\:gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .sm\:gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .sm\:gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .sm\:gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .sm\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .sm\:gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .sm\:gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .sm\:gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .sm\:gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .sm\:gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .sm\:gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .sm\:gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .sm\:gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .sm\:gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .sm\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .sm\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .sm\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .sm\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .sm\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .sm\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .sm\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .sm\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .sm\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .sm\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .sm\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .sm\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .sm\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .sm\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .sm\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .sm\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .sm\:grid-cols-none {
    grid-template-columns: none
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .sm\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .sm\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .sm\:col-auto {
    grid-column: auto
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .sm\:col-span-full {
    grid-column: 1 / -1
  }

  .sm\:col-start-1 {
    grid-column-start: 1
  }

  .sm\:col-start-2 {
    grid-column-start: 2
  }

  .sm\:col-start-3 {
    grid-column-start: 3
  }

  .sm\:col-start-4 {
    grid-column-start: 4
  }

  .sm\:col-start-5 {
    grid-column-start: 5
  }

  .sm\:col-start-6 {
    grid-column-start: 6
  }

  .sm\:col-start-7 {
    grid-column-start: 7
  }

  .sm\:col-start-8 {
    grid-column-start: 8
  }

  .sm\:col-start-9 {
    grid-column-start: 9
  }

  .sm\:col-start-10 {
    grid-column-start: 10
  }

  .sm\:col-start-11 {
    grid-column-start: 11
  }

  .sm\:col-start-12 {
    grid-column-start: 12
  }

  .sm\:col-start-13 {
    grid-column-start: 13
  }

  .sm\:col-start-auto {
    grid-column-start: auto
  }

  .sm\:col-end-1 {
    grid-column-end: 1
  }

  .sm\:col-end-2 {
    grid-column-end: 2
  }

  .sm\:col-end-3 {
    grid-column-end: 3
  }

  .sm\:col-end-4 {
    grid-column-end: 4
  }

  .sm\:col-end-5 {
    grid-column-end: 5
  }

  .sm\:col-end-6 {
    grid-column-end: 6
  }

  .sm\:col-end-7 {
    grid-column-end: 7
  }

  .sm\:col-end-8 {
    grid-column-end: 8
  }

  .sm\:col-end-9 {
    grid-column-end: 9
  }

  .sm\:col-end-10 {
    grid-column-end: 10
  }

  .sm\:col-end-11 {
    grid-column-end: 11
  }

  .sm\:col-end-12 {
    grid-column-end: 12
  }

  .sm\:col-end-13 {
    grid-column-end: 13
  }

  .sm\:col-end-auto {
    grid-column-end: auto
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .sm\:grid-rows-none {
    grid-template-rows: none
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .sm\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .sm\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .sm\:row-auto {
    grid-row: auto
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .sm\:row-span-full {
    grid-row: 1 / -1
  }

  .sm\:row-start-1 {
    grid-row-start: 1
  }

  .sm\:row-start-2 {
    grid-row-start: 2
  }

  .sm\:row-start-3 {
    grid-row-start: 3
  }

  .sm\:row-start-4 {
    grid-row-start: 4
  }

  .sm\:row-start-5 {
    grid-row-start: 5
  }

  .sm\:row-start-6 {
    grid-row-start: 6
  }

  .sm\:row-start-7 {
    grid-row-start: 7
  }

  .sm\:row-start-auto {
    grid-row-start: auto
  }

  .sm\:row-end-1 {
    grid-row-end: 1
  }

  .sm\:row-end-2 {
    grid-row-end: 2
  }

  .sm\:row-end-3 {
    grid-row-end: 3
  }

  .sm\:row-end-4 {
    grid-row-end: 4
  }

  .sm\:row-end-5 {
    grid-row-end: 5
  }

  .sm\:row-end-6 {
    grid-row-end: 6
  }

  .sm\:row-end-7 {
    grid-row-end: 7
  }

  .sm\:row-end-auto {
    grid-row-end: auto
  }

  .sm\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .sm\:transform-none {
    transform: none
  }

  .sm\:origin-center {
    transform-origin: center
  }

  .sm\:origin-top {
    transform-origin: top
  }

  .sm\:origin-top-right {
    transform-origin: top right
  }

  .sm\:origin-right {
    transform-origin: right
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right
  }

  .sm\:origin-bottom {
    transform-origin: bottom
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left
  }

  .sm\:origin-left {
    transform-origin: left
  }

  .sm\:origin-top-left {
    transform-origin: top left
  }

  .sm\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:scale-x-0 {
    --transform-scale-x: 0
  }

  .sm\:scale-x-50 {
    --transform-scale-x: .5
  }

  .sm\:scale-x-75 {
    --transform-scale-x: .75
  }

  .sm\:scale-x-90 {
    --transform-scale-x: .9
  }

  .sm\:scale-x-95 {
    --transform-scale-x: .95
  }

  .sm\:scale-x-100 {
    --transform-scale-x: 1
  }

  .sm\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .sm\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .sm\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .sm\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .sm\:scale-y-0 {
    --transform-scale-y: 0
  }

  .sm\:scale-y-50 {
    --transform-scale-y: .5
  }

  .sm\:scale-y-75 {
    --transform-scale-y: .75
  }

  .sm\:scale-y-90 {
    --transform-scale-y: .9
  }

  .sm\:scale-y-95 {
    --transform-scale-y: .95
  }

  .sm\:scale-y-100 {
    --transform-scale-y: 1
  }

  .sm\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .sm\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .sm\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .sm\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .sm\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .sm\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .sm\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .sm\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .sm\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .sm\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .sm\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .sm\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .sm\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .sm\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .sm\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .sm\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .sm\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .sm\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .sm\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .sm\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .sm\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .sm\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .sm\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .sm\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .sm\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .sm\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .sm\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .sm\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .sm\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .sm\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .sm\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .sm\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .sm\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .sm\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .sm\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .sm\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .sm\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .sm\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .sm\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .sm\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .sm\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .sm\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .sm\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .sm\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .sm\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .sm\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .sm\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .sm\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .sm\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .sm\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .sm\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .sm\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .sm\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .sm\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .sm\:rotate-0 {
    --transform-rotate: 0
  }

  .sm\:rotate-1 {
    --transform-rotate: 1deg
  }

  .sm\:rotate-2 {
    --transform-rotate: 2deg
  }

  .sm\:rotate-3 {
    --transform-rotate: 3deg
  }

  .sm\:rotate-6 {
    --transform-rotate: 6deg
  }

  .sm\:rotate-12 {
    --transform-rotate: 12deg
  }

  .sm\:rotate-45 {
    --transform-rotate: 45deg
  }

  .sm\:rotate-90 {
    --transform-rotate: 90deg
  }

  .sm\:rotate-180 {
    --transform-rotate: 180deg
  }

  .sm\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .sm\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .sm\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .sm\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .sm\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .sm\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .sm\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .sm\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .sm\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .sm\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .sm\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .sm\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .sm\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .sm\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .sm\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .sm\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .sm\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .sm\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .sm\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .sm\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .sm\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .sm\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .sm\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .sm\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .sm\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .sm\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .sm\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .sm\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .sm\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .sm\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .sm\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .sm\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .sm\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .sm\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .sm\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .sm\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .sm\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .sm\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .sm\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .sm\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .sm\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .sm\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .sm\:translate-x-0 {
    --transform-translate-x: 0
  }

  .sm\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .sm\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .sm\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .sm\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .sm\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .sm\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .sm\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .sm\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .sm\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .sm\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .sm\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .sm\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .sm\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .sm\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .sm\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .sm\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .sm\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .sm\:translate-x-px {
    --transform-translate-x: 1px
  }

  .sm\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .sm\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .sm\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .sm\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .sm\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .sm\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .sm\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .sm\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .sm\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .sm\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .sm\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .sm\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .sm\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .sm\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .sm\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .sm\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .sm\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .sm\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .sm\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .sm\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .sm\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .sm\:translate-x-full {
    --transform-translate-x: 100%
  }

  .sm\:translate-y-0 {
    --transform-translate-y: 0
  }

  .sm\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .sm\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .sm\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .sm\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .sm\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .sm\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .sm\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .sm\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .sm\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .sm\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .sm\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .sm\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .sm\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .sm\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .sm\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .sm\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .sm\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .sm\:translate-y-px {
    --transform-translate-y: 1px
  }

  .sm\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .sm\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .sm\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .sm\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .sm\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .sm\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .sm\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .sm\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .sm\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .sm\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .sm\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .sm\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .sm\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .sm\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .sm\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .sm\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .sm\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .sm\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .sm\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .sm\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .sm\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .sm\:translate-y-full {
    --transform-translate-y: 100%
  }

  .sm\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .sm\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .sm\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .sm\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .sm\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .sm\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .sm\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .sm\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .sm\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .sm\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .sm\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .sm\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .sm\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .sm\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .sm\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .sm\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .sm\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .sm\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .sm\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .sm\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .sm\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .sm\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .sm\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .sm\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .sm\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .sm\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .sm\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .sm\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .sm\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .sm\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .sm\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .sm\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .sm\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .sm\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .sm\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .sm\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .sm\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .sm\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .sm\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .sm\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .sm\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .sm\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .sm\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .sm\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .sm\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .sm\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .sm\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .sm\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .sm\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .sm\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .sm\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .sm\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .sm\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .sm\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .sm\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .sm\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .sm\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .sm\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .sm\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .sm\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .sm\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .sm\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .sm\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .sm\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .sm\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .sm\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .sm\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .sm\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .sm\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .sm\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .sm\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .sm\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .sm\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .sm\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .sm\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .sm\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .sm\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .sm\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .sm\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .sm\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .sm\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .sm\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .sm\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .sm\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .sm\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .sm\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .sm\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .sm\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .sm\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .sm\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .sm\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .sm\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .sm\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .sm\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .sm\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .sm\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .sm\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .sm\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .sm\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .sm\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .sm\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .sm\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .sm\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .sm\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .sm\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .sm\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .sm\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .sm\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .sm\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .sm\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .sm\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .sm\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .sm\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .sm\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .sm\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .sm\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .sm\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .sm\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .sm\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .sm\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .sm\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .sm\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .sm\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .sm\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .sm\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .sm\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .sm\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .sm\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .sm\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .sm\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .sm\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .sm\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .sm\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .sm\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .sm\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .sm\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .sm\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .sm\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .sm\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .sm\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .sm\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .sm\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .sm\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .sm\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .sm\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .sm\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .sm\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .sm\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .sm\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .sm\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .sm\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .sm\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .sm\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .sm\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .sm\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .sm\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .sm\:skew-x-0 {
    --transform-skew-x: 0
  }

  .sm\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .sm\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .sm\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .sm\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .sm\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .sm\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .sm\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .sm\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .sm\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .sm\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .sm\:skew-y-0 {
    --transform-skew-y: 0
  }

  .sm\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .sm\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .sm\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .sm\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .sm\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .sm\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .sm\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .sm\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .sm\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .sm\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .sm\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .sm\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .sm\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .sm\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .sm\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .sm\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .sm\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .sm\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .sm\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .sm\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .sm\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .sm\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .sm\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .sm\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .sm\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .sm\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .sm\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .sm\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .sm\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .sm\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .sm\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .sm\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .sm\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .sm\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .sm\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .sm\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .sm\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .sm\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .sm\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .sm\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .sm\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .sm\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .sm\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .sm\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .sm\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .sm\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .sm\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .sm\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .sm\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .sm\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .sm\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .sm\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .sm\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .sm\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .sm\:transition-none {
    transition-property: none
  }

  .sm\:transition-all {
    transition-property: all
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .sm\:transition-opacity {
    transition-property: opacity
  }

  .sm\:transition-shadow {
    transition-property: box-shadow
  }

  .sm\:transition-transform {
    transition-property: transform
  }

  .sm\:ease-linear {
    transition-timing-function: linear
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .sm\:duration-75 {
    transition-duration: 75ms
  }

  .sm\:duration-100 {
    transition-duration: 100ms
  }

  .sm\:duration-150 {
    transition-duration: 150ms
  }

  .sm\:duration-200 {
    transition-duration: 200ms
  }

  .sm\:duration-300 {
    transition-duration: 300ms
  }

  .sm\:duration-500 {
    transition-duration: 500ms
  }

  .sm\:duration-700 {
    transition-duration: 700ms
  }

  .sm\:duration-1000 {
    transition-duration: 1000ms
  }

  .sm\:delay-75 {
    transition-delay: 75ms
  }

  .sm\:delay-100 {
    transition-delay: 100ms
  }

  .sm\:delay-150 {
    transition-delay: 150ms
  }

  .sm\:delay-200 {
    transition-delay: 200ms
  }

  .sm\:delay-300 {
    transition-delay: 300ms
  }

  .sm\:delay-500 {
    transition-delay: 500ms
  }

  .sm\:delay-700 {
    transition-delay: 700ms
  }

  .sm\:delay-1000 {
    transition-delay: 1000ms
  }

  .sm\:animate-none {
    -webkit-animation: none;
            animation: none
  }

  .sm\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .sm\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .sm\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .sm\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .md\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary)
  }

  .md\:divide-primary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-shade)
  }

  .md\:divide-primary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-tint)
  }

  .md\:divide-secondary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary)
  }

  .md\:divide-secondary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-shade)
  }

  .md\:divide-secondary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-tint)
  }

  .md\:divide-tertiary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary)
  }

  .md\:divide-tertiary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-shade)
  }

  .md\:divide-tertiary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-tint)
  }

  .md\:divide-light > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light)
  }

  .md\:divide-light-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-shade)
  }

  .md\:divide-light-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-tint)
  }

  .md\:divide-medium > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium)
  }

  .md\:divide-medium-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-shade)
  }

  .md\:divide-medium-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-tint)
  }

  .md\:divide-dark > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark)
  }

  .md\:divide-dark-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-shade)
  }

  .md\:divide-dark-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-tint)
  }

  .md\:divide-success > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success)
  }

  .md\:divide-success-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-shade)
  }

  .md\:divide-success-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-tint)
  }

  .md\:divide-warning > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning)
  }

  .md\:divide-warning-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-shade)
  }

  .md\:divide-warning-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-tint)
  }

  .md\:divide-danger > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger)
  }

  .md\:divide-danger-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-shade)
  }

  .md\:divide-danger-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-tint)
  }

  .md\:divide-step-50 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-50)
  }

  .md\:divide-step-100 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-100)
  }

  .md\:divide-step-150 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-150)
  }

  .md\:divide-step-200 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-200)
  }

  .md\:divide-step-250 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-250)
  }

  .md\:divide-step-300 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-300)
  }

  .md\:divide-step-350 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-350)
  }

  .md\:divide-step-400 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-400)
  }

  .md\:divide-step-450 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-450)
  }

  .md\:divide-step-500 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-500)
  }

  .md\:divide-step-550 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-550)
  }

  .md\:divide-step-600 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-600)
  }

  .md\:divide-step-650 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-650)
  }

  .md\:divide-step-700 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-700)
  }

  .md\:divide-step-750 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-750)
  }

  .md\:divide-step-800 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-800)
  }

  .md\:divide-step-850 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-850)
  }

  .md\:divide-step-900 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-900)
  }

  .md\:divide-step-950 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-950)
  }

  .md\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .md\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .md\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .md\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .md\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .md\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .md\:bg-fixed {
    background-attachment: fixed
  }

  .md\:bg-local {
    background-attachment: local
  }

  .md\:bg-scroll {
    background-attachment: scroll
  }

  .md\:bg-clip-border {
    background-clip: border-box
  }

  .md\:bg-clip-padding {
    background-clip: padding-box
  }

  .md\:bg-clip-content {
    background-clip: content-box
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .md\:bg-primary {
    background-color: var(--ion-color-primary)
  }

  .md\:bg-primary-shade {
    background-color: var(--ion-color-primary-shade)
  }

  .md\:bg-primary-tint {
    background-color: var(--ion-color-primary-tint)
  }

  .md\:bg-secondary {
    background-color: var(--ion-color-secondary)
  }

  .md\:bg-secondary-shade {
    background-color: var(--ion-color-secondary-shade)
  }

  .md\:bg-secondary-tint {
    background-color: var(--ion-color-secondary-tint)
  }

  .md\:bg-tertiary {
    background-color: var(--ion-color-tertiary)
  }

  .md\:bg-tertiary-shade {
    background-color: var(--ion-color-tertiary-shade)
  }

  .md\:bg-tertiary-tint {
    background-color: var(--ion-color-tertiary-tint)
  }

  .md\:bg-light {
    background-color: var(--ion-color-light)
  }

  .md\:bg-light-shade {
    background-color: var(--ion-color-light-shade)
  }

  .md\:bg-light-tint {
    background-color: var(--ion-color-light-tint)
  }

  .md\:bg-medium {
    background-color: var(--ion-color-medium)
  }

  .md\:bg-medium-shade {
    background-color: var(--ion-color-medium-shade)
  }

  .md\:bg-medium-tint {
    background-color: var(--ion-color-medium-tint)
  }

  .md\:bg-dark {
    background-color: var(--ion-color-dark)
  }

  .md\:bg-dark-shade {
    background-color: var(--ion-color-dark-shade)
  }

  .md\:bg-dark-tint {
    background-color: var(--ion-color-dark-tint)
  }

  .md\:bg-success {
    background-color: var(--ion-color-success)
  }

  .md\:bg-success-shade {
    background-color: var(--ion-color-success-shade)
  }

  .md\:bg-success-tint {
    background-color: var(--ion-color-success-tint)
  }

  .md\:bg-warning {
    background-color: var(--ion-color-warning)
  }

  .md\:bg-warning-shade {
    background-color: var(--ion-color-warning-shade)
  }

  .md\:bg-warning-tint {
    background-color: var(--ion-color-warning-tint)
  }

  .md\:bg-danger {
    background-color: var(--ion-color-danger)
  }

  .md\:bg-danger-shade {
    background-color: var(--ion-color-danger-shade)
  }

  .md\:bg-danger-tint {
    background-color: var(--ion-color-danger-tint)
  }

  .md\:bg-step-50 {
    background-color: var(--ion-color-step-50)
  }

  .md\:bg-step-100 {
    background-color: var(--ion-color-step-100)
  }

  .md\:bg-step-150 {
    background-color: var(--ion-color-step-150)
  }

  .md\:bg-step-200 {
    background-color: var(--ion-color-step-200)
  }

  .md\:bg-step-250 {
    background-color: var(--ion-color-step-250)
  }

  .md\:bg-step-300 {
    background-color: var(--ion-color-step-300)
  }

  .md\:bg-step-350 {
    background-color: var(--ion-color-step-350)
  }

  .md\:bg-step-400 {
    background-color: var(--ion-color-step-400)
  }

  .md\:bg-step-450 {
    background-color: var(--ion-color-step-450)
  }

  .md\:bg-step-500 {
    background-color: var(--ion-color-step-500)
  }

  .md\:bg-step-550 {
    background-color: var(--ion-color-step-550)
  }

  .md\:bg-step-600 {
    background-color: var(--ion-color-step-600)
  }

  .md\:bg-step-650 {
    background-color: var(--ion-color-step-650)
  }

  .md\:bg-step-700 {
    background-color: var(--ion-color-step-700)
  }

  .md\:bg-step-750 {
    background-color: var(--ion-color-step-750)
  }

  .md\:bg-step-800 {
    background-color: var(--ion-color-step-800)
  }

  .md\:bg-step-850 {
    background-color: var(--ion-color-step-850)
  }

  .md\:bg-step-900 {
    background-color: var(--ion-color-step-900)
  }

  .md\:bg-step-950 {
    background-color: var(--ion-color-step-950)
  }

  .md\:hover\:bg-primary:hover {
    background-color: var(--ion-color-primary)
  }

  .md\:hover\:bg-primary-shade:hover {
    background-color: var(--ion-color-primary-shade)
  }

  .md\:hover\:bg-primary-tint:hover {
    background-color: var(--ion-color-primary-tint)
  }

  .md\:hover\:bg-secondary:hover {
    background-color: var(--ion-color-secondary)
  }

  .md\:hover\:bg-secondary-shade:hover {
    background-color: var(--ion-color-secondary-shade)
  }

  .md\:hover\:bg-secondary-tint:hover {
    background-color: var(--ion-color-secondary-tint)
  }

  .md\:hover\:bg-tertiary:hover {
    background-color: var(--ion-color-tertiary)
  }

  .md\:hover\:bg-tertiary-shade:hover {
    background-color: var(--ion-color-tertiary-shade)
  }

  .md\:hover\:bg-tertiary-tint:hover {
    background-color: var(--ion-color-tertiary-tint)
  }

  .md\:hover\:bg-light:hover {
    background-color: var(--ion-color-light)
  }

  .md\:hover\:bg-light-shade:hover {
    background-color: var(--ion-color-light-shade)
  }

  .md\:hover\:bg-light-tint:hover {
    background-color: var(--ion-color-light-tint)
  }

  .md\:hover\:bg-medium:hover {
    background-color: var(--ion-color-medium)
  }

  .md\:hover\:bg-medium-shade:hover {
    background-color: var(--ion-color-medium-shade)
  }

  .md\:hover\:bg-medium-tint:hover {
    background-color: var(--ion-color-medium-tint)
  }

  .md\:hover\:bg-dark:hover {
    background-color: var(--ion-color-dark)
  }

  .md\:hover\:bg-dark-shade:hover {
    background-color: var(--ion-color-dark-shade)
  }

  .md\:hover\:bg-dark-tint:hover {
    background-color: var(--ion-color-dark-tint)
  }

  .md\:hover\:bg-success:hover {
    background-color: var(--ion-color-success)
  }

  .md\:hover\:bg-success-shade:hover {
    background-color: var(--ion-color-success-shade)
  }

  .md\:hover\:bg-success-tint:hover {
    background-color: var(--ion-color-success-tint)
  }

  .md\:hover\:bg-warning:hover {
    background-color: var(--ion-color-warning)
  }

  .md\:hover\:bg-warning-shade:hover {
    background-color: var(--ion-color-warning-shade)
  }

  .md\:hover\:bg-warning-tint:hover {
    background-color: var(--ion-color-warning-tint)
  }

  .md\:hover\:bg-danger:hover {
    background-color: var(--ion-color-danger)
  }

  .md\:hover\:bg-danger-shade:hover {
    background-color: var(--ion-color-danger-shade)
  }

  .md\:hover\:bg-danger-tint:hover {
    background-color: var(--ion-color-danger-tint)
  }

  .md\:hover\:bg-step-50:hover {
    background-color: var(--ion-color-step-50)
  }

  .md\:hover\:bg-step-100:hover {
    background-color: var(--ion-color-step-100)
  }

  .md\:hover\:bg-step-150:hover {
    background-color: var(--ion-color-step-150)
  }

  .md\:hover\:bg-step-200:hover {
    background-color: var(--ion-color-step-200)
  }

  .md\:hover\:bg-step-250:hover {
    background-color: var(--ion-color-step-250)
  }

  .md\:hover\:bg-step-300:hover {
    background-color: var(--ion-color-step-300)
  }

  .md\:hover\:bg-step-350:hover {
    background-color: var(--ion-color-step-350)
  }

  .md\:hover\:bg-step-400:hover {
    background-color: var(--ion-color-step-400)
  }

  .md\:hover\:bg-step-450:hover {
    background-color: var(--ion-color-step-450)
  }

  .md\:hover\:bg-step-500:hover {
    background-color: var(--ion-color-step-500)
  }

  .md\:hover\:bg-step-550:hover {
    background-color: var(--ion-color-step-550)
  }

  .md\:hover\:bg-step-600:hover {
    background-color: var(--ion-color-step-600)
  }

  .md\:hover\:bg-step-650:hover {
    background-color: var(--ion-color-step-650)
  }

  .md\:hover\:bg-step-700:hover {
    background-color: var(--ion-color-step-700)
  }

  .md\:hover\:bg-step-750:hover {
    background-color: var(--ion-color-step-750)
  }

  .md\:hover\:bg-step-800:hover {
    background-color: var(--ion-color-step-800)
  }

  .md\:hover\:bg-step-850:hover {
    background-color: var(--ion-color-step-850)
  }

  .md\:hover\:bg-step-900:hover {
    background-color: var(--ion-color-step-900)
  }

  .md\:hover\:bg-step-950:hover {
    background-color: var(--ion-color-step-950)
  }

  .md\:focus\:bg-primary:focus {
    background-color: var(--ion-color-primary)
  }

  .md\:focus\:bg-primary-shade:focus {
    background-color: var(--ion-color-primary-shade)
  }

  .md\:focus\:bg-primary-tint:focus {
    background-color: var(--ion-color-primary-tint)
  }

  .md\:focus\:bg-secondary:focus {
    background-color: var(--ion-color-secondary)
  }

  .md\:focus\:bg-secondary-shade:focus {
    background-color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:bg-secondary-tint:focus {
    background-color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:bg-tertiary:focus {
    background-color: var(--ion-color-tertiary)
  }

  .md\:focus\:bg-tertiary-shade:focus {
    background-color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:bg-tertiary-tint:focus {
    background-color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:bg-light:focus {
    background-color: var(--ion-color-light)
  }

  .md\:focus\:bg-light-shade:focus {
    background-color: var(--ion-color-light-shade)
  }

  .md\:focus\:bg-light-tint:focus {
    background-color: var(--ion-color-light-tint)
  }

  .md\:focus\:bg-medium:focus {
    background-color: var(--ion-color-medium)
  }

  .md\:focus\:bg-medium-shade:focus {
    background-color: var(--ion-color-medium-shade)
  }

  .md\:focus\:bg-medium-tint:focus {
    background-color: var(--ion-color-medium-tint)
  }

  .md\:focus\:bg-dark:focus {
    background-color: var(--ion-color-dark)
  }

  .md\:focus\:bg-dark-shade:focus {
    background-color: var(--ion-color-dark-shade)
  }

  .md\:focus\:bg-dark-tint:focus {
    background-color: var(--ion-color-dark-tint)
  }

  .md\:focus\:bg-success:focus {
    background-color: var(--ion-color-success)
  }

  .md\:focus\:bg-success-shade:focus {
    background-color: var(--ion-color-success-shade)
  }

  .md\:focus\:bg-success-tint:focus {
    background-color: var(--ion-color-success-tint)
  }

  .md\:focus\:bg-warning:focus {
    background-color: var(--ion-color-warning)
  }

  .md\:focus\:bg-warning-shade:focus {
    background-color: var(--ion-color-warning-shade)
  }

  .md\:focus\:bg-warning-tint:focus {
    background-color: var(--ion-color-warning-tint)
  }

  .md\:focus\:bg-danger:focus {
    background-color: var(--ion-color-danger)
  }

  .md\:focus\:bg-danger-shade:focus {
    background-color: var(--ion-color-danger-shade)
  }

  .md\:focus\:bg-danger-tint:focus {
    background-color: var(--ion-color-danger-tint)
  }

  .md\:focus\:bg-step-50:focus {
    background-color: var(--ion-color-step-50)
  }

  .md\:focus\:bg-step-100:focus {
    background-color: var(--ion-color-step-100)
  }

  .md\:focus\:bg-step-150:focus {
    background-color: var(--ion-color-step-150)
  }

  .md\:focus\:bg-step-200:focus {
    background-color: var(--ion-color-step-200)
  }

  .md\:focus\:bg-step-250:focus {
    background-color: var(--ion-color-step-250)
  }

  .md\:focus\:bg-step-300:focus {
    background-color: var(--ion-color-step-300)
  }

  .md\:focus\:bg-step-350:focus {
    background-color: var(--ion-color-step-350)
  }

  .md\:focus\:bg-step-400:focus {
    background-color: var(--ion-color-step-400)
  }

  .md\:focus\:bg-step-450:focus {
    background-color: var(--ion-color-step-450)
  }

  .md\:focus\:bg-step-500:focus {
    background-color: var(--ion-color-step-500)
  }

  .md\:focus\:bg-step-550:focus {
    background-color: var(--ion-color-step-550)
  }

  .md\:focus\:bg-step-600:focus {
    background-color: var(--ion-color-step-600)
  }

  .md\:focus\:bg-step-650:focus {
    background-color: var(--ion-color-step-650)
  }

  .md\:focus\:bg-step-700:focus {
    background-color: var(--ion-color-step-700)
  }

  .md\:focus\:bg-step-750:focus {
    background-color: var(--ion-color-step-750)
  }

  .md\:focus\:bg-step-800:focus {
    background-color: var(--ion-color-step-800)
  }

  .md\:focus\:bg-step-850:focus {
    background-color: var(--ion-color-step-850)
  }

  .md\:focus\:bg-step-900:focus {
    background-color: var(--ion-color-step-900)
  }

  .md\:focus\:bg-step-950:focus {
    background-color: var(--ion-color-step-950)
  }

  .md\:bg-none {
    background-image: none
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .md\:from-primary {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-primary-shade {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-primary-tint {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-secondary {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-secondary-shade {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-secondary-tint {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-tertiary {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-tertiary-shade {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-tertiary-tint {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-light {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-light-shade {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-light-tint {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-medium {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-medium-shade {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-medium-tint {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-dark {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-dark-shade {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-dark-tint {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-success {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-success-shade {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-success-tint {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-warning {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-warning-shade {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-warning-tint {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-danger {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-danger-shade {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-danger-tint {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-50 {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-100 {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-150 {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-200 {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-250 {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-300 {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-350 {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-400 {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-450 {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-500 {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-550 {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-600 {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-650 {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-700 {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-750 {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-800 {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-850 {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-900 {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:from-step-950 {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-primary {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-primary-shade {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-primary-tint {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-secondary {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-secondary-shade {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-secondary-tint {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-tertiary {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-tertiary-shade {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-tertiary-tint {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-light {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-light-shade {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-light-tint {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-medium {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-medium-shade {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-medium-tint {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-dark {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-dark-shade {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-dark-tint {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-success {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-success-shade {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-success-tint {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-warning {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-warning-shade {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-warning-tint {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-danger {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-danger-shade {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-danger-tint {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-50 {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-100 {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-150 {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-200 {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-250 {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-300 {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-350 {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-400 {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-450 {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-500 {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-550 {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-600 {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-650 {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-700 {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-750 {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-800 {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-850 {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-900 {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:via-step-950 {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:to-primary {
    --gradient-to-color: var(--ion-color-primary)
  }

  .md\:to-primary-shade {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .md\:to-primary-tint {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .md\:to-secondary {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .md\:to-secondary-shade {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .md\:to-secondary-tint {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .md\:to-tertiary {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .md\:to-tertiary-shade {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .md\:to-tertiary-tint {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .md\:to-light {
    --gradient-to-color: var(--ion-color-light)
  }

  .md\:to-light-shade {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .md\:to-light-tint {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .md\:to-medium {
    --gradient-to-color: var(--ion-color-medium)
  }

  .md\:to-medium-shade {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .md\:to-medium-tint {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .md\:to-dark {
    --gradient-to-color: var(--ion-color-dark)
  }

  .md\:to-dark-shade {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .md\:to-dark-tint {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .md\:to-success {
    --gradient-to-color: var(--ion-color-success)
  }

  .md\:to-success-shade {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .md\:to-success-tint {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .md\:to-warning {
    --gradient-to-color: var(--ion-color-warning)
  }

  .md\:to-warning-shade {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .md\:to-warning-tint {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .md\:to-danger {
    --gradient-to-color: var(--ion-color-danger)
  }

  .md\:to-danger-shade {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .md\:to-danger-tint {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .md\:to-step-50 {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .md\:to-step-100 {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .md\:to-step-150 {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .md\:to-step-200 {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .md\:to-step-250 {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .md\:to-step-300 {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .md\:to-step-350 {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .md\:to-step-400 {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .md\:to-step-450 {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .md\:to-step-500 {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .md\:to-step-550 {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .md\:to-step-600 {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .md\:to-step-650 {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .md\:to-step-700 {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .md\:to-step-750 {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .md\:to-step-800 {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .md\:to-step-850 {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .md\:to-step-900 {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .md\:to-step-950 {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .md\:hover\:from-primary:hover {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-primary-shade:hover {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-primary-tint:hover {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-secondary:hover {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-secondary-shade:hover {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-secondary-tint:hover {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-tertiary:hover {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-tertiary-shade:hover {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-tertiary-tint:hover {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-light:hover {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-light-shade:hover {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-light-tint:hover {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-medium:hover {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-medium-shade:hover {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-medium-tint:hover {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-dark:hover {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-dark-shade:hover {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-dark-tint:hover {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-success:hover {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-success-shade:hover {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-success-tint:hover {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-warning:hover {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-warning-shade:hover {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-warning-tint:hover {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-danger:hover {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-danger-shade:hover {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-danger-tint:hover {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-50:hover {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-100:hover {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-150:hover {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-200:hover {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-250:hover {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-300:hover {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-350:hover {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-400:hover {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-450:hover {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-500:hover {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-550:hover {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-600:hover {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-650:hover {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-700:hover {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-750:hover {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-800:hover {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-850:hover {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-900:hover {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:from-step-950:hover {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-primary:hover {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-primary-shade:hover {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-primary-tint:hover {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-secondary:hover {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-secondary-shade:hover {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-secondary-tint:hover {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-tertiary:hover {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-tertiary-shade:hover {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-tertiary-tint:hover {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-light:hover {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-light-shade:hover {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-light-tint:hover {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-medium:hover {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-medium-shade:hover {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-medium-tint:hover {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-dark:hover {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-dark-shade:hover {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-dark-tint:hover {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-success:hover {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-success-shade:hover {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-success-tint:hover {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-warning:hover {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-warning-shade:hover {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-warning-tint:hover {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-danger:hover {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-danger-shade:hover {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-danger-tint:hover {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-50:hover {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-100:hover {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-150:hover {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-200:hover {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-250:hover {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-300:hover {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-350:hover {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-400:hover {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-450:hover {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-500:hover {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-550:hover {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-600:hover {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-650:hover {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-700:hover {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-750:hover {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-800:hover {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-850:hover {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-900:hover {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:via-step-950:hover {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:hover\:to-primary:hover {
    --gradient-to-color: var(--ion-color-primary)
  }

  .md\:hover\:to-primary-shade:hover {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .md\:hover\:to-primary-tint:hover {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .md\:hover\:to-secondary:hover {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .md\:hover\:to-secondary-shade:hover {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .md\:hover\:to-secondary-tint:hover {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .md\:hover\:to-tertiary:hover {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .md\:hover\:to-tertiary-shade:hover {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .md\:hover\:to-tertiary-tint:hover {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .md\:hover\:to-light:hover {
    --gradient-to-color: var(--ion-color-light)
  }

  .md\:hover\:to-light-shade:hover {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .md\:hover\:to-light-tint:hover {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .md\:hover\:to-medium:hover {
    --gradient-to-color: var(--ion-color-medium)
  }

  .md\:hover\:to-medium-shade:hover {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .md\:hover\:to-medium-tint:hover {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .md\:hover\:to-dark:hover {
    --gradient-to-color: var(--ion-color-dark)
  }

  .md\:hover\:to-dark-shade:hover {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .md\:hover\:to-dark-tint:hover {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .md\:hover\:to-success:hover {
    --gradient-to-color: var(--ion-color-success)
  }

  .md\:hover\:to-success-shade:hover {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .md\:hover\:to-success-tint:hover {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .md\:hover\:to-warning:hover {
    --gradient-to-color: var(--ion-color-warning)
  }

  .md\:hover\:to-warning-shade:hover {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .md\:hover\:to-warning-tint:hover {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .md\:hover\:to-danger:hover {
    --gradient-to-color: var(--ion-color-danger)
  }

  .md\:hover\:to-danger-shade:hover {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .md\:hover\:to-danger-tint:hover {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .md\:hover\:to-step-50:hover {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .md\:hover\:to-step-100:hover {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .md\:hover\:to-step-150:hover {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .md\:hover\:to-step-200:hover {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .md\:hover\:to-step-250:hover {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .md\:hover\:to-step-300:hover {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .md\:hover\:to-step-350:hover {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .md\:hover\:to-step-400:hover {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .md\:hover\:to-step-450:hover {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .md\:hover\:to-step-500:hover {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .md\:hover\:to-step-550:hover {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .md\:hover\:to-step-600:hover {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .md\:hover\:to-step-650:hover {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .md\:hover\:to-step-700:hover {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .md\:hover\:to-step-750:hover {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .md\:hover\:to-step-800:hover {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .md\:hover\:to-step-850:hover {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .md\:hover\:to-step-900:hover {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .md\:hover\:to-step-950:hover {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .md\:focus\:from-primary:focus {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-primary-shade:focus {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-primary-tint:focus {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-secondary:focus {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-secondary-shade:focus {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-secondary-tint:focus {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-tertiary:focus {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-tertiary-shade:focus {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-tertiary-tint:focus {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-light:focus {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-light-shade:focus {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-light-tint:focus {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-medium:focus {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-medium-shade:focus {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-medium-tint:focus {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-dark:focus {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-dark-shade:focus {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-dark-tint:focus {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-success:focus {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-success-shade:focus {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-success-tint:focus {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-warning:focus {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-warning-shade:focus {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-warning-tint:focus {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-danger:focus {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-danger-shade:focus {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-danger-tint:focus {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-50:focus {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-100:focus {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-150:focus {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-200:focus {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-250:focus {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-300:focus {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-350:focus {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-400:focus {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-450:focus {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-500:focus {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-550:focus {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-600:focus {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-650:focus {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-700:focus {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-750:focus {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-800:focus {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-850:focus {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-900:focus {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:from-step-950:focus {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-primary:focus {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-primary-shade:focus {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-primary-tint:focus {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-secondary:focus {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-secondary-shade:focus {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-secondary-tint:focus {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-tertiary:focus {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-tertiary-shade:focus {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-tertiary-tint:focus {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-light:focus {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-light-shade:focus {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-light-tint:focus {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-medium:focus {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-medium-shade:focus {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-medium-tint:focus {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-dark:focus {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-dark-shade:focus {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-dark-tint:focus {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-success:focus {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-success-shade:focus {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-success-tint:focus {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-warning:focus {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-warning-shade:focus {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-warning-tint:focus {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-danger:focus {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-danger-shade:focus {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-danger-tint:focus {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-50:focus {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-100:focus {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-150:focus {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-200:focus {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-250:focus {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-300:focus {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-350:focus {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-400:focus {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-450:focus {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-500:focus {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-550:focus {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-600:focus {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-650:focus {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-700:focus {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-750:focus {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-800:focus {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-850:focus {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-900:focus {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:via-step-950:focus {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .md\:focus\:to-primary:focus {
    --gradient-to-color: var(--ion-color-primary)
  }

  .md\:focus\:to-primary-shade:focus {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .md\:focus\:to-primary-tint:focus {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .md\:focus\:to-secondary:focus {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .md\:focus\:to-secondary-shade:focus {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:to-secondary-tint:focus {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:to-tertiary:focus {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .md\:focus\:to-tertiary-shade:focus {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:to-tertiary-tint:focus {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:to-light:focus {
    --gradient-to-color: var(--ion-color-light)
  }

  .md\:focus\:to-light-shade:focus {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .md\:focus\:to-light-tint:focus {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .md\:focus\:to-medium:focus {
    --gradient-to-color: var(--ion-color-medium)
  }

  .md\:focus\:to-medium-shade:focus {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .md\:focus\:to-medium-tint:focus {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .md\:focus\:to-dark:focus {
    --gradient-to-color: var(--ion-color-dark)
  }

  .md\:focus\:to-dark-shade:focus {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .md\:focus\:to-dark-tint:focus {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .md\:focus\:to-success:focus {
    --gradient-to-color: var(--ion-color-success)
  }

  .md\:focus\:to-success-shade:focus {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .md\:focus\:to-success-tint:focus {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .md\:focus\:to-warning:focus {
    --gradient-to-color: var(--ion-color-warning)
  }

  .md\:focus\:to-warning-shade:focus {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .md\:focus\:to-warning-tint:focus {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .md\:focus\:to-danger:focus {
    --gradient-to-color: var(--ion-color-danger)
  }

  .md\:focus\:to-danger-shade:focus {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .md\:focus\:to-danger-tint:focus {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .md\:focus\:to-step-50:focus {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .md\:focus\:to-step-100:focus {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .md\:focus\:to-step-150:focus {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .md\:focus\:to-step-200:focus {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .md\:focus\:to-step-250:focus {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .md\:focus\:to-step-300:focus {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .md\:focus\:to-step-350:focus {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .md\:focus\:to-step-400:focus {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .md\:focus\:to-step-450:focus {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .md\:focus\:to-step-500:focus {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .md\:focus\:to-step-550:focus {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .md\:focus\:to-step-600:focus {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .md\:focus\:to-step-650:focus {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .md\:focus\:to-step-700:focus {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .md\:focus\:to-step-750:focus {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .md\:focus\:to-step-800:focus {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .md\:focus\:to-step-850:focus {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .md\:focus\:to-step-900:focus {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .md\:focus\:to-step-950:focus {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .md\:bg-bottom {
    background-position: bottom
  }

  .md\:bg-center {
    background-position: center
  }

  .md\:bg-left {
    background-position: left
  }

  .md\:bg-left-bottom {
    background-position: left bottom
  }

  .md\:bg-left-top {
    background-position: left top
  }

  .md\:bg-right {
    background-position: right
  }

  .md\:bg-right-bottom {
    background-position: right bottom
  }

  .md\:bg-right-top {
    background-position: right top
  }

  .md\:bg-top {
    background-position: top
  }

  .md\:bg-repeat {
    background-repeat: repeat
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .md\:bg-repeat-round {
    background-repeat: round
  }

  .md\:bg-repeat-space {
    background-repeat: space
  }

  .md\:bg-auto {
    background-size: auto
  }

  .md\:bg-cover {
    background-size: cover
  }

  .md\:bg-contain {
    background-size: contain
  }

  .md\:border-collapse {
    border-collapse: collapse
  }

  .md\:border-separate {
    border-collapse: separate
  }

  .md\:border-primary {
    border-color: var(--ion-color-primary)
  }

  .md\:border-primary-shade {
    border-color: var(--ion-color-primary-shade)
  }

  .md\:border-primary-tint {
    border-color: var(--ion-color-primary-tint)
  }

  .md\:border-secondary {
    border-color: var(--ion-color-secondary)
  }

  .md\:border-secondary-shade {
    border-color: var(--ion-color-secondary-shade)
  }

  .md\:border-secondary-tint {
    border-color: var(--ion-color-secondary-tint)
  }

  .md\:border-tertiary {
    border-color: var(--ion-color-tertiary)
  }

  .md\:border-tertiary-shade {
    border-color: var(--ion-color-tertiary-shade)
  }

  .md\:border-tertiary-tint {
    border-color: var(--ion-color-tertiary-tint)
  }

  .md\:border-light {
    border-color: var(--ion-color-light)
  }

  .md\:border-light-shade {
    border-color: var(--ion-color-light-shade)
  }

  .md\:border-light-tint {
    border-color: var(--ion-color-light-tint)
  }

  .md\:border-medium {
    border-color: var(--ion-color-medium)
  }

  .md\:border-medium-shade {
    border-color: var(--ion-color-medium-shade)
  }

  .md\:border-medium-tint {
    border-color: var(--ion-color-medium-tint)
  }

  .md\:border-dark {
    border-color: var(--ion-color-dark)
  }

  .md\:border-dark-shade {
    border-color: var(--ion-color-dark-shade)
  }

  .md\:border-dark-tint {
    border-color: var(--ion-color-dark-tint)
  }

  .md\:border-success {
    border-color: var(--ion-color-success)
  }

  .md\:border-success-shade {
    border-color: var(--ion-color-success-shade)
  }

  .md\:border-success-tint {
    border-color: var(--ion-color-success-tint)
  }

  .md\:border-warning {
    border-color: var(--ion-color-warning)
  }

  .md\:border-warning-shade {
    border-color: var(--ion-color-warning-shade)
  }

  .md\:border-warning-tint {
    border-color: var(--ion-color-warning-tint)
  }

  .md\:border-danger {
    border-color: var(--ion-color-danger)
  }

  .md\:border-danger-shade {
    border-color: var(--ion-color-danger-shade)
  }

  .md\:border-danger-tint {
    border-color: var(--ion-color-danger-tint)
  }

  .md\:border-step-50 {
    border-color: var(--ion-color-step-50)
  }

  .md\:border-step-100 {
    border-color: var(--ion-color-step-100)
  }

  .md\:border-step-150 {
    border-color: var(--ion-color-step-150)
  }

  .md\:border-step-200 {
    border-color: var(--ion-color-step-200)
  }

  .md\:border-step-250 {
    border-color: var(--ion-color-step-250)
  }

  .md\:border-step-300 {
    border-color: var(--ion-color-step-300)
  }

  .md\:border-step-350 {
    border-color: var(--ion-color-step-350)
  }

  .md\:border-step-400 {
    border-color: var(--ion-color-step-400)
  }

  .md\:border-step-450 {
    border-color: var(--ion-color-step-450)
  }

  .md\:border-step-500 {
    border-color: var(--ion-color-step-500)
  }

  .md\:border-step-550 {
    border-color: var(--ion-color-step-550)
  }

  .md\:border-step-600 {
    border-color: var(--ion-color-step-600)
  }

  .md\:border-step-650 {
    border-color: var(--ion-color-step-650)
  }

  .md\:border-step-700 {
    border-color: var(--ion-color-step-700)
  }

  .md\:border-step-750 {
    border-color: var(--ion-color-step-750)
  }

  .md\:border-step-800 {
    border-color: var(--ion-color-step-800)
  }

  .md\:border-step-850 {
    border-color: var(--ion-color-step-850)
  }

  .md\:border-step-900 {
    border-color: var(--ion-color-step-900)
  }

  .md\:border-step-950 {
    border-color: var(--ion-color-step-950)
  }

  .md\:hover\:border-primary:hover {
    border-color: var(--ion-color-primary)
  }

  .md\:hover\:border-primary-shade:hover {
    border-color: var(--ion-color-primary-shade)
  }

  .md\:hover\:border-primary-tint:hover {
    border-color: var(--ion-color-primary-tint)
  }

  .md\:hover\:border-secondary:hover {
    border-color: var(--ion-color-secondary)
  }

  .md\:hover\:border-secondary-shade:hover {
    border-color: var(--ion-color-secondary-shade)
  }

  .md\:hover\:border-secondary-tint:hover {
    border-color: var(--ion-color-secondary-tint)
  }

  .md\:hover\:border-tertiary:hover {
    border-color: var(--ion-color-tertiary)
  }

  .md\:hover\:border-tertiary-shade:hover {
    border-color: var(--ion-color-tertiary-shade)
  }

  .md\:hover\:border-tertiary-tint:hover {
    border-color: var(--ion-color-tertiary-tint)
  }

  .md\:hover\:border-light:hover {
    border-color: var(--ion-color-light)
  }

  .md\:hover\:border-light-shade:hover {
    border-color: var(--ion-color-light-shade)
  }

  .md\:hover\:border-light-tint:hover {
    border-color: var(--ion-color-light-tint)
  }

  .md\:hover\:border-medium:hover {
    border-color: var(--ion-color-medium)
  }

  .md\:hover\:border-medium-shade:hover {
    border-color: var(--ion-color-medium-shade)
  }

  .md\:hover\:border-medium-tint:hover {
    border-color: var(--ion-color-medium-tint)
  }

  .md\:hover\:border-dark:hover {
    border-color: var(--ion-color-dark)
  }

  .md\:hover\:border-dark-shade:hover {
    border-color: var(--ion-color-dark-shade)
  }

  .md\:hover\:border-dark-tint:hover {
    border-color: var(--ion-color-dark-tint)
  }

  .md\:hover\:border-success:hover {
    border-color: var(--ion-color-success)
  }

  .md\:hover\:border-success-shade:hover {
    border-color: var(--ion-color-success-shade)
  }

  .md\:hover\:border-success-tint:hover {
    border-color: var(--ion-color-success-tint)
  }

  .md\:hover\:border-warning:hover {
    border-color: var(--ion-color-warning)
  }

  .md\:hover\:border-warning-shade:hover {
    border-color: var(--ion-color-warning-shade)
  }

  .md\:hover\:border-warning-tint:hover {
    border-color: var(--ion-color-warning-tint)
  }

  .md\:hover\:border-danger:hover {
    border-color: var(--ion-color-danger)
  }

  .md\:hover\:border-danger-shade:hover {
    border-color: var(--ion-color-danger-shade)
  }

  .md\:hover\:border-danger-tint:hover {
    border-color: var(--ion-color-danger-tint)
  }

  .md\:hover\:border-step-50:hover {
    border-color: var(--ion-color-step-50)
  }

  .md\:hover\:border-step-100:hover {
    border-color: var(--ion-color-step-100)
  }

  .md\:hover\:border-step-150:hover {
    border-color: var(--ion-color-step-150)
  }

  .md\:hover\:border-step-200:hover {
    border-color: var(--ion-color-step-200)
  }

  .md\:hover\:border-step-250:hover {
    border-color: var(--ion-color-step-250)
  }

  .md\:hover\:border-step-300:hover {
    border-color: var(--ion-color-step-300)
  }

  .md\:hover\:border-step-350:hover {
    border-color: var(--ion-color-step-350)
  }

  .md\:hover\:border-step-400:hover {
    border-color: var(--ion-color-step-400)
  }

  .md\:hover\:border-step-450:hover {
    border-color: var(--ion-color-step-450)
  }

  .md\:hover\:border-step-500:hover {
    border-color: var(--ion-color-step-500)
  }

  .md\:hover\:border-step-550:hover {
    border-color: var(--ion-color-step-550)
  }

  .md\:hover\:border-step-600:hover {
    border-color: var(--ion-color-step-600)
  }

  .md\:hover\:border-step-650:hover {
    border-color: var(--ion-color-step-650)
  }

  .md\:hover\:border-step-700:hover {
    border-color: var(--ion-color-step-700)
  }

  .md\:hover\:border-step-750:hover {
    border-color: var(--ion-color-step-750)
  }

  .md\:hover\:border-step-800:hover {
    border-color: var(--ion-color-step-800)
  }

  .md\:hover\:border-step-850:hover {
    border-color: var(--ion-color-step-850)
  }

  .md\:hover\:border-step-900:hover {
    border-color: var(--ion-color-step-900)
  }

  .md\:hover\:border-step-950:hover {
    border-color: var(--ion-color-step-950)
  }

  .md\:focus\:border-primary:focus {
    border-color: var(--ion-color-primary)
  }

  .md\:focus\:border-primary-shade:focus {
    border-color: var(--ion-color-primary-shade)
  }

  .md\:focus\:border-primary-tint:focus {
    border-color: var(--ion-color-primary-tint)
  }

  .md\:focus\:border-secondary:focus {
    border-color: var(--ion-color-secondary)
  }

  .md\:focus\:border-secondary-shade:focus {
    border-color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:border-secondary-tint:focus {
    border-color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:border-tertiary:focus {
    border-color: var(--ion-color-tertiary)
  }

  .md\:focus\:border-tertiary-shade:focus {
    border-color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:border-tertiary-tint:focus {
    border-color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:border-light:focus {
    border-color: var(--ion-color-light)
  }

  .md\:focus\:border-light-shade:focus {
    border-color: var(--ion-color-light-shade)
  }

  .md\:focus\:border-light-tint:focus {
    border-color: var(--ion-color-light-tint)
  }

  .md\:focus\:border-medium:focus {
    border-color: var(--ion-color-medium)
  }

  .md\:focus\:border-medium-shade:focus {
    border-color: var(--ion-color-medium-shade)
  }

  .md\:focus\:border-medium-tint:focus {
    border-color: var(--ion-color-medium-tint)
  }

  .md\:focus\:border-dark:focus {
    border-color: var(--ion-color-dark)
  }

  .md\:focus\:border-dark-shade:focus {
    border-color: var(--ion-color-dark-shade)
  }

  .md\:focus\:border-dark-tint:focus {
    border-color: var(--ion-color-dark-tint)
  }

  .md\:focus\:border-success:focus {
    border-color: var(--ion-color-success)
  }

  .md\:focus\:border-success-shade:focus {
    border-color: var(--ion-color-success-shade)
  }

  .md\:focus\:border-success-tint:focus {
    border-color: var(--ion-color-success-tint)
  }

  .md\:focus\:border-warning:focus {
    border-color: var(--ion-color-warning)
  }

  .md\:focus\:border-warning-shade:focus {
    border-color: var(--ion-color-warning-shade)
  }

  .md\:focus\:border-warning-tint:focus {
    border-color: var(--ion-color-warning-tint)
  }

  .md\:focus\:border-danger:focus {
    border-color: var(--ion-color-danger)
  }

  .md\:focus\:border-danger-shade:focus {
    border-color: var(--ion-color-danger-shade)
  }

  .md\:focus\:border-danger-tint:focus {
    border-color: var(--ion-color-danger-tint)
  }

  .md\:focus\:border-step-50:focus {
    border-color: var(--ion-color-step-50)
  }

  .md\:focus\:border-step-100:focus {
    border-color: var(--ion-color-step-100)
  }

  .md\:focus\:border-step-150:focus {
    border-color: var(--ion-color-step-150)
  }

  .md\:focus\:border-step-200:focus {
    border-color: var(--ion-color-step-200)
  }

  .md\:focus\:border-step-250:focus {
    border-color: var(--ion-color-step-250)
  }

  .md\:focus\:border-step-300:focus {
    border-color: var(--ion-color-step-300)
  }

  .md\:focus\:border-step-350:focus {
    border-color: var(--ion-color-step-350)
  }

  .md\:focus\:border-step-400:focus {
    border-color: var(--ion-color-step-400)
  }

  .md\:focus\:border-step-450:focus {
    border-color: var(--ion-color-step-450)
  }

  .md\:focus\:border-step-500:focus {
    border-color: var(--ion-color-step-500)
  }

  .md\:focus\:border-step-550:focus {
    border-color: var(--ion-color-step-550)
  }

  .md\:focus\:border-step-600:focus {
    border-color: var(--ion-color-step-600)
  }

  .md\:focus\:border-step-650:focus {
    border-color: var(--ion-color-step-650)
  }

  .md\:focus\:border-step-700:focus {
    border-color: var(--ion-color-step-700)
  }

  .md\:focus\:border-step-750:focus {
    border-color: var(--ion-color-step-750)
  }

  .md\:focus\:border-step-800:focus {
    border-color: var(--ion-color-step-800)
  }

  .md\:focus\:border-step-850:focus {
    border-color: var(--ion-color-step-850)
  }

  .md\:focus\:border-step-900:focus {
    border-color: var(--ion-color-step-900)
  }

  .md\:focus\:border-step-950:focus {
    border-color: var(--ion-color-step-950)
  }

  .md\:border-opacity-0 {
    --border-opacity: 0
  }

  .md\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .md\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .md\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .md\:border-opacity-100 {
    --border-opacity: 1
  }

  .md\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .md\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .md\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .md\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .md\:rounded-none {
    border-radius: 0
  }

  .md\:rounded-sm {
    border-radius: 0.125rem
  }

  .md\:rounded {
    border-radius: 0.25rem
  }

  .md\:rounded-md {
    border-radius: 0.375rem
  }

  .md\:rounded-lg {
    border-radius: 0.5rem
  }

  .md\:rounded-xl {
    border-radius: 0.75rem
  }

  .md\:rounded-2xl {
    border-radius: 1rem
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem
  }

  .md\:rounded-full {
    border-radius: 9999px
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .md\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .md\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .md\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .md\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .md\:border-solid {
    border-style: solid
  }

  .md\:border-dashed {
    border-style: dashed
  }

  .md\:border-dotted {
    border-style: dotted
  }

  .md\:border-double {
    border-style: double
  }

  .md\:border-none {
    border-style: none
  }

  .md\:border-0 {
    border-width: 0
  }

  .md\:border-2 {
    border-width: 2px
  }

  .md\:border-4 {
    border-width: 4px
  }

  .md\:border-8 {
    border-width: 8px
  }

  .md\:border {
    border-width: 1px
  }

  .md\:border-t-0 {
    border-top-width: 0
  }

  .md\:border-r-0 {
    border-right-width: 0
  }

  .md\:border-b-0 {
    border-bottom-width: 0
  }

  .md\:border-l-0 {
    border-left-width: 0
  }

  .md\:border-t-2 {
    border-top-width: 2px
  }

  .md\:border-r-2 {
    border-right-width: 2px
  }

  .md\:border-b-2 {
    border-bottom-width: 2px
  }

  .md\:border-l-2 {
    border-left-width: 2px
  }

  .md\:border-t-4 {
    border-top-width: 4px
  }

  .md\:border-r-4 {
    border-right-width: 4px
  }

  .md\:border-b-4 {
    border-bottom-width: 4px
  }

  .md\:border-l-4 {
    border-left-width: 4px
  }

  .md\:border-t-8 {
    border-top-width: 8px
  }

  .md\:border-r-8 {
    border-right-width: 8px
  }

  .md\:border-b-8 {
    border-bottom-width: 8px
  }

  .md\:border-l-8 {
    border-left-width: 8px
  }

  .md\:border-t {
    border-top-width: 1px
  }

  .md\:border-r {
    border-right-width: 1px
  }

  .md\:border-b {
    border-bottom-width: 1px
  }

  .md\:border-l {
    border-left-width: 1px
  }

  .md\:box-border {
    box-sizing: border-box
  }

  .md\:box-content {
    box-sizing: content-box
  }

  .md\:cursor-auto {
    cursor: auto
  }

  .md\:cursor-default {
    cursor: default
  }

  .md\:cursor-pointer {
    cursor: pointer
  }

  .md\:cursor-wait {
    cursor: wait
  }

  .md\:cursor-text {
    cursor: text
  }

  .md\:cursor-move {
    cursor: move
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed
  }

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:table {
    display: table
  }

  .md\:table-caption {
    display: table-caption
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:table-column {
    display: table-column
  }

  .md\:table-column-group {
    display: table-column-group
  }

  .md\:table-footer-group {
    display: table-footer-group
  }

  .md\:table-header-group {
    display: table-header-group
  }

  .md\:table-row-group {
    display: table-row-group
  }

  .md\:table-row {
    display: table-row
  }

  .md\:flow-root {
    display: flow-root
  }

  .md\:grid {
    display: grid
  }

  .md\:inline-grid {
    display: inline-grid
  }

  .md\:contents {
    display: contents
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .md\:place-items-auto {
    place-items: auto
  }

  .md\:place-items-start {
    place-items: start
  }

  .md\:place-items-end {
    place-items: end
  }

  .md\:place-items-center {
    place-items: center
  }

  .md\:place-items-stretch {
    place-items: stretch
  }

  .md\:place-content-center {
    place-content: center
  }

  .md\:place-content-start {
    place-content: start
  }

  .md\:place-content-end {
    place-content: end
  }

  .md\:place-content-between {
    place-content: space-between
  }

  .md\:place-content-around {
    place-content: space-around
  }

  .md\:place-content-evenly {
    place-content: space-evenly
  }

  .md\:place-content-stretch {
    place-content: stretch
  }

  .md\:place-self-auto {
    place-self: auto
  }

  .md\:place-self-start {
    place-self: start
  }

  .md\:place-self-end {
    place-self: end
  }

  .md\:place-self-center {
    place-self: center
  }

  .md\:place-self-stretch {
    place-self: stretch
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:items-center {
    align-items: center
  }

  .md\:items-baseline {
    align-items: baseline
  }

  .md\:items-stretch {
    align-items: stretch
  }

  .md\:content-center {
    align-content: center
  }

  .md\:content-start {
    align-content: flex-start
  }

  .md\:content-end {
    align-content: flex-end
  }

  .md\:content-between {
    align-content: space-between
  }

  .md\:content-around {
    align-content: space-around
  }

  .md\:content-evenly {
    align-content: space-evenly
  }

  .md\:self-auto {
    align-self: auto
  }

  .md\:self-start {
    align-self: flex-start
  }

  .md\:self-end {
    align-self: flex-end
  }

  .md\:self-center {
    align-self: center
  }

  .md\:self-stretch {
    align-self: stretch
  }

  .md\:justify-items-auto {
    justify-items: auto
  }

  .md\:justify-items-start {
    justify-items: start
  }

  .md\:justify-items-end {
    justify-items: end
  }

  .md\:justify-items-center {
    justify-items: center
  }

  .md\:justify-items-stretch {
    justify-items: stretch
  }

  .md\:justify-start {
    justify-content: flex-start
  }

  .md\:justify-end {
    justify-content: flex-end
  }

  .md\:justify-center {
    justify-content: center
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:justify-around {
    justify-content: space-around
  }

  .md\:justify-evenly {
    justify-content: space-evenly
  }

  .md\:justify-self-auto {
    justify-self: auto
  }

  .md\:justify-self-start {
    justify-self: start
  }

  .md\:justify-self-end {
    justify-self: end
  }

  .md\:justify-self-center {
    justify-self: center
  }

  .md\:justify-self-stretch {
    justify-self: stretch
  }

  .md\:flex-1 {
    flex: 1 1 0%
  }

  .md\:flex-auto {
    flex: 1 1 auto
  }

  .md\:flex-initial {
    flex: 0 1 auto
  }

  .md\:flex-none {
    flex: none
  }

  .md\:flex-grow-0 {
    flex-grow: 0
  }

  .md\:flex-grow {
    flex-grow: 1
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:flex-shrink {
    flex-shrink: 1
  }

  .md\:order-1 {
    order: 1
  }

  .md\:order-2 {
    order: 2
  }

  .md\:order-3 {
    order: 3
  }

  .md\:order-4 {
    order: 4
  }

  .md\:order-5 {
    order: 5
  }

  .md\:order-6 {
    order: 6
  }

  .md\:order-7 {
    order: 7
  }

  .md\:order-8 {
    order: 8
  }

  .md\:order-9 {
    order: 9
  }

  .md\:order-10 {
    order: 10
  }

  .md\:order-11 {
    order: 11
  }

  .md\:order-12 {
    order: 12
  }

  .md\:order-first {
    order: -9999
  }

  .md\:order-last {
    order: 9999
  }

  .md\:order-none {
    order: 0
  }

  .md\:float-right {
    float: right
  }

  .md\:float-left {
    float: left
  }

  .md\:float-none {
    float: none
  }

  .md\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .md\:clear-left {
    clear: left
  }

  .md\:clear-right {
    clear: right
  }

  .md\:clear-both {
    clear: both
  }

  .md\:clear-none {
    clear: none
  }

  .md\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .md\:font-hairline {
    font-weight: 100
  }

  .md\:font-thin {
    font-weight: 200
  }

  .md\:font-light {
    font-weight: 300
  }

  .md\:font-normal {
    font-weight: 400
  }

  .md\:font-medium {
    font-weight: 500
  }

  .md\:font-semibold {
    font-weight: 600
  }

  .md\:font-bold {
    font-weight: 700
  }

  .md\:font-extrabold {
    font-weight: 800
  }

  .md\:font-black {
    font-weight: 900
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200
  }

  .md\:hover\:font-light:hover {
    font-weight: 300
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .md\:hover\:font-black:hover {
    font-weight: 900
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200
  }

  .md\:focus\:font-light:focus {
    font-weight: 300
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .md\:focus\:font-black:focus {
    font-weight: 900
  }

  .md\:h-0 {
    height: 0
  }

  .md\:h-1 {
    height: 0.25rem
  }

  .md\:h-2 {
    height: 0.5rem
  }

  .md\:h-3 {
    height: 0.75rem
  }

  .md\:h-4 {
    height: 1rem
  }

  .md\:h-5 {
    height: 1.25rem
  }

  .md\:h-6 {
    height: 1.5rem
  }

  .md\:h-8 {
    height: 2rem
  }

  .md\:h-10 {
    height: 2.5rem
  }

  .md\:h-12 {
    height: 3rem
  }

  .md\:h-16 {
    height: 4rem
  }

  .md\:h-20 {
    height: 5rem
  }

  .md\:h-24 {
    height: 6rem
  }

  .md\:h-32 {
    height: 8rem
  }

  .md\:h-40 {
    height: 10rem
  }

  .md\:h-48 {
    height: 12rem
  }

  .md\:h-56 {
    height: 14rem
  }

  .md\:h-64 {
    height: 16rem
  }

  .md\:h-auto {
    height: auto
  }

  .md\:h-px {
    height: 1px
  }

  .md\:h-full {
    height: 100%
  }

  .md\:h-screen {
    height: 100vh
  }

  .md\:text-xs {
    font-size: 0.75rem
  }

  .md\:text-sm {
    font-size: 0.875rem
  }

  .md\:text-base {
    font-size: 1rem
  }

  .md\:text-lg {
    font-size: 1.125rem
  }

  .md\:text-xl {
    font-size: 1.25rem
  }

  .md\:text-2xl {
    font-size: 1.5rem
  }

  .md\:text-3xl {
    font-size: 1.875rem
  }

  .md\:text-4xl {
    font-size: 2.25rem
  }

  .md\:text-5xl {
    font-size: 3rem
  }

  .md\:text-6xl {
    font-size: 4rem
  }

  .md\:leading-3 {
    line-height: .75rem
  }

  .md\:leading-4 {
    line-height: 1rem
  }

  .md\:leading-5 {
    line-height: 1.25rem
  }

  .md\:leading-6 {
    line-height: 1.5rem
  }

  .md\:leading-7 {
    line-height: 1.75rem
  }

  .md\:leading-8 {
    line-height: 2rem
  }

  .md\:leading-9 {
    line-height: 2.25rem
  }

  .md\:leading-10 {
    line-height: 2.5rem
  }

  .md\:leading-none {
    line-height: 1
  }

  .md\:leading-tight {
    line-height: 1.25
  }

  .md\:leading-snug {
    line-height: 1.375
  }

  .md\:leading-normal {
    line-height: 1.5
  }

  .md\:leading-relaxed {
    line-height: 1.625
  }

  .md\:leading-loose {
    line-height: 2
  }

  .md\:list-inside {
    list-style-position: inside
  }

  .md\:list-outside {
    list-style-position: outside
  }

  .md\:list-none {
    list-style-type: none
  }

  .md\:list-disc {
    list-style-type: disc
  }

  .md\:list-decimal {
    list-style-type: decimal
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:m-1 {
    margin: 0.25rem
  }

  .md\:m-2 {
    margin: 0.5rem
  }

  .md\:m-3 {
    margin: 0.75rem
  }

  .md\:m-4 {
    margin: 1rem
  }

  .md\:m-5 {
    margin: 1.25rem
  }

  .md\:m-6 {
    margin: 1.5rem
  }

  .md\:m-8 {
    margin: 2rem
  }

  .md\:m-10 {
    margin: 2.5rem
  }

  .md\:m-12 {
    margin: 3rem
  }

  .md\:m-16 {
    margin: 4rem
  }

  .md\:m-20 {
    margin: 5rem
  }

  .md\:m-24 {
    margin: 6rem
  }

  .md\:m-32 {
    margin: 8rem
  }

  .md\:m-40 {
    margin: 10rem
  }

  .md\:m-48 {
    margin: 12rem
  }

  .md\:m-56 {
    margin: 14rem
  }

  .md\:m-64 {
    margin: 16rem
  }

  .md\:m-auto {
    margin: auto
  }

  .md\:m-px {
    margin: 1px
  }

  .md\:-m-1 {
    margin: -0.25rem
  }

  .md\:-m-2 {
    margin: -0.5rem
  }

  .md\:-m-3 {
    margin: -0.75rem
  }

  .md\:-m-4 {
    margin: -1rem
  }

  .md\:-m-5 {
    margin: -1.25rem
  }

  .md\:-m-6 {
    margin: -1.5rem
  }

  .md\:-m-8 {
    margin: -2rem
  }

  .md\:-m-10 {
    margin: -2.5rem
  }

  .md\:-m-12 {
    margin: -3rem
  }

  .md\:-m-16 {
    margin: -4rem
  }

  .md\:-m-20 {
    margin: -5rem
  }

  .md\:-m-24 {
    margin: -6rem
  }

  .md\:-m-32 {
    margin: -8rem
  }

  .md\:-m-40 {
    margin: -10rem
  }

  .md\:-m-48 {
    margin: -12rem
  }

  .md\:-m-56 {
    margin: -14rem
  }

  .md\:-m-64 {
    margin: -16rem
  }

  .md\:-m-px {
    margin: -1px
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:ml-0 {
    margin-left: 0
  }

  .md\:mt-1 {
    margin-top: 0.25rem
  }

  .md\:mr-1 {
    margin-right: 0.25rem
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem
  }

  .md\:ml-1 {
    margin-left: 0.25rem
  }

  .md\:mt-2 {
    margin-top: 0.5rem
  }

  .md\:mr-2 {
    margin-right: 0.5rem
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem
  }

  .md\:ml-2 {
    margin-left: 0.5rem
  }

  .md\:mt-3 {
    margin-top: 0.75rem
  }

  .md\:mr-3 {
    margin-right: 0.75rem
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem
  }

  .md\:ml-3 {
    margin-left: 0.75rem
  }

  .md\:mt-4 {
    margin-top: 1rem
  }

  .md\:mr-4 {
    margin-right: 1rem
  }

  .md\:mb-4 {
    margin-bottom: 1rem
  }

  .md\:ml-4 {
    margin-left: 1rem
  }

  .md\:mt-5 {
    margin-top: 1.25rem
  }

  .md\:mr-5 {
    margin-right: 1.25rem
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem
  }

  .md\:ml-5 {
    margin-left: 1.25rem
  }

  .md\:mt-6 {
    margin-top: 1.5rem
  }

  .md\:mr-6 {
    margin-right: 1.5rem
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem
  }

  .md\:ml-6 {
    margin-left: 1.5rem
  }

  .md\:mt-8 {
    margin-top: 2rem
  }

  .md\:mr-8 {
    margin-right: 2rem
  }

  .md\:mb-8 {
    margin-bottom: 2rem
  }

  .md\:ml-8 {
    margin-left: 2rem
  }

  .md\:mt-10 {
    margin-top: 2.5rem
  }

  .md\:mr-10 {
    margin-right: 2.5rem
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem
  }

  .md\:ml-10 {
    margin-left: 2.5rem
  }

  .md\:mt-12 {
    margin-top: 3rem
  }

  .md\:mr-12 {
    margin-right: 3rem
  }

  .md\:mb-12 {
    margin-bottom: 3rem
  }

  .md\:ml-12 {
    margin-left: 3rem
  }

  .md\:mt-16 {
    margin-top: 4rem
  }

  .md\:mr-16 {
    margin-right: 4rem
  }

  .md\:mb-16 {
    margin-bottom: 4rem
  }

  .md\:ml-16 {
    margin-left: 4rem
  }

  .md\:mt-20 {
    margin-top: 5rem
  }

  .md\:mr-20 {
    margin-right: 5rem
  }

  .md\:mb-20 {
    margin-bottom: 5rem
  }

  .md\:ml-20 {
    margin-left: 5rem
  }

  .md\:mt-24 {
    margin-top: 6rem
  }

  .md\:mr-24 {
    margin-right: 6rem
  }

  .md\:mb-24 {
    margin-bottom: 6rem
  }

  .md\:ml-24 {
    margin-left: 6rem
  }

  .md\:mt-32 {
    margin-top: 8rem
  }

  .md\:mr-32 {
    margin-right: 8rem
  }

  .md\:mb-32 {
    margin-bottom: 8rem
  }

  .md\:ml-32 {
    margin-left: 8rem
  }

  .md\:mt-40 {
    margin-top: 10rem
  }

  .md\:mr-40 {
    margin-right: 10rem
  }

  .md\:mb-40 {
    margin-bottom: 10rem
  }

  .md\:ml-40 {
    margin-left: 10rem
  }

  .md\:mt-48 {
    margin-top: 12rem
  }

  .md\:mr-48 {
    margin-right: 12rem
  }

  .md\:mb-48 {
    margin-bottom: 12rem
  }

  .md\:ml-48 {
    margin-left: 12rem
  }

  .md\:mt-56 {
    margin-top: 14rem
  }

  .md\:mr-56 {
    margin-right: 14rem
  }

  .md\:mb-56 {
    margin-bottom: 14rem
  }

  .md\:ml-56 {
    margin-left: 14rem
  }

  .md\:mt-64 {
    margin-top: 16rem
  }

  .md\:mr-64 {
    margin-right: 16rem
  }

  .md\:mb-64 {
    margin-bottom: 16rem
  }

  .md\:ml-64 {
    margin-left: 16rem
  }

  .md\:mt-auto {
    margin-top: auto
  }

  .md\:mr-auto {
    margin-right: auto
  }

  .md\:mb-auto {
    margin-bottom: auto
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:mt-px {
    margin-top: 1px
  }

  .md\:mr-px {
    margin-right: 1px
  }

  .md\:mb-px {
    margin-bottom: 1px
  }

  .md\:ml-px {
    margin-left: 1px
  }

  .md\:-mt-1 {
    margin-top: -0.25rem
  }

  .md\:-mr-1 {
    margin-right: -0.25rem
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .md\:-ml-1 {
    margin-left: -0.25rem
  }

  .md\:-mt-2 {
    margin-top: -0.5rem
  }

  .md\:-mr-2 {
    margin-right: -0.5rem
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .md\:-ml-2 {
    margin-left: -0.5rem
  }

  .md\:-mt-3 {
    margin-top: -0.75rem
  }

  .md\:-mr-3 {
    margin-right: -0.75rem
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .md\:-ml-3 {
    margin-left: -0.75rem
  }

  .md\:-mt-4 {
    margin-top: -1rem
  }

  .md\:-mr-4 {
    margin-right: -1rem
  }

  .md\:-mb-4 {
    margin-bottom: -1rem
  }

  .md\:-ml-4 {
    margin-left: -1rem
  }

  .md\:-mt-5 {
    margin-top: -1.25rem
  }

  .md\:-mr-5 {
    margin-right: -1.25rem
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .md\:-ml-5 {
    margin-left: -1.25rem
  }

  .md\:-mt-6 {
    margin-top: -1.5rem
  }

  .md\:-mr-6 {
    margin-right: -1.5rem
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .md\:-ml-6 {
    margin-left: -1.5rem
  }

  .md\:-mt-8 {
    margin-top: -2rem
  }

  .md\:-mr-8 {
    margin-right: -2rem
  }

  .md\:-mb-8 {
    margin-bottom: -2rem
  }

  .md\:-ml-8 {
    margin-left: -2rem
  }

  .md\:-mt-10 {
    margin-top: -2.5rem
  }

  .md\:-mr-10 {
    margin-right: -2.5rem
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .md\:-ml-10 {
    margin-left: -2.5rem
  }

  .md\:-mt-12 {
    margin-top: -3rem
  }

  .md\:-mr-12 {
    margin-right: -3rem
  }

  .md\:-mb-12 {
    margin-bottom: -3rem
  }

  .md\:-ml-12 {
    margin-left: -3rem
  }

  .md\:-mt-16 {
    margin-top: -4rem
  }

  .md\:-mr-16 {
    margin-right: -4rem
  }

  .md\:-mb-16 {
    margin-bottom: -4rem
  }

  .md\:-ml-16 {
    margin-left: -4rem
  }

  .md\:-mt-20 {
    margin-top: -5rem
  }

  .md\:-mr-20 {
    margin-right: -5rem
  }

  .md\:-mb-20 {
    margin-bottom: -5rem
  }

  .md\:-ml-20 {
    margin-left: -5rem
  }

  .md\:-mt-24 {
    margin-top: -6rem
  }

  .md\:-mr-24 {
    margin-right: -6rem
  }

  .md\:-mb-24 {
    margin-bottom: -6rem
  }

  .md\:-ml-24 {
    margin-left: -6rem
  }

  .md\:-mt-32 {
    margin-top: -8rem
  }

  .md\:-mr-32 {
    margin-right: -8rem
  }

  .md\:-mb-32 {
    margin-bottom: -8rem
  }

  .md\:-ml-32 {
    margin-left: -8rem
  }

  .md\:-mt-40 {
    margin-top: -10rem
  }

  .md\:-mr-40 {
    margin-right: -10rem
  }

  .md\:-mb-40 {
    margin-bottom: -10rem
  }

  .md\:-ml-40 {
    margin-left: -10rem
  }

  .md\:-mt-48 {
    margin-top: -12rem
  }

  .md\:-mr-48 {
    margin-right: -12rem
  }

  .md\:-mb-48 {
    margin-bottom: -12rem
  }

  .md\:-ml-48 {
    margin-left: -12rem
  }

  .md\:-mt-56 {
    margin-top: -14rem
  }

  .md\:-mr-56 {
    margin-right: -14rem
  }

  .md\:-mb-56 {
    margin-bottom: -14rem
  }

  .md\:-ml-56 {
    margin-left: -14rem
  }

  .md\:-mt-64 {
    margin-top: -16rem
  }

  .md\:-mr-64 {
    margin-right: -16rem
  }

  .md\:-mb-64 {
    margin-bottom: -16rem
  }

  .md\:-ml-64 {
    margin-left: -16rem
  }

  .md\:-mt-px {
    margin-top: -1px
  }

  .md\:-mr-px {
    margin-right: -1px
  }

  .md\:-mb-px {
    margin-bottom: -1px
  }

  .md\:-ml-px {
    margin-left: -1px
  }

  .md\:max-h-full {
    max-height: 100%
  }

  .md\:max-h-screen {
    max-height: 100vh
  }

  .md\:max-w-none {
    max-width: none
  }

  .md\:max-w-xs {
    max-width: 20rem
  }

  .md\:max-w-sm {
    max-width: 24rem
  }

  .md\:max-w-md {
    max-width: 28rem
  }

  .md\:max-w-lg {
    max-width: 32rem
  }

  .md\:max-w-xl {
    max-width: 36rem
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:max-w-3xl {
    max-width: 48rem
  }

  .md\:max-w-4xl {
    max-width: 56rem
  }

  .md\:max-w-5xl {
    max-width: 64rem
  }

  .md\:max-w-6xl {
    max-width: 72rem
  }

  .md\:max-w-full {
    max-width: 100%
  }

  .md\:max-w-screen-sm {
    max-width: 640px
  }

  .md\:max-w-screen-md {
    max-width: 768px
  }

  .md\:max-w-screen-lg {
    max-width: 1024px
  }

  .md\:max-w-screen-xl {
    max-width: 1280px
  }

  .md\:min-h-0 {
    min-height: 0
  }

  .md\:min-h-full {
    min-height: 100%
  }

  .md\:min-h-screen {
    min-height: 100vh
  }

  .md\:min-w-0 {
    min-width: 0
  }

  .md\:min-w-full {
    min-width: 100%
  }

  .md\:object-contain {
    -o-object-fit: contain;
       object-fit: contain
  }

  .md\:object-cover {
    -o-object-fit: cover;
       object-fit: cover
  }

  .md\:object-fill {
    -o-object-fit: fill;
       object-fit: fill
  }

  .md\:object-none {
    -o-object-fit: none;
       object-fit: none
  }

  .md\:object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down
  }

  .md\:object-bottom {
    -o-object-position: bottom;
       object-position: bottom
  }

  .md\:object-center {
    -o-object-position: center;
       object-position: center
  }

  .md\:object-left {
    -o-object-position: left;
       object-position: left
  }

  .md\:object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom
  }

  .md\:object-left-top {
    -o-object-position: left top;
       object-position: left top
  }

  .md\:object-right {
    -o-object-position: right;
       object-position: right
  }

  .md\:object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom
  }

  .md\:object-right-top {
    -o-object-position: right top;
       object-position: right top
  }

  .md\:object-top {
    -o-object-position: top;
       object-position: top
  }

  .md\:opacity-0 {
    opacity: 0
  }

  .md\:opacity-25 {
    opacity: 0.25
  }

  .md\:opacity-50 {
    opacity: 0.5
  }

  .md\:opacity-75 {
    opacity: 0.75
  }

  .md\:opacity-100 {
    opacity: 1
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1
  }

  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .md\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .md\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .md\:overflow-auto {
    overflow: auto
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:overflow-visible {
    overflow: visible
  }

  .md\:overflow-scroll {
    overflow: scroll
  }

  .md\:overflow-x-auto {
    overflow-x: auto
  }

  .md\:overflow-y-auto {
    overflow-y: auto
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden
  }

  .md\:overflow-x-visible {
    overflow-x: visible
  }

  .md\:overflow-y-visible {
    overflow-y: visible
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain
  }

  .md\:overscroll-none {
    overscroll-behavior: none
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-1 {
    padding: 0.25rem
  }

  .md\:p-2 {
    padding: 0.5rem
  }

  .md\:p-3 {
    padding: 0.75rem
  }

  .md\:p-4 {
    padding: 1rem
  }

  .md\:p-5 {
    padding: 1.25rem
  }

  .md\:p-6 {
    padding: 1.5rem
  }

  .md\:p-8 {
    padding: 2rem
  }

  .md\:p-10 {
    padding: 2.5rem
  }

  .md\:p-12 {
    padding: 3rem
  }

  .md\:p-16 {
    padding: 4rem
  }

  .md\:p-20 {
    padding: 5rem
  }

  .md\:p-24 {
    padding: 6rem
  }

  .md\:p-32 {
    padding: 8rem
  }

  .md\:p-40 {
    padding: 10rem
  }

  .md\:p-48 {
    padding: 12rem
  }

  .md\:p-56 {
    padding: 14rem
  }

  .md\:p-64 {
    padding: 16rem
  }

  .md\:p-px {
    padding: 1px
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pt-1 {
    padding-top: 0.25rem
  }

  .md\:pr-1 {
    padding-right: 0.25rem
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem
  }

  .md\:pl-1 {
    padding-left: 0.25rem
  }

  .md\:pt-2 {
    padding-top: 0.5rem
  }

  .md\:pr-2 {
    padding-right: 0.5rem
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem
  }

  .md\:pl-2 {
    padding-left: 0.5rem
  }

  .md\:pt-3 {
    padding-top: 0.75rem
  }

  .md\:pr-3 {
    padding-right: 0.75rem
  }

  .md\:pb-3 {
    padding-bottom: 0.75rem
  }

  .md\:pl-3 {
    padding-left: 0.75rem
  }

  .md\:pt-4 {
    padding-top: 1rem
  }

  .md\:pr-4 {
    padding-right: 1rem
  }

  .md\:pb-4 {
    padding-bottom: 1rem
  }

  .md\:pl-4 {
    padding-left: 1rem
  }

  .md\:pt-5 {
    padding-top: 1.25rem
  }

  .md\:pr-5 {
    padding-right: 1.25rem
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem
  }

  .md\:pl-5 {
    padding-left: 1.25rem
  }

  .md\:pt-6 {
    padding-top: 1.5rem
  }

  .md\:pr-6 {
    padding-right: 1.5rem
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem
  }

  .md\:pl-6 {
    padding-left: 1.5rem
  }

  .md\:pt-8 {
    padding-top: 2rem
  }

  .md\:pr-8 {
    padding-right: 2rem
  }

  .md\:pb-8 {
    padding-bottom: 2rem
  }

  .md\:pl-8 {
    padding-left: 2rem
  }

  .md\:pt-10 {
    padding-top: 2.5rem
  }

  .md\:pr-10 {
    padding-right: 2.5rem
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem
  }

  .md\:pl-10 {
    padding-left: 2.5rem
  }

  .md\:pt-12 {
    padding-top: 3rem
  }

  .md\:pr-12 {
    padding-right: 3rem
  }

  .md\:pb-12 {
    padding-bottom: 3rem
  }

  .md\:pl-12 {
    padding-left: 3rem
  }

  .md\:pt-16 {
    padding-top: 4rem
  }

  .md\:pr-16 {
    padding-right: 4rem
  }

  .md\:pb-16 {
    padding-bottom: 4rem
  }

  .md\:pl-16 {
    padding-left: 4rem
  }

  .md\:pt-20 {
    padding-top: 5rem
  }

  .md\:pr-20 {
    padding-right: 5rem
  }

  .md\:pb-20 {
    padding-bottom: 5rem
  }

  .md\:pl-20 {
    padding-left: 5rem
  }

  .md\:pt-24 {
    padding-top: 6rem
  }

  .md\:pr-24 {
    padding-right: 6rem
  }

  .md\:pb-24 {
    padding-bottom: 6rem
  }

  .md\:pl-24 {
    padding-left: 6rem
  }

  .md\:pt-32 {
    padding-top: 8rem
  }

  .md\:pr-32 {
    padding-right: 8rem
  }

  .md\:pb-32 {
    padding-bottom: 8rem
  }

  .md\:pl-32 {
    padding-left: 8rem
  }

  .md\:pt-40 {
    padding-top: 10rem
  }

  .md\:pr-40 {
    padding-right: 10rem
  }

  .md\:pb-40 {
    padding-bottom: 10rem
  }

  .md\:pl-40 {
    padding-left: 10rem
  }

  .md\:pt-48 {
    padding-top: 12rem
  }

  .md\:pr-48 {
    padding-right: 12rem
  }

  .md\:pb-48 {
    padding-bottom: 12rem
  }

  .md\:pl-48 {
    padding-left: 12rem
  }

  .md\:pt-56 {
    padding-top: 14rem
  }

  .md\:pr-56 {
    padding-right: 14rem
  }

  .md\:pb-56 {
    padding-bottom: 14rem
  }

  .md\:pl-56 {
    padding-left: 14rem
  }

  .md\:pt-64 {
    padding-top: 16rem
  }

  .md\:pr-64 {
    padding-right: 16rem
  }

  .md\:pb-64 {
    padding-bottom: 16rem
  }

  .md\:pl-64 {
    padding-left: 16rem
  }

  .md\:pt-px {
    padding-top: 1px
  }

  .md\:pr-px {
    padding-right: 1px
  }

  .md\:pb-px {
    padding-bottom: 1px
  }

  .md\:pl-px {
    padding-left: 1px
  }

  .md\:placeholder-primary::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .md\:placeholder-primary::placeholder {
    color: var(--ion-color-primary)
  }

  .md\:placeholder-primary-shade::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .md\:placeholder-primary-shade::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .md\:placeholder-primary-tint::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .md\:placeholder-primary-tint::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .md\:placeholder-secondary::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .md\:placeholder-secondary::placeholder {
    color: var(--ion-color-secondary)
  }

  .md\:placeholder-secondary-shade::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .md\:placeholder-secondary-shade::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .md\:placeholder-secondary-tint::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .md\:placeholder-secondary-tint::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .md\:placeholder-tertiary::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .md\:placeholder-tertiary::placeholder {
    color: var(--ion-color-tertiary)
  }

  .md\:placeholder-tertiary-shade::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:placeholder-tertiary-shade::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:placeholder-tertiary-tint::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:placeholder-tertiary-tint::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:placeholder-light::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .md\:placeholder-light::placeholder {
    color: var(--ion-color-light)
  }

  .md\:placeholder-light-shade::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .md\:placeholder-light-shade::placeholder {
    color: var(--ion-color-light-shade)
  }

  .md\:placeholder-light-tint::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .md\:placeholder-light-tint::placeholder {
    color: var(--ion-color-light-tint)
  }

  .md\:placeholder-medium::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .md\:placeholder-medium::placeholder {
    color: var(--ion-color-medium)
  }

  .md\:placeholder-medium-shade::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .md\:placeholder-medium-shade::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .md\:placeholder-medium-tint::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .md\:placeholder-medium-tint::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .md\:placeholder-dark::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .md\:placeholder-dark::placeholder {
    color: var(--ion-color-dark)
  }

  .md\:placeholder-dark-shade::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .md\:placeholder-dark-shade::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .md\:placeholder-dark-tint::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .md\:placeholder-dark-tint::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .md\:placeholder-success::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .md\:placeholder-success::placeholder {
    color: var(--ion-color-success)
  }

  .md\:placeholder-success-shade::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .md\:placeholder-success-shade::placeholder {
    color: var(--ion-color-success-shade)
  }

  .md\:placeholder-success-tint::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .md\:placeholder-success-tint::placeholder {
    color: var(--ion-color-success-tint)
  }

  .md\:placeholder-warning::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .md\:placeholder-warning::placeholder {
    color: var(--ion-color-warning)
  }

  .md\:placeholder-warning-shade::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .md\:placeholder-warning-shade::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .md\:placeholder-warning-tint::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .md\:placeholder-warning-tint::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .md\:placeholder-danger::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .md\:placeholder-danger::placeholder {
    color: var(--ion-color-danger)
  }

  .md\:placeholder-danger-shade::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .md\:placeholder-danger-shade::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .md\:placeholder-danger-tint::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .md\:placeholder-danger-tint::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .md\:placeholder-step-50::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .md\:placeholder-step-50::placeholder {
    color: var(--ion-color-step-50)
  }

  .md\:placeholder-step-100::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .md\:placeholder-step-100::placeholder {
    color: var(--ion-color-step-100)
  }

  .md\:placeholder-step-150::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .md\:placeholder-step-150::placeholder {
    color: var(--ion-color-step-150)
  }

  .md\:placeholder-step-200::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .md\:placeholder-step-200::placeholder {
    color: var(--ion-color-step-200)
  }

  .md\:placeholder-step-250::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .md\:placeholder-step-250::placeholder {
    color: var(--ion-color-step-250)
  }

  .md\:placeholder-step-300::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .md\:placeholder-step-300::placeholder {
    color: var(--ion-color-step-300)
  }

  .md\:placeholder-step-350::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .md\:placeholder-step-350::placeholder {
    color: var(--ion-color-step-350)
  }

  .md\:placeholder-step-400::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .md\:placeholder-step-400::placeholder {
    color: var(--ion-color-step-400)
  }

  .md\:placeholder-step-450::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .md\:placeholder-step-450::placeholder {
    color: var(--ion-color-step-450)
  }

  .md\:placeholder-step-500::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .md\:placeholder-step-500::placeholder {
    color: var(--ion-color-step-500)
  }

  .md\:placeholder-step-550::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .md\:placeholder-step-550::placeholder {
    color: var(--ion-color-step-550)
  }

  .md\:placeholder-step-600::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .md\:placeholder-step-600::placeholder {
    color: var(--ion-color-step-600)
  }

  .md\:placeholder-step-650::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .md\:placeholder-step-650::placeholder {
    color: var(--ion-color-step-650)
  }

  .md\:placeholder-step-700::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .md\:placeholder-step-700::placeholder {
    color: var(--ion-color-step-700)
  }

  .md\:placeholder-step-750::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .md\:placeholder-step-750::placeholder {
    color: var(--ion-color-step-750)
  }

  .md\:placeholder-step-800::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .md\:placeholder-step-800::placeholder {
    color: var(--ion-color-step-800)
  }

  .md\:placeholder-step-850::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .md\:placeholder-step-850::placeholder {
    color: var(--ion-color-step-850)
  }

  .md\:placeholder-step-900::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .md\:placeholder-step-900::placeholder {
    color: var(--ion-color-step-900)
  }

  .md\:placeholder-step-950::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .md\:placeholder-step-950::placeholder {
    color: var(--ion-color-step-950)
  }

  .md\:focus\:placeholder-primary:focus::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .md\:focus\:placeholder-primary:focus::placeholder {
    color: var(--ion-color-primary)
  }

  .md\:focus\:placeholder-primary-shade:focus::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .md\:focus\:placeholder-primary-shade:focus::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .md\:focus\:placeholder-primary-tint:focus::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .md\:focus\:placeholder-primary-tint:focus::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .md\:focus\:placeholder-secondary:focus::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .md\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--ion-color-secondary)
  }

  .md\:focus\:placeholder-secondary-shade:focus::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:placeholder-secondary-shade:focus::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:placeholder-secondary-tint:focus::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:placeholder-secondary-tint:focus::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:placeholder-tertiary:focus::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .md\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--ion-color-tertiary)
  }

  .md\:focus\:placeholder-tertiary-shade:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:placeholder-tertiary-shade:focus::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:placeholder-tertiary-tint:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:placeholder-tertiary-tint:focus::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:placeholder-light:focus::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .md\:focus\:placeholder-light:focus::placeholder {
    color: var(--ion-color-light)
  }

  .md\:focus\:placeholder-light-shade:focus::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .md\:focus\:placeholder-light-shade:focus::placeholder {
    color: var(--ion-color-light-shade)
  }

  .md\:focus\:placeholder-light-tint:focus::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .md\:focus\:placeholder-light-tint:focus::placeholder {
    color: var(--ion-color-light-tint)
  }

  .md\:focus\:placeholder-medium:focus::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .md\:focus\:placeholder-medium:focus::placeholder {
    color: var(--ion-color-medium)
  }

  .md\:focus\:placeholder-medium-shade:focus::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .md\:focus\:placeholder-medium-shade:focus::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .md\:focus\:placeholder-medium-tint:focus::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .md\:focus\:placeholder-medium-tint:focus::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .md\:focus\:placeholder-dark:focus::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .md\:focus\:placeholder-dark:focus::placeholder {
    color: var(--ion-color-dark)
  }

  .md\:focus\:placeholder-dark-shade:focus::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .md\:focus\:placeholder-dark-shade:focus::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .md\:focus\:placeholder-dark-tint:focus::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .md\:focus\:placeholder-dark-tint:focus::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .md\:focus\:placeholder-success:focus::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .md\:focus\:placeholder-success:focus::placeholder {
    color: var(--ion-color-success)
  }

  .md\:focus\:placeholder-success-shade:focus::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .md\:focus\:placeholder-success-shade:focus::placeholder {
    color: var(--ion-color-success-shade)
  }

  .md\:focus\:placeholder-success-tint:focus::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .md\:focus\:placeholder-success-tint:focus::placeholder {
    color: var(--ion-color-success-tint)
  }

  .md\:focus\:placeholder-warning:focus::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .md\:focus\:placeholder-warning:focus::placeholder {
    color: var(--ion-color-warning)
  }

  .md\:focus\:placeholder-warning-shade:focus::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .md\:focus\:placeholder-warning-shade:focus::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .md\:focus\:placeholder-warning-tint:focus::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .md\:focus\:placeholder-warning-tint:focus::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .md\:focus\:placeholder-danger:focus::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .md\:focus\:placeholder-danger:focus::placeholder {
    color: var(--ion-color-danger)
  }

  .md\:focus\:placeholder-danger-shade:focus::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .md\:focus\:placeholder-danger-shade:focus::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .md\:focus\:placeholder-danger-tint:focus::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .md\:focus\:placeholder-danger-tint:focus::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .md\:focus\:placeholder-step-50:focus::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .md\:focus\:placeholder-step-50:focus::placeholder {
    color: var(--ion-color-step-50)
  }

  .md\:focus\:placeholder-step-100:focus::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .md\:focus\:placeholder-step-100:focus::placeholder {
    color: var(--ion-color-step-100)
  }

  .md\:focus\:placeholder-step-150:focus::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .md\:focus\:placeholder-step-150:focus::placeholder {
    color: var(--ion-color-step-150)
  }

  .md\:focus\:placeholder-step-200:focus::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .md\:focus\:placeholder-step-200:focus::placeholder {
    color: var(--ion-color-step-200)
  }

  .md\:focus\:placeholder-step-250:focus::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .md\:focus\:placeholder-step-250:focus::placeholder {
    color: var(--ion-color-step-250)
  }

  .md\:focus\:placeholder-step-300:focus::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .md\:focus\:placeholder-step-300:focus::placeholder {
    color: var(--ion-color-step-300)
  }

  .md\:focus\:placeholder-step-350:focus::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .md\:focus\:placeholder-step-350:focus::placeholder {
    color: var(--ion-color-step-350)
  }

  .md\:focus\:placeholder-step-400:focus::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .md\:focus\:placeholder-step-400:focus::placeholder {
    color: var(--ion-color-step-400)
  }

  .md\:focus\:placeholder-step-450:focus::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .md\:focus\:placeholder-step-450:focus::placeholder {
    color: var(--ion-color-step-450)
  }

  .md\:focus\:placeholder-step-500:focus::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .md\:focus\:placeholder-step-500:focus::placeholder {
    color: var(--ion-color-step-500)
  }

  .md\:focus\:placeholder-step-550:focus::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .md\:focus\:placeholder-step-550:focus::placeholder {
    color: var(--ion-color-step-550)
  }

  .md\:focus\:placeholder-step-600:focus::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .md\:focus\:placeholder-step-600:focus::placeholder {
    color: var(--ion-color-step-600)
  }

  .md\:focus\:placeholder-step-650:focus::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .md\:focus\:placeholder-step-650:focus::placeholder {
    color: var(--ion-color-step-650)
  }

  .md\:focus\:placeholder-step-700:focus::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .md\:focus\:placeholder-step-700:focus::placeholder {
    color: var(--ion-color-step-700)
  }

  .md\:focus\:placeholder-step-750:focus::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .md\:focus\:placeholder-step-750:focus::placeholder {
    color: var(--ion-color-step-750)
  }

  .md\:focus\:placeholder-step-800:focus::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .md\:focus\:placeholder-step-800:focus::placeholder {
    color: var(--ion-color-step-800)
  }

  .md\:focus\:placeholder-step-850:focus::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .md\:focus\:placeholder-step-850:focus::placeholder {
    color: var(--ion-color-step-850)
  }

  .md\:focus\:placeholder-step-900:focus::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .md\:focus\:placeholder-step-900:focus::placeholder {
    color: var(--ion-color-step-900)
  }

  .md\:focus\:placeholder-step-950:focus::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .md\:focus\:placeholder-step-950:focus::placeholder {
    color: var(--ion-color-step-950)
  }

  .md\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .md\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .md\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .md\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .md\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .md\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .md\:pointer-events-none {
    pointer-events: none
  }

  .md\:pointer-events-auto {
    pointer-events: auto
  }

  .md\:static {
    position: static
  }

  .md\:fixed {
    position: fixed
  }

  .md\:absolute {
    position: absolute
  }

  .md\:relative {
    position: relative
  }

  .md\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .md\:inset-x-0 {
    right: 0;
    left: 0
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .md\:inset-x-auto {
    right: auto;
    left: auto
  }

  .md\:top-0 {
    top: 0
  }

  .md\:right-0 {
    right: 0
  }

  .md\:bottom-0 {
    bottom: 0
  }

  .md\:left-0 {
    left: 0
  }

  .md\:top-auto {
    top: auto
  }

  .md\:right-auto {
    right: auto
  }

  .md\:bottom-auto {
    bottom: auto
  }

  .md\:left-auto {
    left: auto
  }

  .md\:resize-none {
    resize: none
  }

  .md\:resize-y {
    resize: vertical
  }

  .md\:resize-x {
    resize: horizontal
  }

  .md\:resize {
    resize: both
  }

  .md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:shadow-none {
    box-shadow: none
  }

  .md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .md\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .md\:fill-current {
    fill: currentColor
  }

  .md\:stroke-current {
    stroke: currentColor
  }

  .md\:stroke-0 {
    stroke-width: 0
  }

  .md\:stroke-1 {
    stroke-width: 1
  }

  .md\:stroke-2 {
    stroke-width: 2
  }

  .md\:table-auto {
    table-layout: auto
  }

  .md\:table-fixed {
    table-layout: fixed
  }

  .md\:text-left {
    text-align: left
  }

  .md\:text-center {
    text-align: center
  }

  .md\:text-right {
    text-align: right
  }

  .md\:text-justify {
    text-align: justify
  }

  .md\:text-primary {
    color: var(--ion-color-primary)
  }

  .md\:text-primary-shade {
    color: var(--ion-color-primary-shade)
  }

  .md\:text-primary-tint {
    color: var(--ion-color-primary-tint)
  }

  .md\:text-secondary {
    color: var(--ion-color-secondary)
  }

  .md\:text-secondary-shade {
    color: var(--ion-color-secondary-shade)
  }

  .md\:text-secondary-tint {
    color: var(--ion-color-secondary-tint)
  }

  .md\:text-tertiary {
    color: var(--ion-color-tertiary)
  }

  .md\:text-tertiary-shade {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:text-tertiary-tint {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:text-light {
    color: var(--ion-color-light)
  }

  .md\:text-light-shade {
    color: var(--ion-color-light-shade)
  }

  .md\:text-light-tint {
    color: var(--ion-color-light-tint)
  }

  .md\:text-medium {
    color: var(--ion-color-medium)
  }

  .md\:text-medium-shade {
    color: var(--ion-color-medium-shade)
  }

  .md\:text-medium-tint {
    color: var(--ion-color-medium-tint)
  }

  .md\:text-dark {
    color: var(--ion-color-dark)
  }

  .md\:text-dark-shade {
    color: var(--ion-color-dark-shade)
  }

  .md\:text-dark-tint {
    color: var(--ion-color-dark-tint)
  }

  .md\:text-success {
    color: var(--ion-color-success)
  }

  .md\:text-success-shade {
    color: var(--ion-color-success-shade)
  }

  .md\:text-success-tint {
    color: var(--ion-color-success-tint)
  }

  .md\:text-warning {
    color: var(--ion-color-warning)
  }

  .md\:text-warning-shade {
    color: var(--ion-color-warning-shade)
  }

  .md\:text-warning-tint {
    color: var(--ion-color-warning-tint)
  }

  .md\:text-danger {
    color: var(--ion-color-danger)
  }

  .md\:text-danger-shade {
    color: var(--ion-color-danger-shade)
  }

  .md\:text-danger-tint {
    color: var(--ion-color-danger-tint)
  }

  .md\:text-step-50 {
    color: var(--ion-color-step-50)
  }

  .md\:text-step-100 {
    color: var(--ion-color-step-100)
  }

  .md\:text-step-150 {
    color: var(--ion-color-step-150)
  }

  .md\:text-step-200 {
    color: var(--ion-color-step-200)
  }

  .md\:text-step-250 {
    color: var(--ion-color-step-250)
  }

  .md\:text-step-300 {
    color: var(--ion-color-step-300)
  }

  .md\:text-step-350 {
    color: var(--ion-color-step-350)
  }

  .md\:text-step-400 {
    color: var(--ion-color-step-400)
  }

  .md\:text-step-450 {
    color: var(--ion-color-step-450)
  }

  .md\:text-step-500 {
    color: var(--ion-color-step-500)
  }

  .md\:text-step-550 {
    color: var(--ion-color-step-550)
  }

  .md\:text-step-600 {
    color: var(--ion-color-step-600)
  }

  .md\:text-step-650 {
    color: var(--ion-color-step-650)
  }

  .md\:text-step-700 {
    color: var(--ion-color-step-700)
  }

  .md\:text-step-750 {
    color: var(--ion-color-step-750)
  }

  .md\:text-step-800 {
    color: var(--ion-color-step-800)
  }

  .md\:text-step-850 {
    color: var(--ion-color-step-850)
  }

  .md\:text-step-900 {
    color: var(--ion-color-step-900)
  }

  .md\:text-step-950 {
    color: var(--ion-color-step-950)
  }

  .md\:hover\:text-primary:hover {
    color: var(--ion-color-primary)
  }

  .md\:hover\:text-primary-shade:hover {
    color: var(--ion-color-primary-shade)
  }

  .md\:hover\:text-primary-tint:hover {
    color: var(--ion-color-primary-tint)
  }

  .md\:hover\:text-secondary:hover {
    color: var(--ion-color-secondary)
  }

  .md\:hover\:text-secondary-shade:hover {
    color: var(--ion-color-secondary-shade)
  }

  .md\:hover\:text-secondary-tint:hover {
    color: var(--ion-color-secondary-tint)
  }

  .md\:hover\:text-tertiary:hover {
    color: var(--ion-color-tertiary)
  }

  .md\:hover\:text-tertiary-shade:hover {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:hover\:text-tertiary-tint:hover {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:hover\:text-light:hover {
    color: var(--ion-color-light)
  }

  .md\:hover\:text-light-shade:hover {
    color: var(--ion-color-light-shade)
  }

  .md\:hover\:text-light-tint:hover {
    color: var(--ion-color-light-tint)
  }

  .md\:hover\:text-medium:hover {
    color: var(--ion-color-medium)
  }

  .md\:hover\:text-medium-shade:hover {
    color: var(--ion-color-medium-shade)
  }

  .md\:hover\:text-medium-tint:hover {
    color: var(--ion-color-medium-tint)
  }

  .md\:hover\:text-dark:hover {
    color: var(--ion-color-dark)
  }

  .md\:hover\:text-dark-shade:hover {
    color: var(--ion-color-dark-shade)
  }

  .md\:hover\:text-dark-tint:hover {
    color: var(--ion-color-dark-tint)
  }

  .md\:hover\:text-success:hover {
    color: var(--ion-color-success)
  }

  .md\:hover\:text-success-shade:hover {
    color: var(--ion-color-success-shade)
  }

  .md\:hover\:text-success-tint:hover {
    color: var(--ion-color-success-tint)
  }

  .md\:hover\:text-warning:hover {
    color: var(--ion-color-warning)
  }

  .md\:hover\:text-warning-shade:hover {
    color: var(--ion-color-warning-shade)
  }

  .md\:hover\:text-warning-tint:hover {
    color: var(--ion-color-warning-tint)
  }

  .md\:hover\:text-danger:hover {
    color: var(--ion-color-danger)
  }

  .md\:hover\:text-danger-shade:hover {
    color: var(--ion-color-danger-shade)
  }

  .md\:hover\:text-danger-tint:hover {
    color: var(--ion-color-danger-tint)
  }

  .md\:hover\:text-step-50:hover {
    color: var(--ion-color-step-50)
  }

  .md\:hover\:text-step-100:hover {
    color: var(--ion-color-step-100)
  }

  .md\:hover\:text-step-150:hover {
    color: var(--ion-color-step-150)
  }

  .md\:hover\:text-step-200:hover {
    color: var(--ion-color-step-200)
  }

  .md\:hover\:text-step-250:hover {
    color: var(--ion-color-step-250)
  }

  .md\:hover\:text-step-300:hover {
    color: var(--ion-color-step-300)
  }

  .md\:hover\:text-step-350:hover {
    color: var(--ion-color-step-350)
  }

  .md\:hover\:text-step-400:hover {
    color: var(--ion-color-step-400)
  }

  .md\:hover\:text-step-450:hover {
    color: var(--ion-color-step-450)
  }

  .md\:hover\:text-step-500:hover {
    color: var(--ion-color-step-500)
  }

  .md\:hover\:text-step-550:hover {
    color: var(--ion-color-step-550)
  }

  .md\:hover\:text-step-600:hover {
    color: var(--ion-color-step-600)
  }

  .md\:hover\:text-step-650:hover {
    color: var(--ion-color-step-650)
  }

  .md\:hover\:text-step-700:hover {
    color: var(--ion-color-step-700)
  }

  .md\:hover\:text-step-750:hover {
    color: var(--ion-color-step-750)
  }

  .md\:hover\:text-step-800:hover {
    color: var(--ion-color-step-800)
  }

  .md\:hover\:text-step-850:hover {
    color: var(--ion-color-step-850)
  }

  .md\:hover\:text-step-900:hover {
    color: var(--ion-color-step-900)
  }

  .md\:hover\:text-step-950:hover {
    color: var(--ion-color-step-950)
  }

  .md\:focus\:text-primary:focus {
    color: var(--ion-color-primary)
  }

  .md\:focus\:text-primary-shade:focus {
    color: var(--ion-color-primary-shade)
  }

  .md\:focus\:text-primary-tint:focus {
    color: var(--ion-color-primary-tint)
  }

  .md\:focus\:text-secondary:focus {
    color: var(--ion-color-secondary)
  }

  .md\:focus\:text-secondary-shade:focus {
    color: var(--ion-color-secondary-shade)
  }

  .md\:focus\:text-secondary-tint:focus {
    color: var(--ion-color-secondary-tint)
  }

  .md\:focus\:text-tertiary:focus {
    color: var(--ion-color-tertiary)
  }

  .md\:focus\:text-tertiary-shade:focus {
    color: var(--ion-color-tertiary-shade)
  }

  .md\:focus\:text-tertiary-tint:focus {
    color: var(--ion-color-tertiary-tint)
  }

  .md\:focus\:text-light:focus {
    color: var(--ion-color-light)
  }

  .md\:focus\:text-light-shade:focus {
    color: var(--ion-color-light-shade)
  }

  .md\:focus\:text-light-tint:focus {
    color: var(--ion-color-light-tint)
  }

  .md\:focus\:text-medium:focus {
    color: var(--ion-color-medium)
  }

  .md\:focus\:text-medium-shade:focus {
    color: var(--ion-color-medium-shade)
  }

  .md\:focus\:text-medium-tint:focus {
    color: var(--ion-color-medium-tint)
  }

  .md\:focus\:text-dark:focus {
    color: var(--ion-color-dark)
  }

  .md\:focus\:text-dark-shade:focus {
    color: var(--ion-color-dark-shade)
  }

  .md\:focus\:text-dark-tint:focus {
    color: var(--ion-color-dark-tint)
  }

  .md\:focus\:text-success:focus {
    color: var(--ion-color-success)
  }

  .md\:focus\:text-success-shade:focus {
    color: var(--ion-color-success-shade)
  }

  .md\:focus\:text-success-tint:focus {
    color: var(--ion-color-success-tint)
  }

  .md\:focus\:text-warning:focus {
    color: var(--ion-color-warning)
  }

  .md\:focus\:text-warning-shade:focus {
    color: var(--ion-color-warning-shade)
  }

  .md\:focus\:text-warning-tint:focus {
    color: var(--ion-color-warning-tint)
  }

  .md\:focus\:text-danger:focus {
    color: var(--ion-color-danger)
  }

  .md\:focus\:text-danger-shade:focus {
    color: var(--ion-color-danger-shade)
  }

  .md\:focus\:text-danger-tint:focus {
    color: var(--ion-color-danger-tint)
  }

  .md\:focus\:text-step-50:focus {
    color: var(--ion-color-step-50)
  }

  .md\:focus\:text-step-100:focus {
    color: var(--ion-color-step-100)
  }

  .md\:focus\:text-step-150:focus {
    color: var(--ion-color-step-150)
  }

  .md\:focus\:text-step-200:focus {
    color: var(--ion-color-step-200)
  }

  .md\:focus\:text-step-250:focus {
    color: var(--ion-color-step-250)
  }

  .md\:focus\:text-step-300:focus {
    color: var(--ion-color-step-300)
  }

  .md\:focus\:text-step-350:focus {
    color: var(--ion-color-step-350)
  }

  .md\:focus\:text-step-400:focus {
    color: var(--ion-color-step-400)
  }

  .md\:focus\:text-step-450:focus {
    color: var(--ion-color-step-450)
  }

  .md\:focus\:text-step-500:focus {
    color: var(--ion-color-step-500)
  }

  .md\:focus\:text-step-550:focus {
    color: var(--ion-color-step-550)
  }

  .md\:focus\:text-step-600:focus {
    color: var(--ion-color-step-600)
  }

  .md\:focus\:text-step-650:focus {
    color: var(--ion-color-step-650)
  }

  .md\:focus\:text-step-700:focus {
    color: var(--ion-color-step-700)
  }

  .md\:focus\:text-step-750:focus {
    color: var(--ion-color-step-750)
  }

  .md\:focus\:text-step-800:focus {
    color: var(--ion-color-step-800)
  }

  .md\:focus\:text-step-850:focus {
    color: var(--ion-color-step-850)
  }

  .md\:focus\:text-step-900:focus {
    color: var(--ion-color-step-900)
  }

  .md\:focus\:text-step-950:focus {
    color: var(--ion-color-step-950)
  }

  .md\:italic {
    font-style: italic
  }

  .md\:not-italic {
    font-style: normal
  }

  .md\:uppercase {
    text-transform: uppercase
  }

  .md\:lowercase {
    text-transform: lowercase
  }

  .md\:capitalize {
    text-transform: capitalize
  }

  .md\:normal-case {
    text-transform: none
  }

  .md\:underline {
    text-decoration: underline
  }

  .md\:line-through {
    text-decoration: line-through
  }

  .md\:no-underline {
    text-decoration: none
  }

  .md\:hover\:underline:hover {
    text-decoration: underline
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none
  }

  .md\:focus\:underline:focus {
    text-decoration: underline
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .md\:normal-nums {
    font-variant-numeric: normal
  }

  .md\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .md\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .md\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .md\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .md\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .md\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .md\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .md\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em
  }

  .md\:tracking-normal {
    letter-spacing: 0
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em
  }

  .md\:select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none
  }

  .md\:select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text
  }

  .md\:select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all
  }

  .md\:select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto
  }

  .md\:align-baseline {
    vertical-align: baseline
  }

  .md\:align-top {
    vertical-align: top
  }

  .md\:align-middle {
    vertical-align: middle
  }

  .md\:align-bottom {
    vertical-align: bottom
  }

  .md\:align-text-top {
    vertical-align: text-top
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom
  }

  .md\:visible {
    visibility: visible
  }

  .md\:invisible {
    visibility: hidden
  }

  .md\:whitespace-normal {
    white-space: normal
  }

  .md\:whitespace-no-wrap {
    white-space: nowrap
  }

  .md\:whitespace-pre {
    white-space: pre
  }

  .md\:whitespace-pre-line {
    white-space: pre-line
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .md\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .md\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .md\:break-all {
    word-break: break-all
  }

  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .md\:w-0 {
    width: 0
  }

  .md\:w-1 {
    width: 0.25rem
  }

  .md\:w-2 {
    width: 0.5rem
  }

  .md\:w-3 {
    width: 0.75rem
  }

  .md\:w-4 {
    width: 1rem
  }

  .md\:w-5 {
    width: 1.25rem
  }

  .md\:w-6 {
    width: 1.5rem
  }

  .md\:w-8 {
    width: 2rem
  }

  .md\:w-10 {
    width: 2.5rem
  }

  .md\:w-12 {
    width: 3rem
  }

  .md\:w-16 {
    width: 4rem
  }

  .md\:w-20 {
    width: 5rem
  }

  .md\:w-24 {
    width: 6rem
  }

  .md\:w-32 {
    width: 8rem
  }

  .md\:w-40 {
    width: 10rem
  }

  .md\:w-48 {
    width: 12rem
  }

  .md\:w-56 {
    width: 14rem
  }

  .md\:w-64 {
    width: 16rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-px {
    width: 1px
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-2\/4 {
    width: 50%
  }

  .md\:w-3\/4 {
    width: 75%
  }

  .md\:w-1\/5 {
    width: 20%
  }

  .md\:w-2\/5 {
    width: 40%
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-4\/5 {
    width: 80%
  }

  .md\:w-1\/6 {
    width: 16.666667%
  }

  .md\:w-2\/6 {
    width: 33.333333%
  }

  .md\:w-3\/6 {
    width: 50%
  }

  .md\:w-4\/6 {
    width: 66.666667%
  }

  .md\:w-5\/6 {
    width: 83.333333%
  }

  .md\:w-1\/12 {
    width: 8.333333%
  }

  .md\:w-2\/12 {
    width: 16.666667%
  }

  .md\:w-3\/12 {
    width: 25%
  }

  .md\:w-4\/12 {
    width: 33.333333%
  }

  .md\:w-5\/12 {
    width: 41.666667%
  }

  .md\:w-6\/12 {
    width: 50%
  }

  .md\:w-7\/12 {
    width: 58.333333%
  }

  .md\:w-8\/12 {
    width: 66.666667%
  }

  .md\:w-9\/12 {
    width: 75%
  }

  .md\:w-10\/12 {
    width: 83.333333%
  }

  .md\:w-11\/12 {
    width: 91.666667%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:w-screen {
    width: 100vw
  }

  .md\:z-0 {
    z-index: 0
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:z-20 {
    z-index: 20
  }

  .md\:z-30 {
    z-index: 30
  }

  .md\:z-40 {
    z-index: 40
  }

  .md\:z-50 {
    z-index: 50
  }

  .md\:z-auto {
    z-index: auto
  }

  .md\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .md\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .md\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .md\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .md\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .md\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .md\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .md\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .md\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .md\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .md\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .md\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .md\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .md\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .md\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .md\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .md\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .md\:col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .md\:col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .md\:col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .md\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .md\:col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .md\:col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .md\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .md\:col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .md\:col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .md\:col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .md\:col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .md\:col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .md\:col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .md\:col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .md\:col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .md\:col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .md\:col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .md\:col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .md\:col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .md\:gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .md\:gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .md\:gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .md\:gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .md\:gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .md\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .md\:gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .md\:gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .md\:gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .md\:gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .md\:gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .md\:gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .md\:gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .md\:gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .md\:gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .md\:gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .md\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .md\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .md\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .md\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .md\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .md\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .md\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .md\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .md\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .md\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .md\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .md\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .md\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .md\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .md\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .md\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .md\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .md\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .md\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .md\:grid-flow-row {
    grid-auto-flow: row
  }

  .md\:grid-flow-col {
    grid-auto-flow: column
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:grid-cols-none {
    grid-template-columns: none
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .md\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .md\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .md\:col-auto {
    grid-column: auto
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .md\:col-span-full {
    grid-column: 1 / -1
  }

  .md\:col-start-1 {
    grid-column-start: 1
  }

  .md\:col-start-2 {
    grid-column-start: 2
  }

  .md\:col-start-3 {
    grid-column-start: 3
  }

  .md\:col-start-4 {
    grid-column-start: 4
  }

  .md\:col-start-5 {
    grid-column-start: 5
  }

  .md\:col-start-6 {
    grid-column-start: 6
  }

  .md\:col-start-7 {
    grid-column-start: 7
  }

  .md\:col-start-8 {
    grid-column-start: 8
  }

  .md\:col-start-9 {
    grid-column-start: 9
  }

  .md\:col-start-10 {
    grid-column-start: 10
  }

  .md\:col-start-11 {
    grid-column-start: 11
  }

  .md\:col-start-12 {
    grid-column-start: 12
  }

  .md\:col-start-13 {
    grid-column-start: 13
  }

  .md\:col-start-auto {
    grid-column-start: auto
  }

  .md\:col-end-1 {
    grid-column-end: 1
  }

  .md\:col-end-2 {
    grid-column-end: 2
  }

  .md\:col-end-3 {
    grid-column-end: 3
  }

  .md\:col-end-4 {
    grid-column-end: 4
  }

  .md\:col-end-5 {
    grid-column-end: 5
  }

  .md\:col-end-6 {
    grid-column-end: 6
  }

  .md\:col-end-7 {
    grid-column-end: 7
  }

  .md\:col-end-8 {
    grid-column-end: 8
  }

  .md\:col-end-9 {
    grid-column-end: 9
  }

  .md\:col-end-10 {
    grid-column-end: 10
  }

  .md\:col-end-11 {
    grid-column-end: 11
  }

  .md\:col-end-12 {
    grid-column-end: 12
  }

  .md\:col-end-13 {
    grid-column-end: 13
  }

  .md\:col-end-auto {
    grid-column-end: auto
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .md\:grid-rows-none {
    grid-template-rows: none
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .md\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .md\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .md\:row-auto {
    grid-row: auto
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .md\:row-span-full {
    grid-row: 1 / -1
  }

  .md\:row-start-1 {
    grid-row-start: 1
  }

  .md\:row-start-2 {
    grid-row-start: 2
  }

  .md\:row-start-3 {
    grid-row-start: 3
  }

  .md\:row-start-4 {
    grid-row-start: 4
  }

  .md\:row-start-5 {
    grid-row-start: 5
  }

  .md\:row-start-6 {
    grid-row-start: 6
  }

  .md\:row-start-7 {
    grid-row-start: 7
  }

  .md\:row-start-auto {
    grid-row-start: auto
  }

  .md\:row-end-1 {
    grid-row-end: 1
  }

  .md\:row-end-2 {
    grid-row-end: 2
  }

  .md\:row-end-3 {
    grid-row-end: 3
  }

  .md\:row-end-4 {
    grid-row-end: 4
  }

  .md\:row-end-5 {
    grid-row-end: 5
  }

  .md\:row-end-6 {
    grid-row-end: 6
  }

  .md\:row-end-7 {
    grid-row-end: 7
  }

  .md\:row-end-auto {
    grid-row-end: auto
  }

  .md\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .md\:transform-none {
    transform: none
  }

  .md\:origin-center {
    transform-origin: center
  }

  .md\:origin-top {
    transform-origin: top
  }

  .md\:origin-top-right {
    transform-origin: top right
  }

  .md\:origin-right {
    transform-origin: right
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right
  }

  .md\:origin-bottom {
    transform-origin: bottom
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left
  }

  .md\:origin-left {
    transform-origin: left
  }

  .md\:origin-top-left {
    transform-origin: top left
  }

  .md\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:scale-x-0 {
    --transform-scale-x: 0
  }

  .md\:scale-x-50 {
    --transform-scale-x: .5
  }

  .md\:scale-x-75 {
    --transform-scale-x: .75
  }

  .md\:scale-x-90 {
    --transform-scale-x: .9
  }

  .md\:scale-x-95 {
    --transform-scale-x: .95
  }

  .md\:scale-x-100 {
    --transform-scale-x: 1
  }

  .md\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .md\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .md\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .md\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .md\:scale-y-0 {
    --transform-scale-y: 0
  }

  .md\:scale-y-50 {
    --transform-scale-y: .5
  }

  .md\:scale-y-75 {
    --transform-scale-y: .75
  }

  .md\:scale-y-90 {
    --transform-scale-y: .9
  }

  .md\:scale-y-95 {
    --transform-scale-y: .95
  }

  .md\:scale-y-100 {
    --transform-scale-y: 1
  }

  .md\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .md\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .md\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .md\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .md\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .md\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .md\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .md\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .md\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .md\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .md\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .md\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .md\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .md\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .md\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .md\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .md\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .md\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .md\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .md\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .md\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .md\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .md\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .md\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .md\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .md\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .md\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .md\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .md\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .md\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .md\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .md\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .md\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .md\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .md\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .md\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .md\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .md\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .md\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .md\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .md\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .md\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .md\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .md\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .md\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .md\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .md\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .md\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .md\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .md\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .md\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .md\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .md\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .md\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .md\:rotate-0 {
    --transform-rotate: 0
  }

  .md\:rotate-1 {
    --transform-rotate: 1deg
  }

  .md\:rotate-2 {
    --transform-rotate: 2deg
  }

  .md\:rotate-3 {
    --transform-rotate: 3deg
  }

  .md\:rotate-6 {
    --transform-rotate: 6deg
  }

  .md\:rotate-12 {
    --transform-rotate: 12deg
  }

  .md\:rotate-45 {
    --transform-rotate: 45deg
  }

  .md\:rotate-90 {
    --transform-rotate: 90deg
  }

  .md\:rotate-180 {
    --transform-rotate: 180deg
  }

  .md\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .md\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .md\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .md\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .md\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .md\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .md\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .md\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .md\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .md\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .md\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .md\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .md\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .md\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .md\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .md\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .md\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .md\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .md\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .md\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .md\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .md\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .md\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .md\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .md\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .md\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .md\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .md\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .md\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .md\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .md\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .md\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .md\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .md\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .md\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .md\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .md\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .md\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .md\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .md\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .md\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .md\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .md\:translate-x-0 {
    --transform-translate-x: 0
  }

  .md\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .md\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .md\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .md\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .md\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .md\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .md\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .md\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .md\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .md\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .md\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .md\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .md\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .md\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .md\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .md\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .md\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .md\:translate-x-px {
    --transform-translate-x: 1px
  }

  .md\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .md\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .md\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .md\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .md\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .md\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .md\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .md\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .md\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .md\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .md\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .md\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .md\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .md\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .md\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .md\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .md\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .md\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .md\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .md\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .md\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .md\:translate-x-full {
    --transform-translate-x: 100%
  }

  .md\:translate-y-0 {
    --transform-translate-y: 0
  }

  .md\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .md\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .md\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .md\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .md\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .md\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .md\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .md\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .md\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .md\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .md\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .md\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .md\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .md\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .md\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .md\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .md\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .md\:translate-y-px {
    --transform-translate-y: 1px
  }

  .md\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .md\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .md\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .md\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .md\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .md\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .md\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .md\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .md\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .md\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .md\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .md\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .md\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .md\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .md\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .md\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .md\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .md\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .md\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .md\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .md\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .md\:translate-y-full {
    --transform-translate-y: 100%
  }

  .md\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .md\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .md\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .md\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .md\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .md\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .md\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .md\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .md\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .md\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .md\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .md\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .md\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .md\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .md\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .md\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .md\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .md\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .md\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .md\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .md\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .md\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .md\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .md\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .md\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .md\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .md\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .md\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .md\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .md\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .md\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .md\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .md\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .md\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .md\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .md\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .md\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .md\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .md\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .md\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .md\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .md\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .md\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .md\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .md\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .md\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .md\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .md\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .md\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .md\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .md\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .md\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .md\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .md\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .md\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .md\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .md\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .md\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .md\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .md\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .md\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .md\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .md\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .md\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .md\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .md\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .md\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .md\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .md\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .md\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .md\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .md\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .md\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .md\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .md\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .md\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .md\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .md\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .md\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .md\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .md\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .md\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .md\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .md\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .md\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .md\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .md\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .md\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .md\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .md\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .md\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .md\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .md\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .md\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .md\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .md\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .md\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .md\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .md\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .md\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .md\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .md\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .md\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .md\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .md\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .md\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .md\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .md\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .md\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .md\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .md\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .md\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .md\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .md\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .md\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .md\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .md\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .md\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .md\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .md\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .md\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .md\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .md\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .md\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .md\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .md\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .md\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .md\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .md\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .md\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .md\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .md\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .md\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .md\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .md\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .md\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .md\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .md\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .md\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .md\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .md\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .md\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .md\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .md\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .md\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .md\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .md\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .md\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .md\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .md\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .md\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .md\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .md\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .md\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .md\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .md\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .md\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .md\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .md\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .md\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .md\:skew-x-0 {
    --transform-skew-x: 0
  }

  .md\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .md\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .md\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .md\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .md\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .md\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .md\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .md\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .md\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .md\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .md\:skew-y-0 {
    --transform-skew-y: 0
  }

  .md\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .md\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .md\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .md\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .md\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .md\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .md\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .md\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .md\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .md\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .md\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .md\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .md\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .md\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .md\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .md\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .md\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .md\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .md\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .md\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .md\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .md\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .md\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .md\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .md\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .md\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .md\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .md\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .md\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .md\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .md\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .md\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .md\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .md\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .md\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .md\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .md\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .md\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .md\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .md\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .md\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .md\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .md\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .md\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .md\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .md\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .md\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .md\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .md\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .md\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .md\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .md\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .md\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .md\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .md\:transition-none {
    transition-property: none
  }

  .md\:transition-all {
    transition-property: all
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .md\:transition-opacity {
    transition-property: opacity
  }

  .md\:transition-shadow {
    transition-property: box-shadow
  }

  .md\:transition-transform {
    transition-property: transform
  }

  .md\:ease-linear {
    transition-timing-function: linear
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .md\:duration-75 {
    transition-duration: 75ms
  }

  .md\:duration-100 {
    transition-duration: 100ms
  }

  .md\:duration-150 {
    transition-duration: 150ms
  }

  .md\:duration-200 {
    transition-duration: 200ms
  }

  .md\:duration-300 {
    transition-duration: 300ms
  }

  .md\:duration-500 {
    transition-duration: 500ms
  }

  .md\:duration-700 {
    transition-duration: 700ms
  }

  .md\:duration-1000 {
    transition-duration: 1000ms
  }

  .md\:delay-75 {
    transition-delay: 75ms
  }

  .md\:delay-100 {
    transition-delay: 100ms
  }

  .md\:delay-150 {
    transition-delay: 150ms
  }

  .md\:delay-200 {
    transition-delay: 200ms
  }

  .md\:delay-300 {
    transition-delay: 300ms
  }

  .md\:delay-500 {
    transition-delay: 500ms
  }

  .md\:delay-700 {
    transition-delay: 700ms
  }

  .md\:delay-1000 {
    transition-delay: 1000ms
  }

  .md\:animate-none {
    -webkit-animation: none;
            animation: none
  }

  .md\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .md\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .md\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .md\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .lg\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary)
  }

  .lg\:divide-primary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-shade)
  }

  .lg\:divide-primary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-tint)
  }

  .lg\:divide-secondary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary)
  }

  .lg\:divide-secondary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-shade)
  }

  .lg\:divide-secondary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-tint)
  }

  .lg\:divide-tertiary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary)
  }

  .lg\:divide-tertiary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-shade)
  }

  .lg\:divide-tertiary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-tint)
  }

  .lg\:divide-light > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light)
  }

  .lg\:divide-light-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-shade)
  }

  .lg\:divide-light-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-tint)
  }

  .lg\:divide-medium > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium)
  }

  .lg\:divide-medium-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-shade)
  }

  .lg\:divide-medium-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-tint)
  }

  .lg\:divide-dark > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark)
  }

  .lg\:divide-dark-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-shade)
  }

  .lg\:divide-dark-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-tint)
  }

  .lg\:divide-success > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success)
  }

  .lg\:divide-success-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-shade)
  }

  .lg\:divide-success-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-tint)
  }

  .lg\:divide-warning > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning)
  }

  .lg\:divide-warning-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-shade)
  }

  .lg\:divide-warning-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-tint)
  }

  .lg\:divide-danger > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger)
  }

  .lg\:divide-danger-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-shade)
  }

  .lg\:divide-danger-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-tint)
  }

  .lg\:divide-step-50 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-50)
  }

  .lg\:divide-step-100 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-100)
  }

  .lg\:divide-step-150 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-150)
  }

  .lg\:divide-step-200 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-200)
  }

  .lg\:divide-step-250 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-250)
  }

  .lg\:divide-step-300 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-300)
  }

  .lg\:divide-step-350 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-350)
  }

  .lg\:divide-step-400 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-400)
  }

  .lg\:divide-step-450 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-450)
  }

  .lg\:divide-step-500 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-500)
  }

  .lg\:divide-step-550 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-550)
  }

  .lg\:divide-step-600 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-600)
  }

  .lg\:divide-step-650 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-650)
  }

  .lg\:divide-step-700 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-700)
  }

  .lg\:divide-step-750 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-750)
  }

  .lg\:divide-step-800 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-800)
  }

  .lg\:divide-step-850 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-850)
  }

  .lg\:divide-step-900 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-900)
  }

  .lg\:divide-step-950 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-950)
  }

  .lg\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .lg\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .lg\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .lg\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .lg\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .lg\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .lg\:bg-fixed {
    background-attachment: fixed
  }

  .lg\:bg-local {
    background-attachment: local
  }

  .lg\:bg-scroll {
    background-attachment: scroll
  }

  .lg\:bg-clip-border {
    background-clip: border-box
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box
  }

  .lg\:bg-clip-content {
    background-clip: content-box
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .lg\:bg-primary {
    background-color: var(--ion-color-primary)
  }

  .lg\:bg-primary-shade {
    background-color: var(--ion-color-primary-shade)
  }

  .lg\:bg-primary-tint {
    background-color: var(--ion-color-primary-tint)
  }

  .lg\:bg-secondary {
    background-color: var(--ion-color-secondary)
  }

  .lg\:bg-secondary-shade {
    background-color: var(--ion-color-secondary-shade)
  }

  .lg\:bg-secondary-tint {
    background-color: var(--ion-color-secondary-tint)
  }

  .lg\:bg-tertiary {
    background-color: var(--ion-color-tertiary)
  }

  .lg\:bg-tertiary-shade {
    background-color: var(--ion-color-tertiary-shade)
  }

  .lg\:bg-tertiary-tint {
    background-color: var(--ion-color-tertiary-tint)
  }

  .lg\:bg-light {
    background-color: var(--ion-color-light)
  }

  .lg\:bg-light-shade {
    background-color: var(--ion-color-light-shade)
  }

  .lg\:bg-light-tint {
    background-color: var(--ion-color-light-tint)
  }

  .lg\:bg-medium {
    background-color: var(--ion-color-medium)
  }

  .lg\:bg-medium-shade {
    background-color: var(--ion-color-medium-shade)
  }

  .lg\:bg-medium-tint {
    background-color: var(--ion-color-medium-tint)
  }

  .lg\:bg-dark {
    background-color: var(--ion-color-dark)
  }

  .lg\:bg-dark-shade {
    background-color: var(--ion-color-dark-shade)
  }

  .lg\:bg-dark-tint {
    background-color: var(--ion-color-dark-tint)
  }

  .lg\:bg-success {
    background-color: var(--ion-color-success)
  }

  .lg\:bg-success-shade {
    background-color: var(--ion-color-success-shade)
  }

  .lg\:bg-success-tint {
    background-color: var(--ion-color-success-tint)
  }

  .lg\:bg-warning {
    background-color: var(--ion-color-warning)
  }

  .lg\:bg-warning-shade {
    background-color: var(--ion-color-warning-shade)
  }

  .lg\:bg-warning-tint {
    background-color: var(--ion-color-warning-tint)
  }

  .lg\:bg-danger {
    background-color: var(--ion-color-danger)
  }

  .lg\:bg-danger-shade {
    background-color: var(--ion-color-danger-shade)
  }

  .lg\:bg-danger-tint {
    background-color: var(--ion-color-danger-tint)
  }

  .lg\:bg-step-50 {
    background-color: var(--ion-color-step-50)
  }

  .lg\:bg-step-100 {
    background-color: var(--ion-color-step-100)
  }

  .lg\:bg-step-150 {
    background-color: var(--ion-color-step-150)
  }

  .lg\:bg-step-200 {
    background-color: var(--ion-color-step-200)
  }

  .lg\:bg-step-250 {
    background-color: var(--ion-color-step-250)
  }

  .lg\:bg-step-300 {
    background-color: var(--ion-color-step-300)
  }

  .lg\:bg-step-350 {
    background-color: var(--ion-color-step-350)
  }

  .lg\:bg-step-400 {
    background-color: var(--ion-color-step-400)
  }

  .lg\:bg-step-450 {
    background-color: var(--ion-color-step-450)
  }

  .lg\:bg-step-500 {
    background-color: var(--ion-color-step-500)
  }

  .lg\:bg-step-550 {
    background-color: var(--ion-color-step-550)
  }

  .lg\:bg-step-600 {
    background-color: var(--ion-color-step-600)
  }

  .lg\:bg-step-650 {
    background-color: var(--ion-color-step-650)
  }

  .lg\:bg-step-700 {
    background-color: var(--ion-color-step-700)
  }

  .lg\:bg-step-750 {
    background-color: var(--ion-color-step-750)
  }

  .lg\:bg-step-800 {
    background-color: var(--ion-color-step-800)
  }

  .lg\:bg-step-850 {
    background-color: var(--ion-color-step-850)
  }

  .lg\:bg-step-900 {
    background-color: var(--ion-color-step-900)
  }

  .lg\:bg-step-950 {
    background-color: var(--ion-color-step-950)
  }

  .lg\:hover\:bg-primary:hover {
    background-color: var(--ion-color-primary)
  }

  .lg\:hover\:bg-primary-shade:hover {
    background-color: var(--ion-color-primary-shade)
  }

  .lg\:hover\:bg-primary-tint:hover {
    background-color: var(--ion-color-primary-tint)
  }

  .lg\:hover\:bg-secondary:hover {
    background-color: var(--ion-color-secondary)
  }

  .lg\:hover\:bg-secondary-shade:hover {
    background-color: var(--ion-color-secondary-shade)
  }

  .lg\:hover\:bg-secondary-tint:hover {
    background-color: var(--ion-color-secondary-tint)
  }

  .lg\:hover\:bg-tertiary:hover {
    background-color: var(--ion-color-tertiary)
  }

  .lg\:hover\:bg-tertiary-shade:hover {
    background-color: var(--ion-color-tertiary-shade)
  }

  .lg\:hover\:bg-tertiary-tint:hover {
    background-color: var(--ion-color-tertiary-tint)
  }

  .lg\:hover\:bg-light:hover {
    background-color: var(--ion-color-light)
  }

  .lg\:hover\:bg-light-shade:hover {
    background-color: var(--ion-color-light-shade)
  }

  .lg\:hover\:bg-light-tint:hover {
    background-color: var(--ion-color-light-tint)
  }

  .lg\:hover\:bg-medium:hover {
    background-color: var(--ion-color-medium)
  }

  .lg\:hover\:bg-medium-shade:hover {
    background-color: var(--ion-color-medium-shade)
  }

  .lg\:hover\:bg-medium-tint:hover {
    background-color: var(--ion-color-medium-tint)
  }

  .lg\:hover\:bg-dark:hover {
    background-color: var(--ion-color-dark)
  }

  .lg\:hover\:bg-dark-shade:hover {
    background-color: var(--ion-color-dark-shade)
  }

  .lg\:hover\:bg-dark-tint:hover {
    background-color: var(--ion-color-dark-tint)
  }

  .lg\:hover\:bg-success:hover {
    background-color: var(--ion-color-success)
  }

  .lg\:hover\:bg-success-shade:hover {
    background-color: var(--ion-color-success-shade)
  }

  .lg\:hover\:bg-success-tint:hover {
    background-color: var(--ion-color-success-tint)
  }

  .lg\:hover\:bg-warning:hover {
    background-color: var(--ion-color-warning)
  }

  .lg\:hover\:bg-warning-shade:hover {
    background-color: var(--ion-color-warning-shade)
  }

  .lg\:hover\:bg-warning-tint:hover {
    background-color: var(--ion-color-warning-tint)
  }

  .lg\:hover\:bg-danger:hover {
    background-color: var(--ion-color-danger)
  }

  .lg\:hover\:bg-danger-shade:hover {
    background-color: var(--ion-color-danger-shade)
  }

  .lg\:hover\:bg-danger-tint:hover {
    background-color: var(--ion-color-danger-tint)
  }

  .lg\:hover\:bg-step-50:hover {
    background-color: var(--ion-color-step-50)
  }

  .lg\:hover\:bg-step-100:hover {
    background-color: var(--ion-color-step-100)
  }

  .lg\:hover\:bg-step-150:hover {
    background-color: var(--ion-color-step-150)
  }

  .lg\:hover\:bg-step-200:hover {
    background-color: var(--ion-color-step-200)
  }

  .lg\:hover\:bg-step-250:hover {
    background-color: var(--ion-color-step-250)
  }

  .lg\:hover\:bg-step-300:hover {
    background-color: var(--ion-color-step-300)
  }

  .lg\:hover\:bg-step-350:hover {
    background-color: var(--ion-color-step-350)
  }

  .lg\:hover\:bg-step-400:hover {
    background-color: var(--ion-color-step-400)
  }

  .lg\:hover\:bg-step-450:hover {
    background-color: var(--ion-color-step-450)
  }

  .lg\:hover\:bg-step-500:hover {
    background-color: var(--ion-color-step-500)
  }

  .lg\:hover\:bg-step-550:hover {
    background-color: var(--ion-color-step-550)
  }

  .lg\:hover\:bg-step-600:hover {
    background-color: var(--ion-color-step-600)
  }

  .lg\:hover\:bg-step-650:hover {
    background-color: var(--ion-color-step-650)
  }

  .lg\:hover\:bg-step-700:hover {
    background-color: var(--ion-color-step-700)
  }

  .lg\:hover\:bg-step-750:hover {
    background-color: var(--ion-color-step-750)
  }

  .lg\:hover\:bg-step-800:hover {
    background-color: var(--ion-color-step-800)
  }

  .lg\:hover\:bg-step-850:hover {
    background-color: var(--ion-color-step-850)
  }

  .lg\:hover\:bg-step-900:hover {
    background-color: var(--ion-color-step-900)
  }

  .lg\:hover\:bg-step-950:hover {
    background-color: var(--ion-color-step-950)
  }

  .lg\:focus\:bg-primary:focus {
    background-color: var(--ion-color-primary)
  }

  .lg\:focus\:bg-primary-shade:focus {
    background-color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:bg-primary-tint:focus {
    background-color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:bg-secondary:focus {
    background-color: var(--ion-color-secondary)
  }

  .lg\:focus\:bg-secondary-shade:focus {
    background-color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:bg-secondary-tint:focus {
    background-color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:bg-tertiary:focus {
    background-color: var(--ion-color-tertiary)
  }

  .lg\:focus\:bg-tertiary-shade:focus {
    background-color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:bg-tertiary-tint:focus {
    background-color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:bg-light:focus {
    background-color: var(--ion-color-light)
  }

  .lg\:focus\:bg-light-shade:focus {
    background-color: var(--ion-color-light-shade)
  }

  .lg\:focus\:bg-light-tint:focus {
    background-color: var(--ion-color-light-tint)
  }

  .lg\:focus\:bg-medium:focus {
    background-color: var(--ion-color-medium)
  }

  .lg\:focus\:bg-medium-shade:focus {
    background-color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:bg-medium-tint:focus {
    background-color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:bg-dark:focus {
    background-color: var(--ion-color-dark)
  }

  .lg\:focus\:bg-dark-shade:focus {
    background-color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:bg-dark-tint:focus {
    background-color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:bg-success:focus {
    background-color: var(--ion-color-success)
  }

  .lg\:focus\:bg-success-shade:focus {
    background-color: var(--ion-color-success-shade)
  }

  .lg\:focus\:bg-success-tint:focus {
    background-color: var(--ion-color-success-tint)
  }

  .lg\:focus\:bg-warning:focus {
    background-color: var(--ion-color-warning)
  }

  .lg\:focus\:bg-warning-shade:focus {
    background-color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:bg-warning-tint:focus {
    background-color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:bg-danger:focus {
    background-color: var(--ion-color-danger)
  }

  .lg\:focus\:bg-danger-shade:focus {
    background-color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:bg-danger-tint:focus {
    background-color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:bg-step-50:focus {
    background-color: var(--ion-color-step-50)
  }

  .lg\:focus\:bg-step-100:focus {
    background-color: var(--ion-color-step-100)
  }

  .lg\:focus\:bg-step-150:focus {
    background-color: var(--ion-color-step-150)
  }

  .lg\:focus\:bg-step-200:focus {
    background-color: var(--ion-color-step-200)
  }

  .lg\:focus\:bg-step-250:focus {
    background-color: var(--ion-color-step-250)
  }

  .lg\:focus\:bg-step-300:focus {
    background-color: var(--ion-color-step-300)
  }

  .lg\:focus\:bg-step-350:focus {
    background-color: var(--ion-color-step-350)
  }

  .lg\:focus\:bg-step-400:focus {
    background-color: var(--ion-color-step-400)
  }

  .lg\:focus\:bg-step-450:focus {
    background-color: var(--ion-color-step-450)
  }

  .lg\:focus\:bg-step-500:focus {
    background-color: var(--ion-color-step-500)
  }

  .lg\:focus\:bg-step-550:focus {
    background-color: var(--ion-color-step-550)
  }

  .lg\:focus\:bg-step-600:focus {
    background-color: var(--ion-color-step-600)
  }

  .lg\:focus\:bg-step-650:focus {
    background-color: var(--ion-color-step-650)
  }

  .lg\:focus\:bg-step-700:focus {
    background-color: var(--ion-color-step-700)
  }

  .lg\:focus\:bg-step-750:focus {
    background-color: var(--ion-color-step-750)
  }

  .lg\:focus\:bg-step-800:focus {
    background-color: var(--ion-color-step-800)
  }

  .lg\:focus\:bg-step-850:focus {
    background-color: var(--ion-color-step-850)
  }

  .lg\:focus\:bg-step-900:focus {
    background-color: var(--ion-color-step-900)
  }

  .lg\:focus\:bg-step-950:focus {
    background-color: var(--ion-color-step-950)
  }

  .lg\:bg-none {
    background-image: none
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .lg\:from-primary {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-primary-shade {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-primary-tint {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-secondary {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-secondary-shade {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-secondary-tint {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-tertiary {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-tertiary-shade {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-tertiary-tint {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-light {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-light-shade {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-light-tint {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-medium {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-medium-shade {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-medium-tint {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-dark {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-dark-shade {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-dark-tint {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-success {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-success-shade {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-success-tint {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-warning {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-warning-shade {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-warning-tint {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-danger {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-danger-shade {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-danger-tint {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-50 {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-100 {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-150 {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-200 {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-250 {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-300 {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-350 {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-400 {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-450 {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-500 {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-550 {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-600 {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-650 {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-700 {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-750 {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-800 {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-850 {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-900 {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:from-step-950 {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-primary {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-primary-shade {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-primary-tint {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-secondary {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-secondary-shade {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-secondary-tint {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-tertiary {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-tertiary-shade {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-tertiary-tint {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-light {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-light-shade {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-light-tint {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-medium {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-medium-shade {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-medium-tint {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-dark {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-dark-shade {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-dark-tint {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-success {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-success-shade {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-success-tint {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-warning {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-warning-shade {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-warning-tint {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-danger {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-danger-shade {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-danger-tint {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-50 {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-100 {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-150 {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-200 {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-250 {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-300 {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-350 {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-400 {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-450 {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-500 {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-550 {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-600 {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-650 {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-700 {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-750 {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-800 {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-850 {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-900 {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:via-step-950 {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:to-primary {
    --gradient-to-color: var(--ion-color-primary)
  }

  .lg\:to-primary-shade {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .lg\:to-primary-tint {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .lg\:to-secondary {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .lg\:to-secondary-shade {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .lg\:to-secondary-tint {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .lg\:to-tertiary {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .lg\:to-tertiary-shade {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .lg\:to-tertiary-tint {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .lg\:to-light {
    --gradient-to-color: var(--ion-color-light)
  }

  .lg\:to-light-shade {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .lg\:to-light-tint {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .lg\:to-medium {
    --gradient-to-color: var(--ion-color-medium)
  }

  .lg\:to-medium-shade {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .lg\:to-medium-tint {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .lg\:to-dark {
    --gradient-to-color: var(--ion-color-dark)
  }

  .lg\:to-dark-shade {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .lg\:to-dark-tint {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .lg\:to-success {
    --gradient-to-color: var(--ion-color-success)
  }

  .lg\:to-success-shade {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .lg\:to-success-tint {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .lg\:to-warning {
    --gradient-to-color: var(--ion-color-warning)
  }

  .lg\:to-warning-shade {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .lg\:to-warning-tint {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .lg\:to-danger {
    --gradient-to-color: var(--ion-color-danger)
  }

  .lg\:to-danger-shade {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .lg\:to-danger-tint {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .lg\:to-step-50 {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .lg\:to-step-100 {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .lg\:to-step-150 {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .lg\:to-step-200 {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .lg\:to-step-250 {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .lg\:to-step-300 {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .lg\:to-step-350 {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .lg\:to-step-400 {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .lg\:to-step-450 {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .lg\:to-step-500 {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .lg\:to-step-550 {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .lg\:to-step-600 {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .lg\:to-step-650 {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .lg\:to-step-700 {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .lg\:to-step-750 {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .lg\:to-step-800 {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .lg\:to-step-850 {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .lg\:to-step-900 {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .lg\:to-step-950 {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .lg\:hover\:from-primary:hover {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-primary-shade:hover {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-primary-tint:hover {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-secondary:hover {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-secondary-shade:hover {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-secondary-tint:hover {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-tertiary:hover {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-tertiary-shade:hover {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-tertiary-tint:hover {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-light:hover {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-light-shade:hover {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-light-tint:hover {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-medium:hover {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-medium-shade:hover {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-medium-tint:hover {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-dark:hover {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-dark-shade:hover {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-dark-tint:hover {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-success:hover {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-success-shade:hover {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-success-tint:hover {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-warning:hover {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-warning-shade:hover {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-warning-tint:hover {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-danger:hover {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-danger-shade:hover {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-danger-tint:hover {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-50:hover {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-100:hover {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-150:hover {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-200:hover {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-250:hover {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-300:hover {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-350:hover {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-400:hover {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-450:hover {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-500:hover {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-550:hover {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-600:hover {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-650:hover {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-700:hover {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-750:hover {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-800:hover {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-850:hover {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-900:hover {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:from-step-950:hover {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-primary:hover {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-primary-shade:hover {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-primary-tint:hover {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-secondary:hover {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-secondary-shade:hover {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-secondary-tint:hover {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-tertiary:hover {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-tertiary-shade:hover {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-tertiary-tint:hover {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-light:hover {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-light-shade:hover {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-light-tint:hover {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-medium:hover {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-medium-shade:hover {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-medium-tint:hover {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-dark:hover {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-dark-shade:hover {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-dark-tint:hover {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-success:hover {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-success-shade:hover {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-success-tint:hover {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-warning:hover {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-warning-shade:hover {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-warning-tint:hover {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-danger:hover {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-danger-shade:hover {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-danger-tint:hover {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-50:hover {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-100:hover {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-150:hover {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-200:hover {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-250:hover {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-300:hover {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-350:hover {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-400:hover {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-450:hover {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-500:hover {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-550:hover {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-600:hover {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-650:hover {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-700:hover {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-750:hover {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-800:hover {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-850:hover {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-900:hover {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:via-step-950:hover {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:hover\:to-primary:hover {
    --gradient-to-color: var(--ion-color-primary)
  }

  .lg\:hover\:to-primary-shade:hover {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .lg\:hover\:to-primary-tint:hover {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .lg\:hover\:to-secondary:hover {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .lg\:hover\:to-secondary-shade:hover {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .lg\:hover\:to-secondary-tint:hover {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .lg\:hover\:to-tertiary:hover {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .lg\:hover\:to-tertiary-shade:hover {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .lg\:hover\:to-tertiary-tint:hover {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .lg\:hover\:to-light:hover {
    --gradient-to-color: var(--ion-color-light)
  }

  .lg\:hover\:to-light-shade:hover {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .lg\:hover\:to-light-tint:hover {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .lg\:hover\:to-medium:hover {
    --gradient-to-color: var(--ion-color-medium)
  }

  .lg\:hover\:to-medium-shade:hover {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .lg\:hover\:to-medium-tint:hover {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .lg\:hover\:to-dark:hover {
    --gradient-to-color: var(--ion-color-dark)
  }

  .lg\:hover\:to-dark-shade:hover {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .lg\:hover\:to-dark-tint:hover {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .lg\:hover\:to-success:hover {
    --gradient-to-color: var(--ion-color-success)
  }

  .lg\:hover\:to-success-shade:hover {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .lg\:hover\:to-success-tint:hover {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .lg\:hover\:to-warning:hover {
    --gradient-to-color: var(--ion-color-warning)
  }

  .lg\:hover\:to-warning-shade:hover {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .lg\:hover\:to-warning-tint:hover {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .lg\:hover\:to-danger:hover {
    --gradient-to-color: var(--ion-color-danger)
  }

  .lg\:hover\:to-danger-shade:hover {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .lg\:hover\:to-danger-tint:hover {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .lg\:hover\:to-step-50:hover {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .lg\:hover\:to-step-100:hover {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .lg\:hover\:to-step-150:hover {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .lg\:hover\:to-step-200:hover {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .lg\:hover\:to-step-250:hover {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .lg\:hover\:to-step-300:hover {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .lg\:hover\:to-step-350:hover {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .lg\:hover\:to-step-400:hover {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .lg\:hover\:to-step-450:hover {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .lg\:hover\:to-step-500:hover {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .lg\:hover\:to-step-550:hover {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .lg\:hover\:to-step-600:hover {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .lg\:hover\:to-step-650:hover {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .lg\:hover\:to-step-700:hover {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .lg\:hover\:to-step-750:hover {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .lg\:hover\:to-step-800:hover {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .lg\:hover\:to-step-850:hover {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .lg\:hover\:to-step-900:hover {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .lg\:hover\:to-step-950:hover {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .lg\:focus\:from-primary:focus {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-primary-shade:focus {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-primary-tint:focus {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-secondary:focus {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-secondary-shade:focus {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-secondary-tint:focus {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-tertiary:focus {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-tertiary-shade:focus {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-tertiary-tint:focus {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-light:focus {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-light-shade:focus {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-light-tint:focus {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-medium:focus {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-medium-shade:focus {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-medium-tint:focus {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-dark:focus {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-dark-shade:focus {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-dark-tint:focus {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-success:focus {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-success-shade:focus {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-success-tint:focus {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-warning:focus {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-warning-shade:focus {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-warning-tint:focus {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-danger:focus {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-danger-shade:focus {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-danger-tint:focus {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-50:focus {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-100:focus {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-150:focus {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-200:focus {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-250:focus {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-300:focus {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-350:focus {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-400:focus {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-450:focus {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-500:focus {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-550:focus {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-600:focus {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-650:focus {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-700:focus {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-750:focus {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-800:focus {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-850:focus {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-900:focus {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:from-step-950:focus {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-primary:focus {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-primary-shade:focus {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-primary-tint:focus {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-secondary:focus {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-secondary-shade:focus {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-secondary-tint:focus {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-tertiary:focus {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-tertiary-shade:focus {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-tertiary-tint:focus {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-light:focus {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-light-shade:focus {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-light-tint:focus {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-medium:focus {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-medium-shade:focus {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-medium-tint:focus {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-dark:focus {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-dark-shade:focus {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-dark-tint:focus {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-success:focus {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-success-shade:focus {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-success-tint:focus {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-warning:focus {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-warning-shade:focus {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-warning-tint:focus {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-danger:focus {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-danger-shade:focus {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-danger-tint:focus {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-50:focus {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-100:focus {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-150:focus {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-200:focus {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-250:focus {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-300:focus {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-350:focus {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-400:focus {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-450:focus {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-500:focus {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-550:focus {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-600:focus {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-650:focus {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-700:focus {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-750:focus {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-800:focus {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-850:focus {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-900:focus {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:via-step-950:focus {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .lg\:focus\:to-primary:focus {
    --gradient-to-color: var(--ion-color-primary)
  }

  .lg\:focus\:to-primary-shade:focus {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:to-primary-tint:focus {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:to-secondary:focus {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .lg\:focus\:to-secondary-shade:focus {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:to-secondary-tint:focus {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:to-tertiary:focus {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .lg\:focus\:to-tertiary-shade:focus {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:to-tertiary-tint:focus {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:to-light:focus {
    --gradient-to-color: var(--ion-color-light)
  }

  .lg\:focus\:to-light-shade:focus {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .lg\:focus\:to-light-tint:focus {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .lg\:focus\:to-medium:focus {
    --gradient-to-color: var(--ion-color-medium)
  }

  .lg\:focus\:to-medium-shade:focus {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:to-medium-tint:focus {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:to-dark:focus {
    --gradient-to-color: var(--ion-color-dark)
  }

  .lg\:focus\:to-dark-shade:focus {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:to-dark-tint:focus {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:to-success:focus {
    --gradient-to-color: var(--ion-color-success)
  }

  .lg\:focus\:to-success-shade:focus {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .lg\:focus\:to-success-tint:focus {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .lg\:focus\:to-warning:focus {
    --gradient-to-color: var(--ion-color-warning)
  }

  .lg\:focus\:to-warning-shade:focus {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:to-warning-tint:focus {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:to-danger:focus {
    --gradient-to-color: var(--ion-color-danger)
  }

  .lg\:focus\:to-danger-shade:focus {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:to-danger-tint:focus {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:to-step-50:focus {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .lg\:focus\:to-step-100:focus {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .lg\:focus\:to-step-150:focus {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .lg\:focus\:to-step-200:focus {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .lg\:focus\:to-step-250:focus {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .lg\:focus\:to-step-300:focus {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .lg\:focus\:to-step-350:focus {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .lg\:focus\:to-step-400:focus {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .lg\:focus\:to-step-450:focus {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .lg\:focus\:to-step-500:focus {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .lg\:focus\:to-step-550:focus {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .lg\:focus\:to-step-600:focus {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .lg\:focus\:to-step-650:focus {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .lg\:focus\:to-step-700:focus {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .lg\:focus\:to-step-750:focus {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .lg\:focus\:to-step-800:focus {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .lg\:focus\:to-step-850:focus {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .lg\:focus\:to-step-900:focus {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .lg\:focus\:to-step-950:focus {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .lg\:bg-bottom {
    background-position: bottom
  }

  .lg\:bg-center {
    background-position: center
  }

  .lg\:bg-left {
    background-position: left
  }

  .lg\:bg-left-bottom {
    background-position: left bottom
  }

  .lg\:bg-left-top {
    background-position: left top
  }

  .lg\:bg-right {
    background-position: right
  }

  .lg\:bg-right-bottom {
    background-position: right bottom
  }

  .lg\:bg-right-top {
    background-position: right top
  }

  .lg\:bg-top {
    background-position: top
  }

  .lg\:bg-repeat {
    background-repeat: repeat
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .lg\:bg-repeat-round {
    background-repeat: round
  }

  .lg\:bg-repeat-space {
    background-repeat: space
  }

  .lg\:bg-auto {
    background-size: auto
  }

  .lg\:bg-cover {
    background-size: cover
  }

  .lg\:bg-contain {
    background-size: contain
  }

  .lg\:border-collapse {
    border-collapse: collapse
  }

  .lg\:border-separate {
    border-collapse: separate
  }

  .lg\:border-primary {
    border-color: var(--ion-color-primary)
  }

  .lg\:border-primary-shade {
    border-color: var(--ion-color-primary-shade)
  }

  .lg\:border-primary-tint {
    border-color: var(--ion-color-primary-tint)
  }

  .lg\:border-secondary {
    border-color: var(--ion-color-secondary)
  }

  .lg\:border-secondary-shade {
    border-color: var(--ion-color-secondary-shade)
  }

  .lg\:border-secondary-tint {
    border-color: var(--ion-color-secondary-tint)
  }

  .lg\:border-tertiary {
    border-color: var(--ion-color-tertiary)
  }

  .lg\:border-tertiary-shade {
    border-color: var(--ion-color-tertiary-shade)
  }

  .lg\:border-tertiary-tint {
    border-color: var(--ion-color-tertiary-tint)
  }

  .lg\:border-light {
    border-color: var(--ion-color-light)
  }

  .lg\:border-light-shade {
    border-color: var(--ion-color-light-shade)
  }

  .lg\:border-light-tint {
    border-color: var(--ion-color-light-tint)
  }

  .lg\:border-medium {
    border-color: var(--ion-color-medium)
  }

  .lg\:border-medium-shade {
    border-color: var(--ion-color-medium-shade)
  }

  .lg\:border-medium-tint {
    border-color: var(--ion-color-medium-tint)
  }

  .lg\:border-dark {
    border-color: var(--ion-color-dark)
  }

  .lg\:border-dark-shade {
    border-color: var(--ion-color-dark-shade)
  }

  .lg\:border-dark-tint {
    border-color: var(--ion-color-dark-tint)
  }

  .lg\:border-success {
    border-color: var(--ion-color-success)
  }

  .lg\:border-success-shade {
    border-color: var(--ion-color-success-shade)
  }

  .lg\:border-success-tint {
    border-color: var(--ion-color-success-tint)
  }

  .lg\:border-warning {
    border-color: var(--ion-color-warning)
  }

  .lg\:border-warning-shade {
    border-color: var(--ion-color-warning-shade)
  }

  .lg\:border-warning-tint {
    border-color: var(--ion-color-warning-tint)
  }

  .lg\:border-danger {
    border-color: var(--ion-color-danger)
  }

  .lg\:border-danger-shade {
    border-color: var(--ion-color-danger-shade)
  }

  .lg\:border-danger-tint {
    border-color: var(--ion-color-danger-tint)
  }

  .lg\:border-step-50 {
    border-color: var(--ion-color-step-50)
  }

  .lg\:border-step-100 {
    border-color: var(--ion-color-step-100)
  }

  .lg\:border-step-150 {
    border-color: var(--ion-color-step-150)
  }

  .lg\:border-step-200 {
    border-color: var(--ion-color-step-200)
  }

  .lg\:border-step-250 {
    border-color: var(--ion-color-step-250)
  }

  .lg\:border-step-300 {
    border-color: var(--ion-color-step-300)
  }

  .lg\:border-step-350 {
    border-color: var(--ion-color-step-350)
  }

  .lg\:border-step-400 {
    border-color: var(--ion-color-step-400)
  }

  .lg\:border-step-450 {
    border-color: var(--ion-color-step-450)
  }

  .lg\:border-step-500 {
    border-color: var(--ion-color-step-500)
  }

  .lg\:border-step-550 {
    border-color: var(--ion-color-step-550)
  }

  .lg\:border-step-600 {
    border-color: var(--ion-color-step-600)
  }

  .lg\:border-step-650 {
    border-color: var(--ion-color-step-650)
  }

  .lg\:border-step-700 {
    border-color: var(--ion-color-step-700)
  }

  .lg\:border-step-750 {
    border-color: var(--ion-color-step-750)
  }

  .lg\:border-step-800 {
    border-color: var(--ion-color-step-800)
  }

  .lg\:border-step-850 {
    border-color: var(--ion-color-step-850)
  }

  .lg\:border-step-900 {
    border-color: var(--ion-color-step-900)
  }

  .lg\:border-step-950 {
    border-color: var(--ion-color-step-950)
  }

  .lg\:hover\:border-primary:hover {
    border-color: var(--ion-color-primary)
  }

  .lg\:hover\:border-primary-shade:hover {
    border-color: var(--ion-color-primary-shade)
  }

  .lg\:hover\:border-primary-tint:hover {
    border-color: var(--ion-color-primary-tint)
  }

  .lg\:hover\:border-secondary:hover {
    border-color: var(--ion-color-secondary)
  }

  .lg\:hover\:border-secondary-shade:hover {
    border-color: var(--ion-color-secondary-shade)
  }

  .lg\:hover\:border-secondary-tint:hover {
    border-color: var(--ion-color-secondary-tint)
  }

  .lg\:hover\:border-tertiary:hover {
    border-color: var(--ion-color-tertiary)
  }

  .lg\:hover\:border-tertiary-shade:hover {
    border-color: var(--ion-color-tertiary-shade)
  }

  .lg\:hover\:border-tertiary-tint:hover {
    border-color: var(--ion-color-tertiary-tint)
  }

  .lg\:hover\:border-light:hover {
    border-color: var(--ion-color-light)
  }

  .lg\:hover\:border-light-shade:hover {
    border-color: var(--ion-color-light-shade)
  }

  .lg\:hover\:border-light-tint:hover {
    border-color: var(--ion-color-light-tint)
  }

  .lg\:hover\:border-medium:hover {
    border-color: var(--ion-color-medium)
  }

  .lg\:hover\:border-medium-shade:hover {
    border-color: var(--ion-color-medium-shade)
  }

  .lg\:hover\:border-medium-tint:hover {
    border-color: var(--ion-color-medium-tint)
  }

  .lg\:hover\:border-dark:hover {
    border-color: var(--ion-color-dark)
  }

  .lg\:hover\:border-dark-shade:hover {
    border-color: var(--ion-color-dark-shade)
  }

  .lg\:hover\:border-dark-tint:hover {
    border-color: var(--ion-color-dark-tint)
  }

  .lg\:hover\:border-success:hover {
    border-color: var(--ion-color-success)
  }

  .lg\:hover\:border-success-shade:hover {
    border-color: var(--ion-color-success-shade)
  }

  .lg\:hover\:border-success-tint:hover {
    border-color: var(--ion-color-success-tint)
  }

  .lg\:hover\:border-warning:hover {
    border-color: var(--ion-color-warning)
  }

  .lg\:hover\:border-warning-shade:hover {
    border-color: var(--ion-color-warning-shade)
  }

  .lg\:hover\:border-warning-tint:hover {
    border-color: var(--ion-color-warning-tint)
  }

  .lg\:hover\:border-danger:hover {
    border-color: var(--ion-color-danger)
  }

  .lg\:hover\:border-danger-shade:hover {
    border-color: var(--ion-color-danger-shade)
  }

  .lg\:hover\:border-danger-tint:hover {
    border-color: var(--ion-color-danger-tint)
  }

  .lg\:hover\:border-step-50:hover {
    border-color: var(--ion-color-step-50)
  }

  .lg\:hover\:border-step-100:hover {
    border-color: var(--ion-color-step-100)
  }

  .lg\:hover\:border-step-150:hover {
    border-color: var(--ion-color-step-150)
  }

  .lg\:hover\:border-step-200:hover {
    border-color: var(--ion-color-step-200)
  }

  .lg\:hover\:border-step-250:hover {
    border-color: var(--ion-color-step-250)
  }

  .lg\:hover\:border-step-300:hover {
    border-color: var(--ion-color-step-300)
  }

  .lg\:hover\:border-step-350:hover {
    border-color: var(--ion-color-step-350)
  }

  .lg\:hover\:border-step-400:hover {
    border-color: var(--ion-color-step-400)
  }

  .lg\:hover\:border-step-450:hover {
    border-color: var(--ion-color-step-450)
  }

  .lg\:hover\:border-step-500:hover {
    border-color: var(--ion-color-step-500)
  }

  .lg\:hover\:border-step-550:hover {
    border-color: var(--ion-color-step-550)
  }

  .lg\:hover\:border-step-600:hover {
    border-color: var(--ion-color-step-600)
  }

  .lg\:hover\:border-step-650:hover {
    border-color: var(--ion-color-step-650)
  }

  .lg\:hover\:border-step-700:hover {
    border-color: var(--ion-color-step-700)
  }

  .lg\:hover\:border-step-750:hover {
    border-color: var(--ion-color-step-750)
  }

  .lg\:hover\:border-step-800:hover {
    border-color: var(--ion-color-step-800)
  }

  .lg\:hover\:border-step-850:hover {
    border-color: var(--ion-color-step-850)
  }

  .lg\:hover\:border-step-900:hover {
    border-color: var(--ion-color-step-900)
  }

  .lg\:hover\:border-step-950:hover {
    border-color: var(--ion-color-step-950)
  }

  .lg\:focus\:border-primary:focus {
    border-color: var(--ion-color-primary)
  }

  .lg\:focus\:border-primary-shade:focus {
    border-color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:border-primary-tint:focus {
    border-color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:border-secondary:focus {
    border-color: var(--ion-color-secondary)
  }

  .lg\:focus\:border-secondary-shade:focus {
    border-color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:border-secondary-tint:focus {
    border-color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:border-tertiary:focus {
    border-color: var(--ion-color-tertiary)
  }

  .lg\:focus\:border-tertiary-shade:focus {
    border-color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:border-tertiary-tint:focus {
    border-color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:border-light:focus {
    border-color: var(--ion-color-light)
  }

  .lg\:focus\:border-light-shade:focus {
    border-color: var(--ion-color-light-shade)
  }

  .lg\:focus\:border-light-tint:focus {
    border-color: var(--ion-color-light-tint)
  }

  .lg\:focus\:border-medium:focus {
    border-color: var(--ion-color-medium)
  }

  .lg\:focus\:border-medium-shade:focus {
    border-color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:border-medium-tint:focus {
    border-color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:border-dark:focus {
    border-color: var(--ion-color-dark)
  }

  .lg\:focus\:border-dark-shade:focus {
    border-color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:border-dark-tint:focus {
    border-color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:border-success:focus {
    border-color: var(--ion-color-success)
  }

  .lg\:focus\:border-success-shade:focus {
    border-color: var(--ion-color-success-shade)
  }

  .lg\:focus\:border-success-tint:focus {
    border-color: var(--ion-color-success-tint)
  }

  .lg\:focus\:border-warning:focus {
    border-color: var(--ion-color-warning)
  }

  .lg\:focus\:border-warning-shade:focus {
    border-color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:border-warning-tint:focus {
    border-color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:border-danger:focus {
    border-color: var(--ion-color-danger)
  }

  .lg\:focus\:border-danger-shade:focus {
    border-color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:border-danger-tint:focus {
    border-color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:border-step-50:focus {
    border-color: var(--ion-color-step-50)
  }

  .lg\:focus\:border-step-100:focus {
    border-color: var(--ion-color-step-100)
  }

  .lg\:focus\:border-step-150:focus {
    border-color: var(--ion-color-step-150)
  }

  .lg\:focus\:border-step-200:focus {
    border-color: var(--ion-color-step-200)
  }

  .lg\:focus\:border-step-250:focus {
    border-color: var(--ion-color-step-250)
  }

  .lg\:focus\:border-step-300:focus {
    border-color: var(--ion-color-step-300)
  }

  .lg\:focus\:border-step-350:focus {
    border-color: var(--ion-color-step-350)
  }

  .lg\:focus\:border-step-400:focus {
    border-color: var(--ion-color-step-400)
  }

  .lg\:focus\:border-step-450:focus {
    border-color: var(--ion-color-step-450)
  }

  .lg\:focus\:border-step-500:focus {
    border-color: var(--ion-color-step-500)
  }

  .lg\:focus\:border-step-550:focus {
    border-color: var(--ion-color-step-550)
  }

  .lg\:focus\:border-step-600:focus {
    border-color: var(--ion-color-step-600)
  }

  .lg\:focus\:border-step-650:focus {
    border-color: var(--ion-color-step-650)
  }

  .lg\:focus\:border-step-700:focus {
    border-color: var(--ion-color-step-700)
  }

  .lg\:focus\:border-step-750:focus {
    border-color: var(--ion-color-step-750)
  }

  .lg\:focus\:border-step-800:focus {
    border-color: var(--ion-color-step-800)
  }

  .lg\:focus\:border-step-850:focus {
    border-color: var(--ion-color-step-850)
  }

  .lg\:focus\:border-step-900:focus {
    border-color: var(--ion-color-step-900)
  }

  .lg\:focus\:border-step-950:focus {
    border-color: var(--ion-color-step-950)
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0
  }

  .lg\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .lg\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .lg\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .lg\:rounded-none {
    border-radius: 0
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem
  }

  .lg\:rounded {
    border-radius: 0.25rem
  }

  .lg\:rounded-md {
    border-radius: 0.375rem
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem
  }

  .lg\:rounded-xl {
    border-radius: 0.75rem
  }

  .lg\:rounded-2xl {
    border-radius: 1rem
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem
  }

  .lg\:rounded-full {
    border-radius: 9999px
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .lg\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .lg\:border-solid {
    border-style: solid
  }

  .lg\:border-dashed {
    border-style: dashed
  }

  .lg\:border-dotted {
    border-style: dotted
  }

  .lg\:border-double {
    border-style: double
  }

  .lg\:border-none {
    border-style: none
  }

  .lg\:border-0 {
    border-width: 0
  }

  .lg\:border-2 {
    border-width: 2px
  }

  .lg\:border-4 {
    border-width: 4px
  }

  .lg\:border-8 {
    border-width: 8px
  }

  .lg\:border {
    border-width: 1px
  }

  .lg\:border-t-0 {
    border-top-width: 0
  }

  .lg\:border-r-0 {
    border-right-width: 0
  }

  .lg\:border-b-0 {
    border-bottom-width: 0
  }

  .lg\:border-l-0 {
    border-left-width: 0
  }

  .lg\:border-t-2 {
    border-top-width: 2px
  }

  .lg\:border-r-2 {
    border-right-width: 2px
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px
  }

  .lg\:border-l-2 {
    border-left-width: 2px
  }

  .lg\:border-t-4 {
    border-top-width: 4px
  }

  .lg\:border-r-4 {
    border-right-width: 4px
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px
  }

  .lg\:border-l-4 {
    border-left-width: 4px
  }

  .lg\:border-t-8 {
    border-top-width: 8px
  }

  .lg\:border-r-8 {
    border-right-width: 8px
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px
  }

  .lg\:border-l-8 {
    border-left-width: 8px
  }

  .lg\:border-t {
    border-top-width: 1px
  }

  .lg\:border-r {
    border-right-width: 1px
  }

  .lg\:border-b {
    border-bottom-width: 1px
  }

  .lg\:border-l {
    border-left-width: 1px
  }

  .lg\:box-border {
    box-sizing: border-box
  }

  .lg\:box-content {
    box-sizing: content-box
  }

  .lg\:cursor-auto {
    cursor: auto
  }

  .lg\:cursor-default {
    cursor: default
  }

  .lg\:cursor-pointer {
    cursor: pointer
  }

  .lg\:cursor-wait {
    cursor: wait
  }

  .lg\:cursor-text {
    cursor: text
  }

  .lg\:cursor-move {
    cursor: move
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:inline {
    display: inline
  }

  .lg\:flex {
    display: flex
  }

  .lg\:inline-flex {
    display: inline-flex
  }

  .lg\:table {
    display: table
  }

  .lg\:table-caption {
    display: table-caption
  }

  .lg\:table-cell {
    display: table-cell
  }

  .lg\:table-column {
    display: table-column
  }

  .lg\:table-column-group {
    display: table-column-group
  }

  .lg\:table-footer-group {
    display: table-footer-group
  }

  .lg\:table-header-group {
    display: table-header-group
  }

  .lg\:table-row-group {
    display: table-row-group
  }

  .lg\:table-row {
    display: table-row
  }

  .lg\:flow-root {
    display: flow-root
  }

  .lg\:grid {
    display: grid
  }

  .lg\:inline-grid {
    display: inline-grid
  }

  .lg\:contents {
    display: contents
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .lg\:flex-col {
    flex-direction: column
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .lg\:flex-wrap {
    flex-wrap: wrap
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .lg\:place-items-auto {
    place-items: auto
  }

  .lg\:place-items-start {
    place-items: start
  }

  .lg\:place-items-end {
    place-items: end
  }

  .lg\:place-items-center {
    place-items: center
  }

  .lg\:place-items-stretch {
    place-items: stretch
  }

  .lg\:place-content-center {
    place-content: center
  }

  .lg\:place-content-start {
    place-content: start
  }

  .lg\:place-content-end {
    place-content: end
  }

  .lg\:place-content-between {
    place-content: space-between
  }

  .lg\:place-content-around {
    place-content: space-around
  }

  .lg\:place-content-evenly {
    place-content: space-evenly
  }

  .lg\:place-content-stretch {
    place-content: stretch
  }

  .lg\:place-self-auto {
    place-self: auto
  }

  .lg\:place-self-start {
    place-self: start
  }

  .lg\:place-self-end {
    place-self: end
  }

  .lg\:place-self-center {
    place-self: center
  }

  .lg\:place-self-stretch {
    place-self: stretch
  }

  .lg\:items-start {
    align-items: flex-start
  }

  .lg\:items-end {
    align-items: flex-end
  }

  .lg\:items-center {
    align-items: center
  }

  .lg\:items-baseline {
    align-items: baseline
  }

  .lg\:items-stretch {
    align-items: stretch
  }

  .lg\:content-center {
    align-content: center
  }

  .lg\:content-start {
    align-content: flex-start
  }

  .lg\:content-end {
    align-content: flex-end
  }

  .lg\:content-between {
    align-content: space-between
  }

  .lg\:content-around {
    align-content: space-around
  }

  .lg\:content-evenly {
    align-content: space-evenly
  }

  .lg\:self-auto {
    align-self: auto
  }

  .lg\:self-start {
    align-self: flex-start
  }

  .lg\:self-end {
    align-self: flex-end
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:self-stretch {
    align-self: stretch
  }

  .lg\:justify-items-auto {
    justify-items: auto
  }

  .lg\:justify-items-start {
    justify-items: start
  }

  .lg\:justify-items-end {
    justify-items: end
  }

  .lg\:justify-items-center {
    justify-items: center
  }

  .lg\:justify-items-stretch {
    justify-items: stretch
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-end {
    justify-content: flex-end
  }

  .lg\:justify-center {
    justify-content: center
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:justify-around {
    justify-content: space-around
  }

  .lg\:justify-evenly {
    justify-content: space-evenly
  }

  .lg\:justify-self-auto {
    justify-self: auto
  }

  .lg\:justify-self-start {
    justify-self: start
  }

  .lg\:justify-self-end {
    justify-self: end
  }

  .lg\:justify-self-center {
    justify-self: center
  }

  .lg\:justify-self-stretch {
    justify-self: stretch
  }

  .lg\:flex-1 {
    flex: 1 1 0%
  }

  .lg\:flex-auto {
    flex: 1 1 auto
  }

  .lg\:flex-initial {
    flex: 0 1 auto
  }

  .lg\:flex-none {
    flex: none
  }

  .lg\:flex-grow-0 {
    flex-grow: 0
  }

  .lg\:flex-grow {
    flex-grow: 1
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:flex-shrink {
    flex-shrink: 1
  }

  .lg\:order-1 {
    order: 1
  }

  .lg\:order-2 {
    order: 2
  }

  .lg\:order-3 {
    order: 3
  }

  .lg\:order-4 {
    order: 4
  }

  .lg\:order-5 {
    order: 5
  }

  .lg\:order-6 {
    order: 6
  }

  .lg\:order-7 {
    order: 7
  }

  .lg\:order-8 {
    order: 8
  }

  .lg\:order-9 {
    order: 9
  }

  .lg\:order-10 {
    order: 10
  }

  .lg\:order-11 {
    order: 11
  }

  .lg\:order-12 {
    order: 12
  }

  .lg\:order-first {
    order: -9999
  }

  .lg\:order-last {
    order: 9999
  }

  .lg\:order-none {
    order: 0
  }

  .lg\:float-right {
    float: right
  }

  .lg\:float-left {
    float: left
  }

  .lg\:float-none {
    float: none
  }

  .lg\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .lg\:clear-left {
    clear: left
  }

  .lg\:clear-right {
    clear: right
  }

  .lg\:clear-both {
    clear: both
  }

  .lg\:clear-none {
    clear: none
  }

  .lg\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .lg\:font-hairline {
    font-weight: 100
  }

  .lg\:font-thin {
    font-weight: 200
  }

  .lg\:font-light {
    font-weight: 300
  }

  .lg\:font-normal {
    font-weight: 400
  }

  .lg\:font-medium {
    font-weight: 500
  }

  .lg\:font-semibold {
    font-weight: 600
  }

  .lg\:font-bold {
    font-weight: 700
  }

  .lg\:font-extrabold {
    font-weight: 800
  }

  .lg\:font-black {
    font-weight: 900
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900
  }

  .lg\:h-0 {
    height: 0
  }

  .lg\:h-1 {
    height: 0.25rem
  }

  .lg\:h-2 {
    height: 0.5rem
  }

  .lg\:h-3 {
    height: 0.75rem
  }

  .lg\:h-4 {
    height: 1rem
  }

  .lg\:h-5 {
    height: 1.25rem
  }

  .lg\:h-6 {
    height: 1.5rem
  }

  .lg\:h-8 {
    height: 2rem
  }

  .lg\:h-10 {
    height: 2.5rem
  }

  .lg\:h-12 {
    height: 3rem
  }

  .lg\:h-16 {
    height: 4rem
  }

  .lg\:h-20 {
    height: 5rem
  }

  .lg\:h-24 {
    height: 6rem
  }

  .lg\:h-32 {
    height: 8rem
  }

  .lg\:h-40 {
    height: 10rem
  }

  .lg\:h-48 {
    height: 12rem
  }

  .lg\:h-56 {
    height: 14rem
  }

  .lg\:h-64 {
    height: 16rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:h-px {
    height: 1px
  }

  .lg\:h-full {
    height: 100%
  }

  .lg\:h-screen {
    height: 100vh
  }

  .lg\:text-xs {
    font-size: 0.75rem
  }

  .lg\:text-sm {
    font-size: 0.875rem
  }

  .lg\:text-base {
    font-size: 1rem
  }

  .lg\:text-lg {
    font-size: 1.125rem
  }

  .lg\:text-xl {
    font-size: 1.25rem
  }

  .lg\:text-2xl {
    font-size: 1.5rem
  }

  .lg\:text-3xl {
    font-size: 1.875rem
  }

  .lg\:text-4xl {
    font-size: 2.25rem
  }

  .lg\:text-5xl {
    font-size: 3rem
  }

  .lg\:text-6xl {
    font-size: 4rem
  }

  .lg\:leading-3 {
    line-height: .75rem
  }

  .lg\:leading-4 {
    line-height: 1rem
  }

  .lg\:leading-5 {
    line-height: 1.25rem
  }

  .lg\:leading-6 {
    line-height: 1.5rem
  }

  .lg\:leading-7 {
    line-height: 1.75rem
  }

  .lg\:leading-8 {
    line-height: 2rem
  }

  .lg\:leading-9 {
    line-height: 2.25rem
  }

  .lg\:leading-10 {
    line-height: 2.5rem
  }

  .lg\:leading-none {
    line-height: 1
  }

  .lg\:leading-tight {
    line-height: 1.25
  }

  .lg\:leading-snug {
    line-height: 1.375
  }

  .lg\:leading-normal {
    line-height: 1.5
  }

  .lg\:leading-relaxed {
    line-height: 1.625
  }

  .lg\:leading-loose {
    line-height: 2
  }

  .lg\:list-inside {
    list-style-position: inside
  }

  .lg\:list-outside {
    list-style-position: outside
  }

  .lg\:list-none {
    list-style-type: none
  }

  .lg\:list-disc {
    list-style-type: disc
  }

  .lg\:list-decimal {
    list-style-type: decimal
  }

  .lg\:m-0 {
    margin: 0
  }

  .lg\:m-1 {
    margin: 0.25rem
  }

  .lg\:m-2 {
    margin: 0.5rem
  }

  .lg\:m-3 {
    margin: 0.75rem
  }

  .lg\:m-4 {
    margin: 1rem
  }

  .lg\:m-5 {
    margin: 1.25rem
  }

  .lg\:m-6 {
    margin: 1.5rem
  }

  .lg\:m-8 {
    margin: 2rem
  }

  .lg\:m-10 {
    margin: 2.5rem
  }

  .lg\:m-12 {
    margin: 3rem
  }

  .lg\:m-16 {
    margin: 4rem
  }

  .lg\:m-20 {
    margin: 5rem
  }

  .lg\:m-24 {
    margin: 6rem
  }

  .lg\:m-32 {
    margin: 8rem
  }

  .lg\:m-40 {
    margin: 10rem
  }

  .lg\:m-48 {
    margin: 12rem
  }

  .lg\:m-56 {
    margin: 14rem
  }

  .lg\:m-64 {
    margin: 16rem
  }

  .lg\:m-auto {
    margin: auto
  }

  .lg\:m-px {
    margin: 1px
  }

  .lg\:-m-1 {
    margin: -0.25rem
  }

  .lg\:-m-2 {
    margin: -0.5rem
  }

  .lg\:-m-3 {
    margin: -0.75rem
  }

  .lg\:-m-4 {
    margin: -1rem
  }

  .lg\:-m-5 {
    margin: -1.25rem
  }

  .lg\:-m-6 {
    margin: -1.5rem
  }

  .lg\:-m-8 {
    margin: -2rem
  }

  .lg\:-m-10 {
    margin: -2.5rem
  }

  .lg\:-m-12 {
    margin: -3rem
  }

  .lg\:-m-16 {
    margin: -4rem
  }

  .lg\:-m-20 {
    margin: -5rem
  }

  .lg\:-m-24 {
    margin: -6rem
  }

  .lg\:-m-32 {
    margin: -8rem
  }

  .lg\:-m-40 {
    margin: -10rem
  }

  .lg\:-m-48 {
    margin: -12rem
  }

  .lg\:-m-56 {
    margin: -14rem
  }

  .lg\:-m-64 {
    margin: -16rem
  }

  .lg\:-m-px {
    margin: -1px
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:mr-0 {
    margin-right: 0
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:ml-0 {
    margin-left: 0
  }

  .lg\:mt-1 {
    margin-top: 0.25rem
  }

  .lg\:mr-1 {
    margin-right: 0.25rem
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem
  }

  .lg\:ml-1 {
    margin-left: 0.25rem
  }

  .lg\:mt-2 {
    margin-top: 0.5rem
  }

  .lg\:mr-2 {
    margin-right: 0.5rem
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem
  }

  .lg\:ml-2 {
    margin-left: 0.5rem
  }

  .lg\:mt-3 {
    margin-top: 0.75rem
  }

  .lg\:mr-3 {
    margin-right: 0.75rem
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem
  }

  .lg\:ml-3 {
    margin-left: 0.75rem
  }

  .lg\:mt-4 {
    margin-top: 1rem
  }

  .lg\:mr-4 {
    margin-right: 1rem
  }

  .lg\:mb-4 {
    margin-bottom: 1rem
  }

  .lg\:ml-4 {
    margin-left: 1rem
  }

  .lg\:mt-5 {
    margin-top: 1.25rem
  }

  .lg\:mr-5 {
    margin-right: 1.25rem
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem
  }

  .lg\:ml-5 {
    margin-left: 1.25rem
  }

  .lg\:mt-6 {
    margin-top: 1.5rem
  }

  .lg\:mr-6 {
    margin-right: 1.5rem
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem
  }

  .lg\:ml-6 {
    margin-left: 1.5rem
  }

  .lg\:mt-8 {
    margin-top: 2rem
  }

  .lg\:mr-8 {
    margin-right: 2rem
  }

  .lg\:mb-8 {
    margin-bottom: 2rem
  }

  .lg\:ml-8 {
    margin-left: 2rem
  }

  .lg\:mt-10 {
    margin-top: 2.5rem
  }

  .lg\:mr-10 {
    margin-right: 2.5rem
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem
  }

  .lg\:ml-10 {
    margin-left: 2.5rem
  }

  .lg\:mt-12 {
    margin-top: 3rem
  }

  .lg\:mr-12 {
    margin-right: 3rem
  }

  .lg\:mb-12 {
    margin-bottom: 3rem
  }

  .lg\:ml-12 {
    margin-left: 3rem
  }

  .lg\:mt-16 {
    margin-top: 4rem
  }

  .lg\:mr-16 {
    margin-right: 4rem
  }

  .lg\:mb-16 {
    margin-bottom: 4rem
  }

  .lg\:ml-16 {
    margin-left: 4rem
  }

  .lg\:mt-20 {
    margin-top: 5rem
  }

  .lg\:mr-20 {
    margin-right: 5rem
  }

  .lg\:mb-20 {
    margin-bottom: 5rem
  }

  .lg\:ml-20 {
    margin-left: 5rem
  }

  .lg\:mt-24 {
    margin-top: 6rem
  }

  .lg\:mr-24 {
    margin-right: 6rem
  }

  .lg\:mb-24 {
    margin-bottom: 6rem
  }

  .lg\:ml-24 {
    margin-left: 6rem
  }

  .lg\:mt-32 {
    margin-top: 8rem
  }

  .lg\:mr-32 {
    margin-right: 8rem
  }

  .lg\:mb-32 {
    margin-bottom: 8rem
  }

  .lg\:ml-32 {
    margin-left: 8rem
  }

  .lg\:mt-40 {
    margin-top: 10rem
  }

  .lg\:mr-40 {
    margin-right: 10rem
  }

  .lg\:mb-40 {
    margin-bottom: 10rem
  }

  .lg\:ml-40 {
    margin-left: 10rem
  }

  .lg\:mt-48 {
    margin-top: 12rem
  }

  .lg\:mr-48 {
    margin-right: 12rem
  }

  .lg\:mb-48 {
    margin-bottom: 12rem
  }

  .lg\:ml-48 {
    margin-left: 12rem
  }

  .lg\:mt-56 {
    margin-top: 14rem
  }

  .lg\:mr-56 {
    margin-right: 14rem
  }

  .lg\:mb-56 {
    margin-bottom: 14rem
  }

  .lg\:ml-56 {
    margin-left: 14rem
  }

  .lg\:mt-64 {
    margin-top: 16rem
  }

  .lg\:mr-64 {
    margin-right: 16rem
  }

  .lg\:mb-64 {
    margin-bottom: 16rem
  }

  .lg\:ml-64 {
    margin-left: 16rem
  }

  .lg\:mt-auto {
    margin-top: auto
  }

  .lg\:mr-auto {
    margin-right: auto
  }

  .lg\:mb-auto {
    margin-bottom: auto
  }

  .lg\:ml-auto {
    margin-left: auto
  }

  .lg\:mt-px {
    margin-top: 1px
  }

  .lg\:mr-px {
    margin-right: 1px
  }

  .lg\:mb-px {
    margin-bottom: 1px
  }

  .lg\:ml-px {
    margin-left: 1px
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem
  }

  .lg\:-mt-4 {
    margin-top: -1rem
  }

  .lg\:-mr-4 {
    margin-right: -1rem
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem
  }

  .lg\:-ml-4 {
    margin-left: -1rem
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem
  }

  .lg\:-mt-8 {
    margin-top: -2rem
  }

  .lg\:-mr-8 {
    margin-right: -2rem
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem
  }

  .lg\:-ml-8 {
    margin-left: -2rem
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem
  }

  .lg\:-mt-12 {
    margin-top: -3rem
  }

  .lg\:-mr-12 {
    margin-right: -3rem
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem
  }

  .lg\:-ml-12 {
    margin-left: -3rem
  }

  .lg\:-mt-16 {
    margin-top: -4rem
  }

  .lg\:-mr-16 {
    margin-right: -4rem
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem
  }

  .lg\:-ml-16 {
    margin-left: -4rem
  }

  .lg\:-mt-20 {
    margin-top: -5rem
  }

  .lg\:-mr-20 {
    margin-right: -5rem
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem
  }

  .lg\:-ml-20 {
    margin-left: -5rem
  }

  .lg\:-mt-24 {
    margin-top: -6rem
  }

  .lg\:-mr-24 {
    margin-right: -6rem
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem
  }

  .lg\:-ml-24 {
    margin-left: -6rem
  }

  .lg\:-mt-32 {
    margin-top: -8rem
  }

  .lg\:-mr-32 {
    margin-right: -8rem
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem
  }

  .lg\:-ml-32 {
    margin-left: -8rem
  }

  .lg\:-mt-40 {
    margin-top: -10rem
  }

  .lg\:-mr-40 {
    margin-right: -10rem
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem
  }

  .lg\:-ml-40 {
    margin-left: -10rem
  }

  .lg\:-mt-48 {
    margin-top: -12rem
  }

  .lg\:-mr-48 {
    margin-right: -12rem
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem
  }

  .lg\:-ml-48 {
    margin-left: -12rem
  }

  .lg\:-mt-56 {
    margin-top: -14rem
  }

  .lg\:-mr-56 {
    margin-right: -14rem
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem
  }

  .lg\:-ml-56 {
    margin-left: -14rem
  }

  .lg\:-mt-64 {
    margin-top: -16rem
  }

  .lg\:-mr-64 {
    margin-right: -16rem
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem
  }

  .lg\:-ml-64 {
    margin-left: -16rem
  }

  .lg\:-mt-px {
    margin-top: -1px
  }

  .lg\:-mr-px {
    margin-right: -1px
  }

  .lg\:-mb-px {
    margin-bottom: -1px
  }

  .lg\:-ml-px {
    margin-left: -1px
  }

  .lg\:max-h-full {
    max-height: 100%
  }

  .lg\:max-h-screen {
    max-height: 100vh
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-xs {
    max-width: 20rem
  }

  .lg\:max-w-sm {
    max-width: 24rem
  }

  .lg\:max-w-md {
    max-width: 28rem
  }

  .lg\:max-w-lg {
    max-width: 32rem
  }

  .lg\:max-w-xl {
    max-width: 36rem
  }

  .lg\:max-w-2xl {
    max-width: 42rem
  }

  .lg\:max-w-3xl {
    max-width: 48rem
  }

  .lg\:max-w-4xl {
    max-width: 56rem
  }

  .lg\:max-w-5xl {
    max-width: 64rem
  }

  .lg\:max-w-6xl {
    max-width: 72rem
  }

  .lg\:max-w-full {
    max-width: 100%
  }

  .lg\:max-w-screen-sm {
    max-width: 640px
  }

  .lg\:max-w-screen-md {
    max-width: 768px
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px
  }

  .lg\:min-h-0 {
    min-height: 0
  }

  .lg\:min-h-full {
    min-height: 100%
  }

  .lg\:min-h-screen {
    min-height: 100vh
  }

  .lg\:min-w-0 {
    min-width: 0
  }

  .lg\:min-w-full {
    min-width: 100%
  }

  .lg\:object-contain {
    -o-object-fit: contain;
       object-fit: contain
  }

  .lg\:object-cover {
    -o-object-fit: cover;
       object-fit: cover
  }

  .lg\:object-fill {
    -o-object-fit: fill;
       object-fit: fill
  }

  .lg\:object-none {
    -o-object-fit: none;
       object-fit: none
  }

  .lg\:object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down
  }

  .lg\:object-bottom {
    -o-object-position: bottom;
       object-position: bottom
  }

  .lg\:object-center {
    -o-object-position: center;
       object-position: center
  }

  .lg\:object-left {
    -o-object-position: left;
       object-position: left
  }

  .lg\:object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom
  }

  .lg\:object-left-top {
    -o-object-position: left top;
       object-position: left top
  }

  .lg\:object-right {
    -o-object-position: right;
       object-position: right
  }

  .lg\:object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom
  }

  .lg\:object-right-top {
    -o-object-position: right top;
       object-position: right top
  }

  .lg\:object-top {
    -o-object-position: top;
       object-position: top
  }

  .lg\:opacity-0 {
    opacity: 0
  }

  .lg\:opacity-25 {
    opacity: 0.25
  }

  .lg\:opacity-50 {
    opacity: 0.5
  }

  .lg\:opacity-75 {
    opacity: 0.75
  }

  .lg\:opacity-100 {
    opacity: 1
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1
  }

  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .lg\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .lg\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .lg\:overflow-auto {
    overflow: auto
  }

  .lg\:overflow-hidden {
    overflow: hidden
  }

  .lg\:overflow-visible {
    overflow: visible
  }

  .lg\:overflow-scroll {
    overflow: scroll
  }

  .lg\:overflow-x-auto {
    overflow-x: auto
  }

  .lg\:overflow-y-auto {
    overflow-y: auto
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden
  }

  .lg\:overflow-x-visible {
    overflow-x: visible
  }

  .lg\:overflow-y-visible {
    overflow-y: visible
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain
  }

  .lg\:overscroll-none {
    overscroll-behavior: none
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-1 {
    padding: 0.25rem
  }

  .lg\:p-2 {
    padding: 0.5rem
  }

  .lg\:p-3 {
    padding: 0.75rem
  }

  .lg\:p-4 {
    padding: 1rem
  }

  .lg\:p-5 {
    padding: 1.25rem
  }

  .lg\:p-6 {
    padding: 1.5rem
  }

  .lg\:p-8 {
    padding: 2rem
  }

  .lg\:p-10 {
    padding: 2.5rem
  }

  .lg\:p-12 {
    padding: 3rem
  }

  .lg\:p-16 {
    padding: 4rem
  }

  .lg\:p-20 {
    padding: 5rem
  }

  .lg\:p-24 {
    padding: 6rem
  }

  .lg\:p-32 {
    padding: 8rem
  }

  .lg\:p-40 {
    padding: 10rem
  }

  .lg\:p-48 {
    padding: 12rem
  }

  .lg\:p-56 {
    padding: 14rem
  }

  .lg\:p-64 {
    padding: 16rem
  }

  .lg\:p-px {
    padding: 1px
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }

  .lg\:pt-1 {
    padding-top: 0.25rem
  }

  .lg\:pr-1 {
    padding-right: 0.25rem
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem
  }

  .lg\:pl-1 {
    padding-left: 0.25rem
  }

  .lg\:pt-2 {
    padding-top: 0.5rem
  }

  .lg\:pr-2 {
    padding-right: 0.5rem
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem
  }

  .lg\:pl-2 {
    padding-left: 0.5rem
  }

  .lg\:pt-3 {
    padding-top: 0.75rem
  }

  .lg\:pr-3 {
    padding-right: 0.75rem
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem
  }

  .lg\:pl-3 {
    padding-left: 0.75rem
  }

  .lg\:pt-4 {
    padding-top: 1rem
  }

  .lg\:pr-4 {
    padding-right: 1rem
  }

  .lg\:pb-4 {
    padding-bottom: 1rem
  }

  .lg\:pl-4 {
    padding-left: 1rem
  }

  .lg\:pt-5 {
    padding-top: 1.25rem
  }

  .lg\:pr-5 {
    padding-right: 1.25rem
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem
  }

  .lg\:pl-5 {
    padding-left: 1.25rem
  }

  .lg\:pt-6 {
    padding-top: 1.5rem
  }

  .lg\:pr-6 {
    padding-right: 1.5rem
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem
  }

  .lg\:pl-6 {
    padding-left: 1.5rem
  }

  .lg\:pt-8 {
    padding-top: 2rem
  }

  .lg\:pr-8 {
    padding-right: 2rem
  }

  .lg\:pb-8 {
    padding-bottom: 2rem
  }

  .lg\:pl-8 {
    padding-left: 2rem
  }

  .lg\:pt-10 {
    padding-top: 2.5rem
  }

  .lg\:pr-10 {
    padding-right: 2.5rem
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem
  }

  .lg\:pl-10 {
    padding-left: 2.5rem
  }

  .lg\:pt-12 {
    padding-top: 3rem
  }

  .lg\:pr-12 {
    padding-right: 3rem
  }

  .lg\:pb-12 {
    padding-bottom: 3rem
  }

  .lg\:pl-12 {
    padding-left: 3rem
  }

  .lg\:pt-16 {
    padding-top: 4rem
  }

  .lg\:pr-16 {
    padding-right: 4rem
  }

  .lg\:pb-16 {
    padding-bottom: 4rem
  }

  .lg\:pl-16 {
    padding-left: 4rem
  }

  .lg\:pt-20 {
    padding-top: 5rem
  }

  .lg\:pr-20 {
    padding-right: 5rem
  }

  .lg\:pb-20 {
    padding-bottom: 5rem
  }

  .lg\:pl-20 {
    padding-left: 5rem
  }

  .lg\:pt-24 {
    padding-top: 6rem
  }

  .lg\:pr-24 {
    padding-right: 6rem
  }

  .lg\:pb-24 {
    padding-bottom: 6rem
  }

  .lg\:pl-24 {
    padding-left: 6rem
  }

  .lg\:pt-32 {
    padding-top: 8rem
  }

  .lg\:pr-32 {
    padding-right: 8rem
  }

  .lg\:pb-32 {
    padding-bottom: 8rem
  }

  .lg\:pl-32 {
    padding-left: 8rem
  }

  .lg\:pt-40 {
    padding-top: 10rem
  }

  .lg\:pr-40 {
    padding-right: 10rem
  }

  .lg\:pb-40 {
    padding-bottom: 10rem
  }

  .lg\:pl-40 {
    padding-left: 10rem
  }

  .lg\:pt-48 {
    padding-top: 12rem
  }

  .lg\:pr-48 {
    padding-right: 12rem
  }

  .lg\:pb-48 {
    padding-bottom: 12rem
  }

  .lg\:pl-48 {
    padding-left: 12rem
  }

  .lg\:pt-56 {
    padding-top: 14rem
  }

  .lg\:pr-56 {
    padding-right: 14rem
  }

  .lg\:pb-56 {
    padding-bottom: 14rem
  }

  .lg\:pl-56 {
    padding-left: 14rem
  }

  .lg\:pt-64 {
    padding-top: 16rem
  }

  .lg\:pr-64 {
    padding-right: 16rem
  }

  .lg\:pb-64 {
    padding-bottom: 16rem
  }

  .lg\:pl-64 {
    padding-left: 16rem
  }

  .lg\:pt-px {
    padding-top: 1px
  }

  .lg\:pr-px {
    padding-right: 1px
  }

  .lg\:pb-px {
    padding-bottom: 1px
  }

  .lg\:pl-px {
    padding-left: 1px
  }

  .lg\:placeholder-primary::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .lg\:placeholder-primary::placeholder {
    color: var(--ion-color-primary)
  }

  .lg\:placeholder-primary-shade::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .lg\:placeholder-primary-shade::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .lg\:placeholder-primary-tint::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .lg\:placeholder-primary-tint::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .lg\:placeholder-secondary::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .lg\:placeholder-secondary::placeholder {
    color: var(--ion-color-secondary)
  }

  .lg\:placeholder-secondary-shade::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:placeholder-secondary-shade::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:placeholder-secondary-tint::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:placeholder-secondary-tint::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:placeholder-tertiary::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .lg\:placeholder-tertiary::placeholder {
    color: var(--ion-color-tertiary)
  }

  .lg\:placeholder-tertiary-shade::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:placeholder-tertiary-shade::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:placeholder-tertiary-tint::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:placeholder-tertiary-tint::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:placeholder-light::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .lg\:placeholder-light::placeholder {
    color: var(--ion-color-light)
  }

  .lg\:placeholder-light-shade::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .lg\:placeholder-light-shade::placeholder {
    color: var(--ion-color-light-shade)
  }

  .lg\:placeholder-light-tint::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .lg\:placeholder-light-tint::placeholder {
    color: var(--ion-color-light-tint)
  }

  .lg\:placeholder-medium::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .lg\:placeholder-medium::placeholder {
    color: var(--ion-color-medium)
  }

  .lg\:placeholder-medium-shade::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .lg\:placeholder-medium-shade::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .lg\:placeholder-medium-tint::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .lg\:placeholder-medium-tint::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .lg\:placeholder-dark::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .lg\:placeholder-dark::placeholder {
    color: var(--ion-color-dark)
  }

  .lg\:placeholder-dark-shade::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .lg\:placeholder-dark-shade::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .lg\:placeholder-dark-tint::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .lg\:placeholder-dark-tint::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .lg\:placeholder-success::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .lg\:placeholder-success::placeholder {
    color: var(--ion-color-success)
  }

  .lg\:placeholder-success-shade::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .lg\:placeholder-success-shade::placeholder {
    color: var(--ion-color-success-shade)
  }

  .lg\:placeholder-success-tint::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .lg\:placeholder-success-tint::placeholder {
    color: var(--ion-color-success-tint)
  }

  .lg\:placeholder-warning::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .lg\:placeholder-warning::placeholder {
    color: var(--ion-color-warning)
  }

  .lg\:placeholder-warning-shade::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .lg\:placeholder-warning-shade::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .lg\:placeholder-warning-tint::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .lg\:placeholder-warning-tint::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .lg\:placeholder-danger::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .lg\:placeholder-danger::placeholder {
    color: var(--ion-color-danger)
  }

  .lg\:placeholder-danger-shade::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .lg\:placeholder-danger-shade::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .lg\:placeholder-danger-tint::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .lg\:placeholder-danger-tint::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .lg\:placeholder-step-50::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .lg\:placeholder-step-50::placeholder {
    color: var(--ion-color-step-50)
  }

  .lg\:placeholder-step-100::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .lg\:placeholder-step-100::placeholder {
    color: var(--ion-color-step-100)
  }

  .lg\:placeholder-step-150::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .lg\:placeholder-step-150::placeholder {
    color: var(--ion-color-step-150)
  }

  .lg\:placeholder-step-200::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .lg\:placeholder-step-200::placeholder {
    color: var(--ion-color-step-200)
  }

  .lg\:placeholder-step-250::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .lg\:placeholder-step-250::placeholder {
    color: var(--ion-color-step-250)
  }

  .lg\:placeholder-step-300::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .lg\:placeholder-step-300::placeholder {
    color: var(--ion-color-step-300)
  }

  .lg\:placeholder-step-350::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .lg\:placeholder-step-350::placeholder {
    color: var(--ion-color-step-350)
  }

  .lg\:placeholder-step-400::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .lg\:placeholder-step-400::placeholder {
    color: var(--ion-color-step-400)
  }

  .lg\:placeholder-step-450::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .lg\:placeholder-step-450::placeholder {
    color: var(--ion-color-step-450)
  }

  .lg\:placeholder-step-500::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .lg\:placeholder-step-500::placeholder {
    color: var(--ion-color-step-500)
  }

  .lg\:placeholder-step-550::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .lg\:placeholder-step-550::placeholder {
    color: var(--ion-color-step-550)
  }

  .lg\:placeholder-step-600::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .lg\:placeholder-step-600::placeholder {
    color: var(--ion-color-step-600)
  }

  .lg\:placeholder-step-650::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .lg\:placeholder-step-650::placeholder {
    color: var(--ion-color-step-650)
  }

  .lg\:placeholder-step-700::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .lg\:placeholder-step-700::placeholder {
    color: var(--ion-color-step-700)
  }

  .lg\:placeholder-step-750::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .lg\:placeholder-step-750::placeholder {
    color: var(--ion-color-step-750)
  }

  .lg\:placeholder-step-800::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .lg\:placeholder-step-800::placeholder {
    color: var(--ion-color-step-800)
  }

  .lg\:placeholder-step-850::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .lg\:placeholder-step-850::placeholder {
    color: var(--ion-color-step-850)
  }

  .lg\:placeholder-step-900::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .lg\:placeholder-step-900::placeholder {
    color: var(--ion-color-step-900)
  }

  .lg\:placeholder-step-950::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .lg\:placeholder-step-950::placeholder {
    color: var(--ion-color-step-950)
  }

  .lg\:focus\:placeholder-primary:focus::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .lg\:focus\:placeholder-primary:focus::placeholder {
    color: var(--ion-color-primary)
  }

  .lg\:focus\:placeholder-primary-shade:focus::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:placeholder-primary-shade:focus::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:placeholder-primary-tint:focus::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:placeholder-primary-tint:focus::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:placeholder-secondary:focus::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .lg\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--ion-color-secondary)
  }

  .lg\:focus\:placeholder-secondary-shade:focus::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:placeholder-secondary-shade:focus::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:placeholder-secondary-tint:focus::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:placeholder-secondary-tint:focus::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:placeholder-tertiary:focus::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .lg\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--ion-color-tertiary)
  }

  .lg\:focus\:placeholder-tertiary-shade:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:placeholder-tertiary-shade:focus::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:placeholder-tertiary-tint:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:placeholder-tertiary-tint:focus::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:placeholder-light:focus::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .lg\:focus\:placeholder-light:focus::placeholder {
    color: var(--ion-color-light)
  }

  .lg\:focus\:placeholder-light-shade:focus::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .lg\:focus\:placeholder-light-shade:focus::placeholder {
    color: var(--ion-color-light-shade)
  }

  .lg\:focus\:placeholder-light-tint:focus::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .lg\:focus\:placeholder-light-tint:focus::placeholder {
    color: var(--ion-color-light-tint)
  }

  .lg\:focus\:placeholder-medium:focus::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .lg\:focus\:placeholder-medium:focus::placeholder {
    color: var(--ion-color-medium)
  }

  .lg\:focus\:placeholder-medium-shade:focus::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:placeholder-medium-shade:focus::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:placeholder-medium-tint:focus::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:placeholder-medium-tint:focus::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:placeholder-dark:focus::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .lg\:focus\:placeholder-dark:focus::placeholder {
    color: var(--ion-color-dark)
  }

  .lg\:focus\:placeholder-dark-shade:focus::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:placeholder-dark-shade:focus::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:placeholder-dark-tint:focus::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:placeholder-dark-tint:focus::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:placeholder-success:focus::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .lg\:focus\:placeholder-success:focus::placeholder {
    color: var(--ion-color-success)
  }

  .lg\:focus\:placeholder-success-shade:focus::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .lg\:focus\:placeholder-success-shade:focus::placeholder {
    color: var(--ion-color-success-shade)
  }

  .lg\:focus\:placeholder-success-tint:focus::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .lg\:focus\:placeholder-success-tint:focus::placeholder {
    color: var(--ion-color-success-tint)
  }

  .lg\:focus\:placeholder-warning:focus::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .lg\:focus\:placeholder-warning:focus::placeholder {
    color: var(--ion-color-warning)
  }

  .lg\:focus\:placeholder-warning-shade:focus::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:placeholder-warning-shade:focus::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:placeholder-warning-tint:focus::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:placeholder-warning-tint:focus::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:placeholder-danger:focus::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .lg\:focus\:placeholder-danger:focus::placeholder {
    color: var(--ion-color-danger)
  }

  .lg\:focus\:placeholder-danger-shade:focus::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:placeholder-danger-shade:focus::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:placeholder-danger-tint:focus::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:placeholder-danger-tint:focus::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:placeholder-step-50:focus::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .lg\:focus\:placeholder-step-50:focus::placeholder {
    color: var(--ion-color-step-50)
  }

  .lg\:focus\:placeholder-step-100:focus::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .lg\:focus\:placeholder-step-100:focus::placeholder {
    color: var(--ion-color-step-100)
  }

  .lg\:focus\:placeholder-step-150:focus::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .lg\:focus\:placeholder-step-150:focus::placeholder {
    color: var(--ion-color-step-150)
  }

  .lg\:focus\:placeholder-step-200:focus::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .lg\:focus\:placeholder-step-200:focus::placeholder {
    color: var(--ion-color-step-200)
  }

  .lg\:focus\:placeholder-step-250:focus::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .lg\:focus\:placeholder-step-250:focus::placeholder {
    color: var(--ion-color-step-250)
  }

  .lg\:focus\:placeholder-step-300:focus::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .lg\:focus\:placeholder-step-300:focus::placeholder {
    color: var(--ion-color-step-300)
  }

  .lg\:focus\:placeholder-step-350:focus::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .lg\:focus\:placeholder-step-350:focus::placeholder {
    color: var(--ion-color-step-350)
  }

  .lg\:focus\:placeholder-step-400:focus::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .lg\:focus\:placeholder-step-400:focus::placeholder {
    color: var(--ion-color-step-400)
  }

  .lg\:focus\:placeholder-step-450:focus::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .lg\:focus\:placeholder-step-450:focus::placeholder {
    color: var(--ion-color-step-450)
  }

  .lg\:focus\:placeholder-step-500:focus::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .lg\:focus\:placeholder-step-500:focus::placeholder {
    color: var(--ion-color-step-500)
  }

  .lg\:focus\:placeholder-step-550:focus::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .lg\:focus\:placeholder-step-550:focus::placeholder {
    color: var(--ion-color-step-550)
  }

  .lg\:focus\:placeholder-step-600:focus::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .lg\:focus\:placeholder-step-600:focus::placeholder {
    color: var(--ion-color-step-600)
  }

  .lg\:focus\:placeholder-step-650:focus::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .lg\:focus\:placeholder-step-650:focus::placeholder {
    color: var(--ion-color-step-650)
  }

  .lg\:focus\:placeholder-step-700:focus::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .lg\:focus\:placeholder-step-700:focus::placeholder {
    color: var(--ion-color-step-700)
  }

  .lg\:focus\:placeholder-step-750:focus::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .lg\:focus\:placeholder-step-750:focus::placeholder {
    color: var(--ion-color-step-750)
  }

  .lg\:focus\:placeholder-step-800:focus::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .lg\:focus\:placeholder-step-800:focus::placeholder {
    color: var(--ion-color-step-800)
  }

  .lg\:focus\:placeholder-step-850:focus::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .lg\:focus\:placeholder-step-850:focus::placeholder {
    color: var(--ion-color-step-850)
  }

  .lg\:focus\:placeholder-step-900:focus::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .lg\:focus\:placeholder-step-900:focus::placeholder {
    color: var(--ion-color-step-900)
  }

  .lg\:focus\:placeholder-step-950:focus::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .lg\:focus\:placeholder-step-950:focus::placeholder {
    color: var(--ion-color-step-950)
  }

  .lg\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .lg\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .lg\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .lg\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .lg\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .lg\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .lg\:pointer-events-none {
    pointer-events: none
  }

  .lg\:pointer-events-auto {
    pointer-events: auto
  }

  .lg\:static {
    position: static
  }

  .lg\:fixed {
    position: fixed
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .lg\:inset-x-0 {
    right: 0;
    left: 0
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .lg\:inset-x-auto {
    right: auto;
    left: auto
  }

  .lg\:top-0 {
    top: 0
  }

  .lg\:right-0 {
    right: 0
  }

  .lg\:bottom-0 {
    bottom: 0
  }

  .lg\:left-0 {
    left: 0
  }

  .lg\:top-auto {
    top: auto
  }

  .lg\:right-auto {
    right: auto
  }

  .lg\:bottom-auto {
    bottom: auto
  }

  .lg\:left-auto {
    left: auto
  }

  .lg\:resize-none {
    resize: none
  }

  .lg\:resize-y {
    resize: vertical
  }

  .lg\:resize-x {
    resize: horizontal
  }

  .lg\:resize {
    resize: both
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:shadow-none {
    box-shadow: none
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .lg\:fill-current {
    fill: currentColor
  }

  .lg\:stroke-current {
    stroke: currentColor
  }

  .lg\:stroke-0 {
    stroke-width: 0
  }

  .lg\:stroke-1 {
    stroke-width: 1
  }

  .lg\:stroke-2 {
    stroke-width: 2
  }

  .lg\:table-auto {
    table-layout: auto
  }

  .lg\:table-fixed {
    table-layout: fixed
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-center {
    text-align: center
  }

  .lg\:text-right {
    text-align: right
  }

  .lg\:text-justify {
    text-align: justify
  }

  .lg\:text-primary {
    color: var(--ion-color-primary)
  }

  .lg\:text-primary-shade {
    color: var(--ion-color-primary-shade)
  }

  .lg\:text-primary-tint {
    color: var(--ion-color-primary-tint)
  }

  .lg\:text-secondary {
    color: var(--ion-color-secondary)
  }

  .lg\:text-secondary-shade {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:text-secondary-tint {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:text-tertiary {
    color: var(--ion-color-tertiary)
  }

  .lg\:text-tertiary-shade {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:text-tertiary-tint {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:text-light {
    color: var(--ion-color-light)
  }

  .lg\:text-light-shade {
    color: var(--ion-color-light-shade)
  }

  .lg\:text-light-tint {
    color: var(--ion-color-light-tint)
  }

  .lg\:text-medium {
    color: var(--ion-color-medium)
  }

  .lg\:text-medium-shade {
    color: var(--ion-color-medium-shade)
  }

  .lg\:text-medium-tint {
    color: var(--ion-color-medium-tint)
  }

  .lg\:text-dark {
    color: var(--ion-color-dark)
  }

  .lg\:text-dark-shade {
    color: var(--ion-color-dark-shade)
  }

  .lg\:text-dark-tint {
    color: var(--ion-color-dark-tint)
  }

  .lg\:text-success {
    color: var(--ion-color-success)
  }

  .lg\:text-success-shade {
    color: var(--ion-color-success-shade)
  }

  .lg\:text-success-tint {
    color: var(--ion-color-success-tint)
  }

  .lg\:text-warning {
    color: var(--ion-color-warning)
  }

  .lg\:text-warning-shade {
    color: var(--ion-color-warning-shade)
  }

  .lg\:text-warning-tint {
    color: var(--ion-color-warning-tint)
  }

  .lg\:text-danger {
    color: var(--ion-color-danger)
  }

  .lg\:text-danger-shade {
    color: var(--ion-color-danger-shade)
  }

  .lg\:text-danger-tint {
    color: var(--ion-color-danger-tint)
  }

  .lg\:text-step-50 {
    color: var(--ion-color-step-50)
  }

  .lg\:text-step-100 {
    color: var(--ion-color-step-100)
  }

  .lg\:text-step-150 {
    color: var(--ion-color-step-150)
  }

  .lg\:text-step-200 {
    color: var(--ion-color-step-200)
  }

  .lg\:text-step-250 {
    color: var(--ion-color-step-250)
  }

  .lg\:text-step-300 {
    color: var(--ion-color-step-300)
  }

  .lg\:text-step-350 {
    color: var(--ion-color-step-350)
  }

  .lg\:text-step-400 {
    color: var(--ion-color-step-400)
  }

  .lg\:text-step-450 {
    color: var(--ion-color-step-450)
  }

  .lg\:text-step-500 {
    color: var(--ion-color-step-500)
  }

  .lg\:text-step-550 {
    color: var(--ion-color-step-550)
  }

  .lg\:text-step-600 {
    color: var(--ion-color-step-600)
  }

  .lg\:text-step-650 {
    color: var(--ion-color-step-650)
  }

  .lg\:text-step-700 {
    color: var(--ion-color-step-700)
  }

  .lg\:text-step-750 {
    color: var(--ion-color-step-750)
  }

  .lg\:text-step-800 {
    color: var(--ion-color-step-800)
  }

  .lg\:text-step-850 {
    color: var(--ion-color-step-850)
  }

  .lg\:text-step-900 {
    color: var(--ion-color-step-900)
  }

  .lg\:text-step-950 {
    color: var(--ion-color-step-950)
  }

  .lg\:hover\:text-primary:hover {
    color: var(--ion-color-primary)
  }

  .lg\:hover\:text-primary-shade:hover {
    color: var(--ion-color-primary-shade)
  }

  .lg\:hover\:text-primary-tint:hover {
    color: var(--ion-color-primary-tint)
  }

  .lg\:hover\:text-secondary:hover {
    color: var(--ion-color-secondary)
  }

  .lg\:hover\:text-secondary-shade:hover {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:hover\:text-secondary-tint:hover {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:hover\:text-tertiary:hover {
    color: var(--ion-color-tertiary)
  }

  .lg\:hover\:text-tertiary-shade:hover {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:hover\:text-tertiary-tint:hover {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:hover\:text-light:hover {
    color: var(--ion-color-light)
  }

  .lg\:hover\:text-light-shade:hover {
    color: var(--ion-color-light-shade)
  }

  .lg\:hover\:text-light-tint:hover {
    color: var(--ion-color-light-tint)
  }

  .lg\:hover\:text-medium:hover {
    color: var(--ion-color-medium)
  }

  .lg\:hover\:text-medium-shade:hover {
    color: var(--ion-color-medium-shade)
  }

  .lg\:hover\:text-medium-tint:hover {
    color: var(--ion-color-medium-tint)
  }

  .lg\:hover\:text-dark:hover {
    color: var(--ion-color-dark)
  }

  .lg\:hover\:text-dark-shade:hover {
    color: var(--ion-color-dark-shade)
  }

  .lg\:hover\:text-dark-tint:hover {
    color: var(--ion-color-dark-tint)
  }

  .lg\:hover\:text-success:hover {
    color: var(--ion-color-success)
  }

  .lg\:hover\:text-success-shade:hover {
    color: var(--ion-color-success-shade)
  }

  .lg\:hover\:text-success-tint:hover {
    color: var(--ion-color-success-tint)
  }

  .lg\:hover\:text-warning:hover {
    color: var(--ion-color-warning)
  }

  .lg\:hover\:text-warning-shade:hover {
    color: var(--ion-color-warning-shade)
  }

  .lg\:hover\:text-warning-tint:hover {
    color: var(--ion-color-warning-tint)
  }

  .lg\:hover\:text-danger:hover {
    color: var(--ion-color-danger)
  }

  .lg\:hover\:text-danger-shade:hover {
    color: var(--ion-color-danger-shade)
  }

  .lg\:hover\:text-danger-tint:hover {
    color: var(--ion-color-danger-tint)
  }

  .lg\:hover\:text-step-50:hover {
    color: var(--ion-color-step-50)
  }

  .lg\:hover\:text-step-100:hover {
    color: var(--ion-color-step-100)
  }

  .lg\:hover\:text-step-150:hover {
    color: var(--ion-color-step-150)
  }

  .lg\:hover\:text-step-200:hover {
    color: var(--ion-color-step-200)
  }

  .lg\:hover\:text-step-250:hover {
    color: var(--ion-color-step-250)
  }

  .lg\:hover\:text-step-300:hover {
    color: var(--ion-color-step-300)
  }

  .lg\:hover\:text-step-350:hover {
    color: var(--ion-color-step-350)
  }

  .lg\:hover\:text-step-400:hover {
    color: var(--ion-color-step-400)
  }

  .lg\:hover\:text-step-450:hover {
    color: var(--ion-color-step-450)
  }

  .lg\:hover\:text-step-500:hover {
    color: var(--ion-color-step-500)
  }

  .lg\:hover\:text-step-550:hover {
    color: var(--ion-color-step-550)
  }

  .lg\:hover\:text-step-600:hover {
    color: var(--ion-color-step-600)
  }

  .lg\:hover\:text-step-650:hover {
    color: var(--ion-color-step-650)
  }

  .lg\:hover\:text-step-700:hover {
    color: var(--ion-color-step-700)
  }

  .lg\:hover\:text-step-750:hover {
    color: var(--ion-color-step-750)
  }

  .lg\:hover\:text-step-800:hover {
    color: var(--ion-color-step-800)
  }

  .lg\:hover\:text-step-850:hover {
    color: var(--ion-color-step-850)
  }

  .lg\:hover\:text-step-900:hover {
    color: var(--ion-color-step-900)
  }

  .lg\:hover\:text-step-950:hover {
    color: var(--ion-color-step-950)
  }

  .lg\:focus\:text-primary:focus {
    color: var(--ion-color-primary)
  }

  .lg\:focus\:text-primary-shade:focus {
    color: var(--ion-color-primary-shade)
  }

  .lg\:focus\:text-primary-tint:focus {
    color: var(--ion-color-primary-tint)
  }

  .lg\:focus\:text-secondary:focus {
    color: var(--ion-color-secondary)
  }

  .lg\:focus\:text-secondary-shade:focus {
    color: var(--ion-color-secondary-shade)
  }

  .lg\:focus\:text-secondary-tint:focus {
    color: var(--ion-color-secondary-tint)
  }

  .lg\:focus\:text-tertiary:focus {
    color: var(--ion-color-tertiary)
  }

  .lg\:focus\:text-tertiary-shade:focus {
    color: var(--ion-color-tertiary-shade)
  }

  .lg\:focus\:text-tertiary-tint:focus {
    color: var(--ion-color-tertiary-tint)
  }

  .lg\:focus\:text-light:focus {
    color: var(--ion-color-light)
  }

  .lg\:focus\:text-light-shade:focus {
    color: var(--ion-color-light-shade)
  }

  .lg\:focus\:text-light-tint:focus {
    color: var(--ion-color-light-tint)
  }

  .lg\:focus\:text-medium:focus {
    color: var(--ion-color-medium)
  }

  .lg\:focus\:text-medium-shade:focus {
    color: var(--ion-color-medium-shade)
  }

  .lg\:focus\:text-medium-tint:focus {
    color: var(--ion-color-medium-tint)
  }

  .lg\:focus\:text-dark:focus {
    color: var(--ion-color-dark)
  }

  .lg\:focus\:text-dark-shade:focus {
    color: var(--ion-color-dark-shade)
  }

  .lg\:focus\:text-dark-tint:focus {
    color: var(--ion-color-dark-tint)
  }

  .lg\:focus\:text-success:focus {
    color: var(--ion-color-success)
  }

  .lg\:focus\:text-success-shade:focus {
    color: var(--ion-color-success-shade)
  }

  .lg\:focus\:text-success-tint:focus {
    color: var(--ion-color-success-tint)
  }

  .lg\:focus\:text-warning:focus {
    color: var(--ion-color-warning)
  }

  .lg\:focus\:text-warning-shade:focus {
    color: var(--ion-color-warning-shade)
  }

  .lg\:focus\:text-warning-tint:focus {
    color: var(--ion-color-warning-tint)
  }

  .lg\:focus\:text-danger:focus {
    color: var(--ion-color-danger)
  }

  .lg\:focus\:text-danger-shade:focus {
    color: var(--ion-color-danger-shade)
  }

  .lg\:focus\:text-danger-tint:focus {
    color: var(--ion-color-danger-tint)
  }

  .lg\:focus\:text-step-50:focus {
    color: var(--ion-color-step-50)
  }

  .lg\:focus\:text-step-100:focus {
    color: var(--ion-color-step-100)
  }

  .lg\:focus\:text-step-150:focus {
    color: var(--ion-color-step-150)
  }

  .lg\:focus\:text-step-200:focus {
    color: var(--ion-color-step-200)
  }

  .lg\:focus\:text-step-250:focus {
    color: var(--ion-color-step-250)
  }

  .lg\:focus\:text-step-300:focus {
    color: var(--ion-color-step-300)
  }

  .lg\:focus\:text-step-350:focus {
    color: var(--ion-color-step-350)
  }

  .lg\:focus\:text-step-400:focus {
    color: var(--ion-color-step-400)
  }

  .lg\:focus\:text-step-450:focus {
    color: var(--ion-color-step-450)
  }

  .lg\:focus\:text-step-500:focus {
    color: var(--ion-color-step-500)
  }

  .lg\:focus\:text-step-550:focus {
    color: var(--ion-color-step-550)
  }

  .lg\:focus\:text-step-600:focus {
    color: var(--ion-color-step-600)
  }

  .lg\:focus\:text-step-650:focus {
    color: var(--ion-color-step-650)
  }

  .lg\:focus\:text-step-700:focus {
    color: var(--ion-color-step-700)
  }

  .lg\:focus\:text-step-750:focus {
    color: var(--ion-color-step-750)
  }

  .lg\:focus\:text-step-800:focus {
    color: var(--ion-color-step-800)
  }

  .lg\:focus\:text-step-850:focus {
    color: var(--ion-color-step-850)
  }

  .lg\:focus\:text-step-900:focus {
    color: var(--ion-color-step-900)
  }

  .lg\:focus\:text-step-950:focus {
    color: var(--ion-color-step-950)
  }

  .lg\:italic {
    font-style: italic
  }

  .lg\:not-italic {
    font-style: normal
  }

  .lg\:uppercase {
    text-transform: uppercase
  }

  .lg\:lowercase {
    text-transform: lowercase
  }

  .lg\:capitalize {
    text-transform: capitalize
  }

  .lg\:normal-case {
    text-transform: none
  }

  .lg\:underline {
    text-decoration: underline
  }

  .lg\:line-through {
    text-decoration: line-through
  }

  .lg\:no-underline {
    text-decoration: none
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .lg\:normal-nums {
    font-variant-numeric: normal
  }

  .lg\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .lg\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .lg\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .lg\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .lg\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .lg\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .lg\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .lg\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em
  }

  .lg\:tracking-normal {
    letter-spacing: 0
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em
  }

  .lg\:select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none
  }

  .lg\:select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text
  }

  .lg\:select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto
  }

  .lg\:align-baseline {
    vertical-align: baseline
  }

  .lg\:align-top {
    vertical-align: top
  }

  .lg\:align-middle {
    vertical-align: middle
  }

  .lg\:align-bottom {
    vertical-align: bottom
  }

  .lg\:align-text-top {
    vertical-align: text-top
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom
  }

  .lg\:visible {
    visibility: visible
  }

  .lg\:invisible {
    visibility: hidden
  }

  .lg\:whitespace-normal {
    white-space: normal
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap
  }

  .lg\:whitespace-pre {
    white-space: pre
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .lg\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .lg\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .lg\:break-all {
    word-break: break-all
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .lg\:w-0 {
    width: 0
  }

  .lg\:w-1 {
    width: 0.25rem
  }

  .lg\:w-2 {
    width: 0.5rem
  }

  .lg\:w-3 {
    width: 0.75rem
  }

  .lg\:w-4 {
    width: 1rem
  }

  .lg\:w-5 {
    width: 1.25rem
  }

  .lg\:w-6 {
    width: 1.5rem
  }

  .lg\:w-8 {
    width: 2rem
  }

  .lg\:w-10 {
    width: 2.5rem
  }

  .lg\:w-12 {
    width: 3rem
  }

  .lg\:w-16 {
    width: 4rem
  }

  .lg\:w-20 {
    width: 5rem
  }

  .lg\:w-24 {
    width: 6rem
  }

  .lg\:w-32 {
    width: 8rem
  }

  .lg\:w-40 {
    width: 10rem
  }

  .lg\:w-48 {
    width: 12rem
  }

  .lg\:w-56 {
    width: 14rem
  }

  .lg\:w-64 {
    width: 16rem
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-px {
    width: 1px
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-2\/3 {
    width: 66.666667%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-2\/4 {
    width: 50%
  }

  .lg\:w-3\/4 {
    width: 75%
  }

  .lg\:w-1\/5 {
    width: 20%
  }

  .lg\:w-2\/5 {
    width: 40%
  }

  .lg\:w-3\/5 {
    width: 60%
  }

  .lg\:w-4\/5 {
    width: 80%
  }

  .lg\:w-1\/6 {
    width: 16.666667%
  }

  .lg\:w-2\/6 {
    width: 33.333333%
  }

  .lg\:w-3\/6 {
    width: 50%
  }

  .lg\:w-4\/6 {
    width: 66.666667%
  }

  .lg\:w-5\/6 {
    width: 83.333333%
  }

  .lg\:w-1\/12 {
    width: 8.333333%
  }

  .lg\:w-2\/12 {
    width: 16.666667%
  }

  .lg\:w-3\/12 {
    width: 25%
  }

  .lg\:w-4\/12 {
    width: 33.333333%
  }

  .lg\:w-5\/12 {
    width: 41.666667%
  }

  .lg\:w-6\/12 {
    width: 50%
  }

  .lg\:w-7\/12 {
    width: 58.333333%
  }

  .lg\:w-8\/12 {
    width: 66.666667%
  }

  .lg\:w-9\/12 {
    width: 75%
  }

  .lg\:w-10\/12 {
    width: 83.333333%
  }

  .lg\:w-11\/12 {
    width: 91.666667%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:w-screen {
    width: 100vw
  }

  .lg\:z-0 {
    z-index: 0
  }

  .lg\:z-10 {
    z-index: 10
  }

  .lg\:z-20 {
    z-index: 20
  }

  .lg\:z-30 {
    z-index: 30
  }

  .lg\:z-40 {
    z-index: 40
  }

  .lg\:z-50 {
    z-index: 50
  }

  .lg\:z-auto {
    z-index: auto
  }

  .lg\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .lg\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .lg\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .lg\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .lg\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .lg\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .lg\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .lg\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .lg\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .lg\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .lg\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .lg\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .lg\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .lg\:col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .lg\:col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .lg\:col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .lg\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .lg\:col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .lg\:col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .lg\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .lg\:col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .lg\:col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .lg\:col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .lg\:col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .lg\:col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .lg\:col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .lg\:col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .lg\:col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .lg\:col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .lg\:col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .lg\:col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .lg\:col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .lg\:gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .lg\:gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .lg\:gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .lg\:gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .lg\:gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .lg\:gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .lg\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .lg\:gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .lg\:gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .lg\:gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .lg\:gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .lg\:gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .lg\:gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .lg\:gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .lg\:gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .lg\:gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .lg\:gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .lg\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .lg\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .lg\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .lg\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .lg\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .lg\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .lg\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .lg\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .lg\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .lg\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .lg\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .lg\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .lg\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .lg\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .lg\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .lg\:grid-cols-none {
    grid-template-columns: none
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .lg\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .lg\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .lg\:col-auto {
    grid-column: auto
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .lg\:col-span-full {
    grid-column: 1 / -1
  }

  .lg\:col-start-1 {
    grid-column-start: 1
  }

  .lg\:col-start-2 {
    grid-column-start: 2
  }

  .lg\:col-start-3 {
    grid-column-start: 3
  }

  .lg\:col-start-4 {
    grid-column-start: 4
  }

  .lg\:col-start-5 {
    grid-column-start: 5
  }

  .lg\:col-start-6 {
    grid-column-start: 6
  }

  .lg\:col-start-7 {
    grid-column-start: 7
  }

  .lg\:col-start-8 {
    grid-column-start: 8
  }

  .lg\:col-start-9 {
    grid-column-start: 9
  }

  .lg\:col-start-10 {
    grid-column-start: 10
  }

  .lg\:col-start-11 {
    grid-column-start: 11
  }

  .lg\:col-start-12 {
    grid-column-start: 12
  }

  .lg\:col-start-13 {
    grid-column-start: 13
  }

  .lg\:col-start-auto {
    grid-column-start: auto
  }

  .lg\:col-end-1 {
    grid-column-end: 1
  }

  .lg\:col-end-2 {
    grid-column-end: 2
  }

  .lg\:col-end-3 {
    grid-column-end: 3
  }

  .lg\:col-end-4 {
    grid-column-end: 4
  }

  .lg\:col-end-5 {
    grid-column-end: 5
  }

  .lg\:col-end-6 {
    grid-column-end: 6
  }

  .lg\:col-end-7 {
    grid-column-end: 7
  }

  .lg\:col-end-8 {
    grid-column-end: 8
  }

  .lg\:col-end-9 {
    grid-column-end: 9
  }

  .lg\:col-end-10 {
    grid-column-end: 10
  }

  .lg\:col-end-11 {
    grid-column-end: 11
  }

  .lg\:col-end-12 {
    grid-column-end: 12
  }

  .lg\:col-end-13 {
    grid-column-end: 13
  }

  .lg\:col-end-auto {
    grid-column-end: auto
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .lg\:grid-rows-none {
    grid-template-rows: none
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .lg\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .lg\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .lg\:row-auto {
    grid-row: auto
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .lg\:row-span-full {
    grid-row: 1 / -1
  }

  .lg\:row-start-1 {
    grid-row-start: 1
  }

  .lg\:row-start-2 {
    grid-row-start: 2
  }

  .lg\:row-start-3 {
    grid-row-start: 3
  }

  .lg\:row-start-4 {
    grid-row-start: 4
  }

  .lg\:row-start-5 {
    grid-row-start: 5
  }

  .lg\:row-start-6 {
    grid-row-start: 6
  }

  .lg\:row-start-7 {
    grid-row-start: 7
  }

  .lg\:row-start-auto {
    grid-row-start: auto
  }

  .lg\:row-end-1 {
    grid-row-end: 1
  }

  .lg\:row-end-2 {
    grid-row-end: 2
  }

  .lg\:row-end-3 {
    grid-row-end: 3
  }

  .lg\:row-end-4 {
    grid-row-end: 4
  }

  .lg\:row-end-5 {
    grid-row-end: 5
  }

  .lg\:row-end-6 {
    grid-row-end: 6
  }

  .lg\:row-end-7 {
    grid-row-end: 7
  }

  .lg\:row-end-auto {
    grid-row-end: auto
  }

  .lg\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .lg\:transform-none {
    transform: none
  }

  .lg\:origin-center {
    transform-origin: center
  }

  .lg\:origin-top {
    transform-origin: top
  }

  .lg\:origin-top-right {
    transform-origin: top right
  }

  .lg\:origin-right {
    transform-origin: right
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right
  }

  .lg\:origin-bottom {
    transform-origin: bottom
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left
  }

  .lg\:origin-left {
    transform-origin: left
  }

  .lg\:origin-top-left {
    transform-origin: top left
  }

  .lg\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:scale-x-0 {
    --transform-scale-x: 0
  }

  .lg\:scale-x-50 {
    --transform-scale-x: .5
  }

  .lg\:scale-x-75 {
    --transform-scale-x: .75
  }

  .lg\:scale-x-90 {
    --transform-scale-x: .9
  }

  .lg\:scale-x-95 {
    --transform-scale-x: .95
  }

  .lg\:scale-x-100 {
    --transform-scale-x: 1
  }

  .lg\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .lg\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .lg\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .lg\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .lg\:scale-y-0 {
    --transform-scale-y: 0
  }

  .lg\:scale-y-50 {
    --transform-scale-y: .5
  }

  .lg\:scale-y-75 {
    --transform-scale-y: .75
  }

  .lg\:scale-y-90 {
    --transform-scale-y: .9
  }

  .lg\:scale-y-95 {
    --transform-scale-y: .95
  }

  .lg\:scale-y-100 {
    --transform-scale-y: 1
  }

  .lg\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .lg\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .lg\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .lg\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .lg\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .lg\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .lg\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .lg\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .lg\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .lg\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .lg\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .lg\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .lg\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .lg\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .lg\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .lg\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .lg\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .lg\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .lg\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .lg\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .lg\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .lg\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .lg\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .lg\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .lg\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .lg\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .lg\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .lg\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .lg\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .lg\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .lg\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .lg\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .lg\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .lg\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .lg\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .lg\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .lg\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .lg\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .lg\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .lg\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .lg\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .lg\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .lg\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .lg\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .lg\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .lg\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .lg\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .lg\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .lg\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .lg\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .lg\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .lg\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .lg\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .lg\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .lg\:rotate-0 {
    --transform-rotate: 0
  }

  .lg\:rotate-1 {
    --transform-rotate: 1deg
  }

  .lg\:rotate-2 {
    --transform-rotate: 2deg
  }

  .lg\:rotate-3 {
    --transform-rotate: 3deg
  }

  .lg\:rotate-6 {
    --transform-rotate: 6deg
  }

  .lg\:rotate-12 {
    --transform-rotate: 12deg
  }

  .lg\:rotate-45 {
    --transform-rotate: 45deg
  }

  .lg\:rotate-90 {
    --transform-rotate: 90deg
  }

  .lg\:rotate-180 {
    --transform-rotate: 180deg
  }

  .lg\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .lg\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .lg\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .lg\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .lg\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .lg\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .lg\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .lg\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .lg\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .lg\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .lg\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .lg\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .lg\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .lg\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .lg\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .lg\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .lg\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .lg\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .lg\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .lg\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .lg\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .lg\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .lg\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .lg\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .lg\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .lg\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .lg\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .lg\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .lg\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .lg\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .lg\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .lg\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .lg\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .lg\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .lg\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .lg\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .lg\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .lg\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .lg\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .lg\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .lg\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .lg\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .lg\:translate-x-0 {
    --transform-translate-x: 0
  }

  .lg\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .lg\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .lg\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .lg\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .lg\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .lg\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .lg\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .lg\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .lg\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .lg\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .lg\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .lg\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .lg\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .lg\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .lg\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .lg\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .lg\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .lg\:translate-x-px {
    --transform-translate-x: 1px
  }

  .lg\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .lg\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .lg\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .lg\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .lg\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .lg\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .lg\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .lg\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .lg\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .lg\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .lg\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .lg\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .lg\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .lg\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .lg\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .lg\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .lg\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .lg\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .lg\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .lg\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .lg\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .lg\:translate-x-full {
    --transform-translate-x: 100%
  }

  .lg\:translate-y-0 {
    --transform-translate-y: 0
  }

  .lg\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .lg\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .lg\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .lg\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .lg\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .lg\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .lg\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .lg\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .lg\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .lg\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .lg\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .lg\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .lg\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .lg\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .lg\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .lg\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .lg\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .lg\:translate-y-px {
    --transform-translate-y: 1px
  }

  .lg\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .lg\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .lg\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .lg\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .lg\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .lg\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .lg\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .lg\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .lg\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .lg\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .lg\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .lg\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .lg\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .lg\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .lg\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .lg\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .lg\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .lg\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .lg\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .lg\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .lg\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .lg\:translate-y-full {
    --transform-translate-y: 100%
  }

  .lg\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .lg\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .lg\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .lg\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .lg\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .lg\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .lg\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .lg\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .lg\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .lg\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .lg\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .lg\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .lg\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .lg\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .lg\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .lg\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .lg\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .lg\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .lg\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .lg\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .lg\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .lg\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .lg\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .lg\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .lg\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .lg\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .lg\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .lg\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .lg\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .lg\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .lg\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .lg\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .lg\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .lg\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .lg\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .lg\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .lg\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .lg\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .lg\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .lg\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .lg\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .lg\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .lg\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .lg\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .lg\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .lg\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .lg\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .lg\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .lg\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .lg\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .lg\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .lg\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .lg\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .lg\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .lg\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .lg\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .lg\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .lg\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .lg\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .lg\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .lg\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .lg\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .lg\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .lg\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .lg\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .lg\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .lg\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .lg\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .lg\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .lg\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .lg\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .lg\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .lg\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .lg\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .lg\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .lg\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .lg\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .lg\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .lg\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .lg\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .lg\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .lg\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .lg\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .lg\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .lg\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .lg\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .lg\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .lg\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .lg\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .lg\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .lg\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .lg\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .lg\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .lg\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .lg\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .lg\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .lg\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .lg\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .lg\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .lg\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .lg\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .lg\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .lg\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .lg\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .lg\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .lg\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .lg\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .lg\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .lg\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .lg\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .lg\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .lg\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .lg\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .lg\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .lg\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .lg\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .lg\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .lg\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .lg\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .lg\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .lg\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .lg\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .lg\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .lg\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .lg\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .lg\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .lg\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .lg\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .lg\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .lg\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .lg\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .lg\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .lg\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .lg\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .lg\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .lg\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .lg\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .lg\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .lg\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .lg\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .lg\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .lg\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .lg\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .lg\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .lg\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .lg\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .lg\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .lg\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .lg\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .lg\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .lg\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .lg\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .lg\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .lg\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .lg\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .lg\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .lg\:skew-x-0 {
    --transform-skew-x: 0
  }

  .lg\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .lg\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .lg\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .lg\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .lg\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .lg\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .lg\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .lg\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .lg\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .lg\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .lg\:skew-y-0 {
    --transform-skew-y: 0
  }

  .lg\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .lg\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .lg\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .lg\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .lg\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .lg\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .lg\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .lg\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .lg\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .lg\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .lg\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .lg\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .lg\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .lg\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .lg\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .lg\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .lg\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .lg\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .lg\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .lg\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .lg\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .lg\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .lg\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .lg\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .lg\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .lg\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .lg\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .lg\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .lg\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .lg\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .lg\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .lg\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .lg\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .lg\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .lg\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .lg\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .lg\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .lg\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .lg\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .lg\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .lg\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .lg\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .lg\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .lg\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .lg\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .lg\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .lg\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .lg\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .lg\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .lg\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .lg\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .lg\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .lg\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .lg\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .lg\:transition-none {
    transition-property: none
  }

  .lg\:transition-all {
    transition-property: all
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .lg\:transition-opacity {
    transition-property: opacity
  }

  .lg\:transition-shadow {
    transition-property: box-shadow
  }

  .lg\:transition-transform {
    transition-property: transform
  }

  .lg\:ease-linear {
    transition-timing-function: linear
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .lg\:duration-75 {
    transition-duration: 75ms
  }

  .lg\:duration-100 {
    transition-duration: 100ms
  }

  .lg\:duration-150 {
    transition-duration: 150ms
  }

  .lg\:duration-200 {
    transition-duration: 200ms
  }

  .lg\:duration-300 {
    transition-duration: 300ms
  }

  .lg\:duration-500 {
    transition-duration: 500ms
  }

  .lg\:duration-700 {
    transition-duration: 700ms
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms
  }

  .lg\:delay-75 {
    transition-delay: 75ms
  }

  .lg\:delay-100 {
    transition-delay: 100ms
  }

  .lg\:delay-150 {
    transition-delay: 150ms
  }

  .lg\:delay-200 {
    transition-delay: 200ms
  }

  .lg\:delay-300 {
    transition-delay: 300ms
  }

  .lg\:delay-500 {
    transition-delay: 500ms
  }

  .lg\:delay-700 {
    transition-delay: 700ms
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms
  }

  .lg\:animate-none {
    -webkit-animation: none;
            animation: none
  }

  .lg\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .lg\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .lg\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .lg\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

@media (min-width: 1280px) {
  .xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse))
  }

  .xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse))
  }

  .xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse))
  }

  .xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse))
  }

  .xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse))
  }

  .xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse))
  }

  .xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse))
  }

  .xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse))
  }

  .xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse))
  }

  .xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse))
  }

  .xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse))
  }

  .xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse))
  }

  .xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse))
  }

  .xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse))
  }

  .xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse))
  }

  .xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse))
  }

  .xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse))
  }

  .xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse))
  }

  .xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse))
  }

  .xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse))
  }

  .xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse))
  }

  .xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse))
  }

  .xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse))
  }

  .xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse))
  }

  .xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse))
  }

  .xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse))
  }

  .xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse))
  }

  .xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)))
  }

  .xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse))
  }

  .xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)))
  }

  .xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1
  }

  .xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1
  }

  .xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse))
  }

  .xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse))
  }

  .xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse))
  }

  .xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse))
  }

  .xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse))
  }

  .xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)))
  }

  .xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1
  }

  .xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1
  }

  .xl\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary)
  }

  .xl\:divide-primary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-shade)
  }

  .xl\:divide-primary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-primary-tint)
  }

  .xl\:divide-secondary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary)
  }

  .xl\:divide-secondary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-shade)
  }

  .xl\:divide-secondary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-secondary-tint)
  }

  .xl\:divide-tertiary > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary)
  }

  .xl\:divide-tertiary-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-shade)
  }

  .xl\:divide-tertiary-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-tertiary-tint)
  }

  .xl\:divide-light > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light)
  }

  .xl\:divide-light-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-shade)
  }

  .xl\:divide-light-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-light-tint)
  }

  .xl\:divide-medium > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium)
  }

  .xl\:divide-medium-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-shade)
  }

  .xl\:divide-medium-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-medium-tint)
  }

  .xl\:divide-dark > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark)
  }

  .xl\:divide-dark-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-shade)
  }

  .xl\:divide-dark-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-dark-tint)
  }

  .xl\:divide-success > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success)
  }

  .xl\:divide-success-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-shade)
  }

  .xl\:divide-success-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-success-tint)
  }

  .xl\:divide-warning > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning)
  }

  .xl\:divide-warning-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-shade)
  }

  .xl\:divide-warning-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-warning-tint)
  }

  .xl\:divide-danger > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger)
  }

  .xl\:divide-danger-shade > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-shade)
  }

  .xl\:divide-danger-tint > :not(template) ~ :not(template) {
    border-color: var(--ion-color-danger-tint)
  }

  .xl\:divide-step-50 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-50)
  }

  .xl\:divide-step-100 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-100)
  }

  .xl\:divide-step-150 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-150)
  }

  .xl\:divide-step-200 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-200)
  }

  .xl\:divide-step-250 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-250)
  }

  .xl\:divide-step-300 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-300)
  }

  .xl\:divide-step-350 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-350)
  }

  .xl\:divide-step-400 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-400)
  }

  .xl\:divide-step-450 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-450)
  }

  .xl\:divide-step-500 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-500)
  }

  .xl\:divide-step-550 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-550)
  }

  .xl\:divide-step-600 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-600)
  }

  .xl\:divide-step-650 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-650)
  }

  .xl\:divide-step-700 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-700)
  }

  .xl\:divide-step-750 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-750)
  }

  .xl\:divide-step-800 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-800)
  }

  .xl\:divide-step-850 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-850)
  }

  .xl\:divide-step-900 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-900)
  }

  .xl\:divide-step-950 > :not(template) ~ :not(template) {
    border-color: var(--ion-color-step-950)
  }

  .xl\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid
  }

  .xl\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed
  }

  .xl\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted
  }

  .xl\:divide-double > :not(template) ~ :not(template) {
    border-style: double
  }

  .xl\:divide-none > :not(template) ~ :not(template) {
    border-style: none
  }

  .xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0
  }

  .xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25
  }

  .xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5
  }

  .xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75
  }

  .xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1
  }

  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
  }

  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .xl\:appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
  }

  .xl\:bg-fixed {
    background-attachment: fixed
  }

  .xl\:bg-local {
    background-attachment: local
  }

  .xl\:bg-scroll {
    background-attachment: scroll
  }

  .xl\:bg-clip-border {
    background-clip: border-box
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box
  }

  .xl\:bg-clip-content {
    background-clip: content-box
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text
  }

  .xl\:bg-primary {
    background-color: var(--ion-color-primary)
  }

  .xl\:bg-primary-shade {
    background-color: var(--ion-color-primary-shade)
  }

  .xl\:bg-primary-tint {
    background-color: var(--ion-color-primary-tint)
  }

  .xl\:bg-secondary {
    background-color: var(--ion-color-secondary)
  }

  .xl\:bg-secondary-shade {
    background-color: var(--ion-color-secondary-shade)
  }

  .xl\:bg-secondary-tint {
    background-color: var(--ion-color-secondary-tint)
  }

  .xl\:bg-tertiary {
    background-color: var(--ion-color-tertiary)
  }

  .xl\:bg-tertiary-shade {
    background-color: var(--ion-color-tertiary-shade)
  }

  .xl\:bg-tertiary-tint {
    background-color: var(--ion-color-tertiary-tint)
  }

  .xl\:bg-light {
    background-color: var(--ion-color-light)
  }

  .xl\:bg-light-shade {
    background-color: var(--ion-color-light-shade)
  }

  .xl\:bg-light-tint {
    background-color: var(--ion-color-light-tint)
  }

  .xl\:bg-medium {
    background-color: var(--ion-color-medium)
  }

  .xl\:bg-medium-shade {
    background-color: var(--ion-color-medium-shade)
  }

  .xl\:bg-medium-tint {
    background-color: var(--ion-color-medium-tint)
  }

  .xl\:bg-dark {
    background-color: var(--ion-color-dark)
  }

  .xl\:bg-dark-shade {
    background-color: var(--ion-color-dark-shade)
  }

  .xl\:bg-dark-tint {
    background-color: var(--ion-color-dark-tint)
  }

  .xl\:bg-success {
    background-color: var(--ion-color-success)
  }

  .xl\:bg-success-shade {
    background-color: var(--ion-color-success-shade)
  }

  .xl\:bg-success-tint {
    background-color: var(--ion-color-success-tint)
  }

  .xl\:bg-warning {
    background-color: var(--ion-color-warning)
  }

  .xl\:bg-warning-shade {
    background-color: var(--ion-color-warning-shade)
  }

  .xl\:bg-warning-tint {
    background-color: var(--ion-color-warning-tint)
  }

  .xl\:bg-danger {
    background-color: var(--ion-color-danger)
  }

  .xl\:bg-danger-shade {
    background-color: var(--ion-color-danger-shade)
  }

  .xl\:bg-danger-tint {
    background-color: var(--ion-color-danger-tint)
  }

  .xl\:bg-step-50 {
    background-color: var(--ion-color-step-50)
  }

  .xl\:bg-step-100 {
    background-color: var(--ion-color-step-100)
  }

  .xl\:bg-step-150 {
    background-color: var(--ion-color-step-150)
  }

  .xl\:bg-step-200 {
    background-color: var(--ion-color-step-200)
  }

  .xl\:bg-step-250 {
    background-color: var(--ion-color-step-250)
  }

  .xl\:bg-step-300 {
    background-color: var(--ion-color-step-300)
  }

  .xl\:bg-step-350 {
    background-color: var(--ion-color-step-350)
  }

  .xl\:bg-step-400 {
    background-color: var(--ion-color-step-400)
  }

  .xl\:bg-step-450 {
    background-color: var(--ion-color-step-450)
  }

  .xl\:bg-step-500 {
    background-color: var(--ion-color-step-500)
  }

  .xl\:bg-step-550 {
    background-color: var(--ion-color-step-550)
  }

  .xl\:bg-step-600 {
    background-color: var(--ion-color-step-600)
  }

  .xl\:bg-step-650 {
    background-color: var(--ion-color-step-650)
  }

  .xl\:bg-step-700 {
    background-color: var(--ion-color-step-700)
  }

  .xl\:bg-step-750 {
    background-color: var(--ion-color-step-750)
  }

  .xl\:bg-step-800 {
    background-color: var(--ion-color-step-800)
  }

  .xl\:bg-step-850 {
    background-color: var(--ion-color-step-850)
  }

  .xl\:bg-step-900 {
    background-color: var(--ion-color-step-900)
  }

  .xl\:bg-step-950 {
    background-color: var(--ion-color-step-950)
  }

  .xl\:hover\:bg-primary:hover {
    background-color: var(--ion-color-primary)
  }

  .xl\:hover\:bg-primary-shade:hover {
    background-color: var(--ion-color-primary-shade)
  }

  .xl\:hover\:bg-primary-tint:hover {
    background-color: var(--ion-color-primary-tint)
  }

  .xl\:hover\:bg-secondary:hover {
    background-color: var(--ion-color-secondary)
  }

  .xl\:hover\:bg-secondary-shade:hover {
    background-color: var(--ion-color-secondary-shade)
  }

  .xl\:hover\:bg-secondary-tint:hover {
    background-color: var(--ion-color-secondary-tint)
  }

  .xl\:hover\:bg-tertiary:hover {
    background-color: var(--ion-color-tertiary)
  }

  .xl\:hover\:bg-tertiary-shade:hover {
    background-color: var(--ion-color-tertiary-shade)
  }

  .xl\:hover\:bg-tertiary-tint:hover {
    background-color: var(--ion-color-tertiary-tint)
  }

  .xl\:hover\:bg-light:hover {
    background-color: var(--ion-color-light)
  }

  .xl\:hover\:bg-light-shade:hover {
    background-color: var(--ion-color-light-shade)
  }

  .xl\:hover\:bg-light-tint:hover {
    background-color: var(--ion-color-light-tint)
  }

  .xl\:hover\:bg-medium:hover {
    background-color: var(--ion-color-medium)
  }

  .xl\:hover\:bg-medium-shade:hover {
    background-color: var(--ion-color-medium-shade)
  }

  .xl\:hover\:bg-medium-tint:hover {
    background-color: var(--ion-color-medium-tint)
  }

  .xl\:hover\:bg-dark:hover {
    background-color: var(--ion-color-dark)
  }

  .xl\:hover\:bg-dark-shade:hover {
    background-color: var(--ion-color-dark-shade)
  }

  .xl\:hover\:bg-dark-tint:hover {
    background-color: var(--ion-color-dark-tint)
  }

  .xl\:hover\:bg-success:hover {
    background-color: var(--ion-color-success)
  }

  .xl\:hover\:bg-success-shade:hover {
    background-color: var(--ion-color-success-shade)
  }

  .xl\:hover\:bg-success-tint:hover {
    background-color: var(--ion-color-success-tint)
  }

  .xl\:hover\:bg-warning:hover {
    background-color: var(--ion-color-warning)
  }

  .xl\:hover\:bg-warning-shade:hover {
    background-color: var(--ion-color-warning-shade)
  }

  .xl\:hover\:bg-warning-tint:hover {
    background-color: var(--ion-color-warning-tint)
  }

  .xl\:hover\:bg-danger:hover {
    background-color: var(--ion-color-danger)
  }

  .xl\:hover\:bg-danger-shade:hover {
    background-color: var(--ion-color-danger-shade)
  }

  .xl\:hover\:bg-danger-tint:hover {
    background-color: var(--ion-color-danger-tint)
  }

  .xl\:hover\:bg-step-50:hover {
    background-color: var(--ion-color-step-50)
  }

  .xl\:hover\:bg-step-100:hover {
    background-color: var(--ion-color-step-100)
  }

  .xl\:hover\:bg-step-150:hover {
    background-color: var(--ion-color-step-150)
  }

  .xl\:hover\:bg-step-200:hover {
    background-color: var(--ion-color-step-200)
  }

  .xl\:hover\:bg-step-250:hover {
    background-color: var(--ion-color-step-250)
  }

  .xl\:hover\:bg-step-300:hover {
    background-color: var(--ion-color-step-300)
  }

  .xl\:hover\:bg-step-350:hover {
    background-color: var(--ion-color-step-350)
  }

  .xl\:hover\:bg-step-400:hover {
    background-color: var(--ion-color-step-400)
  }

  .xl\:hover\:bg-step-450:hover {
    background-color: var(--ion-color-step-450)
  }

  .xl\:hover\:bg-step-500:hover {
    background-color: var(--ion-color-step-500)
  }

  .xl\:hover\:bg-step-550:hover {
    background-color: var(--ion-color-step-550)
  }

  .xl\:hover\:bg-step-600:hover {
    background-color: var(--ion-color-step-600)
  }

  .xl\:hover\:bg-step-650:hover {
    background-color: var(--ion-color-step-650)
  }

  .xl\:hover\:bg-step-700:hover {
    background-color: var(--ion-color-step-700)
  }

  .xl\:hover\:bg-step-750:hover {
    background-color: var(--ion-color-step-750)
  }

  .xl\:hover\:bg-step-800:hover {
    background-color: var(--ion-color-step-800)
  }

  .xl\:hover\:bg-step-850:hover {
    background-color: var(--ion-color-step-850)
  }

  .xl\:hover\:bg-step-900:hover {
    background-color: var(--ion-color-step-900)
  }

  .xl\:hover\:bg-step-950:hover {
    background-color: var(--ion-color-step-950)
  }

  .xl\:focus\:bg-primary:focus {
    background-color: var(--ion-color-primary)
  }

  .xl\:focus\:bg-primary-shade:focus {
    background-color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:bg-primary-tint:focus {
    background-color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:bg-secondary:focus {
    background-color: var(--ion-color-secondary)
  }

  .xl\:focus\:bg-secondary-shade:focus {
    background-color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:bg-secondary-tint:focus {
    background-color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:bg-tertiary:focus {
    background-color: var(--ion-color-tertiary)
  }

  .xl\:focus\:bg-tertiary-shade:focus {
    background-color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:bg-tertiary-tint:focus {
    background-color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:bg-light:focus {
    background-color: var(--ion-color-light)
  }

  .xl\:focus\:bg-light-shade:focus {
    background-color: var(--ion-color-light-shade)
  }

  .xl\:focus\:bg-light-tint:focus {
    background-color: var(--ion-color-light-tint)
  }

  .xl\:focus\:bg-medium:focus {
    background-color: var(--ion-color-medium)
  }

  .xl\:focus\:bg-medium-shade:focus {
    background-color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:bg-medium-tint:focus {
    background-color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:bg-dark:focus {
    background-color: var(--ion-color-dark)
  }

  .xl\:focus\:bg-dark-shade:focus {
    background-color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:bg-dark-tint:focus {
    background-color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:bg-success:focus {
    background-color: var(--ion-color-success)
  }

  .xl\:focus\:bg-success-shade:focus {
    background-color: var(--ion-color-success-shade)
  }

  .xl\:focus\:bg-success-tint:focus {
    background-color: var(--ion-color-success-tint)
  }

  .xl\:focus\:bg-warning:focus {
    background-color: var(--ion-color-warning)
  }

  .xl\:focus\:bg-warning-shade:focus {
    background-color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:bg-warning-tint:focus {
    background-color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:bg-danger:focus {
    background-color: var(--ion-color-danger)
  }

  .xl\:focus\:bg-danger-shade:focus {
    background-color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:bg-danger-tint:focus {
    background-color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:bg-step-50:focus {
    background-color: var(--ion-color-step-50)
  }

  .xl\:focus\:bg-step-100:focus {
    background-color: var(--ion-color-step-100)
  }

  .xl\:focus\:bg-step-150:focus {
    background-color: var(--ion-color-step-150)
  }

  .xl\:focus\:bg-step-200:focus {
    background-color: var(--ion-color-step-200)
  }

  .xl\:focus\:bg-step-250:focus {
    background-color: var(--ion-color-step-250)
  }

  .xl\:focus\:bg-step-300:focus {
    background-color: var(--ion-color-step-300)
  }

  .xl\:focus\:bg-step-350:focus {
    background-color: var(--ion-color-step-350)
  }

  .xl\:focus\:bg-step-400:focus {
    background-color: var(--ion-color-step-400)
  }

  .xl\:focus\:bg-step-450:focus {
    background-color: var(--ion-color-step-450)
  }

  .xl\:focus\:bg-step-500:focus {
    background-color: var(--ion-color-step-500)
  }

  .xl\:focus\:bg-step-550:focus {
    background-color: var(--ion-color-step-550)
  }

  .xl\:focus\:bg-step-600:focus {
    background-color: var(--ion-color-step-600)
  }

  .xl\:focus\:bg-step-650:focus {
    background-color: var(--ion-color-step-650)
  }

  .xl\:focus\:bg-step-700:focus {
    background-color: var(--ion-color-step-700)
  }

  .xl\:focus\:bg-step-750:focus {
    background-color: var(--ion-color-step-750)
  }

  .xl\:focus\:bg-step-800:focus {
    background-color: var(--ion-color-step-800)
  }

  .xl\:focus\:bg-step-850:focus {
    background-color: var(--ion-color-step-850)
  }

  .xl\:focus\:bg-step-900:focus {
    background-color: var(--ion-color-step-900)
  }

  .xl\:focus\:bg-step-950:focus {
    background-color: var(--ion-color-step-950)
  }

  .xl\:bg-none {
    background-image: none
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xl\:from-primary {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-primary-shade {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-primary-tint {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-secondary {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-secondary-shade {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-secondary-tint {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-tertiary {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-tertiary-shade {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-tertiary-tint {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-light {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-light-shade {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-light-tint {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-medium {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-medium-shade {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-medium-tint {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-dark {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-dark-shade {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-dark-tint {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-success {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-success-shade {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-success-tint {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-warning {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-warning-shade {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-warning-tint {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-danger {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-danger-shade {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-danger-tint {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-50 {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-100 {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-150 {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-200 {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-250 {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-300 {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-350 {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-400 {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-450 {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-500 {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-550 {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-600 {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-650 {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-700 {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-750 {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-800 {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-850 {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-900 {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:from-step-950 {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-primary {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-primary-shade {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-primary-tint {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-secondary {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-secondary-shade {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-secondary-tint {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-tertiary {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-tertiary-shade {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-tertiary-tint {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-light {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-light-shade {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-light-tint {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-medium {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-medium-shade {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-medium-tint {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-dark {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-dark-shade {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-dark-tint {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-success {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-success-shade {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-success-tint {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-warning {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-warning-shade {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-warning-tint {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-danger {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-danger-shade {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-danger-tint {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-50 {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-100 {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-150 {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-200 {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-250 {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-300 {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-350 {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-400 {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-450 {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-500 {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-550 {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-600 {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-650 {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-700 {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-750 {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-800 {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-850 {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-900 {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:via-step-950 {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:to-primary {
    --gradient-to-color: var(--ion-color-primary)
  }

  .xl\:to-primary-shade {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .xl\:to-primary-tint {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .xl\:to-secondary {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .xl\:to-secondary-shade {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .xl\:to-secondary-tint {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .xl\:to-tertiary {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .xl\:to-tertiary-shade {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .xl\:to-tertiary-tint {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .xl\:to-light {
    --gradient-to-color: var(--ion-color-light)
  }

  .xl\:to-light-shade {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .xl\:to-light-tint {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .xl\:to-medium {
    --gradient-to-color: var(--ion-color-medium)
  }

  .xl\:to-medium-shade {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .xl\:to-medium-tint {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .xl\:to-dark {
    --gradient-to-color: var(--ion-color-dark)
  }

  .xl\:to-dark-shade {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .xl\:to-dark-tint {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .xl\:to-success {
    --gradient-to-color: var(--ion-color-success)
  }

  .xl\:to-success-shade {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .xl\:to-success-tint {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .xl\:to-warning {
    --gradient-to-color: var(--ion-color-warning)
  }

  .xl\:to-warning-shade {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .xl\:to-warning-tint {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .xl\:to-danger {
    --gradient-to-color: var(--ion-color-danger)
  }

  .xl\:to-danger-shade {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .xl\:to-danger-tint {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .xl\:to-step-50 {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .xl\:to-step-100 {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .xl\:to-step-150 {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .xl\:to-step-200 {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .xl\:to-step-250 {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .xl\:to-step-300 {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .xl\:to-step-350 {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .xl\:to-step-400 {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .xl\:to-step-450 {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .xl\:to-step-500 {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .xl\:to-step-550 {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .xl\:to-step-600 {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .xl\:to-step-650 {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .xl\:to-step-700 {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .xl\:to-step-750 {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .xl\:to-step-800 {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .xl\:to-step-850 {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .xl\:to-step-900 {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .xl\:to-step-950 {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .xl\:hover\:from-primary:hover {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-primary-shade:hover {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-primary-tint:hover {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-secondary:hover {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-secondary-shade:hover {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-secondary-tint:hover {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-tertiary:hover {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-tertiary-shade:hover {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-tertiary-tint:hover {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-light:hover {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-light-shade:hover {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-light-tint:hover {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-medium:hover {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-medium-shade:hover {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-medium-tint:hover {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-dark:hover {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-dark-shade:hover {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-dark-tint:hover {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-success:hover {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-success-shade:hover {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-success-tint:hover {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-warning:hover {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-warning-shade:hover {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-warning-tint:hover {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-danger:hover {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-danger-shade:hover {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-danger-tint:hover {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-50:hover {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-100:hover {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-150:hover {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-200:hover {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-250:hover {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-300:hover {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-350:hover {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-400:hover {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-450:hover {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-500:hover {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-550:hover {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-600:hover {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-650:hover {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-700:hover {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-750:hover {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-800:hover {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-850:hover {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-900:hover {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:from-step-950:hover {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-primary:hover {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-primary-shade:hover {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-primary-tint:hover {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-secondary:hover {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-secondary-shade:hover {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-secondary-tint:hover {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-tertiary:hover {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-tertiary-shade:hover {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-tertiary-tint:hover {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-light:hover {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-light-shade:hover {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-light-tint:hover {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-medium:hover {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-medium-shade:hover {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-medium-tint:hover {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-dark:hover {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-dark-shade:hover {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-dark-tint:hover {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-success:hover {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-success-shade:hover {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-success-tint:hover {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-warning:hover {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-warning-shade:hover {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-warning-tint:hover {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-danger:hover {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-danger-shade:hover {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-danger-tint:hover {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-50:hover {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-100:hover {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-150:hover {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-200:hover {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-250:hover {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-300:hover {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-350:hover {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-400:hover {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-450:hover {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-500:hover {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-550:hover {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-600:hover {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-650:hover {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-700:hover {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-750:hover {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-800:hover {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-850:hover {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-900:hover {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:via-step-950:hover {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:hover\:to-primary:hover {
    --gradient-to-color: var(--ion-color-primary)
  }

  .xl\:hover\:to-primary-shade:hover {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .xl\:hover\:to-primary-tint:hover {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .xl\:hover\:to-secondary:hover {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .xl\:hover\:to-secondary-shade:hover {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .xl\:hover\:to-secondary-tint:hover {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .xl\:hover\:to-tertiary:hover {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .xl\:hover\:to-tertiary-shade:hover {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .xl\:hover\:to-tertiary-tint:hover {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .xl\:hover\:to-light:hover {
    --gradient-to-color: var(--ion-color-light)
  }

  .xl\:hover\:to-light-shade:hover {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .xl\:hover\:to-light-tint:hover {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .xl\:hover\:to-medium:hover {
    --gradient-to-color: var(--ion-color-medium)
  }

  .xl\:hover\:to-medium-shade:hover {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .xl\:hover\:to-medium-tint:hover {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .xl\:hover\:to-dark:hover {
    --gradient-to-color: var(--ion-color-dark)
  }

  .xl\:hover\:to-dark-shade:hover {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .xl\:hover\:to-dark-tint:hover {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .xl\:hover\:to-success:hover {
    --gradient-to-color: var(--ion-color-success)
  }

  .xl\:hover\:to-success-shade:hover {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .xl\:hover\:to-success-tint:hover {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .xl\:hover\:to-warning:hover {
    --gradient-to-color: var(--ion-color-warning)
  }

  .xl\:hover\:to-warning-shade:hover {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .xl\:hover\:to-warning-tint:hover {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .xl\:hover\:to-danger:hover {
    --gradient-to-color: var(--ion-color-danger)
  }

  .xl\:hover\:to-danger-shade:hover {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .xl\:hover\:to-danger-tint:hover {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .xl\:hover\:to-step-50:hover {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .xl\:hover\:to-step-100:hover {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .xl\:hover\:to-step-150:hover {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .xl\:hover\:to-step-200:hover {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .xl\:hover\:to-step-250:hover {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .xl\:hover\:to-step-300:hover {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .xl\:hover\:to-step-350:hover {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .xl\:hover\:to-step-400:hover {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .xl\:hover\:to-step-450:hover {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .xl\:hover\:to-step-500:hover {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .xl\:hover\:to-step-550:hover {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .xl\:hover\:to-step-600:hover {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .xl\:hover\:to-step-650:hover {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .xl\:hover\:to-step-700:hover {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .xl\:hover\:to-step-750:hover {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .xl\:hover\:to-step-800:hover {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .xl\:hover\:to-step-850:hover {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .xl\:hover\:to-step-900:hover {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .xl\:hover\:to-step-950:hover {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .xl\:focus\:from-primary:focus {
    --gradient-from-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-primary-shade:focus {
    --gradient-from-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-primary-tint:focus {
    --gradient-from-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-secondary:focus {
    --gradient-from-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-secondary-shade:focus {
    --gradient-from-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-secondary-tint:focus {
    --gradient-from-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-tertiary:focus {
    --gradient-from-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-tertiary-shade:focus {
    --gradient-from-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-tertiary-tint:focus {
    --gradient-from-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-light:focus {
    --gradient-from-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-light-shade:focus {
    --gradient-from-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-light-tint:focus {
    --gradient-from-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-medium:focus {
    --gradient-from-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-medium-shade:focus {
    --gradient-from-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-medium-tint:focus {
    --gradient-from-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-dark:focus {
    --gradient-from-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-dark-shade:focus {
    --gradient-from-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-dark-tint:focus {
    --gradient-from-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-success:focus {
    --gradient-from-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-success-shade:focus {
    --gradient-from-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-success-tint:focus {
    --gradient-from-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-warning:focus {
    --gradient-from-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-warning-shade:focus {
    --gradient-from-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-warning-tint:focus {
    --gradient-from-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-danger:focus {
    --gradient-from-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-danger-shade:focus {
    --gradient-from-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-danger-tint:focus {
    --gradient-from-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-50:focus {
    --gradient-from-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-100:focus {
    --gradient-from-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-150:focus {
    --gradient-from-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-200:focus {
    --gradient-from-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-250:focus {
    --gradient-from-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-300:focus {
    --gradient-from-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-350:focus {
    --gradient-from-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-400:focus {
    --gradient-from-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-450:focus {
    --gradient-from-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-500:focus {
    --gradient-from-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-550:focus {
    --gradient-from-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-600:focus {
    --gradient-from-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-650:focus {
    --gradient-from-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-700:focus {
    --gradient-from-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-750:focus {
    --gradient-from-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-800:focus {
    --gradient-from-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-850:focus {
    --gradient-from-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-900:focus {
    --gradient-from-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:from-step-950:focus {
    --gradient-from-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-primary:focus {
    --gradient-via-color: var(--ion-color-primary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-primary-shade:focus {
    --gradient-via-color: var(--ion-color-primary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-primary-tint:focus {
    --gradient-via-color: var(--ion-color-primary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-secondary:focus {
    --gradient-via-color: var(--ion-color-secondary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-secondary-shade:focus {
    --gradient-via-color: var(--ion-color-secondary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-secondary-tint:focus {
    --gradient-via-color: var(--ion-color-secondary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-tertiary:focus {
    --gradient-via-color: var(--ion-color-tertiary);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-tertiary-shade:focus {
    --gradient-via-color: var(--ion-color-tertiary-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-tertiary-tint:focus {
    --gradient-via-color: var(--ion-color-tertiary-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-light:focus {
    --gradient-via-color: var(--ion-color-light);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-light-shade:focus {
    --gradient-via-color: var(--ion-color-light-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-light-tint:focus {
    --gradient-via-color: var(--ion-color-light-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-medium:focus {
    --gradient-via-color: var(--ion-color-medium);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-medium-shade:focus {
    --gradient-via-color: var(--ion-color-medium-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-medium-tint:focus {
    --gradient-via-color: var(--ion-color-medium-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-dark:focus {
    --gradient-via-color: var(--ion-color-dark);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-dark-shade:focus {
    --gradient-via-color: var(--ion-color-dark-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-dark-tint:focus {
    --gradient-via-color: var(--ion-color-dark-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-success:focus {
    --gradient-via-color: var(--ion-color-success);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-success-shade:focus {
    --gradient-via-color: var(--ion-color-success-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-success-tint:focus {
    --gradient-via-color: var(--ion-color-success-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-warning:focus {
    --gradient-via-color: var(--ion-color-warning);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-warning-shade:focus {
    --gradient-via-color: var(--ion-color-warning-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-warning-tint:focus {
    --gradient-via-color: var(--ion-color-warning-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-danger:focus {
    --gradient-via-color: var(--ion-color-danger);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-danger-shade:focus {
    --gradient-via-color: var(--ion-color-danger-shade);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-danger-tint:focus {
    --gradient-via-color: var(--ion-color-danger-tint);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-50:focus {
    --gradient-via-color: var(--ion-color-step-50);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-100:focus {
    --gradient-via-color: var(--ion-color-step-100);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-150:focus {
    --gradient-via-color: var(--ion-color-step-150);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-200:focus {
    --gradient-via-color: var(--ion-color-step-200);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-250:focus {
    --gradient-via-color: var(--ion-color-step-250);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-300:focus {
    --gradient-via-color: var(--ion-color-step-300);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-350:focus {
    --gradient-via-color: var(--ion-color-step-350);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-400:focus {
    --gradient-via-color: var(--ion-color-step-400);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-450:focus {
    --gradient-via-color: var(--ion-color-step-450);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-500:focus {
    --gradient-via-color: var(--ion-color-step-500);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-550:focus {
    --gradient-via-color: var(--ion-color-step-550);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-600:focus {
    --gradient-via-color: var(--ion-color-step-600);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-650:focus {
    --gradient-via-color: var(--ion-color-step-650);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-700:focus {
    --gradient-via-color: var(--ion-color-step-700);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-750:focus {
    --gradient-via-color: var(--ion-color-step-750);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-800:focus {
    --gradient-via-color: var(--ion-color-step-800);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-850:focus {
    --gradient-via-color: var(--ion-color-step-850);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-900:focus {
    --gradient-via-color: var(--ion-color-step-900);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:via-step-950:focus {
    --gradient-via-color: var(--ion-color-step-950);
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
  }

  .xl\:focus\:to-primary:focus {
    --gradient-to-color: var(--ion-color-primary)
  }

  .xl\:focus\:to-primary-shade:focus {
    --gradient-to-color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:to-primary-tint:focus {
    --gradient-to-color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:to-secondary:focus {
    --gradient-to-color: var(--ion-color-secondary)
  }

  .xl\:focus\:to-secondary-shade:focus {
    --gradient-to-color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:to-secondary-tint:focus {
    --gradient-to-color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:to-tertiary:focus {
    --gradient-to-color: var(--ion-color-tertiary)
  }

  .xl\:focus\:to-tertiary-shade:focus {
    --gradient-to-color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:to-tertiary-tint:focus {
    --gradient-to-color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:to-light:focus {
    --gradient-to-color: var(--ion-color-light)
  }

  .xl\:focus\:to-light-shade:focus {
    --gradient-to-color: var(--ion-color-light-shade)
  }

  .xl\:focus\:to-light-tint:focus {
    --gradient-to-color: var(--ion-color-light-tint)
  }

  .xl\:focus\:to-medium:focus {
    --gradient-to-color: var(--ion-color-medium)
  }

  .xl\:focus\:to-medium-shade:focus {
    --gradient-to-color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:to-medium-tint:focus {
    --gradient-to-color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:to-dark:focus {
    --gradient-to-color: var(--ion-color-dark)
  }

  .xl\:focus\:to-dark-shade:focus {
    --gradient-to-color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:to-dark-tint:focus {
    --gradient-to-color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:to-success:focus {
    --gradient-to-color: var(--ion-color-success)
  }

  .xl\:focus\:to-success-shade:focus {
    --gradient-to-color: var(--ion-color-success-shade)
  }

  .xl\:focus\:to-success-tint:focus {
    --gradient-to-color: var(--ion-color-success-tint)
  }

  .xl\:focus\:to-warning:focus {
    --gradient-to-color: var(--ion-color-warning)
  }

  .xl\:focus\:to-warning-shade:focus {
    --gradient-to-color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:to-warning-tint:focus {
    --gradient-to-color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:to-danger:focus {
    --gradient-to-color: var(--ion-color-danger)
  }

  .xl\:focus\:to-danger-shade:focus {
    --gradient-to-color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:to-danger-tint:focus {
    --gradient-to-color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:to-step-50:focus {
    --gradient-to-color: var(--ion-color-step-50)
  }

  .xl\:focus\:to-step-100:focus {
    --gradient-to-color: var(--ion-color-step-100)
  }

  .xl\:focus\:to-step-150:focus {
    --gradient-to-color: var(--ion-color-step-150)
  }

  .xl\:focus\:to-step-200:focus {
    --gradient-to-color: var(--ion-color-step-200)
  }

  .xl\:focus\:to-step-250:focus {
    --gradient-to-color: var(--ion-color-step-250)
  }

  .xl\:focus\:to-step-300:focus {
    --gradient-to-color: var(--ion-color-step-300)
  }

  .xl\:focus\:to-step-350:focus {
    --gradient-to-color: var(--ion-color-step-350)
  }

  .xl\:focus\:to-step-400:focus {
    --gradient-to-color: var(--ion-color-step-400)
  }

  .xl\:focus\:to-step-450:focus {
    --gradient-to-color: var(--ion-color-step-450)
  }

  .xl\:focus\:to-step-500:focus {
    --gradient-to-color: var(--ion-color-step-500)
  }

  .xl\:focus\:to-step-550:focus {
    --gradient-to-color: var(--ion-color-step-550)
  }

  .xl\:focus\:to-step-600:focus {
    --gradient-to-color: var(--ion-color-step-600)
  }

  .xl\:focus\:to-step-650:focus {
    --gradient-to-color: var(--ion-color-step-650)
  }

  .xl\:focus\:to-step-700:focus {
    --gradient-to-color: var(--ion-color-step-700)
  }

  .xl\:focus\:to-step-750:focus {
    --gradient-to-color: var(--ion-color-step-750)
  }

  .xl\:focus\:to-step-800:focus {
    --gradient-to-color: var(--ion-color-step-800)
  }

  .xl\:focus\:to-step-850:focus {
    --gradient-to-color: var(--ion-color-step-850)
  }

  .xl\:focus\:to-step-900:focus {
    --gradient-to-color: var(--ion-color-step-900)
  }

  .xl\:focus\:to-step-950:focus {
    --gradient-to-color: var(--ion-color-step-950)
  }

  .xl\:bg-bottom {
    background-position: bottom
  }

  .xl\:bg-center {
    background-position: center
  }

  .xl\:bg-left {
    background-position: left
  }

  .xl\:bg-left-bottom {
    background-position: left bottom
  }

  .xl\:bg-left-top {
    background-position: left top
  }

  .xl\:bg-right {
    background-position: right
  }

  .xl\:bg-right-bottom {
    background-position: right bottom
  }

  .xl\:bg-right-top {
    background-position: right top
  }

  .xl\:bg-top {
    background-position: top
  }

  .xl\:bg-repeat {
    background-repeat: repeat
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y
  }

  .xl\:bg-repeat-round {
    background-repeat: round
  }

  .xl\:bg-repeat-space {
    background-repeat: space
  }

  .xl\:bg-auto {
    background-size: auto
  }

  .xl\:bg-cover {
    background-size: cover
  }

  .xl\:bg-contain {
    background-size: contain
  }

  .xl\:border-collapse {
    border-collapse: collapse
  }

  .xl\:border-separate {
    border-collapse: separate
  }

  .xl\:border-primary {
    border-color: var(--ion-color-primary)
  }

  .xl\:border-primary-shade {
    border-color: var(--ion-color-primary-shade)
  }

  .xl\:border-primary-tint {
    border-color: var(--ion-color-primary-tint)
  }

  .xl\:border-secondary {
    border-color: var(--ion-color-secondary)
  }

  .xl\:border-secondary-shade {
    border-color: var(--ion-color-secondary-shade)
  }

  .xl\:border-secondary-tint {
    border-color: var(--ion-color-secondary-tint)
  }

  .xl\:border-tertiary {
    border-color: var(--ion-color-tertiary)
  }

  .xl\:border-tertiary-shade {
    border-color: var(--ion-color-tertiary-shade)
  }

  .xl\:border-tertiary-tint {
    border-color: var(--ion-color-tertiary-tint)
  }

  .xl\:border-light {
    border-color: var(--ion-color-light)
  }

  .xl\:border-light-shade {
    border-color: var(--ion-color-light-shade)
  }

  .xl\:border-light-tint {
    border-color: var(--ion-color-light-tint)
  }

  .xl\:border-medium {
    border-color: var(--ion-color-medium)
  }

  .xl\:border-medium-shade {
    border-color: var(--ion-color-medium-shade)
  }

  .xl\:border-medium-tint {
    border-color: var(--ion-color-medium-tint)
  }

  .xl\:border-dark {
    border-color: var(--ion-color-dark)
  }

  .xl\:border-dark-shade {
    border-color: var(--ion-color-dark-shade)
  }

  .xl\:border-dark-tint {
    border-color: var(--ion-color-dark-tint)
  }

  .xl\:border-success {
    border-color: var(--ion-color-success)
  }

  .xl\:border-success-shade {
    border-color: var(--ion-color-success-shade)
  }

  .xl\:border-success-tint {
    border-color: var(--ion-color-success-tint)
  }

  .xl\:border-warning {
    border-color: var(--ion-color-warning)
  }

  .xl\:border-warning-shade {
    border-color: var(--ion-color-warning-shade)
  }

  .xl\:border-warning-tint {
    border-color: var(--ion-color-warning-tint)
  }

  .xl\:border-danger {
    border-color: var(--ion-color-danger)
  }

  .xl\:border-danger-shade {
    border-color: var(--ion-color-danger-shade)
  }

  .xl\:border-danger-tint {
    border-color: var(--ion-color-danger-tint)
  }

  .xl\:border-step-50 {
    border-color: var(--ion-color-step-50)
  }

  .xl\:border-step-100 {
    border-color: var(--ion-color-step-100)
  }

  .xl\:border-step-150 {
    border-color: var(--ion-color-step-150)
  }

  .xl\:border-step-200 {
    border-color: var(--ion-color-step-200)
  }

  .xl\:border-step-250 {
    border-color: var(--ion-color-step-250)
  }

  .xl\:border-step-300 {
    border-color: var(--ion-color-step-300)
  }

  .xl\:border-step-350 {
    border-color: var(--ion-color-step-350)
  }

  .xl\:border-step-400 {
    border-color: var(--ion-color-step-400)
  }

  .xl\:border-step-450 {
    border-color: var(--ion-color-step-450)
  }

  .xl\:border-step-500 {
    border-color: var(--ion-color-step-500)
  }

  .xl\:border-step-550 {
    border-color: var(--ion-color-step-550)
  }

  .xl\:border-step-600 {
    border-color: var(--ion-color-step-600)
  }

  .xl\:border-step-650 {
    border-color: var(--ion-color-step-650)
  }

  .xl\:border-step-700 {
    border-color: var(--ion-color-step-700)
  }

  .xl\:border-step-750 {
    border-color: var(--ion-color-step-750)
  }

  .xl\:border-step-800 {
    border-color: var(--ion-color-step-800)
  }

  .xl\:border-step-850 {
    border-color: var(--ion-color-step-850)
  }

  .xl\:border-step-900 {
    border-color: var(--ion-color-step-900)
  }

  .xl\:border-step-950 {
    border-color: var(--ion-color-step-950)
  }

  .xl\:hover\:border-primary:hover {
    border-color: var(--ion-color-primary)
  }

  .xl\:hover\:border-primary-shade:hover {
    border-color: var(--ion-color-primary-shade)
  }

  .xl\:hover\:border-primary-tint:hover {
    border-color: var(--ion-color-primary-tint)
  }

  .xl\:hover\:border-secondary:hover {
    border-color: var(--ion-color-secondary)
  }

  .xl\:hover\:border-secondary-shade:hover {
    border-color: var(--ion-color-secondary-shade)
  }

  .xl\:hover\:border-secondary-tint:hover {
    border-color: var(--ion-color-secondary-tint)
  }

  .xl\:hover\:border-tertiary:hover {
    border-color: var(--ion-color-tertiary)
  }

  .xl\:hover\:border-tertiary-shade:hover {
    border-color: var(--ion-color-tertiary-shade)
  }

  .xl\:hover\:border-tertiary-tint:hover {
    border-color: var(--ion-color-tertiary-tint)
  }

  .xl\:hover\:border-light:hover {
    border-color: var(--ion-color-light)
  }

  .xl\:hover\:border-light-shade:hover {
    border-color: var(--ion-color-light-shade)
  }

  .xl\:hover\:border-light-tint:hover {
    border-color: var(--ion-color-light-tint)
  }

  .xl\:hover\:border-medium:hover {
    border-color: var(--ion-color-medium)
  }

  .xl\:hover\:border-medium-shade:hover {
    border-color: var(--ion-color-medium-shade)
  }

  .xl\:hover\:border-medium-tint:hover {
    border-color: var(--ion-color-medium-tint)
  }

  .xl\:hover\:border-dark:hover {
    border-color: var(--ion-color-dark)
  }

  .xl\:hover\:border-dark-shade:hover {
    border-color: var(--ion-color-dark-shade)
  }

  .xl\:hover\:border-dark-tint:hover {
    border-color: var(--ion-color-dark-tint)
  }

  .xl\:hover\:border-success:hover {
    border-color: var(--ion-color-success)
  }

  .xl\:hover\:border-success-shade:hover {
    border-color: var(--ion-color-success-shade)
  }

  .xl\:hover\:border-success-tint:hover {
    border-color: var(--ion-color-success-tint)
  }

  .xl\:hover\:border-warning:hover {
    border-color: var(--ion-color-warning)
  }

  .xl\:hover\:border-warning-shade:hover {
    border-color: var(--ion-color-warning-shade)
  }

  .xl\:hover\:border-warning-tint:hover {
    border-color: var(--ion-color-warning-tint)
  }

  .xl\:hover\:border-danger:hover {
    border-color: var(--ion-color-danger)
  }

  .xl\:hover\:border-danger-shade:hover {
    border-color: var(--ion-color-danger-shade)
  }

  .xl\:hover\:border-danger-tint:hover {
    border-color: var(--ion-color-danger-tint)
  }

  .xl\:hover\:border-step-50:hover {
    border-color: var(--ion-color-step-50)
  }

  .xl\:hover\:border-step-100:hover {
    border-color: var(--ion-color-step-100)
  }

  .xl\:hover\:border-step-150:hover {
    border-color: var(--ion-color-step-150)
  }

  .xl\:hover\:border-step-200:hover {
    border-color: var(--ion-color-step-200)
  }

  .xl\:hover\:border-step-250:hover {
    border-color: var(--ion-color-step-250)
  }

  .xl\:hover\:border-step-300:hover {
    border-color: var(--ion-color-step-300)
  }

  .xl\:hover\:border-step-350:hover {
    border-color: var(--ion-color-step-350)
  }

  .xl\:hover\:border-step-400:hover {
    border-color: var(--ion-color-step-400)
  }

  .xl\:hover\:border-step-450:hover {
    border-color: var(--ion-color-step-450)
  }

  .xl\:hover\:border-step-500:hover {
    border-color: var(--ion-color-step-500)
  }

  .xl\:hover\:border-step-550:hover {
    border-color: var(--ion-color-step-550)
  }

  .xl\:hover\:border-step-600:hover {
    border-color: var(--ion-color-step-600)
  }

  .xl\:hover\:border-step-650:hover {
    border-color: var(--ion-color-step-650)
  }

  .xl\:hover\:border-step-700:hover {
    border-color: var(--ion-color-step-700)
  }

  .xl\:hover\:border-step-750:hover {
    border-color: var(--ion-color-step-750)
  }

  .xl\:hover\:border-step-800:hover {
    border-color: var(--ion-color-step-800)
  }

  .xl\:hover\:border-step-850:hover {
    border-color: var(--ion-color-step-850)
  }

  .xl\:hover\:border-step-900:hover {
    border-color: var(--ion-color-step-900)
  }

  .xl\:hover\:border-step-950:hover {
    border-color: var(--ion-color-step-950)
  }

  .xl\:focus\:border-primary:focus {
    border-color: var(--ion-color-primary)
  }

  .xl\:focus\:border-primary-shade:focus {
    border-color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:border-primary-tint:focus {
    border-color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:border-secondary:focus {
    border-color: var(--ion-color-secondary)
  }

  .xl\:focus\:border-secondary-shade:focus {
    border-color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:border-secondary-tint:focus {
    border-color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:border-tertiary:focus {
    border-color: var(--ion-color-tertiary)
  }

  .xl\:focus\:border-tertiary-shade:focus {
    border-color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:border-tertiary-tint:focus {
    border-color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:border-light:focus {
    border-color: var(--ion-color-light)
  }

  .xl\:focus\:border-light-shade:focus {
    border-color: var(--ion-color-light-shade)
  }

  .xl\:focus\:border-light-tint:focus {
    border-color: var(--ion-color-light-tint)
  }

  .xl\:focus\:border-medium:focus {
    border-color: var(--ion-color-medium)
  }

  .xl\:focus\:border-medium-shade:focus {
    border-color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:border-medium-tint:focus {
    border-color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:border-dark:focus {
    border-color: var(--ion-color-dark)
  }

  .xl\:focus\:border-dark-shade:focus {
    border-color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:border-dark-tint:focus {
    border-color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:border-success:focus {
    border-color: var(--ion-color-success)
  }

  .xl\:focus\:border-success-shade:focus {
    border-color: var(--ion-color-success-shade)
  }

  .xl\:focus\:border-success-tint:focus {
    border-color: var(--ion-color-success-tint)
  }

  .xl\:focus\:border-warning:focus {
    border-color: var(--ion-color-warning)
  }

  .xl\:focus\:border-warning-shade:focus {
    border-color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:border-warning-tint:focus {
    border-color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:border-danger:focus {
    border-color: var(--ion-color-danger)
  }

  .xl\:focus\:border-danger-shade:focus {
    border-color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:border-danger-tint:focus {
    border-color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:border-step-50:focus {
    border-color: var(--ion-color-step-50)
  }

  .xl\:focus\:border-step-100:focus {
    border-color: var(--ion-color-step-100)
  }

  .xl\:focus\:border-step-150:focus {
    border-color: var(--ion-color-step-150)
  }

  .xl\:focus\:border-step-200:focus {
    border-color: var(--ion-color-step-200)
  }

  .xl\:focus\:border-step-250:focus {
    border-color: var(--ion-color-step-250)
  }

  .xl\:focus\:border-step-300:focus {
    border-color: var(--ion-color-step-300)
  }

  .xl\:focus\:border-step-350:focus {
    border-color: var(--ion-color-step-350)
  }

  .xl\:focus\:border-step-400:focus {
    border-color: var(--ion-color-step-400)
  }

  .xl\:focus\:border-step-450:focus {
    border-color: var(--ion-color-step-450)
  }

  .xl\:focus\:border-step-500:focus {
    border-color: var(--ion-color-step-500)
  }

  .xl\:focus\:border-step-550:focus {
    border-color: var(--ion-color-step-550)
  }

  .xl\:focus\:border-step-600:focus {
    border-color: var(--ion-color-step-600)
  }

  .xl\:focus\:border-step-650:focus {
    border-color: var(--ion-color-step-650)
  }

  .xl\:focus\:border-step-700:focus {
    border-color: var(--ion-color-step-700)
  }

  .xl\:focus\:border-step-750:focus {
    border-color: var(--ion-color-step-750)
  }

  .xl\:focus\:border-step-800:focus {
    border-color: var(--ion-color-step-800)
  }

  .xl\:focus\:border-step-850:focus {
    border-color: var(--ion-color-step-850)
  }

  .xl\:focus\:border-step-900:focus {
    border-color: var(--ion-color-step-900)
  }

  .xl\:focus\:border-step-950:focus {
    border-color: var(--ion-color-step-950)
  }

  .xl\:border-opacity-0 {
    --border-opacity: 0
  }

  .xl\:border-opacity-25 {
    --border-opacity: 0.25
  }

  .xl\:border-opacity-50 {
    --border-opacity: 0.5
  }

  .xl\:border-opacity-75 {
    --border-opacity: 0.75
  }

  .xl\:border-opacity-100 {
    --border-opacity: 1
  }

  .xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0
  }

  .xl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25
  }

  .xl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5
  }

  .xl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75
  }

  .xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1
  }

  .xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0
  }

  .xl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25
  }

  .xl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5
  }

  .xl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75
  }

  .xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1
  }

  .xl\:rounded-none {
    border-radius: 0
  }

  .xl\:rounded-sm {
    border-radius: 0.125rem
  }

  .xl\:rounded {
    border-radius: 0.25rem
  }

  .xl\:rounded-md {
    border-radius: 0.375rem
  }

  .xl\:rounded-lg {
    border-radius: 0.5rem
  }

  .xl\:rounded-xl {
    border-radius: 0.75rem
  }

  .xl\:rounded-2xl {
    border-radius: 1rem
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem
  }

  .xl\:rounded-full {
    border-radius: 9999px
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }

  .xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem
  }

  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem
  }

  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem
  }

  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem
  }

  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px
  }

  .xl\:border-solid {
    border-style: solid
  }

  .xl\:border-dashed {
    border-style: dashed
  }

  .xl\:border-dotted {
    border-style: dotted
  }

  .xl\:border-double {
    border-style: double
  }

  .xl\:border-none {
    border-style: none
  }

  .xl\:border-0 {
    border-width: 0
  }

  .xl\:border-2 {
    border-width: 2px
  }

  .xl\:border-4 {
    border-width: 4px
  }

  .xl\:border-8 {
    border-width: 8px
  }

  .xl\:border {
    border-width: 1px
  }

  .xl\:border-t-0 {
    border-top-width: 0
  }

  .xl\:border-r-0 {
    border-right-width: 0
  }

  .xl\:border-b-0 {
    border-bottom-width: 0
  }

  .xl\:border-l-0 {
    border-left-width: 0
  }

  .xl\:border-t-2 {
    border-top-width: 2px
  }

  .xl\:border-r-2 {
    border-right-width: 2px
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px
  }

  .xl\:border-l-2 {
    border-left-width: 2px
  }

  .xl\:border-t-4 {
    border-top-width: 4px
  }

  .xl\:border-r-4 {
    border-right-width: 4px
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px
  }

  .xl\:border-l-4 {
    border-left-width: 4px
  }

  .xl\:border-t-8 {
    border-top-width: 8px
  }

  .xl\:border-r-8 {
    border-right-width: 8px
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px
  }

  .xl\:border-l-8 {
    border-left-width: 8px
  }

  .xl\:border-t {
    border-top-width: 1px
  }

  .xl\:border-r {
    border-right-width: 1px
  }

  .xl\:border-b {
    border-bottom-width: 1px
  }

  .xl\:border-l {
    border-left-width: 1px
  }

  .xl\:box-border {
    box-sizing: border-box
  }

  .xl\:box-content {
    box-sizing: content-box
  }

  .xl\:cursor-auto {
    cursor: auto
  }

  .xl\:cursor-default {
    cursor: default
  }

  .xl\:cursor-pointer {
    cursor: pointer
  }

  .xl\:cursor-wait {
    cursor: wait
  }

  .xl\:cursor-text {
    cursor: text
  }

  .xl\:cursor-move {
    cursor: move
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed
  }

  .xl\:block {
    display: block
  }

  .xl\:inline-block {
    display: inline-block
  }

  .xl\:inline {
    display: inline
  }

  .xl\:flex {
    display: flex
  }

  .xl\:inline-flex {
    display: inline-flex
  }

  .xl\:table {
    display: table
  }

  .xl\:table-caption {
    display: table-caption
  }

  .xl\:table-cell {
    display: table-cell
  }

  .xl\:table-column {
    display: table-column
  }

  .xl\:table-column-group {
    display: table-column-group
  }

  .xl\:table-footer-group {
    display: table-footer-group
  }

  .xl\:table-header-group {
    display: table-header-group
  }

  .xl\:table-row-group {
    display: table-row-group
  }

  .xl\:table-row {
    display: table-row
  }

  .xl\:flow-root {
    display: flow-root
  }

  .xl\:grid {
    display: grid
  }

  .xl\:inline-grid {
    display: inline-grid
  }

  .xl\:contents {
    display: contents
  }

  .xl\:hidden {
    display: none
  }

  .xl\:flex-row {
    flex-direction: row
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .xl\:flex-col {
    flex-direction: column
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse
  }

  .xl\:flex-wrap {
    flex-wrap: wrap
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap
  }

  .xl\:place-items-auto {
    place-items: auto
  }

  .xl\:place-items-start {
    place-items: start
  }

  .xl\:place-items-end {
    place-items: end
  }

  .xl\:place-items-center {
    place-items: center
  }

  .xl\:place-items-stretch {
    place-items: stretch
  }

  .xl\:place-content-center {
    place-content: center
  }

  .xl\:place-content-start {
    place-content: start
  }

  .xl\:place-content-end {
    place-content: end
  }

  .xl\:place-content-between {
    place-content: space-between
  }

  .xl\:place-content-around {
    place-content: space-around
  }

  .xl\:place-content-evenly {
    place-content: space-evenly
  }

  .xl\:place-content-stretch {
    place-content: stretch
  }

  .xl\:place-self-auto {
    place-self: auto
  }

  .xl\:place-self-start {
    place-self: start
  }

  .xl\:place-self-end {
    place-self: end
  }

  .xl\:place-self-center {
    place-self: center
  }

  .xl\:place-self-stretch {
    place-self: stretch
  }

  .xl\:items-start {
    align-items: flex-start
  }

  .xl\:items-end {
    align-items: flex-end
  }

  .xl\:items-center {
    align-items: center
  }

  .xl\:items-baseline {
    align-items: baseline
  }

  .xl\:items-stretch {
    align-items: stretch
  }

  .xl\:content-center {
    align-content: center
  }

  .xl\:content-start {
    align-content: flex-start
  }

  .xl\:content-end {
    align-content: flex-end
  }

  .xl\:content-between {
    align-content: space-between
  }

  .xl\:content-around {
    align-content: space-around
  }

  .xl\:content-evenly {
    align-content: space-evenly
  }

  .xl\:self-auto {
    align-self: auto
  }

  .xl\:self-start {
    align-self: flex-start
  }

  .xl\:self-end {
    align-self: flex-end
  }

  .xl\:self-center {
    align-self: center
  }

  .xl\:self-stretch {
    align-self: stretch
  }

  .xl\:justify-items-auto {
    justify-items: auto
  }

  .xl\:justify-items-start {
    justify-items: start
  }

  .xl\:justify-items-end {
    justify-items: end
  }

  .xl\:justify-items-center {
    justify-items: center
  }

  .xl\:justify-items-stretch {
    justify-items: stretch
  }

  .xl\:justify-start {
    justify-content: flex-start
  }

  .xl\:justify-end {
    justify-content: flex-end
  }

  .xl\:justify-center {
    justify-content: center
  }

  .xl\:justify-between {
    justify-content: space-between
  }

  .xl\:justify-around {
    justify-content: space-around
  }

  .xl\:justify-evenly {
    justify-content: space-evenly
  }

  .xl\:justify-self-auto {
    justify-self: auto
  }

  .xl\:justify-self-start {
    justify-self: start
  }

  .xl\:justify-self-end {
    justify-self: end
  }

  .xl\:justify-self-center {
    justify-self: center
  }

  .xl\:justify-self-stretch {
    justify-self: stretch
  }

  .xl\:flex-1 {
    flex: 1 1 0%
  }

  .xl\:flex-auto {
    flex: 1 1 auto
  }

  .xl\:flex-initial {
    flex: 0 1 auto
  }

  .xl\:flex-none {
    flex: none
  }

  .xl\:flex-grow-0 {
    flex-grow: 0
  }

  .xl\:flex-grow {
    flex-grow: 1
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0
  }

  .xl\:flex-shrink {
    flex-shrink: 1
  }

  .xl\:order-1 {
    order: 1
  }

  .xl\:order-2 {
    order: 2
  }

  .xl\:order-3 {
    order: 3
  }

  .xl\:order-4 {
    order: 4
  }

  .xl\:order-5 {
    order: 5
  }

  .xl\:order-6 {
    order: 6
  }

  .xl\:order-7 {
    order: 7
  }

  .xl\:order-8 {
    order: 8
  }

  .xl\:order-9 {
    order: 9
  }

  .xl\:order-10 {
    order: 10
  }

  .xl\:order-11 {
    order: 11
  }

  .xl\:order-12 {
    order: 12
  }

  .xl\:order-first {
    order: -9999
  }

  .xl\:order-last {
    order: 9999
  }

  .xl\:order-none {
    order: 0
  }

  .xl\:float-right {
    float: right
  }

  .xl\:float-left {
    float: left
  }

  .xl\:float-none {
    float: none
  }

  .xl\:clearfix:after {
    content: "";
    display: table;
    clear: both
  }

  .xl\:clear-left {
    clear: left
  }

  .xl\:clear-right {
    clear: right
  }

  .xl\:clear-both {
    clear: both
  }

  .xl\:clear-none {
    clear: none
  }

  .xl\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif
  }

  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  }

  .xl\:font-hairline {
    font-weight: 100
  }

  .xl\:font-thin {
    font-weight: 200
  }

  .xl\:font-light {
    font-weight: 300
  }

  .xl\:font-normal {
    font-weight: 400
  }

  .xl\:font-medium {
    font-weight: 500
  }

  .xl\:font-semibold {
    font-weight: 600
  }

  .xl\:font-bold {
    font-weight: 700
  }

  .xl\:font-extrabold {
    font-weight: 800
  }

  .xl\:font-black {
    font-weight: 900
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900
  }

  .xl\:h-0 {
    height: 0
  }

  .xl\:h-1 {
    height: 0.25rem
  }

  .xl\:h-2 {
    height: 0.5rem
  }

  .xl\:h-3 {
    height: 0.75rem
  }

  .xl\:h-4 {
    height: 1rem
  }

  .xl\:h-5 {
    height: 1.25rem
  }

  .xl\:h-6 {
    height: 1.5rem
  }

  .xl\:h-8 {
    height: 2rem
  }

  .xl\:h-10 {
    height: 2.5rem
  }

  .xl\:h-12 {
    height: 3rem
  }

  .xl\:h-16 {
    height: 4rem
  }

  .xl\:h-20 {
    height: 5rem
  }

  .xl\:h-24 {
    height: 6rem
  }

  .xl\:h-32 {
    height: 8rem
  }

  .xl\:h-40 {
    height: 10rem
  }

  .xl\:h-48 {
    height: 12rem
  }

  .xl\:h-56 {
    height: 14rem
  }

  .xl\:h-64 {
    height: 16rem
  }

  .xl\:h-auto {
    height: auto
  }

  .xl\:h-px {
    height: 1px
  }

  .xl\:h-full {
    height: 100%
  }

  .xl\:h-screen {
    height: 100vh
  }

  .xl\:text-xs {
    font-size: 0.75rem
  }

  .xl\:text-sm {
    font-size: 0.875rem
  }

  .xl\:text-base {
    font-size: 1rem
  }

  .xl\:text-lg {
    font-size: 1.125rem
  }

  .xl\:text-xl {
    font-size: 1.25rem
  }

  .xl\:text-2xl {
    font-size: 1.5rem
  }

  .xl\:text-3xl {
    font-size: 1.875rem
  }

  .xl\:text-4xl {
    font-size: 2.25rem
  }

  .xl\:text-5xl {
    font-size: 3rem
  }

  .xl\:text-6xl {
    font-size: 4rem
  }

  .xl\:leading-3 {
    line-height: .75rem
  }

  .xl\:leading-4 {
    line-height: 1rem
  }

  .xl\:leading-5 {
    line-height: 1.25rem
  }

  .xl\:leading-6 {
    line-height: 1.5rem
  }

  .xl\:leading-7 {
    line-height: 1.75rem
  }

  .xl\:leading-8 {
    line-height: 2rem
  }

  .xl\:leading-9 {
    line-height: 2.25rem
  }

  .xl\:leading-10 {
    line-height: 2.5rem
  }

  .xl\:leading-none {
    line-height: 1
  }

  .xl\:leading-tight {
    line-height: 1.25
  }

  .xl\:leading-snug {
    line-height: 1.375
  }

  .xl\:leading-normal {
    line-height: 1.5
  }

  .xl\:leading-relaxed {
    line-height: 1.625
  }

  .xl\:leading-loose {
    line-height: 2
  }

  .xl\:list-inside {
    list-style-position: inside
  }

  .xl\:list-outside {
    list-style-position: outside
  }

  .xl\:list-none {
    list-style-type: none
  }

  .xl\:list-disc {
    list-style-type: disc
  }

  .xl\:list-decimal {
    list-style-type: decimal
  }

  .xl\:m-0 {
    margin: 0
  }

  .xl\:m-1 {
    margin: 0.25rem
  }

  .xl\:m-2 {
    margin: 0.5rem
  }

  .xl\:m-3 {
    margin: 0.75rem
  }

  .xl\:m-4 {
    margin: 1rem
  }

  .xl\:m-5 {
    margin: 1.25rem
  }

  .xl\:m-6 {
    margin: 1.5rem
  }

  .xl\:m-8 {
    margin: 2rem
  }

  .xl\:m-10 {
    margin: 2.5rem
  }

  .xl\:m-12 {
    margin: 3rem
  }

  .xl\:m-16 {
    margin: 4rem
  }

  .xl\:m-20 {
    margin: 5rem
  }

  .xl\:m-24 {
    margin: 6rem
  }

  .xl\:m-32 {
    margin: 8rem
  }

  .xl\:m-40 {
    margin: 10rem
  }

  .xl\:m-48 {
    margin: 12rem
  }

  .xl\:m-56 {
    margin: 14rem
  }

  .xl\:m-64 {
    margin: 16rem
  }

  .xl\:m-auto {
    margin: auto
  }

  .xl\:m-px {
    margin: 1px
  }

  .xl\:-m-1 {
    margin: -0.25rem
  }

  .xl\:-m-2 {
    margin: -0.5rem
  }

  .xl\:-m-3 {
    margin: -0.75rem
  }

  .xl\:-m-4 {
    margin: -1rem
  }

  .xl\:-m-5 {
    margin: -1.25rem
  }

  .xl\:-m-6 {
    margin: -1.5rem
  }

  .xl\:-m-8 {
    margin: -2rem
  }

  .xl\:-m-10 {
    margin: -2.5rem
  }

  .xl\:-m-12 {
    margin: -3rem
  }

  .xl\:-m-16 {
    margin: -4rem
  }

  .xl\:-m-20 {
    margin: -5rem
  }

  .xl\:-m-24 {
    margin: -6rem
  }

  .xl\:-m-32 {
    margin: -8rem
  }

  .xl\:-m-40 {
    margin: -10rem
  }

  .xl\:-m-48 {
    margin: -12rem
  }

  .xl\:-m-56 {
    margin: -14rem
  }

  .xl\:-m-64 {
    margin: -16rem
  }

  .xl\:-m-px {
    margin: -1px
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0
  }

  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }

  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
  }

  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
  }

  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px
  }

  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem
  }

  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem
  }

  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px
  }

  .xl\:mt-0 {
    margin-top: 0
  }

  .xl\:mr-0 {
    margin-right: 0
  }

  .xl\:mb-0 {
    margin-bottom: 0
  }

  .xl\:ml-0 {
    margin-left: 0
  }

  .xl\:mt-1 {
    margin-top: 0.25rem
  }

  .xl\:mr-1 {
    margin-right: 0.25rem
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem
  }

  .xl\:ml-1 {
    margin-left: 0.25rem
  }

  .xl\:mt-2 {
    margin-top: 0.5rem
  }

  .xl\:mr-2 {
    margin-right: 0.5rem
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem
  }

  .xl\:ml-2 {
    margin-left: 0.5rem
  }

  .xl\:mt-3 {
    margin-top: 0.75rem
  }

  .xl\:mr-3 {
    margin-right: 0.75rem
  }

  .xl\:mb-3 {
    margin-bottom: 0.75rem
  }

  .xl\:ml-3 {
    margin-left: 0.75rem
  }

  .xl\:mt-4 {
    margin-top: 1rem
  }

  .xl\:mr-4 {
    margin-right: 1rem
  }

  .xl\:mb-4 {
    margin-bottom: 1rem
  }

  .xl\:ml-4 {
    margin-left: 1rem
  }

  .xl\:mt-5 {
    margin-top: 1.25rem
  }

  .xl\:mr-5 {
    margin-right: 1.25rem
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem
  }

  .xl\:ml-5 {
    margin-left: 1.25rem
  }

  .xl\:mt-6 {
    margin-top: 1.5rem
  }

  .xl\:mr-6 {
    margin-right: 1.5rem
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem
  }

  .xl\:ml-6 {
    margin-left: 1.5rem
  }

  .xl\:mt-8 {
    margin-top: 2rem
  }

  .xl\:mr-8 {
    margin-right: 2rem
  }

  .xl\:mb-8 {
    margin-bottom: 2rem
  }

  .xl\:ml-8 {
    margin-left: 2rem
  }

  .xl\:mt-10 {
    margin-top: 2.5rem
  }

  .xl\:mr-10 {
    margin-right: 2.5rem
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem
  }

  .xl\:ml-10 {
    margin-left: 2.5rem
  }

  .xl\:mt-12 {
    margin-top: 3rem
  }

  .xl\:mr-12 {
    margin-right: 3rem
  }

  .xl\:mb-12 {
    margin-bottom: 3rem
  }

  .xl\:ml-12 {
    margin-left: 3rem
  }

  .xl\:mt-16 {
    margin-top: 4rem
  }

  .xl\:mr-16 {
    margin-right: 4rem
  }

  .xl\:mb-16 {
    margin-bottom: 4rem
  }

  .xl\:ml-16 {
    margin-left: 4rem
  }

  .xl\:mt-20 {
    margin-top: 5rem
  }

  .xl\:mr-20 {
    margin-right: 5rem
  }

  .xl\:mb-20 {
    margin-bottom: 5rem
  }

  .xl\:ml-20 {
    margin-left: 5rem
  }

  .xl\:mt-24 {
    margin-top: 6rem
  }

  .xl\:mr-24 {
    margin-right: 6rem
  }

  .xl\:mb-24 {
    margin-bottom: 6rem
  }

  .xl\:ml-24 {
    margin-left: 6rem
  }

  .xl\:mt-32 {
    margin-top: 8rem
  }

  .xl\:mr-32 {
    margin-right: 8rem
  }

  .xl\:mb-32 {
    margin-bottom: 8rem
  }

  .xl\:ml-32 {
    margin-left: 8rem
  }

  .xl\:mt-40 {
    margin-top: 10rem
  }

  .xl\:mr-40 {
    margin-right: 10rem
  }

  .xl\:mb-40 {
    margin-bottom: 10rem
  }

  .xl\:ml-40 {
    margin-left: 10rem
  }

  .xl\:mt-48 {
    margin-top: 12rem
  }

  .xl\:mr-48 {
    margin-right: 12rem
  }

  .xl\:mb-48 {
    margin-bottom: 12rem
  }

  .xl\:ml-48 {
    margin-left: 12rem
  }

  .xl\:mt-56 {
    margin-top: 14rem
  }

  .xl\:mr-56 {
    margin-right: 14rem
  }

  .xl\:mb-56 {
    margin-bottom: 14rem
  }

  .xl\:ml-56 {
    margin-left: 14rem
  }

  .xl\:mt-64 {
    margin-top: 16rem
  }

  .xl\:mr-64 {
    margin-right: 16rem
  }

  .xl\:mb-64 {
    margin-bottom: 16rem
  }

  .xl\:ml-64 {
    margin-left: 16rem
  }

  .xl\:mt-auto {
    margin-top: auto
  }

  .xl\:mr-auto {
    margin-right: auto
  }

  .xl\:mb-auto {
    margin-bottom: auto
  }

  .xl\:ml-auto {
    margin-left: auto
  }

  .xl\:mt-px {
    margin-top: 1px
  }

  .xl\:mr-px {
    margin-right: 1px
  }

  .xl\:mb-px {
    margin-bottom: 1px
  }

  .xl\:ml-px {
    margin-left: 1px
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem
  }

  .xl\:-mt-3 {
    margin-top: -0.75rem
  }

  .xl\:-mr-3 {
    margin-right: -0.75rem
  }

  .xl\:-mb-3 {
    margin-bottom: -0.75rem
  }

  .xl\:-ml-3 {
    margin-left: -0.75rem
  }

  .xl\:-mt-4 {
    margin-top: -1rem
  }

  .xl\:-mr-4 {
    margin-right: -1rem
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem
  }

  .xl\:-ml-4 {
    margin-left: -1rem
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem
  }

  .xl\:-mt-8 {
    margin-top: -2rem
  }

  .xl\:-mr-8 {
    margin-right: -2rem
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem
  }

  .xl\:-ml-8 {
    margin-left: -2rem
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem
  }

  .xl\:-mt-12 {
    margin-top: -3rem
  }

  .xl\:-mr-12 {
    margin-right: -3rem
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem
  }

  .xl\:-ml-12 {
    margin-left: -3rem
  }

  .xl\:-mt-16 {
    margin-top: -4rem
  }

  .xl\:-mr-16 {
    margin-right: -4rem
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem
  }

  .xl\:-ml-16 {
    margin-left: -4rem
  }

  .xl\:-mt-20 {
    margin-top: -5rem
  }

  .xl\:-mr-20 {
    margin-right: -5rem
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem
  }

  .xl\:-ml-20 {
    margin-left: -5rem
  }

  .xl\:-mt-24 {
    margin-top: -6rem
  }

  .xl\:-mr-24 {
    margin-right: -6rem
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem
  }

  .xl\:-ml-24 {
    margin-left: -6rem
  }

  .xl\:-mt-32 {
    margin-top: -8rem
  }

  .xl\:-mr-32 {
    margin-right: -8rem
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem
  }

  .xl\:-ml-32 {
    margin-left: -8rem
  }

  .xl\:-mt-40 {
    margin-top: -10rem
  }

  .xl\:-mr-40 {
    margin-right: -10rem
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem
  }

  .xl\:-ml-40 {
    margin-left: -10rem
  }

  .xl\:-mt-48 {
    margin-top: -12rem
  }

  .xl\:-mr-48 {
    margin-right: -12rem
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem
  }

  .xl\:-ml-48 {
    margin-left: -12rem
  }

  .xl\:-mt-56 {
    margin-top: -14rem
  }

  .xl\:-mr-56 {
    margin-right: -14rem
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem
  }

  .xl\:-ml-56 {
    margin-left: -14rem
  }

  .xl\:-mt-64 {
    margin-top: -16rem
  }

  .xl\:-mr-64 {
    margin-right: -16rem
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem
  }

  .xl\:-ml-64 {
    margin-left: -16rem
  }

  .xl\:-mt-px {
    margin-top: -1px
  }

  .xl\:-mr-px {
    margin-right: -1px
  }

  .xl\:-mb-px {
    margin-bottom: -1px
  }

  .xl\:-ml-px {
    margin-left: -1px
  }

  .xl\:max-h-full {
    max-height: 100%
  }

  .xl\:max-h-screen {
    max-height: 100vh
  }

  .xl\:max-w-none {
    max-width: none
  }

  .xl\:max-w-xs {
    max-width: 20rem
  }

  .xl\:max-w-sm {
    max-width: 24rem
  }

  .xl\:max-w-md {
    max-width: 28rem
  }

  .xl\:max-w-lg {
    max-width: 32rem
  }

  .xl\:max-w-xl {
    max-width: 36rem
  }

  .xl\:max-w-2xl {
    max-width: 42rem
  }

  .xl\:max-w-3xl {
    max-width: 48rem
  }

  .xl\:max-w-4xl {
    max-width: 56rem
  }

  .xl\:max-w-5xl {
    max-width: 64rem
  }

  .xl\:max-w-6xl {
    max-width: 72rem
  }

  .xl\:max-w-full {
    max-width: 100%
  }

  .xl\:max-w-screen-sm {
    max-width: 640px
  }

  .xl\:max-w-screen-md {
    max-width: 768px
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px
  }

  .xl\:min-h-0 {
    min-height: 0
  }

  .xl\:min-h-full {
    min-height: 100%
  }

  .xl\:min-h-screen {
    min-height: 100vh
  }

  .xl\:min-w-0 {
    min-width: 0
  }

  .xl\:min-w-full {
    min-width: 100%
  }

  .xl\:object-contain {
    -o-object-fit: contain;
       object-fit: contain
  }

  .xl\:object-cover {
    -o-object-fit: cover;
       object-fit: cover
  }

  .xl\:object-fill {
    -o-object-fit: fill;
       object-fit: fill
  }

  .xl\:object-none {
    -o-object-fit: none;
       object-fit: none
  }

  .xl\:object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down
  }

  .xl\:object-bottom {
    -o-object-position: bottom;
       object-position: bottom
  }

  .xl\:object-center {
    -o-object-position: center;
       object-position: center
  }

  .xl\:object-left {
    -o-object-position: left;
       object-position: left
  }

  .xl\:object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom
  }

  .xl\:object-left-top {
    -o-object-position: left top;
       object-position: left top
  }

  .xl\:object-right {
    -o-object-position: right;
       object-position: right
  }

  .xl\:object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom
  }

  .xl\:object-right-top {
    -o-object-position: right top;
       object-position: right top
  }

  .xl\:object-top {
    -o-object-position: top;
       object-position: top
  }

  .xl\:opacity-0 {
    opacity: 0
  }

  .xl\:opacity-25 {
    opacity: 0.25
  }

  .xl\:opacity-50 {
    opacity: 0.5
  }

  .xl\:opacity-75 {
    opacity: 0.75
  }

  .xl\:opacity-100 {
    opacity: 1
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1
  }

  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
  }

  .xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px
  }

  .xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px
  }

  .xl\:overflow-auto {
    overflow: auto
  }

  .xl\:overflow-hidden {
    overflow: hidden
  }

  .xl\:overflow-visible {
    overflow: visible
  }

  .xl\:overflow-scroll {
    overflow: scroll
  }

  .xl\:overflow-x-auto {
    overflow-x: auto
  }

  .xl\:overflow-y-auto {
    overflow-y: auto
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden
  }

  .xl\:overflow-x-visible {
    overflow-x: visible
  }

  .xl\:overflow-y-visible {
    overflow-y: visible
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain
  }

  .xl\:overscroll-none {
    overscroll-behavior: none
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none
  }

  .xl\:p-0 {
    padding: 0
  }

  .xl\:p-1 {
    padding: 0.25rem
  }

  .xl\:p-2 {
    padding: 0.5rem
  }

  .xl\:p-3 {
    padding: 0.75rem
  }

  .xl\:p-4 {
    padding: 1rem
  }

  .xl\:p-5 {
    padding: 1.25rem
  }

  .xl\:p-6 {
    padding: 1.5rem
  }

  .xl\:p-8 {
    padding: 2rem
  }

  .xl\:p-10 {
    padding: 2.5rem
  }

  .xl\:p-12 {
    padding: 3rem
  }

  .xl\:p-16 {
    padding: 4rem
  }

  .xl\:p-20 {
    padding: 5rem
  }

  .xl\:p-24 {
    padding: 6rem
  }

  .xl\:p-32 {
    padding: 8rem
  }

  .xl\:p-40 {
    padding: 10rem
  }

  .xl\:p-48 {
    padding: 12rem
  }

  .xl\:p-56 {
    padding: 14rem
  }

  .xl\:p-64 {
    padding: 16rem
  }

  .xl\:p-px {
    padding: 1px
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
  }

  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }

  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
  }

  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px
  }

  .xl\:pt-0 {
    padding-top: 0
  }

  .xl\:pr-0 {
    padding-right: 0
  }

  .xl\:pb-0 {
    padding-bottom: 0
  }

  .xl\:pl-0 {
    padding-left: 0
  }

  .xl\:pt-1 {
    padding-top: 0.25rem
  }

  .xl\:pr-1 {
    padding-right: 0.25rem
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem
  }

  .xl\:pl-1 {
    padding-left: 0.25rem
  }

  .xl\:pt-2 {
    padding-top: 0.5rem
  }

  .xl\:pr-2 {
    padding-right: 0.5rem
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem
  }

  .xl\:pl-2 {
    padding-left: 0.5rem
  }

  .xl\:pt-3 {
    padding-top: 0.75rem
  }

  .xl\:pr-3 {
    padding-right: 0.75rem
  }

  .xl\:pb-3 {
    padding-bottom: 0.75rem
  }

  .xl\:pl-3 {
    padding-left: 0.75rem
  }

  .xl\:pt-4 {
    padding-top: 1rem
  }

  .xl\:pr-4 {
    padding-right: 1rem
  }

  .xl\:pb-4 {
    padding-bottom: 1rem
  }

  .xl\:pl-4 {
    padding-left: 1rem
  }

  .xl\:pt-5 {
    padding-top: 1.25rem
  }

  .xl\:pr-5 {
    padding-right: 1.25rem
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem
  }

  .xl\:pl-5 {
    padding-left: 1.25rem
  }

  .xl\:pt-6 {
    padding-top: 1.5rem
  }

  .xl\:pr-6 {
    padding-right: 1.5rem
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem
  }

  .xl\:pl-6 {
    padding-left: 1.5rem
  }

  .xl\:pt-8 {
    padding-top: 2rem
  }

  .xl\:pr-8 {
    padding-right: 2rem
  }

  .xl\:pb-8 {
    padding-bottom: 2rem
  }

  .xl\:pl-8 {
    padding-left: 2rem
  }

  .xl\:pt-10 {
    padding-top: 2.5rem
  }

  .xl\:pr-10 {
    padding-right: 2.5rem
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem
  }

  .xl\:pl-10 {
    padding-left: 2.5rem
  }

  .xl\:pt-12 {
    padding-top: 3rem
  }

  .xl\:pr-12 {
    padding-right: 3rem
  }

  .xl\:pb-12 {
    padding-bottom: 3rem
  }

  .xl\:pl-12 {
    padding-left: 3rem
  }

  .xl\:pt-16 {
    padding-top: 4rem
  }

  .xl\:pr-16 {
    padding-right: 4rem
  }

  .xl\:pb-16 {
    padding-bottom: 4rem
  }

  .xl\:pl-16 {
    padding-left: 4rem
  }

  .xl\:pt-20 {
    padding-top: 5rem
  }

  .xl\:pr-20 {
    padding-right: 5rem
  }

  .xl\:pb-20 {
    padding-bottom: 5rem
  }

  .xl\:pl-20 {
    padding-left: 5rem
  }

  .xl\:pt-24 {
    padding-top: 6rem
  }

  .xl\:pr-24 {
    padding-right: 6rem
  }

  .xl\:pb-24 {
    padding-bottom: 6rem
  }

  .xl\:pl-24 {
    padding-left: 6rem
  }

  .xl\:pt-32 {
    padding-top: 8rem
  }

  .xl\:pr-32 {
    padding-right: 8rem
  }

  .xl\:pb-32 {
    padding-bottom: 8rem
  }

  .xl\:pl-32 {
    padding-left: 8rem
  }

  .xl\:pt-40 {
    padding-top: 10rem
  }

  .xl\:pr-40 {
    padding-right: 10rem
  }

  .xl\:pb-40 {
    padding-bottom: 10rem
  }

  .xl\:pl-40 {
    padding-left: 10rem
  }

  .xl\:pt-48 {
    padding-top: 12rem
  }

  .xl\:pr-48 {
    padding-right: 12rem
  }

  .xl\:pb-48 {
    padding-bottom: 12rem
  }

  .xl\:pl-48 {
    padding-left: 12rem
  }

  .xl\:pt-56 {
    padding-top: 14rem
  }

  .xl\:pr-56 {
    padding-right: 14rem
  }

  .xl\:pb-56 {
    padding-bottom: 14rem
  }

  .xl\:pl-56 {
    padding-left: 14rem
  }

  .xl\:pt-64 {
    padding-top: 16rem
  }

  .xl\:pr-64 {
    padding-right: 16rem
  }

  .xl\:pb-64 {
    padding-bottom: 16rem
  }

  .xl\:pl-64 {
    padding-left: 16rem
  }

  .xl\:pt-px {
    padding-top: 1px
  }

  .xl\:pr-px {
    padding-right: 1px
  }

  .xl\:pb-px {
    padding-bottom: 1px
  }

  .xl\:pl-px {
    padding-left: 1px
  }

  .xl\:placeholder-primary::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .xl\:placeholder-primary::placeholder {
    color: var(--ion-color-primary)
  }

  .xl\:placeholder-primary-shade::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .xl\:placeholder-primary-shade::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .xl\:placeholder-primary-tint::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .xl\:placeholder-primary-tint::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .xl\:placeholder-secondary::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .xl\:placeholder-secondary::placeholder {
    color: var(--ion-color-secondary)
  }

  .xl\:placeholder-secondary-shade::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:placeholder-secondary-shade::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:placeholder-secondary-tint::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:placeholder-secondary-tint::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:placeholder-tertiary::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .xl\:placeholder-tertiary::placeholder {
    color: var(--ion-color-tertiary)
  }

  .xl\:placeholder-tertiary-shade::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:placeholder-tertiary-shade::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:placeholder-tertiary-tint::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:placeholder-tertiary-tint::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:placeholder-light::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .xl\:placeholder-light::placeholder {
    color: var(--ion-color-light)
  }

  .xl\:placeholder-light-shade::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .xl\:placeholder-light-shade::placeholder {
    color: var(--ion-color-light-shade)
  }

  .xl\:placeholder-light-tint::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .xl\:placeholder-light-tint::placeholder {
    color: var(--ion-color-light-tint)
  }

  .xl\:placeholder-medium::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .xl\:placeholder-medium::placeholder {
    color: var(--ion-color-medium)
  }

  .xl\:placeholder-medium-shade::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .xl\:placeholder-medium-shade::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .xl\:placeholder-medium-tint::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .xl\:placeholder-medium-tint::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .xl\:placeholder-dark::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .xl\:placeholder-dark::placeholder {
    color: var(--ion-color-dark)
  }

  .xl\:placeholder-dark-shade::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .xl\:placeholder-dark-shade::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .xl\:placeholder-dark-tint::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .xl\:placeholder-dark-tint::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .xl\:placeholder-success::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .xl\:placeholder-success::placeholder {
    color: var(--ion-color-success)
  }

  .xl\:placeholder-success-shade::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .xl\:placeholder-success-shade::placeholder {
    color: var(--ion-color-success-shade)
  }

  .xl\:placeholder-success-tint::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .xl\:placeholder-success-tint::placeholder {
    color: var(--ion-color-success-tint)
  }

  .xl\:placeholder-warning::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .xl\:placeholder-warning::placeholder {
    color: var(--ion-color-warning)
  }

  .xl\:placeholder-warning-shade::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .xl\:placeholder-warning-shade::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .xl\:placeholder-warning-tint::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .xl\:placeholder-warning-tint::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .xl\:placeholder-danger::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .xl\:placeholder-danger::placeholder {
    color: var(--ion-color-danger)
  }

  .xl\:placeholder-danger-shade::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .xl\:placeholder-danger-shade::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .xl\:placeholder-danger-tint::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .xl\:placeholder-danger-tint::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .xl\:placeholder-step-50::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .xl\:placeholder-step-50::placeholder {
    color: var(--ion-color-step-50)
  }

  .xl\:placeholder-step-100::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .xl\:placeholder-step-100::placeholder {
    color: var(--ion-color-step-100)
  }

  .xl\:placeholder-step-150::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .xl\:placeholder-step-150::placeholder {
    color: var(--ion-color-step-150)
  }

  .xl\:placeholder-step-200::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .xl\:placeholder-step-200::placeholder {
    color: var(--ion-color-step-200)
  }

  .xl\:placeholder-step-250::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .xl\:placeholder-step-250::placeholder {
    color: var(--ion-color-step-250)
  }

  .xl\:placeholder-step-300::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .xl\:placeholder-step-300::placeholder {
    color: var(--ion-color-step-300)
  }

  .xl\:placeholder-step-350::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .xl\:placeholder-step-350::placeholder {
    color: var(--ion-color-step-350)
  }

  .xl\:placeholder-step-400::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .xl\:placeholder-step-400::placeholder {
    color: var(--ion-color-step-400)
  }

  .xl\:placeholder-step-450::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .xl\:placeholder-step-450::placeholder {
    color: var(--ion-color-step-450)
  }

  .xl\:placeholder-step-500::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .xl\:placeholder-step-500::placeholder {
    color: var(--ion-color-step-500)
  }

  .xl\:placeholder-step-550::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .xl\:placeholder-step-550::placeholder {
    color: var(--ion-color-step-550)
  }

  .xl\:placeholder-step-600::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .xl\:placeholder-step-600::placeholder {
    color: var(--ion-color-step-600)
  }

  .xl\:placeholder-step-650::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .xl\:placeholder-step-650::placeholder {
    color: var(--ion-color-step-650)
  }

  .xl\:placeholder-step-700::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .xl\:placeholder-step-700::placeholder {
    color: var(--ion-color-step-700)
  }

  .xl\:placeholder-step-750::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .xl\:placeholder-step-750::placeholder {
    color: var(--ion-color-step-750)
  }

  .xl\:placeholder-step-800::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .xl\:placeholder-step-800::placeholder {
    color: var(--ion-color-step-800)
  }

  .xl\:placeholder-step-850::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .xl\:placeholder-step-850::placeholder {
    color: var(--ion-color-step-850)
  }

  .xl\:placeholder-step-900::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .xl\:placeholder-step-900::placeholder {
    color: var(--ion-color-step-900)
  }

  .xl\:placeholder-step-950::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .xl\:placeholder-step-950::placeholder {
    color: var(--ion-color-step-950)
  }

  .xl\:focus\:placeholder-primary:focus::-moz-placeholder {
    color: var(--ion-color-primary)
  }

  .xl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--ion-color-primary)
  }

  .xl\:focus\:placeholder-primary-shade:focus::-moz-placeholder {
    color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:placeholder-primary-shade:focus::placeholder {
    color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:placeholder-primary-tint:focus::-moz-placeholder {
    color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:placeholder-primary-tint:focus::placeholder {
    color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:placeholder-secondary:focus::-moz-placeholder {
    color: var(--ion-color-secondary)
  }

  .xl\:focus\:placeholder-secondary:focus::placeholder {
    color: var(--ion-color-secondary)
  }

  .xl\:focus\:placeholder-secondary-shade:focus::-moz-placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:placeholder-secondary-shade:focus::placeholder {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:placeholder-secondary-tint:focus::-moz-placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:placeholder-secondary-tint:focus::placeholder {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:placeholder-tertiary:focus::-moz-placeholder {
    color: var(--ion-color-tertiary)
  }

  .xl\:focus\:placeholder-tertiary:focus::placeholder {
    color: var(--ion-color-tertiary)
  }

  .xl\:focus\:placeholder-tertiary-shade:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:placeholder-tertiary-shade:focus::placeholder {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:placeholder-tertiary-tint:focus::-moz-placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:placeholder-tertiary-tint:focus::placeholder {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:placeholder-light:focus::-moz-placeholder {
    color: var(--ion-color-light)
  }

  .xl\:focus\:placeholder-light:focus::placeholder {
    color: var(--ion-color-light)
  }

  .xl\:focus\:placeholder-light-shade:focus::-moz-placeholder {
    color: var(--ion-color-light-shade)
  }

  .xl\:focus\:placeholder-light-shade:focus::placeholder {
    color: var(--ion-color-light-shade)
  }

  .xl\:focus\:placeholder-light-tint:focus::-moz-placeholder {
    color: var(--ion-color-light-tint)
  }

  .xl\:focus\:placeholder-light-tint:focus::placeholder {
    color: var(--ion-color-light-tint)
  }

  .xl\:focus\:placeholder-medium:focus::-moz-placeholder {
    color: var(--ion-color-medium)
  }

  .xl\:focus\:placeholder-medium:focus::placeholder {
    color: var(--ion-color-medium)
  }

  .xl\:focus\:placeholder-medium-shade:focus::-moz-placeholder {
    color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:placeholder-medium-shade:focus::placeholder {
    color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:placeholder-medium-tint:focus::-moz-placeholder {
    color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:placeholder-medium-tint:focus::placeholder {
    color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:placeholder-dark:focus::-moz-placeholder {
    color: var(--ion-color-dark)
  }

  .xl\:focus\:placeholder-dark:focus::placeholder {
    color: var(--ion-color-dark)
  }

  .xl\:focus\:placeholder-dark-shade:focus::-moz-placeholder {
    color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:placeholder-dark-shade:focus::placeholder {
    color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:placeholder-dark-tint:focus::-moz-placeholder {
    color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:placeholder-dark-tint:focus::placeholder {
    color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:placeholder-success:focus::-moz-placeholder {
    color: var(--ion-color-success)
  }

  .xl\:focus\:placeholder-success:focus::placeholder {
    color: var(--ion-color-success)
  }

  .xl\:focus\:placeholder-success-shade:focus::-moz-placeholder {
    color: var(--ion-color-success-shade)
  }

  .xl\:focus\:placeholder-success-shade:focus::placeholder {
    color: var(--ion-color-success-shade)
  }

  .xl\:focus\:placeholder-success-tint:focus::-moz-placeholder {
    color: var(--ion-color-success-tint)
  }

  .xl\:focus\:placeholder-success-tint:focus::placeholder {
    color: var(--ion-color-success-tint)
  }

  .xl\:focus\:placeholder-warning:focus::-moz-placeholder {
    color: var(--ion-color-warning)
  }

  .xl\:focus\:placeholder-warning:focus::placeholder {
    color: var(--ion-color-warning)
  }

  .xl\:focus\:placeholder-warning-shade:focus::-moz-placeholder {
    color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:placeholder-warning-shade:focus::placeholder {
    color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:placeholder-warning-tint:focus::-moz-placeholder {
    color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:placeholder-warning-tint:focus::placeholder {
    color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:placeholder-danger:focus::-moz-placeholder {
    color: var(--ion-color-danger)
  }

  .xl\:focus\:placeholder-danger:focus::placeholder {
    color: var(--ion-color-danger)
  }

  .xl\:focus\:placeholder-danger-shade:focus::-moz-placeholder {
    color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:placeholder-danger-shade:focus::placeholder {
    color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:placeholder-danger-tint:focus::-moz-placeholder {
    color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:placeholder-danger-tint:focus::placeholder {
    color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:placeholder-step-50:focus::-moz-placeholder {
    color: var(--ion-color-step-50)
  }

  .xl\:focus\:placeholder-step-50:focus::placeholder {
    color: var(--ion-color-step-50)
  }

  .xl\:focus\:placeholder-step-100:focus::-moz-placeholder {
    color: var(--ion-color-step-100)
  }

  .xl\:focus\:placeholder-step-100:focus::placeholder {
    color: var(--ion-color-step-100)
  }

  .xl\:focus\:placeholder-step-150:focus::-moz-placeholder {
    color: var(--ion-color-step-150)
  }

  .xl\:focus\:placeholder-step-150:focus::placeholder {
    color: var(--ion-color-step-150)
  }

  .xl\:focus\:placeholder-step-200:focus::-moz-placeholder {
    color: var(--ion-color-step-200)
  }

  .xl\:focus\:placeholder-step-200:focus::placeholder {
    color: var(--ion-color-step-200)
  }

  .xl\:focus\:placeholder-step-250:focus::-moz-placeholder {
    color: var(--ion-color-step-250)
  }

  .xl\:focus\:placeholder-step-250:focus::placeholder {
    color: var(--ion-color-step-250)
  }

  .xl\:focus\:placeholder-step-300:focus::-moz-placeholder {
    color: var(--ion-color-step-300)
  }

  .xl\:focus\:placeholder-step-300:focus::placeholder {
    color: var(--ion-color-step-300)
  }

  .xl\:focus\:placeholder-step-350:focus::-moz-placeholder {
    color: var(--ion-color-step-350)
  }

  .xl\:focus\:placeholder-step-350:focus::placeholder {
    color: var(--ion-color-step-350)
  }

  .xl\:focus\:placeholder-step-400:focus::-moz-placeholder {
    color: var(--ion-color-step-400)
  }

  .xl\:focus\:placeholder-step-400:focus::placeholder {
    color: var(--ion-color-step-400)
  }

  .xl\:focus\:placeholder-step-450:focus::-moz-placeholder {
    color: var(--ion-color-step-450)
  }

  .xl\:focus\:placeholder-step-450:focus::placeholder {
    color: var(--ion-color-step-450)
  }

  .xl\:focus\:placeholder-step-500:focus::-moz-placeholder {
    color: var(--ion-color-step-500)
  }

  .xl\:focus\:placeholder-step-500:focus::placeholder {
    color: var(--ion-color-step-500)
  }

  .xl\:focus\:placeholder-step-550:focus::-moz-placeholder {
    color: var(--ion-color-step-550)
  }

  .xl\:focus\:placeholder-step-550:focus::placeholder {
    color: var(--ion-color-step-550)
  }

  .xl\:focus\:placeholder-step-600:focus::-moz-placeholder {
    color: var(--ion-color-step-600)
  }

  .xl\:focus\:placeholder-step-600:focus::placeholder {
    color: var(--ion-color-step-600)
  }

  .xl\:focus\:placeholder-step-650:focus::-moz-placeholder {
    color: var(--ion-color-step-650)
  }

  .xl\:focus\:placeholder-step-650:focus::placeholder {
    color: var(--ion-color-step-650)
  }

  .xl\:focus\:placeholder-step-700:focus::-moz-placeholder {
    color: var(--ion-color-step-700)
  }

  .xl\:focus\:placeholder-step-700:focus::placeholder {
    color: var(--ion-color-step-700)
  }

  .xl\:focus\:placeholder-step-750:focus::-moz-placeholder {
    color: var(--ion-color-step-750)
  }

  .xl\:focus\:placeholder-step-750:focus::placeholder {
    color: var(--ion-color-step-750)
  }

  .xl\:focus\:placeholder-step-800:focus::-moz-placeholder {
    color: var(--ion-color-step-800)
  }

  .xl\:focus\:placeholder-step-800:focus::placeholder {
    color: var(--ion-color-step-800)
  }

  .xl\:focus\:placeholder-step-850:focus::-moz-placeholder {
    color: var(--ion-color-step-850)
  }

  .xl\:focus\:placeholder-step-850:focus::placeholder {
    color: var(--ion-color-step-850)
  }

  .xl\:focus\:placeholder-step-900:focus::-moz-placeholder {
    color: var(--ion-color-step-900)
  }

  .xl\:focus\:placeholder-step-900:focus::placeholder {
    color: var(--ion-color-step-900)
  }

  .xl\:focus\:placeholder-step-950:focus::-moz-placeholder {
    color: var(--ion-color-step-950)
  }

  .xl\:focus\:placeholder-step-950:focus::placeholder {
    color: var(--ion-color-step-950)
  }

  .xl\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0
  }

  .xl\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0
  }

  .xl\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25
  }

  .xl\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5
  }

  .xl\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75
  }

  .xl\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1
  }

  .xl\:pointer-events-none {
    pointer-events: none
  }

  .xl\:pointer-events-auto {
    pointer-events: auto
  }

  .xl\:static {
    position: static
  }

  .xl\:fixed {
    position: fixed
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:relative {
    position: relative
  }

  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0
  }

  .xl\:inset-x-0 {
    right: 0;
    left: 0
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto
  }

  .xl\:inset-x-auto {
    right: auto;
    left: auto
  }

  .xl\:top-0 {
    top: 0
  }

  .xl\:right-0 {
    right: 0
  }

  .xl\:bottom-0 {
    bottom: 0
  }

  .xl\:left-0 {
    left: 0
  }

  .xl\:top-auto {
    top: auto
  }

  .xl\:right-auto {
    right: auto
  }

  .xl\:bottom-auto {
    bottom: auto
  }

  .xl\:left-auto {
    left: auto
  }

  .xl\:resize-none {
    resize: none
  }

  .xl\:resize-y {
    resize: vertical
  }

  .xl\:resize-x {
    resize: horizontal
  }

  .xl\:resize {
    resize: both
  }

  .xl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:shadow-none {
    box-shadow: none
  }

  .xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none
  }

  .xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
  }

  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
  }

  .xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
  }

  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
  }

  .xl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5)
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none
  }

  .xl\:fill-current {
    fill: currentColor
  }

  .xl\:stroke-current {
    stroke: currentColor
  }

  .xl\:stroke-0 {
    stroke-width: 0
  }

  .xl\:stroke-1 {
    stroke-width: 1
  }

  .xl\:stroke-2 {
    stroke-width: 2
  }

  .xl\:table-auto {
    table-layout: auto
  }

  .xl\:table-fixed {
    table-layout: fixed
  }

  .xl\:text-left {
    text-align: left
  }

  .xl\:text-center {
    text-align: center
  }

  .xl\:text-right {
    text-align: right
  }

  .xl\:text-justify {
    text-align: justify
  }

  .xl\:text-primary {
    color: var(--ion-color-primary)
  }

  .xl\:text-primary-shade {
    color: var(--ion-color-primary-shade)
  }

  .xl\:text-primary-tint {
    color: var(--ion-color-primary-tint)
  }

  .xl\:text-secondary {
    color: var(--ion-color-secondary)
  }

  .xl\:text-secondary-shade {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:text-secondary-tint {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:text-tertiary {
    color: var(--ion-color-tertiary)
  }

  .xl\:text-tertiary-shade {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:text-tertiary-tint {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:text-light {
    color: var(--ion-color-light)
  }

  .xl\:text-light-shade {
    color: var(--ion-color-light-shade)
  }

  .xl\:text-light-tint {
    color: var(--ion-color-light-tint)
  }

  .xl\:text-medium {
    color: var(--ion-color-medium)
  }

  .xl\:text-medium-shade {
    color: var(--ion-color-medium-shade)
  }

  .xl\:text-medium-tint {
    color: var(--ion-color-medium-tint)
  }

  .xl\:text-dark {
    color: var(--ion-color-dark)
  }

  .xl\:text-dark-shade {
    color: var(--ion-color-dark-shade)
  }

  .xl\:text-dark-tint {
    color: var(--ion-color-dark-tint)
  }

  .xl\:text-success {
    color: var(--ion-color-success)
  }

  .xl\:text-success-shade {
    color: var(--ion-color-success-shade)
  }

  .xl\:text-success-tint {
    color: var(--ion-color-success-tint)
  }

  .xl\:text-warning {
    color: var(--ion-color-warning)
  }

  .xl\:text-warning-shade {
    color: var(--ion-color-warning-shade)
  }

  .xl\:text-warning-tint {
    color: var(--ion-color-warning-tint)
  }

  .xl\:text-danger {
    color: var(--ion-color-danger)
  }

  .xl\:text-danger-shade {
    color: var(--ion-color-danger-shade)
  }

  .xl\:text-danger-tint {
    color: var(--ion-color-danger-tint)
  }

  .xl\:text-step-50 {
    color: var(--ion-color-step-50)
  }

  .xl\:text-step-100 {
    color: var(--ion-color-step-100)
  }

  .xl\:text-step-150 {
    color: var(--ion-color-step-150)
  }

  .xl\:text-step-200 {
    color: var(--ion-color-step-200)
  }

  .xl\:text-step-250 {
    color: var(--ion-color-step-250)
  }

  .xl\:text-step-300 {
    color: var(--ion-color-step-300)
  }

  .xl\:text-step-350 {
    color: var(--ion-color-step-350)
  }

  .xl\:text-step-400 {
    color: var(--ion-color-step-400)
  }

  .xl\:text-step-450 {
    color: var(--ion-color-step-450)
  }

  .xl\:text-step-500 {
    color: var(--ion-color-step-500)
  }

  .xl\:text-step-550 {
    color: var(--ion-color-step-550)
  }

  .xl\:text-step-600 {
    color: var(--ion-color-step-600)
  }

  .xl\:text-step-650 {
    color: var(--ion-color-step-650)
  }

  .xl\:text-step-700 {
    color: var(--ion-color-step-700)
  }

  .xl\:text-step-750 {
    color: var(--ion-color-step-750)
  }

  .xl\:text-step-800 {
    color: var(--ion-color-step-800)
  }

  .xl\:text-step-850 {
    color: var(--ion-color-step-850)
  }

  .xl\:text-step-900 {
    color: var(--ion-color-step-900)
  }

  .xl\:text-step-950 {
    color: var(--ion-color-step-950)
  }

  .xl\:hover\:text-primary:hover {
    color: var(--ion-color-primary)
  }

  .xl\:hover\:text-primary-shade:hover {
    color: var(--ion-color-primary-shade)
  }

  .xl\:hover\:text-primary-tint:hover {
    color: var(--ion-color-primary-tint)
  }

  .xl\:hover\:text-secondary:hover {
    color: var(--ion-color-secondary)
  }

  .xl\:hover\:text-secondary-shade:hover {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:hover\:text-secondary-tint:hover {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:hover\:text-tertiary:hover {
    color: var(--ion-color-tertiary)
  }

  .xl\:hover\:text-tertiary-shade:hover {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:hover\:text-tertiary-tint:hover {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:hover\:text-light:hover {
    color: var(--ion-color-light)
  }

  .xl\:hover\:text-light-shade:hover {
    color: var(--ion-color-light-shade)
  }

  .xl\:hover\:text-light-tint:hover {
    color: var(--ion-color-light-tint)
  }

  .xl\:hover\:text-medium:hover {
    color: var(--ion-color-medium)
  }

  .xl\:hover\:text-medium-shade:hover {
    color: var(--ion-color-medium-shade)
  }

  .xl\:hover\:text-medium-tint:hover {
    color: var(--ion-color-medium-tint)
  }

  .xl\:hover\:text-dark:hover {
    color: var(--ion-color-dark)
  }

  .xl\:hover\:text-dark-shade:hover {
    color: var(--ion-color-dark-shade)
  }

  .xl\:hover\:text-dark-tint:hover {
    color: var(--ion-color-dark-tint)
  }

  .xl\:hover\:text-success:hover {
    color: var(--ion-color-success)
  }

  .xl\:hover\:text-success-shade:hover {
    color: var(--ion-color-success-shade)
  }

  .xl\:hover\:text-success-tint:hover {
    color: var(--ion-color-success-tint)
  }

  .xl\:hover\:text-warning:hover {
    color: var(--ion-color-warning)
  }

  .xl\:hover\:text-warning-shade:hover {
    color: var(--ion-color-warning-shade)
  }

  .xl\:hover\:text-warning-tint:hover {
    color: var(--ion-color-warning-tint)
  }

  .xl\:hover\:text-danger:hover {
    color: var(--ion-color-danger)
  }

  .xl\:hover\:text-danger-shade:hover {
    color: var(--ion-color-danger-shade)
  }

  .xl\:hover\:text-danger-tint:hover {
    color: var(--ion-color-danger-tint)
  }

  .xl\:hover\:text-step-50:hover {
    color: var(--ion-color-step-50)
  }

  .xl\:hover\:text-step-100:hover {
    color: var(--ion-color-step-100)
  }

  .xl\:hover\:text-step-150:hover {
    color: var(--ion-color-step-150)
  }

  .xl\:hover\:text-step-200:hover {
    color: var(--ion-color-step-200)
  }

  .xl\:hover\:text-step-250:hover {
    color: var(--ion-color-step-250)
  }

  .xl\:hover\:text-step-300:hover {
    color: var(--ion-color-step-300)
  }

  .xl\:hover\:text-step-350:hover {
    color: var(--ion-color-step-350)
  }

  .xl\:hover\:text-step-400:hover {
    color: var(--ion-color-step-400)
  }

  .xl\:hover\:text-step-450:hover {
    color: var(--ion-color-step-450)
  }

  .xl\:hover\:text-step-500:hover {
    color: var(--ion-color-step-500)
  }

  .xl\:hover\:text-step-550:hover {
    color: var(--ion-color-step-550)
  }

  .xl\:hover\:text-step-600:hover {
    color: var(--ion-color-step-600)
  }

  .xl\:hover\:text-step-650:hover {
    color: var(--ion-color-step-650)
  }

  .xl\:hover\:text-step-700:hover {
    color: var(--ion-color-step-700)
  }

  .xl\:hover\:text-step-750:hover {
    color: var(--ion-color-step-750)
  }

  .xl\:hover\:text-step-800:hover {
    color: var(--ion-color-step-800)
  }

  .xl\:hover\:text-step-850:hover {
    color: var(--ion-color-step-850)
  }

  .xl\:hover\:text-step-900:hover {
    color: var(--ion-color-step-900)
  }

  .xl\:hover\:text-step-950:hover {
    color: var(--ion-color-step-950)
  }

  .xl\:focus\:text-primary:focus {
    color: var(--ion-color-primary)
  }

  .xl\:focus\:text-primary-shade:focus {
    color: var(--ion-color-primary-shade)
  }

  .xl\:focus\:text-primary-tint:focus {
    color: var(--ion-color-primary-tint)
  }

  .xl\:focus\:text-secondary:focus {
    color: var(--ion-color-secondary)
  }

  .xl\:focus\:text-secondary-shade:focus {
    color: var(--ion-color-secondary-shade)
  }

  .xl\:focus\:text-secondary-tint:focus {
    color: var(--ion-color-secondary-tint)
  }

  .xl\:focus\:text-tertiary:focus {
    color: var(--ion-color-tertiary)
  }

  .xl\:focus\:text-tertiary-shade:focus {
    color: var(--ion-color-tertiary-shade)
  }

  .xl\:focus\:text-tertiary-tint:focus {
    color: var(--ion-color-tertiary-tint)
  }

  .xl\:focus\:text-light:focus {
    color: var(--ion-color-light)
  }

  .xl\:focus\:text-light-shade:focus {
    color: var(--ion-color-light-shade)
  }

  .xl\:focus\:text-light-tint:focus {
    color: var(--ion-color-light-tint)
  }

  .xl\:focus\:text-medium:focus {
    color: var(--ion-color-medium)
  }

  .xl\:focus\:text-medium-shade:focus {
    color: var(--ion-color-medium-shade)
  }

  .xl\:focus\:text-medium-tint:focus {
    color: var(--ion-color-medium-tint)
  }

  .xl\:focus\:text-dark:focus {
    color: var(--ion-color-dark)
  }

  .xl\:focus\:text-dark-shade:focus {
    color: var(--ion-color-dark-shade)
  }

  .xl\:focus\:text-dark-tint:focus {
    color: var(--ion-color-dark-tint)
  }

  .xl\:focus\:text-success:focus {
    color: var(--ion-color-success)
  }

  .xl\:focus\:text-success-shade:focus {
    color: var(--ion-color-success-shade)
  }

  .xl\:focus\:text-success-tint:focus {
    color: var(--ion-color-success-tint)
  }

  .xl\:focus\:text-warning:focus {
    color: var(--ion-color-warning)
  }

  .xl\:focus\:text-warning-shade:focus {
    color: var(--ion-color-warning-shade)
  }

  .xl\:focus\:text-warning-tint:focus {
    color: var(--ion-color-warning-tint)
  }

  .xl\:focus\:text-danger:focus {
    color: var(--ion-color-danger)
  }

  .xl\:focus\:text-danger-shade:focus {
    color: var(--ion-color-danger-shade)
  }

  .xl\:focus\:text-danger-tint:focus {
    color: var(--ion-color-danger-tint)
  }

  .xl\:focus\:text-step-50:focus {
    color: var(--ion-color-step-50)
  }

  .xl\:focus\:text-step-100:focus {
    color: var(--ion-color-step-100)
  }

  .xl\:focus\:text-step-150:focus {
    color: var(--ion-color-step-150)
  }

  .xl\:focus\:text-step-200:focus {
    color: var(--ion-color-step-200)
  }

  .xl\:focus\:text-step-250:focus {
    color: var(--ion-color-step-250)
  }

  .xl\:focus\:text-step-300:focus {
    color: var(--ion-color-step-300)
  }

  .xl\:focus\:text-step-350:focus {
    color: var(--ion-color-step-350)
  }

  .xl\:focus\:text-step-400:focus {
    color: var(--ion-color-step-400)
  }

  .xl\:focus\:text-step-450:focus {
    color: var(--ion-color-step-450)
  }

  .xl\:focus\:text-step-500:focus {
    color: var(--ion-color-step-500)
  }

  .xl\:focus\:text-step-550:focus {
    color: var(--ion-color-step-550)
  }

  .xl\:focus\:text-step-600:focus {
    color: var(--ion-color-step-600)
  }

  .xl\:focus\:text-step-650:focus {
    color: var(--ion-color-step-650)
  }

  .xl\:focus\:text-step-700:focus {
    color: var(--ion-color-step-700)
  }

  .xl\:focus\:text-step-750:focus {
    color: var(--ion-color-step-750)
  }

  .xl\:focus\:text-step-800:focus {
    color: var(--ion-color-step-800)
  }

  .xl\:focus\:text-step-850:focus {
    color: var(--ion-color-step-850)
  }

  .xl\:focus\:text-step-900:focus {
    color: var(--ion-color-step-900)
  }

  .xl\:focus\:text-step-950:focus {
    color: var(--ion-color-step-950)
  }

  .xl\:italic {
    font-style: italic
  }

  .xl\:not-italic {
    font-style: normal
  }

  .xl\:uppercase {
    text-transform: uppercase
  }

  .xl\:lowercase {
    text-transform: lowercase
  }

  .xl\:capitalize {
    text-transform: capitalize
  }

  .xl\:normal-case {
    text-transform: none
  }

  .xl\:underline {
    text-decoration: underline
  }

  .xl\:line-through {
    text-decoration: line-through
  }

  .xl\:no-underline {
    text-decoration: none
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction)
  }

  .xl\:normal-nums {
    font-variant-numeric: normal
  }

  .xl\:ordinal {
    --font-variant-numeric-ordinal: ordinal
  }

  .xl\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero
  }

  .xl\:lining-nums {
    --font-variant-numeric-figure: lining-nums
  }

  .xl\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums
  }

  .xl\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums
  }

  .xl\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums
  }

  .xl\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions
  }

  .xl\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em
  }

  .xl\:tracking-normal {
    letter-spacing: 0
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em
  }

  .xl\:select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none
  }

  .xl\:select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text
  }

  .xl\:select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto
  }

  .xl\:align-baseline {
    vertical-align: baseline
  }

  .xl\:align-top {
    vertical-align: top
  }

  .xl\:align-middle {
    vertical-align: middle
  }

  .xl\:align-bottom {
    vertical-align: bottom
  }

  .xl\:align-text-top {
    vertical-align: text-top
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom
  }

  .xl\:visible {
    visibility: visible
  }

  .xl\:invisible {
    visibility: hidden
  }

  .xl\:whitespace-normal {
    white-space: normal
  }

  .xl\:whitespace-no-wrap {
    white-space: nowrap
  }

  .xl\:whitespace-pre {
    white-space: pre
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap
  }

  .xl\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal
  }

  .xl\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word
  }

  .xl\:break-all {
    word-break: break-all
  }

  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .xl\:w-0 {
    width: 0
  }

  .xl\:w-1 {
    width: 0.25rem
  }

  .xl\:w-2 {
    width: 0.5rem
  }

  .xl\:w-3 {
    width: 0.75rem
  }

  .xl\:w-4 {
    width: 1rem
  }

  .xl\:w-5 {
    width: 1.25rem
  }

  .xl\:w-6 {
    width: 1.5rem
  }

  .xl\:w-8 {
    width: 2rem
  }

  .xl\:w-10 {
    width: 2.5rem
  }

  .xl\:w-12 {
    width: 3rem
  }

  .xl\:w-16 {
    width: 4rem
  }

  .xl\:w-20 {
    width: 5rem
  }

  .xl\:w-24 {
    width: 6rem
  }

  .xl\:w-32 {
    width: 8rem
  }

  .xl\:w-40 {
    width: 10rem
  }

  .xl\:w-48 {
    width: 12rem
  }

  .xl\:w-56 {
    width: 14rem
  }

  .xl\:w-64 {
    width: 16rem
  }

  .xl\:w-auto {
    width: auto
  }

  .xl\:w-px {
    width: 1px
  }

  .xl\:w-1\/2 {
    width: 50%
  }

  .xl\:w-1\/3 {
    width: 33.333333%
  }

  .xl\:w-2\/3 {
    width: 66.666667%
  }

  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-2\/4 {
    width: 50%
  }

  .xl\:w-3\/4 {
    width: 75%
  }

  .xl\:w-1\/5 {
    width: 20%
  }

  .xl\:w-2\/5 {
    width: 40%
  }

  .xl\:w-3\/5 {
    width: 60%
  }

  .xl\:w-4\/5 {
    width: 80%
  }

  .xl\:w-1\/6 {
    width: 16.666667%
  }

  .xl\:w-2\/6 {
    width: 33.333333%
  }

  .xl\:w-3\/6 {
    width: 50%
  }

  .xl\:w-4\/6 {
    width: 66.666667%
  }

  .xl\:w-5\/6 {
    width: 83.333333%
  }

  .xl\:w-1\/12 {
    width: 8.333333%
  }

  .xl\:w-2\/12 {
    width: 16.666667%
  }

  .xl\:w-3\/12 {
    width: 25%
  }

  .xl\:w-4\/12 {
    width: 33.333333%
  }

  .xl\:w-5\/12 {
    width: 41.666667%
  }

  .xl\:w-6\/12 {
    width: 50%
  }

  .xl\:w-7\/12 {
    width: 58.333333%
  }

  .xl\:w-8\/12 {
    width: 66.666667%
  }

  .xl\:w-9\/12 {
    width: 75%
  }

  .xl\:w-10\/12 {
    width: 83.333333%
  }

  .xl\:w-11\/12 {
    width: 91.666667%
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:w-screen {
    width: 100vw
  }

  .xl\:z-0 {
    z-index: 0
  }

  .xl\:z-10 {
    z-index: 10
  }

  .xl\:z-20 {
    z-index: 20
  }

  .xl\:z-30 {
    z-index: 30
  }

  .xl\:z-40 {
    z-index: 40
  }

  .xl\:z-50 {
    z-index: 50
  }

  .xl\:z-auto {
    z-index: auto
  }

  .xl\:gap-0 {
    grid-gap: 0;
    gap: 0
  }

  .xl\:gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem
  }

  .xl\:gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem
  }

  .xl\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem
  }

  .xl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem
  }

  .xl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
  }

  .xl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
  }

  .xl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem
  }

  .xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
  }

  .xl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem
  }

  .xl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem
  }

  .xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem
  }

  .xl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem
  }

  .xl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem
  }

  .xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem
  }

  .xl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem
  }

  .xl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem
  }

  .xl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem
  }

  .xl\:gap-px {
    grid-gap: 1px;
    gap: 1px
  }

  .xl\:col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .xl\:col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .xl\:col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .xl\:col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .xl\:col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .xl\:col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .xl\:col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .xl\:col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .xl\:col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .xl\:col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .xl\:col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .xl\:col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .xl\:col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .xl\:col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .xl\:col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .xl\:col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .xl\:col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .xl\:col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .xl\:col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0
  }

  .xl\:gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem
  }

  .xl\:gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
  }

  .xl\:gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem
  }

  .xl\:gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem
  }

  .xl\:gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem
  }

  .xl\:gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
  }

  .xl\:gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem
  }

  .xl\:gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem
  }

  .xl\:gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem
  }

  .xl\:gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem
  }

  .xl\:gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem
  }

  .xl\:gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem
  }

  .xl\:gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem
  }

  .xl\:gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem
  }

  .xl\:gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem
  }

  .xl\:gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px
  }

  .xl\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0
  }

  .xl\:gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem
  }

  .xl\:gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem
  }

  .xl\:gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem
  }

  .xl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
  }

  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
  }

  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
  }

  .xl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
  }

  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
  }

  .xl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
  }

  .xl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
  }

  .xl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
  }

  .xl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
  }

  .xl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
  }

  .xl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem
  }

  .xl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem
  }

  .xl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem
  }

  .xl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem
  }

  .xl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr))
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr))
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .xl\:grid-cols-none {
    grid-template-columns: none
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto
  }

  .xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content
  }

  .xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr)
  }

  .xl\:col-auto {
    grid-column: auto
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .xl\:col-span-full {
    grid-column: 1 / -1
  }

  .xl\:col-start-1 {
    grid-column-start: 1
  }

  .xl\:col-start-2 {
    grid-column-start: 2
  }

  .xl\:col-start-3 {
    grid-column-start: 3
  }

  .xl\:col-start-4 {
    grid-column-start: 4
  }

  .xl\:col-start-5 {
    grid-column-start: 5
  }

  .xl\:col-start-6 {
    grid-column-start: 6
  }

  .xl\:col-start-7 {
    grid-column-start: 7
  }

  .xl\:col-start-8 {
    grid-column-start: 8
  }

  .xl\:col-start-9 {
    grid-column-start: 9
  }

  .xl\:col-start-10 {
    grid-column-start: 10
  }

  .xl\:col-start-11 {
    grid-column-start: 11
  }

  .xl\:col-start-12 {
    grid-column-start: 12
  }

  .xl\:col-start-13 {
    grid-column-start: 13
  }

  .xl\:col-start-auto {
    grid-column-start: auto
  }

  .xl\:col-end-1 {
    grid-column-end: 1
  }

  .xl\:col-end-2 {
    grid-column-end: 2
  }

  .xl\:col-end-3 {
    grid-column-end: 3
  }

  .xl\:col-end-4 {
    grid-column-end: 4
  }

  .xl\:col-end-5 {
    grid-column-end: 5
  }

  .xl\:col-end-6 {
    grid-column-end: 6
  }

  .xl\:col-end-7 {
    grid-column-end: 7
  }

  .xl\:col-end-8 {
    grid-column-end: 8
  }

  .xl\:col-end-9 {
    grid-column-end: 9
  }

  .xl\:col-end-10 {
    grid-column-end: 10
  }

  .xl\:col-end-11 {
    grid-column-end: 11
  }

  .xl\:col-end-12 {
    grid-column-end: 12
  }

  .xl\:col-end-13 {
    grid-column-end: 13
  }

  .xl\:col-end-auto {
    grid-column-end: auto
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
  }

  .xl\:grid-rows-none {
    grid-template-rows: none
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto
  }

  .xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content
  }

  .xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr)
  }

  .xl\:row-auto {
    grid-row: auto
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6
  }

  .xl\:row-span-full {
    grid-row: 1 / -1
  }

  .xl\:row-start-1 {
    grid-row-start: 1
  }

  .xl\:row-start-2 {
    grid-row-start: 2
  }

  .xl\:row-start-3 {
    grid-row-start: 3
  }

  .xl\:row-start-4 {
    grid-row-start: 4
  }

  .xl\:row-start-5 {
    grid-row-start: 5
  }

  .xl\:row-start-6 {
    grid-row-start: 6
  }

  .xl\:row-start-7 {
    grid-row-start: 7
  }

  .xl\:row-start-auto {
    grid-row-start: auto
  }

  .xl\:row-end-1 {
    grid-row-end: 1
  }

  .xl\:row-end-2 {
    grid-row-end: 2
  }

  .xl\:row-end-3 {
    grid-row-end: 3
  }

  .xl\:row-end-4 {
    grid-row-end: 4
  }

  .xl\:row-end-5 {
    grid-row-end: 5
  }

  .xl\:row-end-6 {
    grid-row-end: 6
  }

  .xl\:row-end-7 {
    grid-row-end: 7
  }

  .xl\:row-end-auto {
    grid-row-end: auto
  }

  .xl\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
  }

  .xl\:transform-none {
    transform: none
  }

  .xl\:origin-center {
    transform-origin: center
  }

  .xl\:origin-top {
    transform-origin: top
  }

  .xl\:origin-top-right {
    transform-origin: top right
  }

  .xl\:origin-right {
    transform-origin: right
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right
  }

  .xl\:origin-bottom {
    transform-origin: bottom
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left
  }

  .xl\:origin-left {
    transform-origin: left
  }

  .xl\:origin-top-left {
    transform-origin: top left
  }

  .xl\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:scale-x-0 {
    --transform-scale-x: 0
  }

  .xl\:scale-x-50 {
    --transform-scale-x: .5
  }

  .xl\:scale-x-75 {
    --transform-scale-x: .75
  }

  .xl\:scale-x-90 {
    --transform-scale-x: .9
  }

  .xl\:scale-x-95 {
    --transform-scale-x: .95
  }

  .xl\:scale-x-100 {
    --transform-scale-x: 1
  }

  .xl\:scale-x-105 {
    --transform-scale-x: 1.05
  }

  .xl\:scale-x-110 {
    --transform-scale-x: 1.1
  }

  .xl\:scale-x-125 {
    --transform-scale-x: 1.25
  }

  .xl\:scale-x-150 {
    --transform-scale-x: 1.5
  }

  .xl\:scale-y-0 {
    --transform-scale-y: 0
  }

  .xl\:scale-y-50 {
    --transform-scale-y: .5
  }

  .xl\:scale-y-75 {
    --transform-scale-y: .75
  }

  .xl\:scale-y-90 {
    --transform-scale-y: .9
  }

  .xl\:scale-y-95 {
    --transform-scale-y: .95
  }

  .xl\:scale-y-100 {
    --transform-scale-y: 1
  }

  .xl\:scale-y-105 {
    --transform-scale-y: 1.05
  }

  .xl\:scale-y-110 {
    --transform-scale-y: 1.1
  }

  .xl\:scale-y-125 {
    --transform-scale-y: 1.25
  }

  .xl\:scale-y-150 {
    --transform-scale-y: 1.5
  }

  .xl\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:hover\:scale-x-0:hover {
    --transform-scale-x: 0
  }

  .xl\:hover\:scale-x-50:hover {
    --transform-scale-x: .5
  }

  .xl\:hover\:scale-x-75:hover {
    --transform-scale-x: .75
  }

  .xl\:hover\:scale-x-90:hover {
    --transform-scale-x: .9
  }

  .xl\:hover\:scale-x-95:hover {
    --transform-scale-x: .95
  }

  .xl\:hover\:scale-x-100:hover {
    --transform-scale-x: 1
  }

  .xl\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05
  }

  .xl\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1
  }

  .xl\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25
  }

  .xl\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5
  }

  .xl\:hover\:scale-y-0:hover {
    --transform-scale-y: 0
  }

  .xl\:hover\:scale-y-50:hover {
    --transform-scale-y: .5
  }

  .xl\:hover\:scale-y-75:hover {
    --transform-scale-y: .75
  }

  .xl\:hover\:scale-y-90:hover {
    --transform-scale-y: .9
  }

  .xl\:hover\:scale-y-95:hover {
    --transform-scale-y: .95
  }

  .xl\:hover\:scale-y-100:hover {
    --transform-scale-y: 1
  }

  .xl\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05
  }

  .xl\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1
  }

  .xl\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25
  }

  .xl\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5
  }

  .xl\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0
  }

  .xl\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5
  }

  .xl\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75
  }

  .xl\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9
  }

  .xl\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95
  }

  .xl\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1
  }

  .xl\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05
  }

  .xl\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1
  }

  .xl\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25
  }

  .xl\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5
  }

  .xl\:focus\:scale-x-0:focus {
    --transform-scale-x: 0
  }

  .xl\:focus\:scale-x-50:focus {
    --transform-scale-x: .5
  }

  .xl\:focus\:scale-x-75:focus {
    --transform-scale-x: .75
  }

  .xl\:focus\:scale-x-90:focus {
    --transform-scale-x: .9
  }

  .xl\:focus\:scale-x-95:focus {
    --transform-scale-x: .95
  }

  .xl\:focus\:scale-x-100:focus {
    --transform-scale-x: 1
  }

  .xl\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05
  }

  .xl\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1
  }

  .xl\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25
  }

  .xl\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5
  }

  .xl\:focus\:scale-y-0:focus {
    --transform-scale-y: 0
  }

  .xl\:focus\:scale-y-50:focus {
    --transform-scale-y: .5
  }

  .xl\:focus\:scale-y-75:focus {
    --transform-scale-y: .75
  }

  .xl\:focus\:scale-y-90:focus {
    --transform-scale-y: .9
  }

  .xl\:focus\:scale-y-95:focus {
    --transform-scale-y: .95
  }

  .xl\:focus\:scale-y-100:focus {
    --transform-scale-y: 1
  }

  .xl\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05
  }

  .xl\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1
  }

  .xl\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25
  }

  .xl\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5
  }

  .xl\:rotate-0 {
    --transform-rotate: 0
  }

  .xl\:rotate-1 {
    --transform-rotate: 1deg
  }

  .xl\:rotate-2 {
    --transform-rotate: 2deg
  }

  .xl\:rotate-3 {
    --transform-rotate: 3deg
  }

  .xl\:rotate-6 {
    --transform-rotate: 6deg
  }

  .xl\:rotate-12 {
    --transform-rotate: 12deg
  }

  .xl\:rotate-45 {
    --transform-rotate: 45deg
  }

  .xl\:rotate-90 {
    --transform-rotate: 90deg
  }

  .xl\:rotate-180 {
    --transform-rotate: 180deg
  }

  .xl\:-rotate-180 {
    --transform-rotate: -180deg
  }

  .xl\:-rotate-90 {
    --transform-rotate: -90deg
  }

  .xl\:-rotate-45 {
    --transform-rotate: -45deg
  }

  .xl\:-rotate-12 {
    --transform-rotate: -12deg
  }

  .xl\:-rotate-6 {
    --transform-rotate: -6deg
  }

  .xl\:-rotate-3 {
    --transform-rotate: -3deg
  }

  .xl\:-rotate-2 {
    --transform-rotate: -2deg
  }

  .xl\:-rotate-1 {
    --transform-rotate: -1deg
  }

  .xl\:hover\:rotate-0:hover {
    --transform-rotate: 0
  }

  .xl\:hover\:rotate-1:hover {
    --transform-rotate: 1deg
  }

  .xl\:hover\:rotate-2:hover {
    --transform-rotate: 2deg
  }

  .xl\:hover\:rotate-3:hover {
    --transform-rotate: 3deg
  }

  .xl\:hover\:rotate-6:hover {
    --transform-rotate: 6deg
  }

  .xl\:hover\:rotate-12:hover {
    --transform-rotate: 12deg
  }

  .xl\:hover\:rotate-45:hover {
    --transform-rotate: 45deg
  }

  .xl\:hover\:rotate-90:hover {
    --transform-rotate: 90deg
  }

  .xl\:hover\:rotate-180:hover {
    --transform-rotate: 180deg
  }

  .xl\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg
  }

  .xl\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg
  }

  .xl\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg
  }

  .xl\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg
  }

  .xl\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg
  }

  .xl\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg
  }

  .xl\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg
  }

  .xl\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg
  }

  .xl\:focus\:rotate-0:focus {
    --transform-rotate: 0
  }

  .xl\:focus\:rotate-1:focus {
    --transform-rotate: 1deg
  }

  .xl\:focus\:rotate-2:focus {
    --transform-rotate: 2deg
  }

  .xl\:focus\:rotate-3:focus {
    --transform-rotate: 3deg
  }

  .xl\:focus\:rotate-6:focus {
    --transform-rotate: 6deg
  }

  .xl\:focus\:rotate-12:focus {
    --transform-rotate: 12deg
  }

  .xl\:focus\:rotate-45:focus {
    --transform-rotate: 45deg
  }

  .xl\:focus\:rotate-90:focus {
    --transform-rotate: 90deg
  }

  .xl\:focus\:rotate-180:focus {
    --transform-rotate: 180deg
  }

  .xl\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg
  }

  .xl\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg
  }

  .xl\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg
  }

  .xl\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg
  }

  .xl\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg
  }

  .xl\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg
  }

  .xl\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg
  }

  .xl\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg
  }

  .xl\:translate-x-0 {
    --transform-translate-x: 0
  }

  .xl\:translate-x-1 {
    --transform-translate-x: 0.25rem
  }

  .xl\:translate-x-2 {
    --transform-translate-x: 0.5rem
  }

  .xl\:translate-x-3 {
    --transform-translate-x: 0.75rem
  }

  .xl\:translate-x-4 {
    --transform-translate-x: 1rem
  }

  .xl\:translate-x-5 {
    --transform-translate-x: 1.25rem
  }

  .xl\:translate-x-6 {
    --transform-translate-x: 1.5rem
  }

  .xl\:translate-x-8 {
    --transform-translate-x: 2rem
  }

  .xl\:translate-x-10 {
    --transform-translate-x: 2.5rem
  }

  .xl\:translate-x-12 {
    --transform-translate-x: 3rem
  }

  .xl\:translate-x-16 {
    --transform-translate-x: 4rem
  }

  .xl\:translate-x-20 {
    --transform-translate-x: 5rem
  }

  .xl\:translate-x-24 {
    --transform-translate-x: 6rem
  }

  .xl\:translate-x-32 {
    --transform-translate-x: 8rem
  }

  .xl\:translate-x-40 {
    --transform-translate-x: 10rem
  }

  .xl\:translate-x-48 {
    --transform-translate-x: 12rem
  }

  .xl\:translate-x-56 {
    --transform-translate-x: 14rem
  }

  .xl\:translate-x-64 {
    --transform-translate-x: 16rem
  }

  .xl\:translate-x-px {
    --transform-translate-x: 1px
  }

  .xl\:-translate-x-1 {
    --transform-translate-x: -0.25rem
  }

  .xl\:-translate-x-2 {
    --transform-translate-x: -0.5rem
  }

  .xl\:-translate-x-3 {
    --transform-translate-x: -0.75rem
  }

  .xl\:-translate-x-4 {
    --transform-translate-x: -1rem
  }

  .xl\:-translate-x-5 {
    --transform-translate-x: -1.25rem
  }

  .xl\:-translate-x-6 {
    --transform-translate-x: -1.5rem
  }

  .xl\:-translate-x-8 {
    --transform-translate-x: -2rem
  }

  .xl\:-translate-x-10 {
    --transform-translate-x: -2.5rem
  }

  .xl\:-translate-x-12 {
    --transform-translate-x: -3rem
  }

  .xl\:-translate-x-16 {
    --transform-translate-x: -4rem
  }

  .xl\:-translate-x-20 {
    --transform-translate-x: -5rem
  }

  .xl\:-translate-x-24 {
    --transform-translate-x: -6rem
  }

  .xl\:-translate-x-32 {
    --transform-translate-x: -8rem
  }

  .xl\:-translate-x-40 {
    --transform-translate-x: -10rem
  }

  .xl\:-translate-x-48 {
    --transform-translate-x: -12rem
  }

  .xl\:-translate-x-56 {
    --transform-translate-x: -14rem
  }

  .xl\:-translate-x-64 {
    --transform-translate-x: -16rem
  }

  .xl\:-translate-x-px {
    --transform-translate-x: -1px
  }

  .xl\:-translate-x-full {
    --transform-translate-x: -100%
  }

  .xl\:-translate-x-1\/2 {
    --transform-translate-x: -50%
  }

  .xl\:translate-x-1\/2 {
    --transform-translate-x: 50%
  }

  .xl\:translate-x-full {
    --transform-translate-x: 100%
  }

  .xl\:translate-y-0 {
    --transform-translate-y: 0
  }

  .xl\:translate-y-1 {
    --transform-translate-y: 0.25rem
  }

  .xl\:translate-y-2 {
    --transform-translate-y: 0.5rem
  }

  .xl\:translate-y-3 {
    --transform-translate-y: 0.75rem
  }

  .xl\:translate-y-4 {
    --transform-translate-y: 1rem
  }

  .xl\:translate-y-5 {
    --transform-translate-y: 1.25rem
  }

  .xl\:translate-y-6 {
    --transform-translate-y: 1.5rem
  }

  .xl\:translate-y-8 {
    --transform-translate-y: 2rem
  }

  .xl\:translate-y-10 {
    --transform-translate-y: 2.5rem
  }

  .xl\:translate-y-12 {
    --transform-translate-y: 3rem
  }

  .xl\:translate-y-16 {
    --transform-translate-y: 4rem
  }

  .xl\:translate-y-20 {
    --transform-translate-y: 5rem
  }

  .xl\:translate-y-24 {
    --transform-translate-y: 6rem
  }

  .xl\:translate-y-32 {
    --transform-translate-y: 8rem
  }

  .xl\:translate-y-40 {
    --transform-translate-y: 10rem
  }

  .xl\:translate-y-48 {
    --transform-translate-y: 12rem
  }

  .xl\:translate-y-56 {
    --transform-translate-y: 14rem
  }

  .xl\:translate-y-64 {
    --transform-translate-y: 16rem
  }

  .xl\:translate-y-px {
    --transform-translate-y: 1px
  }

  .xl\:-translate-y-1 {
    --transform-translate-y: -0.25rem
  }

  .xl\:-translate-y-2 {
    --transform-translate-y: -0.5rem
  }

  .xl\:-translate-y-3 {
    --transform-translate-y: -0.75rem
  }

  .xl\:-translate-y-4 {
    --transform-translate-y: -1rem
  }

  .xl\:-translate-y-5 {
    --transform-translate-y: -1.25rem
  }

  .xl\:-translate-y-6 {
    --transform-translate-y: -1.5rem
  }

  .xl\:-translate-y-8 {
    --transform-translate-y: -2rem
  }

  .xl\:-translate-y-10 {
    --transform-translate-y: -2.5rem
  }

  .xl\:-translate-y-12 {
    --transform-translate-y: -3rem
  }

  .xl\:-translate-y-16 {
    --transform-translate-y: -4rem
  }

  .xl\:-translate-y-20 {
    --transform-translate-y: -5rem
  }

  .xl\:-translate-y-24 {
    --transform-translate-y: -6rem
  }

  .xl\:-translate-y-32 {
    --transform-translate-y: -8rem
  }

  .xl\:-translate-y-40 {
    --transform-translate-y: -10rem
  }

  .xl\:-translate-y-48 {
    --transform-translate-y: -12rem
  }

  .xl\:-translate-y-56 {
    --transform-translate-y: -14rem
  }

  .xl\:-translate-y-64 {
    --transform-translate-y: -16rem
  }

  .xl\:-translate-y-px {
    --transform-translate-y: -1px
  }

  .xl\:-translate-y-full {
    --transform-translate-y: -100%
  }

  .xl\:-translate-y-1\/2 {
    --transform-translate-y: -50%
  }

  .xl\:translate-y-1\/2 {
    --transform-translate-y: 50%
  }

  .xl\:translate-y-full {
    --transform-translate-y: 100%
  }

  .xl\:hover\:translate-x-0:hover {
    --transform-translate-x: 0
  }

  .xl\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem
  }

  .xl\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem
  }

  .xl\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem
  }

  .xl\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem
  }

  .xl\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem
  }

  .xl\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem
  }

  .xl\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem
  }

  .xl\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem
  }

  .xl\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem
  }

  .xl\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem
  }

  .xl\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem
  }

  .xl\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem
  }

  .xl\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem
  }

  .xl\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem
  }

  .xl\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem
  }

  .xl\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem
  }

  .xl\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem
  }

  .xl\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px
  }

  .xl\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem
  }

  .xl\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem
  }

  .xl\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem
  }

  .xl\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem
  }

  .xl\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem
  }

  .xl\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem
  }

  .xl\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem
  }

  .xl\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem
  }

  .xl\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem
  }

  .xl\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem
  }

  .xl\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem
  }

  .xl\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem
  }

  .xl\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem
  }

  .xl\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem
  }

  .xl\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem
  }

  .xl\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem
  }

  .xl\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem
  }

  .xl\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px
  }

  .xl\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%
  }

  .xl\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%
  }

  .xl\:hover\:translate-y-0:hover {
    --transform-translate-y: 0
  }

  .xl\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem
  }

  .xl\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem
  }

  .xl\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem
  }

  .xl\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem
  }

  .xl\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem
  }

  .xl\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem
  }

  .xl\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem
  }

  .xl\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem
  }

  .xl\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem
  }

  .xl\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem
  }

  .xl\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem
  }

  .xl\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem
  }

  .xl\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem
  }

  .xl\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem
  }

  .xl\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem
  }

  .xl\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem
  }

  .xl\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem
  }

  .xl\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px
  }

  .xl\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem
  }

  .xl\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem
  }

  .xl\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem
  }

  .xl\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem
  }

  .xl\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem
  }

  .xl\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem
  }

  .xl\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem
  }

  .xl\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem
  }

  .xl\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem
  }

  .xl\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem
  }

  .xl\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem
  }

  .xl\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem
  }

  .xl\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem
  }

  .xl\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem
  }

  .xl\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem
  }

  .xl\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem
  }

  .xl\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem
  }

  .xl\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px
  }

  .xl\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%
  }

  .xl\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%
  }

  .xl\:focus\:translate-x-0:focus {
    --transform-translate-x: 0
  }

  .xl\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem
  }

  .xl\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem
  }

  .xl\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem
  }

  .xl\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem
  }

  .xl\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem
  }

  .xl\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem
  }

  .xl\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem
  }

  .xl\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem
  }

  .xl\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem
  }

  .xl\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem
  }

  .xl\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem
  }

  .xl\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem
  }

  .xl\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem
  }

  .xl\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem
  }

  .xl\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem
  }

  .xl\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem
  }

  .xl\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem
  }

  .xl\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px
  }

  .xl\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem
  }

  .xl\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem
  }

  .xl\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem
  }

  .xl\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem
  }

  .xl\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem
  }

  .xl\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem
  }

  .xl\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem
  }

  .xl\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem
  }

  .xl\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem
  }

  .xl\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem
  }

  .xl\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem
  }

  .xl\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem
  }

  .xl\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem
  }

  .xl\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem
  }

  .xl\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem
  }

  .xl\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem
  }

  .xl\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem
  }

  .xl\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px
  }

  .xl\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%
  }

  .xl\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%
  }

  .xl\:focus\:translate-y-0:focus {
    --transform-translate-y: 0
  }

  .xl\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem
  }

  .xl\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem
  }

  .xl\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem
  }

  .xl\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem
  }

  .xl\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem
  }

  .xl\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem
  }

  .xl\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem
  }

  .xl\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem
  }

  .xl\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem
  }

  .xl\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem
  }

  .xl\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem
  }

  .xl\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem
  }

  .xl\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem
  }

  .xl\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem
  }

  .xl\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem
  }

  .xl\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem
  }

  .xl\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem
  }

  .xl\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px
  }

  .xl\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem
  }

  .xl\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem
  }

  .xl\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem
  }

  .xl\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem
  }

  .xl\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem
  }

  .xl\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem
  }

  .xl\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem
  }

  .xl\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem
  }

  .xl\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem
  }

  .xl\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem
  }

  .xl\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem
  }

  .xl\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem
  }

  .xl\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem
  }

  .xl\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem
  }

  .xl\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem
  }

  .xl\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem
  }

  .xl\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem
  }

  .xl\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px
  }

  .xl\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%
  }

  .xl\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%
  }

  .xl\:skew-x-0 {
    --transform-skew-x: 0
  }

  .xl\:skew-x-1 {
    --transform-skew-x: 1deg
  }

  .xl\:skew-x-2 {
    --transform-skew-x: 2deg
  }

  .xl\:skew-x-3 {
    --transform-skew-x: 3deg
  }

  .xl\:skew-x-6 {
    --transform-skew-x: 6deg
  }

  .xl\:skew-x-12 {
    --transform-skew-x: 12deg
  }

  .xl\:-skew-x-12 {
    --transform-skew-x: -12deg
  }

  .xl\:-skew-x-6 {
    --transform-skew-x: -6deg
  }

  .xl\:-skew-x-3 {
    --transform-skew-x: -3deg
  }

  .xl\:-skew-x-2 {
    --transform-skew-x: -2deg
  }

  .xl\:-skew-x-1 {
    --transform-skew-x: -1deg
  }

  .xl\:skew-y-0 {
    --transform-skew-y: 0
  }

  .xl\:skew-y-1 {
    --transform-skew-y: 1deg
  }

  .xl\:skew-y-2 {
    --transform-skew-y: 2deg
  }

  .xl\:skew-y-3 {
    --transform-skew-y: 3deg
  }

  .xl\:skew-y-6 {
    --transform-skew-y: 6deg
  }

  .xl\:skew-y-12 {
    --transform-skew-y: 12deg
  }

  .xl\:-skew-y-12 {
    --transform-skew-y: -12deg
  }

  .xl\:-skew-y-6 {
    --transform-skew-y: -6deg
  }

  .xl\:-skew-y-3 {
    --transform-skew-y: -3deg
  }

  .xl\:-skew-y-2 {
    --transform-skew-y: -2deg
  }

  .xl\:-skew-y-1 {
    --transform-skew-y: -1deg
  }

  .xl\:hover\:skew-x-0:hover {
    --transform-skew-x: 0
  }

  .xl\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg
  }

  .xl\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg
  }

  .xl\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg
  }

  .xl\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg
  }

  .xl\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg
  }

  .xl\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg
  }

  .xl\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg
  }

  .xl\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg
  }

  .xl\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg
  }

  .xl\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg
  }

  .xl\:hover\:skew-y-0:hover {
    --transform-skew-y: 0
  }

  .xl\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg
  }

  .xl\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg
  }

  .xl\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg
  }

  .xl\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg
  }

  .xl\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg
  }

  .xl\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg
  }

  .xl\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg
  }

  .xl\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg
  }

  .xl\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg
  }

  .xl\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg
  }

  .xl\:focus\:skew-x-0:focus {
    --transform-skew-x: 0
  }

  .xl\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg
  }

  .xl\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg
  }

  .xl\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg
  }

  .xl\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg
  }

  .xl\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg
  }

  .xl\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg
  }

  .xl\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg
  }

  .xl\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg
  }

  .xl\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg
  }

  .xl\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg
  }

  .xl\:focus\:skew-y-0:focus {
    --transform-skew-y: 0
  }

  .xl\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg
  }

  .xl\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg
  }

  .xl\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg
  }

  .xl\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg
  }

  .xl\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg
  }

  .xl\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg
  }

  .xl\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg
  }

  .xl\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg
  }

  .xl\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg
  }

  .xl\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg
  }

  .xl\:transition-none {
    transition-property: none
  }

  .xl\:transition-all {
    transition-property: all
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke
  }

  .xl\:transition-opacity {
    transition-property: opacity
  }

  .xl\:transition-shadow {
    transition-property: box-shadow
  }

  .xl\:transition-transform {
    transition-property: transform
  }

  .xl\:ease-linear {
    transition-timing-function: linear
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
  }

  .xl\:duration-75 {
    transition-duration: 75ms
  }

  .xl\:duration-100 {
    transition-duration: 100ms
  }

  .xl\:duration-150 {
    transition-duration: 150ms
  }

  .xl\:duration-200 {
    transition-duration: 200ms
  }

  .xl\:duration-300 {
    transition-duration: 300ms
  }

  .xl\:duration-500 {
    transition-duration: 500ms
  }

  .xl\:duration-700 {
    transition-duration: 700ms
  }

  .xl\:duration-1000 {
    transition-duration: 1000ms
  }

  .xl\:delay-75 {
    transition-delay: 75ms
  }

  .xl\:delay-100 {
    transition-delay: 100ms
  }

  .xl\:delay-150 {
    transition-delay: 150ms
  }

  .xl\:delay-200 {
    transition-delay: 200ms
  }

  .xl\:delay-300 {
    transition-delay: 300ms
  }

  .xl\:delay-500 {
    transition-delay: 500ms
  }

  .xl\:delay-700 {
    transition-delay: 700ms
  }

  .xl\:delay-1000 {
    transition-delay: 1000ms
  }

  .xl\:animate-none {
    -webkit-animation: none;
            animation: none
  }

  .xl\:animate-spin {
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite
  }

  .xl\:animate-ping {
    -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite
  }

  .xl\:animate-pulse {
    -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
  }

  .xl\:animate-bounce {
    -webkit-animation: bounce 1s infinite;
            animation: bounce 1s infinite
  }
}

.panel.settingsAccordion .panel.card.panel-default .panel-heading.card-header.panel-enabled {
  border: 1px solid var(--ion-color-primary);
}

.panel.settingsAccordion .panel-title {
  padding-left: 5px !important;
  padding: 7px;
}

.panel.customClass1 .panel.card.panel-default .panel-heading.card-header.panel-enabled .panel-title .accordion-toggle button.btn.btn-link {
  font-weight: 600 !important;
}

.panel.customClass1 .panel.card.panel-default .panel-heading.card-header.panel-enabled {
  border-bottom: 1.5px solid #f0b71c;
}

.panel.customClass1 .panel.card.panel-default .panel-heading.card-header.panel-enabled .panel-title .accordion-toggle {
  padding-left: 2.5rem; 
}

.panel.customClass1 .panel-body.card-block.card-body div {
  padding-left: 5rem;
  border-bottom: 1.5px solid lightgray;
}

.panel.customClass .panel-title {
  padding-left: 5px !important;
  padding: 2px;
}

.accordion-toggle[aria-expanded="true"]:before {
  content: "\2212";
  font: normal normal normal 14px/1 FontAwesome;
  padding-right: 2px;
}

.accordion-toggle[aria-expanded="false"]:before {
  content: '\002B';
  font: normal normal normal 14px/1 FontAwesome;
  padding-right: 2px;
}

button.btn.btn-link:focus {
  outline: 0 !important;
}